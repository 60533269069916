import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import WatchlistButton from '../../components/watchlist-button/watchlist-button';
import { isFeatureFlagEnabled } from '../../utils/feature-flags';
import { getSignedInUser } from '../../utils/signed-in-user';

// hooks
import { api } from '../../crackle-sdk/v2';
import mParticle from '../../utils/mparticle';

function WatchlistButtonContainer({
  contentId,
  onContentAdded,
  onContentRemoved,
  inWatchlistLabel,
  notInWatchlistLabel,
  onClick,
  forceAddToWatchlist,
  buttonPosition,
  pageSection,
  ...buttonProps
}) {
  const signedInUser = isFeatureFlagEnabled('auth') ? getSignedInUser() : null;
  const userId = signedInUser?.userId || null;

  const { isLoading, addToWatchlist, removeFromWatchlist, isInWatchlist } =
    api.useWatchlistActions(
      userId,
      contentId,
      onContentAdded,
      onContentRemoved
    );

  async function onWatchlistClickHandler(e) {
    if (isInWatchlist) {
      removeFromWatchlist();
    } else {
      addToWatchlist();
    }

    mParticle.mParticleWatchlistButtonClick({
      contentId,
      buttonLabel: (e.target?.innerText && e.target?.innerText.trim()) || '',
      buttonPosition,
      pageSection,
    });
  }

  const userForcesAddingContent =
    forceAddToWatchlist && userId && !isLoading && !isInWatchlist;

  if (userForcesAddingContent) {
    const forceAddFunction = getClickHandler();
    forceAddFunction();
  }

  // determines wich handler to use
  function getClickHandler() {
    let handlerFunction = null;

    if (userId) {
      // if onClick prop is defined will override default action
      // we only need to override it when it is an episode to show the overlay
      // only when adding the episode to the watchlist

      if (!isInWatchlist && onClick) {
        handlerFunction = () => {
          onClick(isInWatchlist);
        };
      } else {
        handlerFunction = onWatchlistClickHandler;
      }
    } else {
      handlerFunction = onClick;
    }

    return handlerFunction;
  }

  return (
    <WatchlistButton
      onClick={getClickHandler()}
      isInWatchlist={isInWatchlist}
      inWatchlistLabel={inWatchlistLabel || t({ id: 'IN_WATCHLIST' })}
      notInWatchlistLabel={notInWatchlistLabel || t({ id: 'WATCHLIST_ADD' })}
      disabled={isLoading}
      {...buttonProps}
    />
  );
}

WatchlistButtonContainer.propTypes = {
  contentId: PropTypes.string.isRequired,
  onContentAdded: PropTypes.func,
  onContentRemoved: PropTypes.func,
  inWatchlistLabel: PropTypes.string,
  notInWatchlistLabel: PropTypes.string,
  forceAddToWatchlist: PropTypes.bool,
  // set onClick action to override default action
  onClick: PropTypes.func,
  buttonPosition: PropTypes.number,
  pageSection: PropTypes.string,
};

WatchlistButtonContainer.defaultProps = {
  onClick: null,
  inWatchlistLabel: null,
  notInWatchlistLabel: null,
  forceAddToWatchlist: false,
};

export default WatchlistButtonContainer;
