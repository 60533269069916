import Genre from '../entities/genre';
import Rating from '../entities/rating';
import ImageAsset from '../entities/imageAsset';
import { getConfig } from '../../configuration';
import CONTENT_ROW_TYPES from '../enums/content-row-types';

const { locale, region } = getConfig();

function getMetadataByLocale(content) {
  const metadataByLocale = content.metadata.find(
    (m) => m.locale.toLowerCase() === locale.toLowerCase()
  );
  return metadataByLocale;
}

function getGenresByLocale(content) {
  const catalogByLocale = content.catalog.find(
    (c) => c.locale.toLowerCase() === locale.toLowerCase()
  );

  const genres = catalogByLocale?.categories.map(
    (genre) =>
      new Genre({
        id: genre.id,
        name: genre.name,
        label: genre.label,
      })
  );

  return genres || [];
}

function getCreditsByLocale(content) {
  const creditsByLocale = content.credits.find(
    (c) => c.locale.toLowerCase() === locale.toLowerCase()
  );

  return creditsByLocale;
}

function getRatingsByRegion(content) {
  const ratingsByRegion = content.rating.find(
    (r) => r.region.toLowerCase() === region.toLowerCase()
  );

  return ratingsByRegion
    ? new Rating({
        id: ratingsByRegion.id,
        region: ratingsByRegion.region,
        name: ratingsByRegion.rating,
        age: ratingsByRegion.ratingAge,
      })
    : null;
}

function imageMeetsSizeRequirements(image, imageSizes) {
  return imageSizes.some((imageSize) => {
    return image.width === imageSize.width && image.height === imageSize.height;
  });
}

function getImagesBySizeRequirements(images, imageSizes) {
  let filteredImages = images;

  if (imageSizes && imageSizes?.length > 0) {
    filteredImages = images.filter((image) => {
      const meetsRequirement = imageMeetsSizeRequirements(image, imageSizes);
      return meetsRequirement;
    });
  }

  return filteredImages.map((image) => new ImageAsset(image));
}

function getPosterImageForGrid(images) {
  const screenWidth = window.innerWidth;
  if (images) {
    if (
      screenWidth > 1920 &&
      images?.find((i) => i.width === 520 && i.height === 790)?.length
    ) {
      return images.find((i) => i.width === 520 && i.height === 790).url;
    }
    if (
      screenWidth > 1280 &&
      images?.find((i) => i.width === 320 && i.height === 480)?.length
    ) {
      return images.find((i) => i.width === 320 && i.height === 480).url;
    }
    return images?.find((i) => i.width === 220 && i.height === 330)?.url;
  }

  return null;
}

function getBlockBackgroundImage(images) {
  const screenWidth = window.innerWidth;

  if (images) {
    if (
      screenWidth >= 1920 &&
      images?.find((i) => i.width === 1920 && i.height === 1080)
    ) {
      return images.find((i) => i.width === 1920 && i.height === 1080).url;
    }
    if (
      screenWidth >= 1280 &&
      images?.find((i) => i.width === 1280 && i.height === 720)
    ) {
      return images.find((i) => i.width === 1280 && i.height === 720).url;
    }
  }

  return images?.find((i) => i.width === 1280 && i.height === 720)?.url;
}

function getPosterImageForCollection(images) {
  const screenWidth = window.innerWidth;

  if (
    screenWidth >= 1920 &&
    images?.find((i) => i.width === 800 && i.height === 1200)
  ) {
    return images.find((i) => i.width === 800 && i.height === 1200).url;
  }

  if (
    screenWidth >= 1920 &&
    images?.find((i) => i.width === 520 && i.height === 790)
  ) {
    return images.find((i) => i.width === 520 && i.height === 790).url;
  }

  if (
    screenWidth >= 1280 &&
    images?.find((i) => i.width === 320 && i.height === 480)
  ) {
    return images.find((i) => i.width === 320 && i.height === 480).url;
  }

  return null;
}

function getPosterImageForContentRow(contentType, images) {
  const screenWidth = window.innerWidth;
  if (images.length > 0) {
    switch (contentType) {
      case CONTENT_ROW_TYPES.STANDARD:
        if (
          screenWidth >= 1920 &&
          images.find((i) => i.width === 520 && i.height === 790)
        ) {
          return images.find((i) => i.width === 520 && i.height === 790);
        }
        if (
          screenWidth >= 1920 &&
          images.find((i) => i.width === 800 && i.height === 1200)
        ) {
          return images.find((i) => i.width === 800 && i.height === 1200);
        }
        if (
          screenWidth >= 1280 &&
          images.find((i) => i.width === 320 && i.height === 480)
        ) {
          return images.find((i) => i.width === 320 && i.height === 480);
        }
        return images.find((i) => i.width === 220 && i.height === 330);

      case CONTENT_ROW_TYPES.DETAILS:
        if (
          screenWidth >= 1920 &&
          images.find((i) => i.width === 1920 && i.height === 1080)
        ) {
          return images.find((i) => i.width === 1920 && i.height === 1080);
        }
        if (
          screenWidth >= 1280 &&
          images.find((i) => i.width === 806 && i.height === 453)
        ) {
          return images.find((i) => i.width === 806 && i.height === 453);
        }
        return images.find((i) => i.width === 587 && i.height === 330);

      case CONTENT_ROW_TYPES.ORIGINALS:
        if (
          screenWidth >= 1280 &&
          images.find((i) => i.width === 1024 && i.height === 1024)
        ) {
          return images.find((i) => i.width === 1024 && i.height === 1024);
        }
        return images.find((i) => i.width === 460 && i.height === 460);

      default:
        return null;
    }
  }
}
/**
 * Gets duration and formats it to seconds
 *
 * @param {object} data Response object from API
 * @param {boolean} seconds Whether to include seconds in the duration
 * @return {(string|null)} Duration without seconds (e.g. the 138m part) | Full Duration (e.g. 138min + 42sec)
 *                          - Format from API = "138:42 min"
 */
function getDurationSeconds(data, seconds = false) {
  const duration = data?.duration;

  if (duration) {
    const parts = duration.match(/(\d+)/g);
    if (parts) {
      // Divide by 60 for hour format
      const hours = Math.floor(parts[0] / 60);
      const minutes = parts[0] % 60;

      // 1 hour = 3600s
      // 1 minute = 60s
      const durationWithoutSeconds = hours * 3600 + minutes * 60;
      return seconds
        ? durationWithoutSeconds + parseInt(parts[1], 10)
        : durationWithoutSeconds;
    }
  }

  return null;
}

export {
  getMetadataByLocale,
  getGenresByLocale,
  getCreditsByLocale,
  getRatingsByRegion,
  getImagesBySizeRequirements,
  getPosterImageForGrid,
  getBlockBackgroundImage,
  getPosterImageForCollection,
  getPosterImageForContentRow,
  getDurationSeconds,
};
