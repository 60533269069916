/* eslint-disable indent */
import MediaSession from '@mparticle/web-media-sdk';
import {
  getBrowserClientNameAndVersion,
  getUserFriendlyPageName,
} from './utils';
import {
  isTv,
  getNetworkInformation,
  getPlatformDeviceName,
  getDeviceOS,
  getDeviceOsVersion,
  getDeviceInformation,
} from '../platform/index';
import logger from './logger';
import { getLocalStorageItem } from './local-storage';

// Enums
import LOCAL_STORAGE from '../enums/local-storage';
import PLAYER_AD_BREAK_POSITION_TYPES from '../enums/player-ad-break-position-types';
import APP_CONFIG from '../config/app-config.json';
import { getFeatureFlagValue, isFeatureFlagEnabled } from './feature-flags';

let mediaSession;
const PLAYER_NAME = 'BitMovin';

// TODO: Add CSAI || SSAI to ad-related calls when we implement SSAI funcitionality

let mparticleSdkUrl = null;

const protocol = isFeatureFlagEnabled('testHTTP') ? 'http' : 'https';

if (APP_CONFIG.MPARTICLE_SELF_HOSTED_SDK === 'true') {
  mparticleSdkUrl = `${APP_CONFIG.PUBLIC_URL}vendor/mparticle.js`;
} else {
  mparticleSdkUrl = `${protocol}://jssdkcdns.mparticle.com/js/v2/${APP_CONFIG.MPARTICLE_API_KEY}/mparticle.js`;
}

const mParticleSDK = `(function(sdkUrl) {
    // stub mParticle and major mParticle classes EventType, eCommerce, and Identity to exist before full
    // mParticle object is initialized
    window.mParticle = window.mParticle || {};
    window.mParticle.EventType = {
        Unknown: 0,
        Navigation: 1,
        Location: 2,
        Search: 3,
        Transaction: 4,
        UserContent: 5,
        UserPreference: 6,
        Social: 7,
        Other: 8,
    };
    window.mParticle.eCommerce = { Cart: {} };
    window.mParticle.Identity = {};
    window.mParticle.config = window.mParticle.config || {};
    window.mParticle.config.rq = [];
    window.mParticle.config.snippetVersion = 2.3;
    window.mParticle.ready = function(f) {
        window.mParticle.config.rq.push(f);
    };

    // methods to be stubbed from the main mParticle object, mParticle.eCommerce, and mParticle.Identity
    // methods that return objects are not stubbed
    var mainMethods = [
        'endSession',
        'logError',
        'logBaseEvent',
        'logEvent',
        'logForm',
        'logLink',
        'logPageView',
        'setSessionAttribute',
        'setAppName',
        'setAppVersion',
        'setOptOut',
        'setPosition',
        'startNewSession',
        'startTrackingLocation',
        'stopTrackingLocation',
    ];
    var ecommerceMethods = ['setCurrencyCode', 'logCheckout'];
    var identityMethods = ['identify', 'login', 'logout', 'modify'];

    // iterates through methods above to create stubs
    mainMethods.forEach(function(method) {
        window.mParticle[method] = preloadMethod(method);
    });
    ecommerceMethods.forEach(function(method) {
        window.mParticle.eCommerce[method] = preloadMethod(method, 'eCommerce');
    });
    identityMethods.forEach(function(method) {
        window.mParticle.Identity[method] = preloadMethod(method, 'Identity');
    });

    // stubbing function
    // pushes an array of 2 arguments into readyQueue: 1. the method, and 2. the arguments passed to the method
    // if the method is on the eCommerce or identity object, then the 1st argument is base conatenated with "." and the method name
    // ie: Identity.login, eCommerce.setCurrencyCode
    // in main.js, the function "processPreloadedItem" will parse and run stubbed methods stored in the readyQueue (config.rq)
    function preloadMethod(method, base) {
        return function() {
            if (base) {
                method = base + '.' + method;
            }
            var args = Array.prototype.slice.call(arguments);
            args.unshift(method);
            window.mParticle.config.rq.push(args);
        };
    }

    // set data planning query parameters
    var dpId,
        dpV,
        config = window.mParticle.config,
        env = config.isDevelopmentMode ? 1 : 0,
        dbUrl = '?env=' + env,
        dataPlan = window.mParticle.config.dataPlan;

    if (dataPlan) {
        dpId = dataPlan.planId;
        dpV = dataPlan.planVersion;
        if (dpId) {
            if (dpV && (dpV < 1 || dpV > 1000)) {
                dpV = null;
            }
            dbUrl += '&plan_id=' + dpId + (dpV ? '&plan_version=' + dpV : '');
        }
    }    

    // add mParticle script dynamically to the page, insert before the first script tag
    var mp = document.createElement('script');
    mp.type = 'text/javascript';
    mp.async = true;
    mp.src = sdkUrl + dbUrl;  
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(mp, s);
})('sdk_url');
`;

const isDevelopmentMode = () => {
  return isFeatureFlagEnabled('testStgApi') && APP_CONFIG.ENV !== 'production';
};

/**
 * Injects the mParticle SDK snippet into the document head
 *
 * @param {object} config Crackle App Config
 */
async function mParticleAddScript(partnerName, deviceId) {
  const tag = document.createElement('script');
  tag.type = 'text/javascript';

  const logLevel = getFeatureFlagValue('logLevel');

  const mParticleConfig = `window.mParticle = {
      config: {
        isDevelopmentMode: ${isDevelopmentMode()},
        dataPlan: {
          planId: '${APP_CONFIG.MPARTICLE_PLAN_ID}',
        },
        identityCallback: function(result) {
          if (result.getUser()) {
             const currentUser = result.getUser();
             const userAttributes = {};
             userAttributes['${APP_CONFIG.PLATFORM}_device_identifier'] =
             '${deviceId || null}';                        

             for (const index in Object.entries(userAttributes)) {
               const userAttribute = Object.entries(userAttributes)[index]
               currentUser.setUserAttribute(userAttribute[0], userAttribute[1]);
             }
          }
        },
        appVersion: '${APP_CONFIG.VERSION}',
        appName: '${partnerName}',
        logLevel: '${
          logLevel === 'info' || logLevel === 'debug' ? 'verbose' : 'warning'
        }'
      }
    };`;
  const updateSDKwithAPI = mParticleSDK.replace('sdk_url', mparticleSdkUrl);
  const mParticleScript = document.createTextNode(
    mParticleConfig + updateSDKwithAPI
  );

  tag.appendChild(mParticleScript);

  document.querySelector('head').appendChild(tag);
  window.mParticle.config.logger = {
    error(errorMsg) {
      logger.errorAnalytics(errorMsg);
    },
    warn(warningMsg) {
      logger.warnAnalytics(warningMsg);
    },
    verbose(verboseMsg) {
      logger.infoAnalytics(verboseMsg);
    },
  };
}

/**
 * Sends a custom mParticle event when a non navigation or player related button is clicked
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.buttonLabel The label name of the button clicked
 * @param {string} eventData.buttonPosition The position of the button clicked
 * @param {string} eventData.pageSection The page section of the button clicked
 */
const mParticleButtonClick = async ({
  buttonLabel,
  buttonPosition,
  pageSection,
}) => {
  try {
    const defaultEventData = {
      platform_button_label: buttonLabel,
      platform_button_position: buttonPosition,
      platform_page_name: getUserFriendlyPageName(),
      platform_page_section: pageSection,
      platform_page_url: window.location.href,
    };

    window.mParticle.logEvent(
      'Button Click Event',
      window.mParticle.EventType.Navigation,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleButtonClick', e);
  }
};

/**
 * Sends a custom mParticle event when a menu item is clicked
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.categoryName The category name of the element clicked
 * @param {string} eventData.categoryPosition The position of the category clicked
 * @param {string} eventData.pageSection The page section of the category clicked
 */
const mParticleMenuClick = async ({
  categoryName,
  categoryPosition,
  pageSection,
}) => {
  try {
    const defaultEventData = {
      platform_category_name: categoryName,
      platform_category_position: categoryPosition,
      platform_page_name: getUserFriendlyPageName(),
      platform_page_section: pageSection,
      platform_page_url: window.location.href,
    };

    window.mParticle.logEvent(
      'Menu Click Event',
      window.mParticle.EventType.Navigation,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleMenuClick', e);
  }
};

/**
 * Fires when content is clicked from any platform tile
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.contentTileName The friendly name of the content tile clicked; Typically the show name of the underlying media
 * @param {string} eventData.contentTilePosition Content Tile Position is used to indicate the tile position within a certain content tray
 * @param {string} eventData.trayName Content Tray Name is used to capture the friendly name of the content tray on which a user has initiated the click; Content Trays contain content tiles
 * @param {string} eventData.trayPosition Content Tray Position is used to indicate from which position a particular content tray was located when a user has clicked on a content tile.
 * @param {string} eventData.trayType Defines the Content Tray Type like hero, mixed, thumbnail, poster
 * @param {string} eventData.pageSection Contains a user-friendly page section
 *
 */
const mParticleContentTileClick = async ({
  contentTileName,
  contentTilePosition,
  trayName,
  trayPosition,
  trayType,
  pageSection,
}) => {
  try {
    const defaultEventData = {
      platform_content_tile_name: contentTileName,
      platform_content_tile_position: contentTilePosition,
      platform_content_tray_name: trayName,
      platform_content_tray_position: trayPosition,
      platform_content_tray_type: trayType,
      platform_page_section: pageSection,
      platform_page_name: getUserFriendlyPageName(),
      platform_page_url: window.location.href,
    };

    window.mParticle.logEvent(
      'Content Tile Click',
      window.mParticle.EventType.Navigation,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleContentTileClick', e);
  }
};

/**
 * Fires when content is clicked from any platform tile
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.contentTileName The friendly name of the content tile clicked; Typically the show name of the underlying media
 * @param {string} eventData.contentTilePosition Content Tile Position is used to indicate the tile position within a certain content tray
 * @param {string} eventData.trayName Content Tray Name is used to capture the friendly name of the content tray on which a user has initiated the click; Content Trays contain content tiles
 * @param {string} eventData.trayPosition Content Tray Position is used to indicate from which position a particular content tray was located when a user has clicked on a content tile.
 * @param {string} eventData.trayType Defines the Content Tray Type like hero, mixed, thumbnail, poster
 * @param {string} eventData.pageSection Contains a user-friendly page section
 *
 */
const mParticleYMALContentClick = async ({
  contentTileName,
  contentTilePosition,
  trayName,
  trayPosition,
  trayType,
  pageSection,
}) => {
  try {
    const defaultEventData = {
      platform_content_tile_name: contentTileName,
      platform_content_tile_position: contentTilePosition,
      platform_content_tray_name: trayName,
      platform_content_tray_position: trayPosition,
      platform_content_tray_type: trayType,
      platform_page_section: pageSection,
      platform_page_name: getUserFriendlyPageName(),
      platform_page_url: window.location.href,
    };

    window.mParticle.logEvent(
      'YML_DetailsPage_InFocus',
      window.mParticle.EventType.Navigation,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleYMALContentClick', e);
  }
};

/**
 * Fires when content is clicked from any platform tile
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.contentId the id of the content added
 * @param {string} eventData.contentTitle The title of the media
 * @param {string} eventData.contentType Content Type (Video or Audio)
 * @param {string} eventData.contentTileName The friendly name of the content tile clicked; Typically the show name of the underlying media
 * @param {string} eventData.contentTilePosition Content Tile Position is used to indicate the tile position within a certain content tray
 *
 */
const mParticleUpNextHover = async ({
  contentId,
  contentTitle,
  contentType,
  contentTileName,
  contentTilePosition,
}) => {
  try {
    const defaultEventData = {
      content_id: contentId,
      content_title: contentTitle,
      content_type: contentType,
      platform_content_tile_name: contentTileName,
      platform_content_tile_position: contentTilePosition,
    };

    window.mParticle.logEvent(
      'UpNextView_HoverEvent',
      window.mParticle.EventType.Media,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleUpNextHover', e);
  }
};

/**
 * Fires when the player loads content prior to playing that content
 * @param {object} eventData The data to send with the event
 * @param {object} eventData.mParticleInstance mParticle SDK Instance
 * @param {string} eventData.mediaId Custom media ID
 * @param {string} eventData.mediaTitle Custom media Title
 * @param {string} eventData.mediaDuration Duration in milliseconds
 * @param {string} eventData.contentType Content Type (Video or Audio)
 * @param {string} eventData.streamType Stream Type (OnDemand or LiveStream)
 * @param {boolean} eventData.logPageEventToggle Log Page Event Toggle (true/false)
 * @param {boolean} eventData.logMediaEventToggle Log Media Event Toggle (true/false)
 */
const mParticleCreateMediaSession = ({
  mParticleInstance,
  mediaId,
  mediaTitle,
  mediaDuration,
  contentType,
  streamType,
  logPageEventToggle,
  logMediaEventToggle,
}) => {
  mediaSession = new MediaSession(
    mParticleInstance,
    mediaId,
    mediaTitle,
    mediaDuration,
    contentType,
    streamType,
    logPageEventToggle,
    logMediaEventToggle
  );
};

/**
 * Fires when a media session ends due to the user navigating away from a partially played video
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.playerCaptionSetting Tracks when a user enables or disables content
 * @param {string} eventData.playerCastType The casting service used to cast media to device
 * @param {string} eventData.playerMediaContentType The type of content
 * @param {string} eventData.playerMediaEpisodeNumber Media episode number where applicable
 * @param {string} eventData.playerMediaBitrate Bitrate in kbps of the media at time of analytics event
 * @param {string} eventData.playerMediaGenre Genre array of the media
 * @param {string} eventData.playerMediaId The unique identifier for the media
 * @param {string} eventData.playerMediaMilestonePercentage The percentage of video played eg. 25, 50, 75. Fired based on play head position.
 * @param {string} eventData.playerMediaName The friendly name of the media. Can be episode names, movie names etc.
 * @param {string} eventData.playerMediaParentalRatingIdentifier Parental rating of the media
 * @param {string} eventData.playerMediaReferrerId The content id of the previous media played
 * @param {string} eventData.playerMediaReferrerName The name of the previous media played
 * @param {string} eventData.playerMediaSeasonNumber The season number of a media where applicable
 * @param {string} eventData.playerMediaShowName Name of the show or franchise name the media belongs to
 * @param {string} eventData.playerMediaStreamType The type of stream content is being played on
 * @param {string} eventData.playerPlayheadPosition The playhead position in seconds of the media at the time of the event
 * @param {string} eventData.playerPlayheadChapterNumber Tracks the chapter the playhead is currently positioned in
 * @param {string} eventData.playerVersion The player version
 * @param {string} eventData.playerPlayType Tracks the type of media playback
 * @param {string} eventData.playerMediaLength The total length of the media in seconds
 * @param {string} eventData.playerMediaAudioTracknumber The audio track number of the media file
 * @param {string} eventData.playerMediaAudioTrackname The audio track name of the media file
 * @param {string} eventData.playerMediaAudioLanguage IETF language tag - country + Locale of the audiotrack
 * @param {string} eventData.playerCaptionTracknumber Caption track number
 * @param {string} eventData.playerCaptionTrackname Caption track name
 * @param {string} eventData.playerCaptionLanguage IETF language tag - country + Locale
 * @param {string} eventData.playerAutoOrManual Tracks how a play was initiated
 */
const mParticleMediaSessionEnd = async ({
  playerCaptionSetting,
  playerCastType,
  playerMediaContentType,
  playerMediaEpisodeNumber,
  playerMediaBitrate,
  playerMediaGenre,
  playerMediaId,
  playerMediaMilestonePercentage,
  playerMediaName,
  playerMediaParentalRatingIdentifier,
  playerMediaReferrerId,
  playerMediaReferrerName,
  playerMediaSeasonNumber,
  playerMediaShowName,
  playerMediaStreamType,
  playerPlayheadPosition,
  playerPlayheadChapterNumber,
  playerVersion,
  playerPlayType,
  playerMediaLength,
  playerMediaAudioTracknumber,
  playerMediaAudioTrackname,
  playerMediaAudioLanguage,
  playerCaptionTracknumber,
  playerCaptionTrackname,
  playerCaptionLanguage,
  playerAutoOrManual,
}) => {
  try {
    const defaultEventData = {
      player_caption_setting: playerCaptionSetting,
      player_cast_type: playerCastType,
      player_media_content_type: playerMediaContentType,
      player_media_episode_number: playerMediaEpisodeNumber,
      player_media_bitrate: playerMediaBitrate,
      player_media_genre: playerMediaGenre,
      player_media_id: playerMediaId,
      player_media_milestone_percentage: playerMediaMilestonePercentage,
      player_media_name: playerMediaName,
      player_media_parental_rating_identifier:
        playerMediaParentalRatingIdentifier,
      player_media_referrer_id: playerMediaReferrerId,
      player_media_referrer_name: playerMediaReferrerName,
      player_media_season_number: playerMediaSeasonNumber,
      player_media_show_name: playerMediaShowName,
      player_name: PLAYER_NAME,
      player_media_stream_type: playerMediaStreamType,
      player_playhead_position: playerPlayheadPosition,
      player_playhead_chapter_number: playerPlayheadChapterNumber,
      player_version: playerVersion,
      player_play_type: playerPlayType,
      player_media_length: playerMediaLength,
      player_media_audio_tracknumber: playerMediaAudioTracknumber,
      player_media_audio_trackname: playerMediaAudioTrackname,
      player_media_audio_language: playerMediaAudioLanguage,
      player_caption_tracknumber: playerCaptionTracknumber,
      player_caption_trackname: playerCaptionTrackname,
      player_caption_language: playerCaptionLanguage,
      player_auto_or_manual: playerAutoOrManual,
    };

    mediaSession.logMediaSessionEnd({
      customAttributes: mParticleGetEventData(defaultEventData),
    });
  } catch (e) {
    logger.error('Error occurred sending mParticleMediaSessionEnd', e);
  }
};

/**
 * Fires when the playback of an ad break has ended
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.adBreakPosition Position of Ad break in seconds
 * @param {string} eventData.adBreakType Type of Ad Break (PREROLL / MIDROLL)
 * @param {number} eventData.adBreakDuration Length of the ad break in seconds
 * @param {string} eventData.adBreakId The id of the corresponding AdBreakConfig
 * @param {number} eventData.totalAdsInBreak Number of ads in the ad break
 * @param {string} eventData.currentTime The player progress in seconds
 * @param {string} eventData.playerVersion The player version
 * @param {string} eventData.playerAdBreakInsertType CSAI or SSAI
 */
const mParticleAdBreakFinished = async ({
  adBreakPosition,
  adBreakType,
  adBreakDuration,
  adBreakId,
  totalAdsInBreak,
  currentTime,
  playerVersion,
  playerAdBreakInsertType,
}) => {
  try {
    const defaultEventData = {
      ad_break_id: adBreakId,
      ad_break_title: adBreakType,
      ad_break_duration: adBreakDuration,
      player_ad_break_length: adBreakDuration,
      player_ad_break_position: adBreakPosition,
      player_ad_break_type: adBreakType,
      player_ad_break_totalads: totalAdsInBreak,
      player_name: PLAYER_NAME,
      player_playhead_position: currentTime,
      player_version: playerVersion,
      player_ad_break_insert_type: playerAdBreakInsertType,
    };

    mediaSession.logAdBreakEnd({
      customAttributes: mParticleGetEventData(defaultEventData),
    });
  } catch (e) {
    logger.error('Error occurred sending mParticleAdBreakFinished', e);
  }
};

/**
 * Fires when the playback of an ad break has started
 * @param {object} eventData The data to send with the event
 * @param {object} eventData.adBreakEvent Ad break event object
 * @param {number} eventData.adBreakDuration Length of the ad break in seconds
 * @param {number} eventData.totalAdsInBreak Number of ads in the ad break
 * @param {string} eventData.currentTime The player progress in seconds
 * @param {string} eventData.playerVersion The player version
 * @param {string} eventData.playerAdBreakInsertType CSAI or SSAI
 */
const mParticleAdBreakStarted = async ({
  adBreakEvent,
  adBreakDuration,
  totalAdsInBreak,
  currentTime,
  playerVersion,
  playerAdBreakInsertType,
}) => {
  try {
    const defaultEventData = {
      player_ad_break_length: adBreakDuration,
      player_ad_break_position: adBreakEvent.adBreak.scheduleTime,
      player_ad_break_type:
        adBreakEvent.adBreak.position === 'pre'
          ? PLAYER_AD_BREAK_POSITION_TYPES.PREROLL
          : PLAYER_AD_BREAK_POSITION_TYPES.MIDROLL,
      player_ad_break_totalads: totalAdsInBreak,
      player_name: PLAYER_NAME,
      player_playhead_position: currentTime,
      player_version: playerVersion,
      player_ad_break_insert_type: playerAdBreakInsertType,
    };
    mediaSession.logAdBreakStart(adBreakEvent, {
      customAttributes: mParticleGetEventData(defaultEventData),
    });
  } catch (e) {
    logger.error('Error occurred sending mParticleAdBreakStarted', e);
  }
};

/**
 * Fired when we need an inital value for a movie or tv show's progressEventsStatus property
 * @returns An array of progress event objects of the form { value: number, sent: boolean }
 */
const mParticleGetInitialEventsStatus = () => {
  return [
    { value: 10, sent: false },
    { value: 25, sent: false },
    { value: 50, sent: false },
    { value: 75, sent: false },
    { value: 95, sent: false },
  ];
};

/**
 * Fired when we need to determine which player progress events should be sent to mParticle
 * @param {number} playerProgressPercentage
 * @param {object} progressEventsStatus
 * @returns An array of progress event objects of the form { value: number, sent: boolean }
 */
const mParticleGetProgressEventsToSend = (
  playerProgressPercentage,
  progressEventsStatus
) => {
  const percentEventsToSend = [];

  for (const progressEvent of progressEventsStatus) {
    if (
      progressEvent.value <= playerProgressPercentage &&
      progressEvent.sent === false
    ) {
      percentEventsToSend.push(progressEvent);
      progressEvent.sent = true;
    }
  }

  return percentEventsToSend;
};

/**
 * Fires when a media session starts
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.playerCaptionLanguage IETF language tag - country + Locale
 * @param {string} eventData.playerCaptionSetting Tracks when a user enables or disables content
 * @param {string} eventData.playerCaptionTrackname Caption track name
 * @param {string} eventData.playerCaptionTracknumber Caption track number
 * @param {string} eventData.playerCastType The casting service used to cast media to device
 * @param {string} eventData.playerMediaContentType The type of content
 * @param {string} eventData.playerMediaAudioLanguage IETF language tag - country + Locale of the audiotrack
 * @param {string} eventData.playerMediaAudioTrackname The audio track name of the media file
 * @param {string} eventData.playerMediaAudioTracknumber The audio track number of the media file
 * @param {string} eventData.playerMediaBitrate Bitrate in kbps of the media at time of analytics event
 * @param {string} eventData.playerMediaEpisodeNumber Media episode number where applicable
 * @param {string} eventData.playerMediaGenre Genre array of the media
 * @param {string} eventData.playerMediaId The unique identifier for the media
 * @param {string} eventData.playerMediaLength The total length of the media in seconds
 * @param {string} eventData.playerMediaMilestonePercentage The percentage of video played eg. 25, 50, 75. Fired based on play head position.
 * @param {string} eventData.playerMediaName The friendly name of the media. Can be episode names, movie names etc.
 * @param {string} eventData.playerMediaParentalRatingIdentifier Parental rating of the media
 * @param {string} eventData.playerMediaReferrerId The content id of the previous media played
 * @param {string} eventData.playerMediaReferrerName The name of the previous media played
 * @param {string} eventData.playerMediaSeasonNumber The season number of a media where applicable
 * @param {string} eventData.playerMediaShowName Name of the show or franchise name the media belongs to
 * @param {string} eventData.playerMediaStreamType The type of stream content is being played on
 * @param {string} eventData.playerPlayType Tracks the type of media playback
 * @param {string} eventData.playerPlayheadChapterNumber Tracks the chapter the playhead is currently positioned in
 * @param {string} eventData.playerPlayheadPosition The playhead position in seconds of the media at the time of the event
 * @param {string} eventData.playerVersion The version of the video player
 * @param {string} eventData.playerAutoOrManual Tracks how a play was initiated
 */
const mParticleMediaSessionStart = async ({
  playerCaptionLanguage,
  playerCaptionSetting,
  playerCaptionTrackname,
  playerCaptionTracknumber,
  playerCastType,
  playerMediaContentType,
  playerMediaAudioLanguage,
  playerMediaAudioTrackname,
  playerMediaAudioTracknumber,
  playerMediaBitrate,
  playerMediaEpisodeNumber,
  playerMediaGenre,
  playerMediaId,
  playerMediaLength,
  playerMediaMilestonePercentage,
  playerMediaName,
  playerMediaParentalRatingIdentifier,
  playerMediaReferrerId,
  playerMediaReferrerName,
  playerMediaSeasonNumber,
  playerMediaShowName,
  playerMediaStreamType,
  playerPlayType,
  playerPlayheadChapterNumber,
  playerPlayheadPosition,
  playerVersion,
  playerAutoOrManual,
}) => {
  try {
    const defaultEventData = {
      platform_country_code: getLocalStorageItem(LOCAL_STORAGE.CLIENT_REGION),
      player_caption_language: playerCaptionLanguage,
      player_caption_setting: playerCaptionSetting,
      player_caption_trackname: playerCaptionTrackname,
      player_caption_tracknumber: playerCaptionTracknumber,
      player_cast_type: playerCastType,
      player_media_content_type: playerMediaContentType,
      player_media_audio_language: playerMediaAudioLanguage,
      player_media_audio_trackname: playerMediaAudioTrackname,
      player_media_audio_tracknumber: playerMediaAudioTracknumber,
      player_media_bitrate: playerMediaBitrate,
      player_media_episode_number: playerMediaEpisodeNumber,
      player_media_genre: playerMediaGenre,
      player_media_id: playerMediaId,
      player_media_length: playerMediaLength,
      player_media_milestone_percentage: playerMediaMilestonePercentage,
      player_media_name: playerMediaName,
      player_media_parental_rating_identifier:
        playerMediaParentalRatingIdentifier,
      player_media_referrer_id: playerMediaReferrerId,
      player_media_referrer_name: playerMediaReferrerName,
      player_media_season_number: playerMediaSeasonNumber,
      player_media_show_name: playerMediaShowName,
      player_name: PLAYER_NAME,
      player_play_type: playerPlayType,
      player_media_stream_type: playerMediaStreamType,
      player_playhead_chapter_number: playerPlayheadChapterNumber,
      player_playhead_position: playerPlayheadPosition,
      player_version: playerVersion,
      player_auto_or_manual: playerAutoOrManual,
    };

    mediaSession.logMediaSessionStart({
      customAttributes: mParticleGetEventData(defaultEventData),
    });
  } catch (e) {
    logger.error('Error occurred sending mParticleMediaSessionStart', e);
  }
};

/**
 * Fires whenever certain percentages (10, 25, 50, 75, 95) of a video have been played
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.contentTitle The title of the media
 * @param {string} eventData.playerCaptionLanguage IETF language tag - country + Locale
 * @param {string} eventData.playerCaptionSetting Tracks when a user enables or disables content
 * @param {string} eventData.playerCaptionTrackname Caption track name
 * @param {string} eventData.playerCaptionTracknumber Caption track number
 * @param {string} eventData.playerCastType The casting service used to cast media to device
 * @param {string} eventData.playerMediaContentType The type of content
 * @param {string} eventData.playerMediaAudioLanguage IETF language tag - country + Locale of the audiotrack
 * @param {string} eventData.playerMediaAudioTrackname The audio track name of the media file
 * @param {string} eventData.playerMediaAudioTracknumber The audio track number of the media file
 * @param {string} eventData.playerMediaBitrate Bitrate in kbps of the media at time of analytics event
 * @param {string} eventData.playerMediaEpisodeNumber Media episode number where applicable
 * @param {string} eventData.playerMediaGenre Genre array of the media
 * @param {string} eventData.playerMediaId The unique identifier for the media
 * @param {string} eventData.playerMediaLength The total length of the media in seconds
 * @param {string} eventData.playerMediaMilestonePercentage The percentage of video played eg. 25, 50, 75. Fired based on play head position.
 * @param {string} eventData.playerMediaName The friendly name of the media. Can be episode names, movie names etc.
 * @param {string} eventData.playerMediaParentalRatingIdentifier Parental rating of the media
 * @param {string} eventData.playerMediaReferrerId The content id of the previous media played
 * @param {string} eventData.playerMediaReferrerName The name of the previous media played
 * @param {string} eventData.playerMediaSeasonNumber The season number of a media where applicable
 * @param {string} eventData.playerMediaShowName Name of the show or franchise name the media belongs to
 * @param {string} eventData.playerMediaStreamType The type of stream content is being played on
 * @param {string} eventData.playerPlayType Tracks the type of media playback
 * @param {string} eventData.playerPlayheadChapterNumber Tracks the chapter the playhead is currently positioned in
 * @param {string} eventData.playerPlayheadPosition The playhead position in seconds of the media at the time of the event
 * @param {string} eventData.playerVersion The version of the video player
 */
const mParticlePlayheadPosition = async ({
  contentTitle,
  playerCaptionLanguage,
  playerCaptionSetting,
  playerCaptionTrackname,
  playerCaptionTracknumber,
  playerCastType,
  playerMediaContentType,
  playerMediaAudioLanguage,
  playerMediaAudioTrackname,
  playerMediaAudioTracknumber,
  playerMediaBitrate,
  playerMediaEpisodeNumber,
  playerMediaGenre,
  playerMediaId,
  playerMediaLength,
  playerMediaMilestonePercentage,
  playerMediaName,
  playerMediaParentalRatingIdentifier,
  playerMediaReferrerId,
  playerMediaReferrerName,
  playerMediaSeasonNumber,
  playerMediaShowName,
  playerMediaStreamType,
  playerPlayType,
  playerPlayheadChapterNumber,
  playerPlayheadPosition,
  playerVersion,
}) => {
  try {
    const defaultEventData = {
      content_title: contentTitle,
      player_caption_language: playerCaptionLanguage,
      player_caption_setting: playerCaptionSetting,
      player_caption_trackname: playerCaptionTrackname,
      player_caption_tracknumber: playerCaptionTracknumber,
      player_cast_type: playerCastType,
      player_media_content_type: playerMediaContentType,
      player_media_audio_language: playerMediaAudioLanguage,
      player_media_audio_trackname: playerMediaAudioTrackname,
      player_media_audio_tracknumber: playerMediaAudioTracknumber,
      player_media_bitrate: playerMediaBitrate,
      player_media_episode_number: playerMediaEpisodeNumber,
      player_media_genre: playerMediaGenre,
      player_media_id: playerMediaId,
      player_media_length: playerMediaLength,
      player_media_milestone_percentage: playerMediaMilestonePercentage,
      player_media_name: playerMediaName,
      player_media_parental_rating_identifier:
        playerMediaParentalRatingIdentifier,
      player_media_referrer_id: playerMediaReferrerId,
      player_media_referrer_name: playerMediaReferrerName,
      player_media_season_number: playerMediaSeasonNumber,
      player_media_show_name: playerMediaShowName,
      player_name: PLAYER_NAME,
      player_media_stream_type: playerMediaStreamType,
      player_play_type: playerPlayType,
      player_playhead_chapter_number: playerPlayheadChapterNumber,
      player_playhead_position: playerPlayheadPosition,
      player_version: playerVersion,
    };

    // https://github.com/mParticle/mparticle-web-media-sdk#logging-custom-events
    const { name, eventType, data } = mediaSession.createPageEvent(
      `${playerMediaMilestonePercentage}% Progress Marker`,
      mParticleGetEventData(defaultEventData)
    );

    // We can't pass the entire object returned by the mediaSession.createEvent
    // function into wimdow.mParticle.logEvent. Instead, we pass the values of
    // certain properties of that object.
    // See here for the structure of the object that mediaSession.createPageEvent returns:
    // https://github.com/mParticle/mparticle-web-media-sdk/blob/master/src/session.ts#L619
    window.mParticle.logEvent(name, eventType, data);
  } catch (e) {
    logger.error('Error occurred sending mParticlePlayheadPosition', e);
  }
};

/**
 * Fires when an ad finishes playing
 * @param {object} eventData The data to send with the event
 * @param {object} eventData.adEvent Ad event object
 * @param {number} eventData.adPosition Position of ad in ad break
 * @param {string} eventData.currentTime The player progress in seconds
 * @param {string} eventData.playerVersion The player version
 * @param {string} eventData.playerAdBreakInsertType CSAI or SSAI
 */
const mParticleAdFinished = async ({
  adEvent,
  adPosition,
  currentTime,
  playerVersion,
  playerAdBreakInsertType,
}) => {
  try {
    const defaultEventData = {
      player_ad_id: adEvent.ad.id,
      player_ad_length: adEvent.ad.duration,
      player_ad_position: adPosition,
      player_name: PLAYER_NAME,
      player_playhead_position: currentTime,
      player_version: playerVersion,
      player_ad_break_insert_type: playerAdBreakInsertType,
    };

    mediaSession.logAdEnd({
      customAttributes: mParticleGetEventData(defaultEventData),
    });
  } catch (e) {
    logger.error('Error occurred sending mParticleAdFinished', e);
  }
};

/**
 * Fires when an ad starts playing
 * @param {object} eventData The data to send with the event
 * @param {object} eventData.adEvent Ad event object
 * @param {number} eventData.adPosition Position of ad in ad break
 * @param {string} eventData.currentTime The player progress in seconds
 * @param {string} eventData.playerVersion The player version
 * @param {string} eventData.playerAdBreakInsertType CSAI or SSAI
 */
const mParticleAdStarted = async ({
  adEvent,
  adPosition,
  currentTime,
  playerVersion,
  playerAdBreakInsertType,
}) => {
  try {
    const defaultEventData = {
      player_ad_id: adEvent.ad.id,
      player_ad_length: adEvent.ad.duration,
      player_ad_position: adPosition,
      player_name: PLAYER_NAME,
      player_playhead_position: currentTime,
      player_version: playerVersion,
      player_ad_break_insert_type: playerAdBreakInsertType,
    };

    mediaSession.logAdStart(adEvent.ad, {
      customAttributes: mParticleGetEventData(defaultEventData),
    });
  } catch (e) {
    logger.error('Error occurred sending mParticleAdStarted', e);
  }
};
/**
 * Fires when the playback of media content has ended
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.playerCaptionLanguage IETF language tag - country + Locale
 * @param {string} eventData.playerCaptionSetting Tracks when a user enables or disables content
 * @param {string} eventData.playerCaptionTrackname Caption track name
 * @param {string} eventData.playerCaptionTracknumber Caption track number
 * @param {string} eventData.playerCastType The casting service used to cast media to device
 * @param {string} eventData.playerMediaContentType The type of content
 * @param {string} eventData.playerMediaAudioLanguage IETF language tag - country + Locale of the audiotrack
 * @param {string} eventData.playerMediaAudioTrackname The audio track name of the media file
 * @param {string} eventData.playerMediaAudioTracknumber The audio track number of the media file
 * @param {string} eventData.playerMediaBitrate Bitrate in kbps of the media at time of analytics event
 * @param {string} eventData.playerMediaEpisodeNumber Media episode number where applicable
 * @param {string} eventData.playerMediaGenre Genre array of the media
 * @param {string} eventData.playerMediaId The unique identifier for the media
 * @param {string} eventData.playerMediaLength The total length of the media in seconds
 * @param {string} eventData.playerMediaMilestonePercentage The percentage of video played eg. 25, 50, 75. Fired based on play head position.
 * @param {string} eventData.playerMediaName The friendly name of the media. Can be episode names, movie names etc.
 * @param {string} eventData.playerMediaParentalRatingIdentifier Parental rating of the media
 * @param {string} eventData.playerMediaReferrerId The content id of the previous media played
 * @param {string} eventData.playerMediaReferrerName The name of the previous media played
 * @param {string} eventData.playerMediaSeasonNumber The season number of a media where applicable
 * @param {string} eventData.playerMediaShowName Name of the show or franchise name the media belongs to
 * @param {string} eventData.playerMediaStreamType The type of stream content is being played on
 * @param {string} eventData.playerPlayType Tracks the type of media playback
 * @param {string} eventData.playerPlayheadChapterNumber Tracks the chapter the playhead is currently positioned in
 * @param {string} eventData.playerPlayheadPosition The playhead position in seconds of the media at the time of the event
 * @param {string} eventData.playerVersion The version of the video player
 * @param {string} eventData.playerAutoOrManual Tracks how a play was initiated
 */
const mParticlePlaybackFinished = async ({
  playerCaptionLanguage,
  playerCaptionSetting,
  playerCaptionTrackname,
  playerCaptionTracknumber,
  playerCastType,
  playerMediaContentType,
  playerMediaAudioLanguage,
  playerMediaAudioTrackname,
  playerMediaAudioTracknumber,
  playerMediaBitrate,
  playerMediaEpisodeNumber,
  playerMediaGenre,
  playerMediaId,
  playerMediaLength,
  playerMediaMilestonePercentage,
  playerMediaName,
  playerMediaParentalRatingIdentifier,
  playerMediaReferrerId,
  playerMediaReferrerName,
  playerMediaSeasonNumber,
  playerMediaShowName,
  playerMediaStreamType,
  playerPlayType,
  playerPlayheadChapterNumber,
  playerPlayheadPosition,
  playerVersion,
  playerAutoOrManual,
}) => {
  try {
    const defaultEventData = {
      player_caption_language: playerCaptionLanguage,
      player_caption_setting: playerCaptionSetting,
      player_caption_trackname: playerCaptionTrackname,
      player_caption_tracknumber: playerCaptionTracknumber,
      player_cast_type: playerCastType,
      player_media_content_type: playerMediaContentType,
      player_media_audio_language: playerMediaAudioLanguage,
      player_media_audio_trackname: playerMediaAudioTrackname,
      player_media_audio_tracknumber: playerMediaAudioTracknumber,
      player_media_bitrate: playerMediaBitrate,
      player_media_episode_number: playerMediaEpisodeNumber,
      player_media_genre: playerMediaGenre,
      player_media_id: playerMediaId,
      player_media_length: playerMediaLength,
      player_media_milestone_percentage: playerMediaMilestonePercentage,
      player_media_name: playerMediaName,
      player_media_parental_rating_identifier:
        playerMediaParentalRatingIdentifier,
      player_media_referrer_id: playerMediaReferrerId,
      player_media_referrer_name: playerMediaReferrerName,
      player_media_season_number: playerMediaSeasonNumber,
      player_media_show_name: playerMediaShowName,
      player_media_stream_type: playerMediaStreamType,
      player_name: PLAYER_NAME,
      player_play_type: playerPlayType,
      player_playhead_chapter_number: playerPlayheadChapterNumber,
      player_playhead_position: playerPlayheadPosition,
      player_version: playerVersion,
      player_auto_or_manual: playerAutoOrManual,
    };

    mediaSession.logMediaContentEnd({
      customAttributes: mParticleGetEventData(defaultEventData),
    });

    mediaSession.logMediaSessionEnd({
      customAttributes: mParticleGetEventData(defaultEventData),
    });
  } catch (e) {
    logger.error('Error occurred sending mParticlePlaybackFinished', e);
  }
};

/**
 * Fires when playback of media content has started
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.playerCaptionLanguage IETF language tag - country + Locale
 * @param {string} eventData.playerCaptionSetting Tracks when a user enables or disables content
 * @param {string} eventData.playerCaptionTrackname Caption track name
 * @param {string} eventData.playerCaptionTracknumber Caption track number
 * @param {string} eventData.playerCastType The casting service used to cast media to device
 * @param {string} eventData.playerMediaContentType The type of content
 * @param {string} eventData.playerMediaAudioLanguage IETF language tag - country + Locale of the audiotrack
 * @param {string} eventData.playerMediaAudioTrackname The audio track name of the media file
 * @param {string} eventData.playerMediaAudioTracknumber The audio track number of the media file
 * @param {string} eventData.playerMediaBitrate Bitrate in kbps of the media at time of analytics event
 * @param {string} eventData.playerMediaEpisodeNumber Media episode number where applicable
 * @param {string} eventData.playerMediaGenre Genre array of the media
 * @param {string} eventData.playerMediaId The unique identifier for the media
 * @param {string} eventData.playerMediaLength The total length of the media in seconds
 * @param {string} eventData.playerMediaMilestonePercentage The percentage of video played eg. 25, 50, 75. Fired based on play head position.
 * @param {string} eventData.playerMediaName The friendly name of the media. Can be episode names, movie names etc.
 * @param {string} eventData.playerMediaParentalRatingIdentifier Parental rating of the media
 * @param {string} eventData.playerMediaReferrerId The content id of the previous media played
 * @param {string} eventData.playerMediaReferrerName The name of the previous media played
 * @param {string} eventData.playerMediaSeasonNumber The season number of a media where applicable
 * @param {string} eventData.playerMediaShowName Name of the show or franchise name the media belongs to
 * @param {string} eventData.playerMediaStreamType The type of stream content is being played on
 * @param {string} eventData.playerPlayType Tracks the type of media playback
 * @param {string} eventData.playerPlayheadChapterNumber Tracks the chapter the playhead is currently positioned in
 * @param {string} eventData.playerPlayheadPosition The playhead position in seconds of the media at the time of the event
 * @param {string} eventData.playerVersion The version of the video player
 * @param {string} eventData.playerAutoOrManual Tracks how a play was initiated
 */
const mParticlePlaying = async ({
  playerCaptionLanguage,
  playerCaptionSetting,
  playerCaptionTrackname,
  playerCaptionTracknumber,
  playerCastType,
  playerMediaContentType,
  playerMediaAudioLanguage,
  playerMediaAudioTrackname,
  playerMediaAudioTracknumber,
  playerMediaBitrate,
  playerMediaEpisodeNumber,
  playerMediaGenre,
  playerMediaId,
  playerMediaLength,
  playerMediaMilestonePercentage,
  playerMediaName,
  playerMediaParentalRatingIdentifier,
  playerMediaReferrerId,
  playerMediaReferrerName,
  playerMediaSeasonNumber,
  playerMediaShowName,
  playerMediaStreamType,
  playerPlayType,
  playerPlayheadChapterNumber,
  playerPlayheadPosition,
  playerVersion,
  playerAutoOrManual,
  isResuming,
}) => {
  const defaultEventData = {
    platform_country_code: getLocalStorageItem(LOCAL_STORAGE.CLIENT_REGION),
    player_caption_language: playerCaptionLanguage,
    player_caption_setting: playerCaptionSetting,
    player_caption_trackname: playerCaptionTrackname,
    player_caption_tracknumber: playerCaptionTracknumber,
    player_cast_type: playerCastType,
    player_media_content_type: playerMediaContentType,
    player_media_audio_language: playerMediaAudioLanguage,
    player_media_audio_trackname: playerMediaAudioTrackname,
    player_media_audio_tracknumber: playerMediaAudioTracknumber,
    player_media_bitrate: playerMediaBitrate,
    player_media_episode_number: playerMediaEpisodeNumber,
    player_media_genre: playerMediaGenre,
    player_media_id: playerMediaId,
    player_media_length: playerMediaLength,
    player_media_milestone_percentage: playerMediaMilestonePercentage,
    player_media_name: playerMediaName,
    player_media_parental_rating_identifier:
      playerMediaParentalRatingIdentifier,
    player_media_referrer_id: playerMediaReferrerId,
    player_media_referrer_name: playerMediaReferrerName,
    player_media_season_number: playerMediaSeasonNumber,
    player_media_show_name: playerMediaShowName,
    player_media_stream_type: playerMediaStreamType,
    player_play_type: playerPlayType,
    player_name: PLAYER_NAME,
    player_playhead_chapter_number: playerPlayheadChapterNumber,
    player_playhead_position: playerPlayheadPosition,
    player_version: playerVersion,
    player_auto_or_manual: playerAutoOrManual,
  };

  if (isResuming) {
    try {
      const { name, eventType, data } = mediaSession.createPageEvent(
        'Content Resume',
        mParticleGetEventData(defaultEventData)
      );

      // removes streamType and platform_country_code from data object https://crackle.atlassian.net/browse/CD-848
      delete data.stream_type;
      delete data.platform_country_code;

      window.mParticle.logEvent(name, eventType, data);
    } catch (e) {
      logger.error('Error occurred sending mParticleResuming', e);
    }
    return;
  }

  try {
    const { name, eventType, data } = mediaSession.createPageEvent(
      'Content Start',
      mParticleGetEventData(defaultEventData)
    );

    window.mParticle.logEvent(name, eventType, data);
  } catch (e) {
    logger.error('Error occurred sending mParticlePlaying', e);
  }
};

/**
 * Fires when ad playback fails
 * @param {object} eventData The data to send with the event
 * @param {object} eventData.adEvent Ad event object
 * @param {string} eventData.playerAdPosition Position of the ad in the break. Sent with ad start and ad complete.
 * @param {string} eventData.playerPlayheadPosition The playhead position in seconds of the media at the time of the event.
 * @param {string} eventData.playerVersion The version of the video player.
 */
const mParticleAdError = async ({
  adEvent,
  playerAdPosition,
  playerPlayheadPosition,
  playerVersion,
}) => {
  try {
    const defaultEventData = {
      player_ad_id: adEvent.ad?.id || '0',
      player_ad_length: adEvent.ad?.duration || '0',
      player_ad_position: playerAdPosition,
      player_error_code: adEvent.code,
      player_error_message: adEvent.name,
      player_name: PLAYER_NAME,
      player_playhead_position: playerPlayheadPosition,
      player_version: playerVersion,
      player_ad_break_insert_type: 'CSAI',
    };

    const { name, eventType, data } = mediaSession.createPageEvent(
      'Ad Error',
      mParticleGetEventData(defaultEventData)
    );

    window.mParticle.logEvent(name, eventType, data);
  } catch (e) {
    logger.error('Error occurred sending mParticleAdError', e);
  }
};

/**
 * Fired whenever the bitmovin player makes a request to the ad server
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.playerCastType The casting service used to cast media to device
 * @param {string} eventData.playerMediaContentType The type of content
 * @param {string} eventData.playerMediaSeasonNumber The season number of a media where applicable
 * @param {string} eventData.playerMediaEpisodeNumber Media episode number where applicable
 * @param {string} eventData.playerMediaGenre Genre array of the media
 * @param {string} eventData.playerMediaId The unique identifier for the media
 * @param {string} eventData.playerMediaLength The total length of the media in seconds
 * @param {string} eventData.playerMediaName The friendly name of the media. Can be episode names, movie names etc.
 * @param {string} eventData.playerMediaParentalRatingIdentifier Parental rating of the media
 * @param {string} eventData.playerMediaReferrerId The content id of the previous media played
 * @param {string} eventData.playerMediaReferrerName The name of the previous media played
 * @param {string} eventData.playerMediaShowName Name of the show or franchise name the media belongs to
 * @param {string} eventData.playerName The name of the player
 * @param {string} eventData.playerVersion The version of the video player
 * @param {string} eventData.playerPlayheadChapterNumber Tracks the chapter the playhead is currently positioned in
 */
const mParticleAdRequest = async ({
  playerCastType,
  playerMediaContentType,
  playerMediaSeasonNumber,
  playerMediaEpisodeNumber,
  playerMediaGenre,
  playerMediaId,
  playerMediaLength,
  playerMediaName,
  playerMediaParentalRatingIdentifier,
  playerMediaReferrerId,
  playerMediaReferrerName,
  playerMediaShowName,
  playerName,
  playerVersion,
  playerPlayheadChapterNumber,
}) => {
  try {
    const defaultEventData = {
      player_cast_type: playerCastType,
      player_media_content_type: playerMediaContentType,
      player_media_season_number: playerMediaSeasonNumber,
      player_media_episode_number: playerMediaEpisodeNumber,
      player_media_genre: playerMediaGenre,
      player_media_id: playerMediaId,
      player_media_length: playerMediaLength,
      player_media_name: playerMediaName,
      player_media_parental_rating_identifier:
        playerMediaParentalRatingIdentifier,
      player_media_referrer_id: playerMediaReferrerId,
      player_media_referrer_name: playerMediaReferrerName,
      player_media_show_name: playerMediaShowName,
      player_name: playerName,
      player_version: playerVersion,
      player_playhead_chapter_number: playerPlayheadChapterNumber,
    };

    const { name, eventType, data } = mediaSession.createPageEvent(
      'Ad Request',
      mParticleGetEventData(defaultEventData)
    );

    window.mParticle.logEvent(name, eventType, data);
  } catch (e) {
    logger.error('Error occurred sending mParticleAdRequest', e);
  }
};

/**
 * Fires when an error is encountered during player setup or during playback
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.playerErrorCode The error code displayed to the user when a player error occurs
 * @param {string} eventData.playerErrorMessage The error message displayed to the user when a player error occurs
 * @param {string} eventData.playerCaptionLanguage IETF language tag - country + Locale
 * @param {string} eventData.playerCaptionSetting Tracks when a user enables or disables content
 * @param {string} eventData.playerCaptionTrackname Caption track name
 * @param {string} eventData.playerCaptionTracknumber Caption track number
 * @param {string} eventData.playerCastType The casting service used to cast media to device
 * @param {string} eventData.playerMediaContentType The type of content
 * @param {string} eventData.playerMediaAudioLanguage IETF language tag - country + Locale of the audiotrack
 * @param {string} eventData.playerMediaAudioTrackname The audio track name of the media file
 * @param {string} eventData.playerMediaAudioTracknumber The audio track number of the media file
 * @param {string} eventData.playerMediaBitrate Bitrate in kbps of the media at time of analytics event
 * @param {string} eventData.playerMediaEpisodeNumber Media episode number where applicable
 * @param {string} eventData.playerMediaGenre Genre array of the media
 * @param {string} eventData.playerMediaId The unique identifier for the media
 * @param {string} eventData.playerMediaLength The total length of the media in seconds
 * @param {string} eventData.playerMediaMilestonePercentage The percentage of video played eg. 25, 50, 75. Fired based on play head position.
 * @param {string} eventData.playerMediaName The friendly name of the media. Can be episode names, movie names etc.
 * @param {string} eventData.playerMediaParentalRatingIdentifier Parental rating of the media
 * @param {string} eventData.playerMediaReferrerId The content id of the previous media played
 * @param {string} eventData.playerMediaReferrerName The name of the previous media played
 * @param {string} eventData.playerMediaSeasonNumber The season number of a media where applicable
 * @param {string} eventData.playerMediaShowName Name of the show or franchise name the media belongs to
 * @param {string} eventData.playerMediaStreamType The type of stream content is being played on
 * @param {string} eventData.playerPlayType Tracks the type of media playback
 * @param {string} eventData.playerPlayheadChapterNumber Tracks the chapter the playhead is currently positioned in
 * @param {string} eventData.playerPlayheadPosition The playhead position in seconds of the media at the time of the event
 * @param {string} eventData.playerVersion The version of the video player
 */
const mParticlePlayerError = async ({
  playerErrorCode,
  playerErrorMessage,
  playerCaptionLanguage,
  playerCaptionSetting,
  playerCaptionTrackname,
  playerCaptionTracknumber,
  playerCastType,
  playerMediaContentType,
  playerMediaAudioLanguage,
  playerMediaAudioTrackname,
  playerMediaAudioTracknumber,
  playerMediaBitrate,
  playerMediaEpisodeNumber,
  playerMediaGenre,
  playerMediaId,
  playerMediaLength,
  playerMediaMilestonePercentage,
  playerMediaName,
  playerMediaParentalRatingIdentifier,
  playerMediaReferrerId,
  playerMediaReferrerName,
  playerMediaSeasonNumber,
  playerMediaShowName,
  playerMediaStreamType,
  playerPlayType,
  playerPlayheadChapterNumber,
  playerPlayheadPosition,
  playerVersion,
}) => {
  try {
    const defaultEventData = {
      player_error_code: playerErrorCode,
      player_error_message: playerErrorMessage,
      player_caption_language: playerCaptionLanguage,
      player_caption_setting: playerCaptionSetting,
      player_caption_trackname: playerCaptionTrackname,
      player_caption_tracknumber: playerCaptionTracknumber,
      player_cast_type: playerCastType,
      player_media_content_type: playerMediaContentType,
      player_media_audio_language: playerMediaAudioLanguage,
      player_media_audio_trackname: playerMediaAudioTrackname,
      player_media_audio_tracknumber: playerMediaAudioTracknumber,
      player_media_bitrate: playerMediaBitrate,
      player_media_episode_number: playerMediaEpisodeNumber,
      player_media_genre: playerMediaGenre,
      player_media_id: playerMediaId,
      player_media_length: playerMediaLength,
      player_media_milestone_percentage: playerMediaMilestonePercentage,
      player_media_name: playerMediaName,
      player_media_parental_rating_identifier:
        playerMediaParentalRatingIdentifier,
      player_media_referrer_id: playerMediaReferrerId,
      player_media_referrer_name: playerMediaReferrerName,
      player_media_season_number: playerMediaSeasonNumber,
      player_media_show_name: playerMediaShowName,
      player_name: PLAYER_NAME,
      player_media_stream_type: playerMediaStreamType,
      player_play_type: playerPlayType,
      player_playhead_chapter_number: playerPlayheadChapterNumber,
      player_playhead_position: playerPlayheadPosition,
      player_version: playerVersion,
    };

    const { name, eventType, data } = mediaSession.createPageEvent(
      'Player Error',
      mParticleGetEventData(defaultEventData)
    );

    window.mParticle.logEvent(name, eventType, data);
  } catch (e) {
    logger.error('Error occurred sending mParticlePlayerError', e);
  }
};

/**
 * Fires whenever the player is paused
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.playerCaptionLanguage IETF language tag - country + Locale
 * @param {string} eventData.playerCaptionSetting Tracks when a user enables or disables content
 * @param {string} eventData.playerCaptionTrackname Caption track name
 * @param {string} eventData.playerCaptionTracknumber Caption track number
 * @param {string} eventData.playerCastType The casting service used to cast media to device
 * @param {string} eventData.playerMediaContentType The type of content
 * @param {string} eventData.playerMediaAudioLanguage IETF language tag - country + Locale of the audiotrack
 * @param {string} eventData.playerMediaAudioTrackname The audio track name of the media file
 * @param {string} eventData.playerMediaAudioTracknumber The audio track number of the media file
 * @param {string} eventData.playerMediaBitrate Bitrate in kbps of the media at time of analytics event
 * @param {string} eventData.playerMediaEpisodeNumber Media episode number where applicable
 * @param {string} eventData.playerMediaGenre Genre array of the media
 * @param {string} eventData.playerMediaId The unique identifier for the media
 * @param {string} eventData.playerMediaLength The total length of the media in seconds
 * @param {string} eventData.playerMediaMilestonePercentage The percentage of video played eg. 25, 50, 75. Fired based on play head position.
 * @param {string} eventData.playerMediaName The friendly name of the media. Can be episode names, movie names etc.
 * @param {string} eventData.playerMediaParentalRatingIdentifier Parental rating of the media
 * @param {string} eventData.playerMediaReferrerId The content id of the previous media played
 * @param {string} eventData.playerMediaReferrerName The name of the previous media played
 * @param {string} eventData.playerMediaSeasonNumber The season number of a media where applicable
 * @param {string} eventData.playerMediaShowName Name of the show or franchise name the media belongs to
 * @param {string} eventData.playerMediaStreamType The type of stream content is being played on
 * @param {string} eventData.playerPlayType Tracks the type of media playback
 * @param {string} eventData.playerPlayheadChapterNumber Tracks the chapter the playhead is currently positioned in
 * @param {string} eventData.playerPlayheadPosition The playhead position in seconds of the media at the time of the event
 * @param {string} eventData.playerVersion The version of the video player
 * @param {string} eventData.plaerEventOriginator Tracks whether pause event was triggered by system or user
 */
const mParticlePaused = async ({
  playerCaptionLanguage,
  playerCaptionSetting,
  playerCaptionTrackname,
  playerCaptionTracknumber,
  playerCastType,
  playerMediaContentType,
  playerMediaAudioLanguage,
  playerMediaAudioTrackname,
  playerMediaAudioTracknumber,
  playerMediaBitrate,
  playerMediaEpisodeNumber,
  playerMediaGenre,
  playerMediaId,
  playerMediaLength,
  playerMediaMilestonePercentage,
  playerMediaName,
  playerMediaParentalRatingIdentifier,
  playerMediaReferrerId,
  playerMediaReferrerName,
  playerMediaSeasonNumber,
  playerMediaShowName,
  playerMediaStreamType,
  playerPlayType,
  playerPlayheadChapterNumber,
  playerPlayheadPosition,
  playerVersion,
  playerEventOriginator,
}) => {
  try {
    const defaultEventData = {
      player_caption_language: playerCaptionLanguage,
      player_caption_setting: playerCaptionSetting,
      player_caption_trackname: playerCaptionTrackname,
      player_caption_tracknumber: playerCaptionTracknumber,
      player_cast_type: playerCastType,
      player_media_content_type: playerMediaContentType,
      player_media_audio_language: playerMediaAudioLanguage,
      player_media_audio_trackname: playerMediaAudioTrackname,
      player_media_audio_tracknumber: playerMediaAudioTracknumber,
      player_media_bitrate: playerMediaBitrate,
      player_media_episode_number: playerMediaEpisodeNumber,
      player_media_genre: playerMediaGenre,
      player_media_id: playerMediaId,
      player_media_length: playerMediaLength,
      player_media_milestone_percentage: playerMediaMilestonePercentage,
      player_media_name: playerMediaName,
      player_media_parental_rating_identifier:
        playerMediaParentalRatingIdentifier,
      player_media_referrer_id: playerMediaReferrerId,
      player_media_referrer_name: playerMediaReferrerName,
      player_media_season_number: playerMediaSeasonNumber,
      player_media_show_name: playerMediaShowName,
      player_name: PLAYER_NAME,
      player_media_stream_type: playerMediaStreamType,
      player_play_type: playerPlayType,
      player_playhead_chapter_number: playerPlayheadChapterNumber,
      player_playhead_position: playerPlayheadPosition,
      player_version: playerVersion,
      player_event_originator: playerEventOriginator,
    };

    mediaSession.logPause({
      customAttributes: mParticleGetEventData(defaultEventData),
    });
  } catch (e) {
    logger.error('Error occurred sending mParticlePaused', e);
  }
};

/**
 * Fires whenever the player play button is pressed
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.playerCaptionLanguage IETF language tag - country + Locale
 * @param {string} eventData.playerCaptionSetting Tracks when a user enables or disables content
 * @param {string} eventData.playerCaptionTrackname Caption track name
 * @param {string} eventData.playerCaptionTracknumber Caption track number
 * @param {string} eventData.playerCastType The casting service used to cast media to device
 * @param {string} eventData.playerMediaContentType The type of content
 * @param {string} eventData.playerMediaAudioLanguage IETF language tag - country + Locale of the audiotrack
 * @param {string} eventData.playerMediaAudioTrackname The audio track name of the media file
 * @param {string} eventData.playerMediaAudioTracknumber The audio track number of the media file
 * @param {string} eventData.playerMediaBitrate Bitrate in kbps of the media at time of analytics event
 * @param {string} eventData.playerMediaEpisodeNumber Media episode number where applicable
 * @param {string} eventData.playerMediaGenre Genre array of the media
 * @param {string} eventData.playerMediaId The unique identifier for the media
 * @param {string} eventData.playerMediaLength The total length of the media in seconds
 * @param {string} eventData.playerMediaMilestonePercentage The percentage of video played eg. 25, 50, 75. Fired based on play head position.
 * @param {string} eventData.playerMediaName The friendly name of the media. Can be episode names, movie names etc.
 * @param {string} eventData.playerMediaParentalRatingIdentifier Parental rating of the media
 * @param {string} eventData.playerMediaReferrerId The content id of the previous media played
 * @param {string} eventData.playerMediaReferrerName The name of the previous media played
 * @param {string} eventData.playerMediaSeasonNumber The season number of a media where applicable
 * @param {string} eventData.playerMediaShowName Name of the show or franchise name the media belongs to
 * @param {string} eventData.playerMediaStreamType The type of stream content is being played on
 * @param {string} eventData.playerPlayType Tracks the type of media playback
 * @param {string} eventData.playerPlayheadChapterNumber Tracks the chapter the playhead is currently positioned in
 * @param {string} eventData.playerPlayheadPosition The playhead position in seconds of the media at the time of the event
 * @param {string} eventData.playerVersion The version of the video player
 * @param {string} eventData.playerAutoOrManual Tracks how a play was initiated
 * @param {string} eventData.plaerEventOriginator Tracks whether play event was triggered by system or user
 */
const mParticlePlay = async ({
  playerCaptionLanguage,
  playerCaptionSetting,
  playerCaptionTrackname,
  playerCaptionTracknumber,
  playerCastType,
  playerMediaContentType,
  playerMediaAudioLanguage,
  playerMediaAudioTrackname,
  playerMediaAudioTracknumber,
  playerMediaBitrate,
  playerMediaEpisodeNumber,
  playerMediaGenre,
  playerMediaId,
  playerMediaLength,
  playerMediaMilestonePercentage,
  playerMediaName,
  playerMediaParentalRatingIdentifier,
  playerMediaReferrerId,
  playerMediaReferrerName,
  playerMediaSeasonNumber,
  playerMediaShowName,
  playerMediaStreamType,
  playerPlayType,
  playerPlayheadChapterNumber,
  playerPlayheadPosition,
  playerVersion,
  playerAutoOrManual,
  playerEventOriginator,
}) => {
  try {
    const defaultEventData = {
      player_caption_language: playerCaptionLanguage,
      player_caption_setting: playerCaptionSetting,
      player_caption_trackname: playerCaptionTrackname,
      player_caption_tracknumber: playerCaptionTracknumber,
      player_cast_type: playerCastType,
      player_media_content_type: playerMediaContentType,
      player_media_audio_language: playerMediaAudioLanguage,
      player_media_audio_trackname: playerMediaAudioTrackname,
      player_media_audio_tracknumber: playerMediaAudioTracknumber,
      player_media_bitrate: playerMediaBitrate,
      player_media_episode_number: playerMediaEpisodeNumber,
      player_media_genre: playerMediaGenre,
      player_media_id: playerMediaId,
      player_media_length: playerMediaLength,
      player_media_milestone_percentage: playerMediaMilestonePercentage,
      player_media_name: playerMediaName,
      player_media_parental_rating_identifier:
        playerMediaParentalRatingIdentifier,
      player_media_referrer_id: playerMediaReferrerId,
      player_media_referrer_name: playerMediaReferrerName,
      player_media_season_number: playerMediaSeasonNumber,
      player_media_show_name: playerMediaShowName,
      player_name: PLAYER_NAME,
      player_media_stream_type: playerMediaStreamType,
      player_play_type: playerPlayType,
      player_playhead_chapter_number: playerPlayheadChapterNumber,
      player_playhead_position: playerPlayheadPosition,
      player_version: playerVersion,
      player_auto_or_manual: playerAutoOrManual,
      player_event_originator: playerEventOriginator,
    };

    mediaSession.logPlay({
      customAttributes: mParticleGetEventData(defaultEventData),
    });
  } catch (e) {
    logger.error('Error occurred sending mParticlePlay', e);
  }
};

/**
 * Fires when the up next view appears
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.upNextType Up next Movie, Tv Series, or Episode
 * @param {string} eventData.playerCaptionLanguage IETF language tag - country + Locale
 * @param {string} eventData.playerCaptionSetting Tracks when a user enables or disables content
 * @param {string} eventData.playerCaptionTrackname Caption track name
 * @param {string} eventData.playerCaptionTracknumber Caption track number
 * @param {string} eventData.playerCastType The casting service used to cast media to device
 * @param {string} eventData.playerMediaContentType The type of content
 * @param {string} eventData.playerMediaAudioLanguage IETF language tag - country + Locale of the audiotrack
 * @param {string} eventData.playerMediaAudioTrackname The audio track name of the media file
 * @param {string} eventData.playerMediaAudioTracknumber The audio track number of the media file
 * @param {string} eventData.playerMediaBitrate Bitrate in kbps of the media at time of analytics event
 * @param {string} eventData.playerMediaEpisodeNumber Media episode number where applicable
 * @param {string} eventData.playerMediaGenre Genre array of the media
 * @param {string} eventData.playerMediaId The unique identifier for the media
 * @param {string} eventData.playerMediaLength The total length of the media in seconds
 * @param {string} eventData.playerMediaMilestonePercentage The percentage of video played eg. 25, 50, 75. Fired based on play head position.
 * @param {string} eventData.playerMediaName The friendly name of the media. Can be episode names, movie names etc.
 * @param {string} eventData.playerMediaParentalRatingIdentifier Parental rating of the media
 * @param {string} eventData.playerMediaReferrerId The content id of the previous media played
 * @param {string} eventData.playerMediaReferrerName The name of the previous media played
 * @param {string} eventData.playerMediaSeasonNumber The season number of a media where applicable
 * @param {string} eventData.playerMediaShowName Name of the show or franchise name the media belongs to
 * @param {string} eventData.playerMediaStreamType The type of stream content is being played on
 * @param {string} eventData.playerPlayType Tracks the type of media playback
 * @param {string} eventData.playerPlayheadChapterNumber Tracks the chapter the playhead is currently positioned in
 * @param {string} eventData.playerPlayheadPosition The playhead position in seconds of the media at the time of the event
 * @param {string} eventData.playerVersion The version of the video player
 */
const mParticleUpNextViewLoad = async ({
  upNextType,
  playerCaptionLanguage,
  playerCaptionSetting,
  playerCaptionTrackname,
  playerCaptionTracknumber,
  playerCastType,
  playerMediaContentType,
  playerMediaAudioLanguage,
  playerMediaAudioTrackname,
  playerMediaAudioTracknumber,
  playerMediaBitrate,
  playerMediaEpisodeNumber,
  playerMediaGenre,
  playerMediaId,
  playerMediaLength,
  playerMediaMilestonePercentage,
  playerMediaName,
  playerMediaParentalRatingIdentifier,
  playerMediaReferrerId,
  playerMediaReferrerName,
  playerMediaSeasonNumber,
  playerMediaShowName,
  playerMediaStreamType,
  playerPlayType,
  playerPlayheadChapterNumber,
  playerPlayheadPosition,
  playerVersion,
}) => {
  try {
    const defaultEventData = {
      up_next_type: upNextType,
      player_caption_language: playerCaptionLanguage,
      player_caption_setting: playerCaptionSetting,
      player_caption_trackname: playerCaptionTrackname,
      player_caption_tracknumber: playerCaptionTracknumber,
      player_cast_type: playerCastType,
      player_media_content_type: playerMediaContentType,
      player_media_audio_language: playerMediaAudioLanguage,
      player_media_audio_trackname: playerMediaAudioTrackname,
      player_media_audio_tracknumber: playerMediaAudioTracknumber,
      player_media_bitrate: playerMediaBitrate,
      player_media_episode_number: playerMediaEpisodeNumber,
      player_media_genre: playerMediaGenre,
      player_media_id: playerMediaId,
      player_media_length: playerMediaLength,
      player_media_milestone_percentage: playerMediaMilestonePercentage,
      player_media_name: playerMediaName,
      player_media_parental_rating_identifier:
        playerMediaParentalRatingIdentifier,
      player_media_referrer_id: playerMediaReferrerId,
      player_media_referrer_name: playerMediaReferrerName,
      player_media_season_number: playerMediaSeasonNumber,
      player_media_show_name: playerMediaShowName,
      player_media_stream_type: playerMediaStreamType,
      player_name: PLAYER_NAME,
      player_play_type: playerPlayType,
      player_playhead_chapter_number: playerPlayheadChapterNumber,
      player_playhead_position: playerPlayheadPosition,
      player_version: playerVersion,
    };

    const { name, eventType, data } = mediaSession.createPageEvent(
      'Up Next View Load',
      mParticleGetEventData(defaultEventData)
    );

    window.mParticle.logEvent(name, eventType, data);
  } catch (e) {
    logger.error('Error occurred sending mParticleUpNextViewLoad', e);
  }
};

/**
 * Fires a custom mParticle log event when an activation code is generated during the user authentication process
 * @param {string} activationCode The generated activation code for the device
 */
const mParticleUserDeviceActivationCodeGenerated = async (activationCode) => {
  try {
    const networkInfo = await getNetworkInformation();
    const defaultEventData = mParticleGetUserDefaultEventData(networkInfo);

    const eventData = {
      ...defaultEventData,
      device_activation_code: activationCode,
      activation_type: 'sign-in', // TODO: Modify when data is available
    };

    window.mParticle.logEvent(
      'Device Activation Code Generated',
      window.mParticle.EventType.Location,
      eventData
    );
  } catch (e) {
    logger.error(
      'Auth Error occurred sending mParticleAuthDeviceActivationCodeGenerated',
      e
    );
  }
};

/**
 * Fires a custom mParticle log event when an activation code times out during the user authentication process
 * @param {string} activationCode The generated activation code for the device
 */
const mParticleUserDeviceActivationCodeTimeout = async (activationCode) => {
  try {
    const networkInfo = await getNetworkInformation();
    const defaultEventData = mParticleGetUserDefaultEventData(networkInfo);

    const eventData = {
      ...defaultEventData,
      device_activation_code: activationCode,
    };

    window.mParticle.logEvent(
      'Device Activation Code Timeout',
      window.mParticle.EventType.Location,
      eventData
    );
  } catch (error) {
    logger.error(
      'Auth Error occurred sending mParticleUserDeviceActivationCodeTimeout',
      error
    );
  }
};

const mParticleGetUserDefaultEventData = (networkInfo) => {
  const { name, version } = getBrowserClientNameAndVersion();
  const deviceInfo = getDeviceInformation();
  return {
    platform_name: `${APP_CONFIG.APP_NAME}`,
    platform_device_name: getPlatformDeviceName(),
    platform_version:
      `${APP_CONFIG.PLATFORM}_${APP_CONFIG.PLATFORM_ID}` || null,
    platform_page_url: window.location.href,
    platform_page_name: getUserFriendlyPageName(),
    platform_device_id: deviceInfo.duid || null,
    platform_customer_id: '',
    platform_customer_type: '',
    platform_country_code: getLocalStorageItem(LOCAL_STORAGE.CLIENT_REGION),
    platform_client_os_version: '',
    platform_client_os: '',
    platform_client_ip_v6: '',
    platform_client_ip_v4: networkInfo?.ip,
    platform_browser_client_version: version,
    platform_browser_client_name: name,
  };
};

/**
 * Fired when we need data for an mParticle analytics call
 * @param {object} defaultEventData Default data sent with an event
 * @returns {object} eventData Event data conditionally including tv info
 */
export const mParticleGetEventData = (defaultEventData) => {
  let eventData = { ...defaultEventData };

  if (isTv()) {
    eventData = {
      ...eventData,
      platform_device_name: getPlatformDeviceName(),
      platform_name: `${APP_CONFIG.APP_NAME}`,
    };
  }

  return eventData;
};

/**
 * Fired when You May Also Like is in focus on the Asset Details Page.
 * @param {string} contentTileName The friendly name of the content tile clicked; Typically the show name of the underlying media
 * @param {string} pageSection Contains a user-friendly page section
 */
const mParticleDetailsInFocus = async ({ contentTileName, pageSection }) => {
  try {
    const defaultEventData = {
      platform_page_url: window.location.href,
      platform_page_section: pageSection,
      platform_page_name: getUserFriendlyPageName(),
      platform_content_tile_name: contentTileName,
    };

    window.mParticle.logEvent(
      'You Might Also Like Focus',
      window.mParticle.EventType.Navigation,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleDetailsInFocus', e);
  }
};

/**
 * Fired when You May Also Like is in focus on the Asset Details Page.
 * @param {string} contentTileName The friendly name of the content tile clicked; Typically the show name of the underlying media
 * @param {string} pageSection Contains a user-friendly page section
 * @param {string} hoveredTileName Contains the name of the asset which the user has hovered over.
 * @param {string} hoveredTileIndex The index of the hovered tile.
 */
const mParticleYMALHoverTile = async ({
  contentTileName,
  pageSection,
  hoveredTileName,
  hoveredTileIndex,
}) => {
  try {
    const defaultEventData = {
      platform_page_url: window.location.href,
      platform_page_section: pageSection,
      platform_page_name: getUserFriendlyPageName(),
      platform_content_tile_name: contentTileName,
      platform_YML_detailspage_name: hoveredTileName,
      platform_YML_detailspage_position: hoveredTileIndex,
    };

    window.mParticle.logEvent(
      'YML_DetailsPage_HoverOverTile',
      window.mParticle.EventType.Navigation,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleYMALHoverTile', e);
  }
};

/**
 * Fires wen loyalty player UI message is displayed
 * @param {string} loyaltyMessageType ‘Learn More’ or ‘Earn Points By Watching’
 * @param {string} LoyaltyMessageDisplayed  <the message that was displayed on-screen>
 * @param {string} contentPoints The points that content is worth
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.playerCaptionLanguage IETF language tag - country + Locale
 * @param {string} eventData.playerCaptionSetting Tracks when a user enables or disables content
 * @param {string} eventData.playerCaptionTrackname Caption track name
 * @param {string} eventData.playerCaptionTracknumber Caption track number
 * @param {string} eventData.playerCastType The casting service used to cast media to device
 * @param {string} eventData.playerMediaContentType The type of content
 * @param {string} eventData.playerMediaAudioLanguage IETF language tag - country + Locale of the audiotrack
 * @param {string} eventData.playerMediaAudioTrackname The audio track name of the media file
 * @param {string} eventData.playerMediaAudioTracknumber The audio track number of the media file
 * @param {string} eventData.playerMediaBitrate Bitrate in kbps of the media at time of analytics event
 * @param {string} eventData.playerMediaEpisodeNumber Media episode number where applicable
 * @param {string} eventData.playerMediaGenre Genre array of the media
 * @param {string} eventData.playerMediaId The unique identifier for the media
 * @param {string} eventData.playerMediaLength The total length of the media in seconds
 * @param {string} eventData.playerMediaMilestonePercentage The percentage of video played eg. 25, 50, 75. Fired based on play head position.
 * @param {string} eventData.playerMediaName The friendly name of the media. Can be episode names, movie names etc.
 * @param {string} eventData.playerMediaParentalRatingIdentifier Parental rating of the media
 * @param {string} eventData.playerMediaReferrerId The content id of the previous media played
 * @param {string} eventData.playerMediaReferrerName The name of the previous media played
 * @param {string} eventData.playerMediaSeasonNumber The season number of a media where applicable
 * @param {string} eventData.playerMediaShowName Name of the show or franchise name the media belongs to
 * @param {string} eventData.playerMediaStreamType The type of stream content is being played on
 * @param {string} eventData.playerPlayType Tracks the type of media playback
 * @param {string} eventData.playerPlayheadChapterNumber Tracks the chapter the playhead is currently positioned in
 * @param {string} eventData.playerPlayheadPosition The playhead position in seconds of the media at the time of the event
 * @param {string} eventData.playerVersion The version of the video player
 * @param {string} eventData.playerAutoOrManual Tracks how a play was initiated
 * @param {string} eventData.plaerEventOriginator Tracks whether play event was triggered by system or user
 */
const mParticlePlayerUILoyaltyMessage = async ({
  loyaltyMessageType,
  loyaltyMessageDisplayed,
  contentPoints,
  playerCaptionLanguage,
  playerCaptionSetting,
  playerCaptionTrackname,
  playerCaptionTracknumber,
  playerCastType,
  playerMediaContentType,
  playerMediaAudioLanguage,
  playerMediaAudioTrackname,
  playerMediaAudioTracknumber,
  playerMediaBitrate,
  playerMediaEpisodeNumber,
  playerMediaGenre,
  playerMediaId,
  playerMediaLength,
  playerMediaMilestonePercentage,
  playerMediaName,
  playerMediaParentalRatingIdentifier,
  playerMediaReferrerId,
  playerMediaReferrerName,
  playerMediaSeasonNumber,
  playerMediaShowName,
  playerMediaStreamType,
  playerPlayType,
  playerPlayheadChapterNumber,
  playerPlayheadPosition,
  playerVersion,
  playerAutoOrManual,
  playerEventOriginator,
}) => {
  try {
    const defaultEventData = {
      player_loyalty_playerUI_msgtype: loyaltyMessageType,
      player_loyalty_playerUI_msgdisplayed: loyaltyMessageDisplayed,
      player_loyalty_playerUI_numpoints: contentPoints,
      player_caption_language: playerCaptionLanguage,
      player_caption_setting: playerCaptionSetting,
      player_caption_trackname: playerCaptionTrackname,
      player_caption_tracknumber: playerCaptionTracknumber,
      player_cast_type: playerCastType,
      player_media_content_type: playerMediaContentType,
      player_media_audio_language: playerMediaAudioLanguage,
      player_media_audio_trackname: playerMediaAudioTrackname,
      player_media_audio_tracknumber: playerMediaAudioTracknumber,
      player_media_bitrate: playerMediaBitrate,
      player_media_episode_number: playerMediaEpisodeNumber,
      player_media_genre: playerMediaGenre,
      player_media_id: playerMediaId,
      player_media_length: playerMediaLength,
      player_media_milestone_percentage: playerMediaMilestonePercentage,
      player_media_name: playerMediaName,
      player_media_parental_rating_identifier:
        playerMediaParentalRatingIdentifier,
      player_media_referrer_id: playerMediaReferrerId,
      player_media_referrer_name: playerMediaReferrerName,
      player_media_season_number: playerMediaSeasonNumber,
      player_media_show_name: playerMediaShowName,
      player_name: PLAYER_NAME,
      player_media_stream_type: playerMediaStreamType,
      player_play_type: playerPlayType,
      player_playhead_chapter_number: playerPlayheadChapterNumber,
      player_playhead_position: playerPlayheadPosition,
      player_version: playerVersion,
      player_event_originator: playerEventOriginator,
      player_auto_or_manual: playerAutoOrManual,
    };

    window.mParticle.logEvent(
      'Player UI Loyalty message',
      window.mParticle.EventType.Location,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticlePlayerUILoyaltyMessage', e);
  }
};

/**
 * Fired when the user checks the box in settings to opt out of the loyalty program.
 * @param {boolean} loyaltyOptOut Boolean that refers to the user has opted out loyalty
 * @param {string} userId The id of the user
 * @param {string} userEmail the email of the user
 */
const mParticleOptOutLoyalty = async ({ loyaltyOptOut, userId, userEmail }) => {
  const networkInfo = await getNetworkInformation();
  const { name, version } = getBrowserClientNameAndVersion();
  const clientRegion = getLocalStorageItem(LOCAL_STORAGE.CLIENT_REGION);
  try {
    const defaultEventData = {
      loyalty_opt_out: loyaltyOptOut,
      mparticleuserID: userId,
      user_email_address: userEmail,
      platform_version:
        `${APP_CONFIG.PLATFORM}_${APP_CONFIG.PLATFORM_ID}` || null,
      platform_customer_id: '',
      platform_country_code: clientRegion?.toLowerCase() || null,
      platform_client_os_version: getDeviceOsVersion(),
      platform_client_os: getDeviceOS(),
      platform_client_ip_v4: networkInfo?.ip,
      platform_client_ip_v6: '',
      platform_browser_client_name: name,
      platform_browser_client_version: version,
    };

    window.mParticle.logEvent(
      'Opt out loyalty program',
      window.mParticle.EventType.UserPreference,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleOptOutLoyalty', e);
  }
};

/**
 * Fired when the user redeems a reward
 * @param {string} rewardType To check what kind of reward is the user redeeming
 * @param {string} rewardPoints The value of the reward being redeemed
 * @param {string} userId The id of the user
 * @param {string} userEmail the email of the user
 */
const mParticleLoyaltyRedeemReward = async ({
  rewardType,
  rewardPoints,
  userId,
  userEmail,
}) => {
  const networkInfo = await getNetworkInformation();
  const { name, version } = getBrowserClientNameAndVersion();
  const clientRegion = getLocalStorageItem(LOCAL_STORAGE.CLIENT_REGION);
  try {
    const defaultEventData = {
      loyalty_prize_redeemed: rewardType,
      points_redeemed: rewardPoints,
      mparticleuserID: userId,
      user_email_address: userEmail,
      platform_version:
        `${APP_CONFIG.PLATFORM}_${APP_CONFIG.PLATFORM_ID}` || null,
      platform_customer_id: '',
      platform_country_code: clientRegion?.toLowerCase() || null,
      platform_client_os_version: getDeviceOsVersion(),
      platform_client_os: getDeviceOS(),
      platform_client_ip_v4: networkInfo?.ip,
      platform_client_ip_v6: '',
      platform_browser_client_name: name,
      platform_browser_client_version: version,
    };

    window.mParticle.logEvent(
      'Loyalty reward redeemed',
      window.mParticle.EventType.UserPreference,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleLoyaltyRedeemReward', e);
  }
};

/**
 * Fires wen loyalty player UI message is displayed
 * @param {string} loyaltyMessageType ‘Learn More’ or ‘Earn Points By Watching’
 * @param {string} LoyaltyMessageDisplayed  <the message that was displayed on-screen>
 * @param {string} contentPoints The points that content is worth
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.playerCaptionLanguage IETF language tag - country + Locale
 * @param {string} eventData.playerCaptionSetting Tracks when a user enables or disables content
 * @param {string} eventData.playerCaptionTrackname Caption track name
 * @param {string} eventData.playerCaptionTracknumber Caption track number
 * @param {string} eventData.playerCastType The casting service used to cast media to device
 * @param {string} eventData.playerMediaContentType The type of content
 * @param {string} eventData.playerMediaAudioLanguage IETF language tag - country + Locale of the audiotrack
 * @param {string} eventData.playerMediaAudioTrackname The audio track name of the media file
 * @param {string} eventData.playerMediaAudioTracknumber The audio track number of the media file
 * @param {string} eventData.playerMediaBitrate Bitrate in kbps of the media at time of analytics event
 * @param {string} eventData.playerMediaEpisodeNumber Media episode number where applicable
 * @param {string} eventData.playerMediaGenre Genre array of the media
 * @param {string} eventData.playerMediaId The unique identifier for the media
 * @param {string} eventData.playerMediaLength The total length of the media in seconds
 * @param {string} eventData.playerMediaMilestonePercentage The percentage of video played eg. 25, 50, 75. Fired based on play head position.
 * @param {string} eventData.playerMediaName The friendly name of the media. Can be episode names, movie names etc.
 * @param {string} eventData.playerMediaParentalRatingIdentifier Parental rating of the media
 * @param {string} eventData.playerMediaReferrerId The content id of the previous media played
 * @param {string} eventData.playerMediaReferrerName The name of the previous media played
 * @param {string} eventData.playerMediaSeasonNumber The season number of a media where applicable
 * @param {string} eventData.playerMediaShowName Name of the show or franchise name the media belongs to
 * @param {string} eventData.playerMediaStreamType The type of stream content is being played on
 * @param {string} eventData.playerPlayType Tracks the type of media playback
 * @param {string} eventData.playerPlayheadChapterNumber Tracks the chapter the playhead is currently positioned in
 * @param {string} eventData.playerPlayheadPosition The playhead position in seconds of the media at the time of the event
 * @param {string} eventData.playerVersion The version of the video player
 * @param {string} eventData.playerAutoOrManual Tracks how a play was initiated
 */
const mParticleLoyaltyPostWatchMsg = async ({
  loyaltyMessageType,
  loyaltyMessageDisplayed,
  contentPoints,
  playerCaptionLanguage,
  playerCaptionSetting,
  playerCaptionTrackname,
  playerCaptionTracknumber,
  playerCastType,
  playerMediaContentType,
  playerMediaAudioLanguage,
  playerMediaAudioTrackname,
  playerMediaAudioTracknumber,
  playerMediaBitrate,
  playerMediaEpisodeNumber,
  playerMediaGenre,
  playerMediaId,
  playerMediaLength,
  playerMediaMilestonePercentage,
  playerMediaName,
  playerMediaParentalRatingIdentifier,
  playerMediaReferrerId,
  playerMediaReferrerName,
  playerMediaSeasonNumber,
  playerMediaShowName,
  playerMediaStreamType,
  playerPlayType,
  playerPlayheadChapterNumber,
  playerPlayheadPosition,
  playerVersion,
  playerAutoOrManual,
}) => {
  try {
    const defaultEventData = {
      player_loyalty_playerUI_msgtype: loyaltyMessageType,
      player_loyalty_playerUI_msgdisplayed: loyaltyMessageDisplayed,
      player_loyalty_playerUI_numpoints: contentPoints,
      player_caption_language: playerCaptionLanguage,
      player_caption_setting: playerCaptionSetting,
      player_caption_trackname: playerCaptionTrackname,
      player_caption_tracknumber: playerCaptionTracknumber,
      player_cast_type: playerCastType,
      player_media_content_type: playerMediaContentType,
      player_media_audio_language: playerMediaAudioLanguage,
      player_media_audio_trackname: playerMediaAudioTrackname,
      player_media_audio_tracknumber: playerMediaAudioTracknumber,
      player_media_bitrate: playerMediaBitrate,
      player_media_episode_number: playerMediaEpisodeNumber,
      player_media_genre: playerMediaGenre,
      player_media_id: playerMediaId,
      player_media_length: playerMediaLength,
      player_media_milestone_percentage: playerMediaMilestonePercentage,
      player_media_name: playerMediaName,
      player_media_parental_rating_identifier:
        playerMediaParentalRatingIdentifier,
      player_media_referrer_id: playerMediaReferrerId,
      player_media_referrer_name: playerMediaReferrerName,
      player_media_season_number: playerMediaSeasonNumber,
      player_media_show_name: playerMediaShowName,
      player_name: PLAYER_NAME,
      player_media_stream_type: playerMediaStreamType,
      player_play_type: playerPlayType,
      player_playhead_chapter_number: playerPlayheadChapterNumber,
      player_playhead_position: playerPlayheadPosition,
      player_version: playerVersion,
      player_auto_or_manual: playerAutoOrManual,
    };

    window.mParticle.logEvent(
      'Loyalty post watch message',
      window.mParticle.EventType.Location,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleLoyaltyPostWatchMsg', e);
  }
};

/**
 * Fired when the Zombie Mode is dismissed
 * @param {string} option option being selected when the user dismisses the popup
 */
const mParticleZombieModeDismissed = async (option) => {
  try {
    const defaultEventData = {
      option,
      platform_device_name: getPlatformDeviceName(),
      platform_name: `${APP_CONFIG.APP_NAME}`,
    };

    window.mParticle.logEvent(
      'Zombie Mode Dismiss',
      window.mParticle.EventType.Other,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleZombieModeDismissed', e);
  }
};
/**
 * Fires when “still watching“ is shown to the user
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.mediaDuration time in minutes without user interaction before the app elects to inquire.
 * @param {string} eventData.playerMediaName The friendly name of the media. Can be episode names, movie names etc.
 * @param {string} eventData.playerMediaStreamType The type of stream content is being played on
 * @param {string} eventData.playerMediaMilestonePercentage The percentage of video played eg. 25, 50, 75. Fired based on play head position.
 * @param {string} eventData.playerMediaShowName Name of the show or franchise name the media belongs to
 * @param {string} eventData.playerAutoOrManual Tracks how a play was initiated
 */
const mParticleZombieModeTimeout = async ({
  duration,
  playerMediaName,
  playerMediaStreamType,
  playerMediaMilestonePercentage,
  playerMediaShowName,
  playerAutoOrManual,
}) => {
  try {
    const defaultEventData = {
      duration,
      platform_device_name: getPlatformDeviceName(),
      platform_name: `${APP_CONFIG.APP_NAME}`,
      player_media_name: playerMediaName,
      player_media_stream_type: playerMediaStreamType,
      player_media_milestone_percentage: playerMediaMilestonePercentage,
      player_media_show_name: playerMediaShowName,
      player_auto_or_manual: playerAutoOrManual,
    };

    window.mParticle.logEvent(
      'Zombie Mode Timeout',
      window.mParticle.EventType.Other,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleZombieModeTimeout', e);
  }
};

const mParticleLoginEvent = async ({ email, userId }) => {
  const userIdentities = {
    email,
    customerid: userId,
  };

  window.mParticle.Identity.login({ userIdentities });
};

const mParticleLogoutEvent = async () => {
  window.mParticle.Identity.logout({});
};

const mParticleZombieModeDuration = async (duration, option) => {
  try {
    const defaultEventData = { duration, option };
    window.mParticle.logEvent(
      'Zombie Mode Duration',
      window.mParticle.EventType.Other,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleZombieModeDuration', e);
  }
};

/** * Fires when “sort modal“ is shown to the user and the user selects an option
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.sorType Type of sort selected by the user {Latest, Alpha, Reverse Alpha}
 * @param {string} eventData.categoryName The category name of the element clicked
 * @param {string} eventData.categoryPosition The position of the category clicked
 * @param {string} eventData.pageSection The page section of the button clicked
 * @param {string} eventData.buttonLabel button label of the pressed button
 */

const mParticleSortContentView = async ({
  sortType,
  categoryName,
  categoryPosition,
  pageSection,
  buttonLabel,
}) => {
  try {
    const defaultEventData = {
      sort_content_type: sortType,
      platform_category_name: categoryName,
      platform_category_position: categoryPosition,
      platform_page_name: getUserFriendlyPageName(),
      platform_page_section: pageSection,
      platform_page_url: window.location.href,
      platform_button_label: buttonLabel,
      platform_button_position: Number(categoryPosition) || 1,
      platform_device_name: getPlatformDeviceName(),
      platform_name: `${APP_CONFIG.APP_NAME}`,
    };

    window.mParticle.logEvent(
      'Button Click Event',
      window.mParticle.EventType.Navigation,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleSortContentView', e);
  }
};

/**
 * Fired when Search is clicked on menu
 * @param {string} eventId Event id generated to associate with submit event
 * *
 *  */

const mParticleSearchInitiate = async ({ eventId }) => {
  try {
    const defaultEventData = {
      platform_page_name: getUserFriendlyPageName(),
      platform_page_section:
        getUserFriendlyPageName()?.replace(' Page', '') || '',
      platform_page_url: window.location.href,
      event_id: eventId,
    };
    window.mParticle.logEvent(
      'Search Initiate',
      window.mParticle.EventType.Navigation,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleSearchInitiate', e);
  }
};

/**
 * Fired when Search is initiated on search page
 * @param {string} searchInitiateEventId Event id generated to associate with initiate event
 * @param {number} resultLength number of results in the results array
 * @param {string} searchTerm The search term that brought the results
 * *
 *  */

const mParticleSearchSubmit = async ({
  searchTerm,
  resultsLength,
  searchInitiateEventId,
}) => {
  try {
    const defaultEventData = {
      platform_page_name: getUserFriendlyPageName(),
      platform_page_section:
        getUserFriendlyPageName()?.replace(' Page', '') || '',
      platform_page_url: window.location.href,
      platform_search_term: searchTerm,
      platform_search_result_set: resultsLength,
      search_initiate_event: searchInitiateEventId,
    };
    window.mParticle.logEvent(
      'Search Submit',
      window.mParticle.EventType.Search,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleSearchSubmit', e);
  }
};

/**
 * Sends a custom mParticle event after user clicked to add or remove Watchlist content
 * @param {object} eventData The data to send with the event
 * @param {string} eventData.contentId the id of the content added
 * @param {string} eventData.buttonLabel the button label from where that was clicked
 * @param {string} eventData.buttonPosition The position of the button clicked
 * @param {string} eventData.pageSection The page section of the button clicked
 */
const mParticleWatchlistButtonClick = async ({
  contentId,
  buttonLabel,
  buttonPosition,
  pageSection,
}) => {
  try {
    const defaultEventData = {
      platform_name: `${APP_CONFIG.PLATFORM}`,
      platform_device_name: getPlatformDeviceName(),
      platform_page_name: getUserFriendlyPageName(),
      content_id: contentId,
      platform_button_label: buttonLabel,
      platform_button_position: buttonPosition,
      platform_page_section: pageSection,
      platform_page_url: window.location.href,
    };

    window.mParticle.logEvent(
      'Button Click Event',
      window.mParticle.EventType.Navigation,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleWatchlistButtonClick', e);
  }
};

const mParticle = {
  mParticleAddScript,
  mParticleAdBreakFinished,
  mParticleAdBreakStarted,
  mParticleAdRequest,
  mParticleAdFinished,
  mParticleAdStarted,
  mParticleAdError,
  mParticleButtonClick,
  mParticleContentTileClick,
  mParticleYMALContentClick,
  mParticleUpNextHover,
  mParticleCreateMediaSession,
  mParticleGetInitialEventsStatus,
  mParticleGetProgressEventsToSend,
  mParticleMediaSessionStart,
  mParticleMediaSessionEnd,
  mParticleMenuClick,
  mParticlePaused,
  mParticlePlay,
  mParticlePlaybackFinished,
  mParticlePlayerError,
  mParticlePlayheadPosition,
  mParticlePlaying,
  mParticleUpNextViewLoad,
  mParticleUserDeviceActivationCodeGenerated,
  mParticleUserDeviceActivationCodeTimeout,
  mParticleDetailsInFocus,
  mParticleYMALHoverTile,
  mParticlePlayerUILoyaltyMessage,
  mParticleLoyaltyRedeemReward,
  mParticleOptOutLoyalty,
  mParticleLoyaltyPostWatchMsg,
  mParticleLoginEvent,
  mParticleLogoutEvent,
  mParticleZombieModeDismissed,
  mParticleZombieModeTimeout,
  mParticleZombieModeDuration,
  mParticleSearchInitiate,
  mParticleSearchSubmit,
  mParticleSortContentView,
  mParticleWatchlistButtonClick,
};

export default mParticle;
