/**
 * Additional response error codes for the v2.0.0 Crackle API can be found at
 * https://api-v2-versioned-doc.crackle.com/#:~:text=RESPONSE%20ERROR%20CODES
 */
const CRACKLE_API_RESPONSE_ERROR_CODES = {
  EXPIRED_TOKEN_PROVIDED: 136,
  RESOURCE_NOT_FOUND: 101,
  REQUEST_NOT_AUTHORIZED: 133,
  PLAYLIST_NOT_FOUND: 260,
  BLOCK_NOT_FOUND: 304,
  CONTENT_DOES_NOT_EXIST: 241,
  CONTENT_UNAVAILABLE: 211,
};

const CRACKLE_API_RESPONSE_SUCCESS_CODES = {
  NON_AUTHORITATIVE_INFO: 203,
  NO_CONTENT: 204,
};

// Check for more info
// https://crackle.atlassian.net/wiki/spaces/CP/pages/1477509123/Loyalty+and+Rewards#Reward-Redemptions-Error-Codes

const LOYALTY_REDEMPTION_STATUS = {
  FUTURE_REWARD: 101,
  EXPIRED_REWARD: 102,
  EXCEEDED_REDEMPTION: 103,
  USER_NOT_OPT_IN: 104,
  NOT_ENOUGH_POINTS: 105,
  EXCEEDED_REDEMPTION_PER_USER: 106,
  REWARD_NO_STACKABLE: 107,
  EXCEEDED_REDEMPTION_GATE_TIME: 108,
  REWARD_WAIT_TIME: 109,
};

export {
  CRACKLE_API_RESPONSE_ERROR_CODES,
  CRACKLE_API_RESPONSE_SUCCESS_CODES,
  LOYALTY_REDEMPTION_STATUS,
};
