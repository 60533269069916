import APP_CONFIG from '../config/app-config.json';

// Function that retrieves the Storage key with the prefix of the brand and the platform
export const generateNewStorageKey = (key) => {
  return `${APP_CONFIG.BRAND}-${APP_CONFIG.PLATFORM}-${key}`;
};

// Verifies if the item with the passed key is stored in local or session storage
const verifyItemInStorage = (key, storage) => {
  return !!storage.getItem(key);
};

// This functions replace the old nomenclature of the objects in local/session storage for the new one
// where the brand and the platform is visible, if the new nomenclature is already set it does nothing
export const verifyAndUpdateStorageItem = (key, storage) => {
  const newStorageKey = generateNewStorageKey(key);
  const storedValue = storage.getItem(key);

  if (verifyItemInStorage(key, storage) && storedValue) {
    storage.removeItem(key);
    storage.setItem(newStorageKey, storedValue);
  }
};

// Local Storage functions
const getLocalStorageItem = (key) => {
  verifyAndUpdateStorageItem(key, localStorage);

  return localStorage.getItem(generateNewStorageKey(key));
};

const setLocalStorageItem = (key, value) => {
  verifyAndUpdateStorageItem(key, localStorage);

  localStorage.setItem(generateNewStorageKey(key), value);
};

const removeLocalStorageItem = (key) => {
  verifyAndUpdateStorageItem(key, localStorage);

  localStorage.removeItem(generateNewStorageKey(key));
};

export { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem };
