// ENUMS
import PARENTAL_CONTROLS from './parental-controls';
import APP_CONFIG from '../config/app-config.json';
import { getAppVersion as getAppVersionUtils } from '../utils/utils';

export const SETTING_PATHS = {
  PRIVACY_POLICY: 'privacy-policy',
  ABOUT: 'about-us',
  TOS: 'terms-of-service',
  PRIVACY: 'privacy',
  DEV: 'dev-settings',
  AD_SETTINGS: 'ad-settings',
  CPRA: 'california-privacy-notice',
};

export const SETTING_TYPE = {
  TOGGLE: 'toggle',
  INFO: 'info',
};

export const SETTING_ELEMENT_ID = {
  HEADER: 'settingsHeader',
  APP_VERSION_TILE: 'support-app-version',
  KIDS_TILE: 'kids',
};

function getAppVersion() {
  return `Version: ${getAppVersionUtils() || 0}`;
}

function getAppBranch() {
  if (APP_CONFIG.ENV !== 'production' && APP_CONFIG.ENV !== 'preprod') {
    return `\u00A0\u00A0\u00A0\u00A0Branch: ${APP_CONFIG.GIT_CURRENT_BRANCH}`;
  }
  return '';
}

function getAppCommit() {
  if (APP_CONFIG.ENV !== 'production' && APP_CONFIG.ENV !== 'preprod') {
    return `\u00A0\u00A0\u00A0\u00A0Commit: ${APP_CONFIG.GIT_CURRENT_COMMIT}`;
  }
  return '';
}

function getAppEnv() {
  if (APP_CONFIG.ENV !== 'production' && APP_CONFIG.ENV !== 'preprod') {
    return `\u00A0\u00A0\u00A0\u00A0Env: ${APP_CONFIG.ENV}`;
  }
  return '';
}

const SETTINGS_DATA = [
  {
    ID: 'parental_settings',
    TITLE: 'PARENTAL_SETTINGS',
    OPTIONS: [
      {
        ID: PARENTAL_CONTROLS.KIDS,
        TYPE: SETTING_TYPE.TOGGLE,
        TITLE: 'Kids Mode',
        DESCRIPTION: 'Only show content rated PG and TV-Y7 and below.',
      },
      {
        ID: PARENTAL_CONTROLS.TEENS,
        TYPE: SETTING_TYPE.TOGGLE,
        TITLE: 'Teen Mode',
        DESCRIPTION: 'Only show content rated PG-13 and TV-Y14 and below.',
      },
      {
        ID: PARENTAL_CONTROLS.GROWN_UPS,
        TYPE: SETTING_TYPE.TOGGLE,
        TITLE: 'Grown-Up Mode',
        DESCRIPTION: 'Show all content without restriction.',
      },
    ],
  },
  {
    ID: 'feedback_support',
    TITLE: 'FEEDBACK_SUPPORT',
    OPTIONS: [
      {
        ID: PARENTAL_CONTROLS.PIN_RESET,
        TITLE: 'Reset PIN',
        TYPE: SETTING_TYPE.INFO,
        DESCRIPTION: 'SETTINGS_PIN_RESET_DESCRIPTION',
      },
      {
        ID: 'support-feedback',
        TITLE: 'SUPPORT',
        TYPE: SETTING_TYPE.INFO,
        DESCRIPTION: 'SETTINGS_SUPPORT_DESCRIPTION',
      },
      {
        ID: 'support-app-version',
        TITLE: 'App Version',
        TYPE: SETTING_TYPE.INFO,
        DESCRIPTION: `${getAppVersion()} ${getAppEnv()} ${getAppBranch()} ${getAppCommit()}`,
      },
    ],
  },
];

export default SETTINGS_DATA;
