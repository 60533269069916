// Enums
import PARENTAL_CONTROLS from '../enums/parental-controls';
import SESSION_HISTORY_TYPES from '../enums/session-history';
import LOCAL_STORAGE from '../enums/local-storage';
import PLAYER_PLAY_TYPES from '../enums/player-play-types';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from './local-storage';

import { stringifyNumber } from './content-details';

/**
 * Make sure our local session object is setup for us
 */
const initSessionHistory = () => {
  // Reset sort history every time when user launch the app
  removeLocalStorageItem(LOCAL_STORAGE.SORT_HISTORY);

  const sessionHistory = getLocalStorageItem(LOCAL_STORAGE.SESSION_HISTORY);
  const parentalControlsHistory = getLocalStorageItem(
    LOCAL_STORAGE.PARENTAL_CONTROLS_HISTORY
  );

  // Save brand new parental controls info
  if (!parentalControlsHistory) {
    const valueObj = {
      [PARENTAL_CONTROLS.MODE_KEY]: PARENTAL_CONTROLS.GROWN_UPS,
      [PARENTAL_CONTROLS.LOCK_KEY]: false,
      [PARENTAL_CONTROLS.PIN_KEY]: '',
    };

    setLocalStorageItem(
      LOCAL_STORAGE.PARENTAL_CONTROLS_HISTORY,
      JSON.stringify(valueObj)
    );
  }

  if (!sessionHistory) {
    // Save brand new object
    setLocalStorageItem(
      LOCAL_STORAGE.SESSION_HISTORY,
      JSON.stringify({
        movies: [],
        tvShows: {},
        referrer: null,
      })
    );
  } else {
    // Make sure our content types exist in current session history object,
    // but use a working session history object so we don't overwrite
    // previously added properties in the case where more than one is absent
    const currentSessionHistoryObj = JSON.parse(sessionHistory);
    let workingSessionHistoryObj = JSON.parse(sessionHistory);

    if (!currentSessionHistoryObj.movies) {
      workingSessionHistoryObj = {
        ...workingSessionHistoryObj,
        movies: [],
      };
    }

    if (!currentSessionHistoryObj.tvShows) {
      workingSessionHistoryObj = {
        ...workingSessionHistoryObj,
        tvShows: {},
      };
    }

    if (!currentSessionHistoryObj.referrer) {
      workingSessionHistoryObj = {
        ...workingSessionHistoryObj,
        referrer: null,
      };
    }

    if (
      !currentSessionHistoryObj.movies ||
      !currentSessionHistoryObj.tvShows ||
      !currentSessionHistoryObj.referrer
    ) {
      setLocalStorageItem(
        LOCAL_STORAGE.SESSION_HISTORY,
        JSON.stringify({
          ...workingSessionHistoryObj,
        })
      );
    }

    // Update session history entries to use the correct session history
    // version if necessary
    const haveMovies = !!workingSessionHistoryObj.movies.length;
    const haveTvShows = !!Object.keys(workingSessionHistoryObj.tvShows).length;
    let sessionHistoryVersionUpdated = false;

    if (haveMovies) {
      for (const [index, movie] of workingSessionHistoryObj.movies.entries()) {
        const { mediaId, isLatestVersion } =
          isLatestSessionHistoryVersion(movie);

        if (!isLatestVersion) {
          sessionHistoryVersionUpdated = true;
          const updatedMovie = {
            ...movie,
            mediaId: getVersionedMediaId(mediaId),
          };
          workingSessionHistoryObj.movies[index] = updatedMovie;
        }
      }
    }

    if (haveTvShows) {
      for (const [seriesId, episodes] of Object.entries(
        workingSessionHistoryObj.tvShows
      )) {
        for (const [index, episode] of episodes.entries()) {
          const { mediaId, isLatestVersion } =
            isLatestSessionHistoryVersion(episode);

          if (!isLatestVersion) {
            sessionHistoryVersionUpdated = true;
            const updatedEpisode = {
              ...episode,
              mediaId: getVersionedMediaId(mediaId),
            };
            workingSessionHistoryObj.tvShows[seriesId][index] = updatedEpisode;
          }
        }
      }
    }

    if (sessionHistoryVersionUpdated) {
      setLocalStorageItem(
        LOCAL_STORAGE.SESSION_HISTORY,
        JSON.stringify({
          ...workingSessionHistoryObj,
        })
      );
    }
  }
};

/**
 * Determine if stored content has latest session history version
 * @param {*} content Content representing a single movie or tv show
 * @returns {object} An object containing a mediaId and a boolean indicating if it has the latest version appended
 */
const isLatestSessionHistoryVersion = (content) => {
  const versionedMediaIdParts = content.mediaId.split('_v');
  const mediaId = versionedMediaIdParts[0];
  const isLatestVersion =
    +versionedMediaIdParts[1] === SESSION_HISTORY_TYPES.VERSION;

  return { mediaId, isLatestVersion };
};

/**
 * Get parsed object from localStorage
 *
 * @return {object} Object from localStorage
 */
const getSavedObj = () => {
  const sessionHistory = getLocalStorageItem(LOCAL_STORAGE.SESSION_HISTORY);
  return JSON.parse(sessionHistory);
};

/**
 * Make sure the item we want to save doesn't already exist
 * If it does, remove it so we can save new version later
 *
 * @param {string} type Type of content from SESSION_HISTORY_TYPES
 * @param {string} mediaId Media ID of content
 * @param {int} season If type is TV, you must include season number
 * @param {int} episode If type is TV, you must include episode number
 * @param {string} seriesId If type is TV, the seriesID is used as a key to store/retrieve episodes and must be included
 *
 * @return {object} savedObj Updated savedObj object
 */
const removeIfFound = (
  type = '',
  mediaId = '',
  season = 0,
  episode = 0,
  seriesId = null
) => {
  const savedObj = getSavedObj();

  switch (type) {
    case SESSION_HISTORY_TYPES.TV:
      if (!savedObj.tvShows[seriesId]) break;
      for (let i = 0; i < savedObj.tvShows[seriesId].length; i++) {
        const item = savedObj.tvShows[seriesId][i];

        // Remove if matching mediaID + season + episode already saved
        if (
          item.mediaId === mediaId &&
          Number(item.season) === Number(season) &&
          Number(item.episode) === Number(episode)
        ) {
          savedObj.tvShows[seriesId].splice(i, 1);
        }
      }
      break;
    case SESSION_HISTORY_TYPES.MOVIE:
      for (let i = 0; i < savedObj.movies.length; i++) {
        const item = savedObj.movies[i];

        // Remove if matching mediaID already saved
        if (item.mediaId === mediaId) {
          savedObj.movies.splice(i, 1);
        }
      }
      break;
    case SESSION_HISTORY_TYPES.REFERRER:
      if (savedObj.referrer === null || savedObj.referrer.mediaId === mediaId) {
        delete savedObj.referrer;
      }
      break;
    default:
      break;
  }

  return savedObj;
};

/**
 * Get a progress/duration or referrer value from session history
 *
 * @param {string} type Type of content from SESSION_HISTORY_TYPES
 * @param {string} mediaId Media ID of content
 * @param {int} season If type is TV, you must include season number
 * @param {int} episode If type is TV, you must include episode number
 *
 * @return {(boolean|object)} If the mediaId was found in session storage
 */
const getSessionHistory = (
  type = '',
  mediaId = '',
  season = 0,
  episode = 0,
  seriesId = null
) => {
  let found = null;
  const savedObj = getSavedObj();
  const versionedMediaId = getVersionedMediaId(mediaId);

  if (type) {
    switch (type) {
      case SESSION_HISTORY_TYPES.TV:
        if (!savedObj?.tvShows[seriesId]) break;

        // if seriesId was provided but no mediaId, we return
        // the watchable episode from that serie
        if (!versionedMediaId && savedObj?.tvShows[seriesId]) {
          found = getWatchableEpisodeFromSeries(seriesId);
        }

        if (season > 0 && episode >= 0 && versionedMediaId) {
          found = savedObj.tvShows[seriesId]?.find(
            (item) =>
              item.mediaId === versionedMediaId &&
              Number(item.season) === Number(season) &&
              Number(item.episode) === Number(episode)
          );
        }
        break;
      case SESSION_HISTORY_TYPES.MOVIE:
        if (!savedObj?.movies && versionedMediaId) break;
        found =
          savedObj &&
          savedObj.movies.find((item) => item.mediaId === versionedMediaId);
        break;
      default:
        break;
    }
  }

  if (type && !mediaId) {
    switch (type) {
      case SESSION_HISTORY_TYPES.REFERRER:
        if (!savedObj?.referrer) break;
        found = savedObj.referrer;
        break;
      default:
        break;
    }
  }

  if (!found) {
    found = false;
  }

  return found;
};

/**
 * Set a progress/duration or referrer value in session history
 * If you need to update an existing item in session storage, this function will
 * remove the old and add the new (overwrite/update the existing)
 *
 * @param {string} type Type of content from SESSION_HISTORY_TYPES
 * @param {string} mediaId Media ID of content
 * @param {string} title Title of current conent
 * @param {string} progress Progress/duration of current content
 * @param {object[]} progressEventsStatus Array of progress event objects
 * @param {string} playType Indicates if new, resume, or restart play
 * @param {int} season If type is TV, you must include season number
 * @param {int} episode If type is TV, you must include episode number
 * @param {string} seriesId If type is TV, the seriesID is used as a key to store/retrieve episodes and must be included
 *
 * @return {boolean} If saving this in session history was successful
 */
const setSessionHistory = (
  type = '',
  mediaId = '',
  title = '',
  progress = '',
  progressEventsStatus = null,
  playType = null,
  contentPoster = '',
  mediaEndTime = null,
  season = 0,
  episode = null,
  seriesId = null,
  nextEpisode = null,
  contentId = null
) => {
  let saved = false;
  let savedObj;

  const versionedMediaId = getVersionedMediaId(mediaId);
  const hasProgress = progress !== '';

  if (type && versionedMediaId && title && hasProgress) {
    switch (type) {
      case SESSION_HISTORY_TYPES.TV:
        if (season > 0 && episode >= 0) {
          savedObj = removeIfFound(
            type,
            versionedMediaId,
            season,
            episode,
            seriesId
          );

          const prevSeriesData = { ...savedObj.tvShows };

          const prevEpisodesData = savedObj.tvShows[seriesId];
          if (prevEpisodesData) clearLastPlayed(prevEpisodesData);
          const prevEpisodes = prevEpisodesData || [];

          const nextEpisodeClean = { ...nextEpisode };
          delete nextEpisodeClean.images;

          const episodeData = {
            mediaId: versionedMediaId,
            title,
            progress,
            progressEventsStatus,
            playType,
            season,
            episode,
            lastPlayed: true,
            nextEpisode: nextEpisodeClean,
            mediaEndTime,
            contentPoster,
            timestamp: new Date().getTime(),
          };

          const sessionData =
            Object.keys(prevSeriesData).length < 1
              ? { [seriesId]: [episodeData] }
              : {
                  ...prevSeriesData,
                  [seriesId]: [episodeData, ...prevEpisodes], // Saving the last played episode data in the first position for easy access
                };

          setLocalStorageItem(
            LOCAL_STORAGE.SESSION_HISTORY,
            JSON.stringify({
              ...savedObj,
              tvShows: sessionData,
              lastUpdated: new Date().getTime(),
            })
          );

          saved = true;
        }
        break;
      case SESSION_HISTORY_TYPES.MOVIE:
        savedObj = removeIfFound(type, versionedMediaId);

        setLocalStorageItem(
          LOCAL_STORAGE.SESSION_HISTORY,
          JSON.stringify({
            ...savedObj,
            movies: [
              ...savedObj.movies,
              {
                mediaId: versionedMediaId,
                title,
                progress,
                progressEventsStatus,
                playType,
                contentPoster,
                mediaEndTime,
                timestamp: new Date().getTime(),
              },
            ],
            lastUpdated: new Date().getTime(),
          })
        );

        saved = true;
        break;
      default:
        break;
    }
  }

  if (type && mediaId && title && !hasProgress) {
    switch (type) {
      case SESSION_HISTORY_TYPES.REFERRER:
        savedObj = removeIfFound(type, mediaId);

        setLocalStorageItem(
          LOCAL_STORAGE.SESSION_HISTORY,
          JSON.stringify({
            ...savedObj,
            referrer: {
              mediaId,
              title,
              contentId,
            },
          })
        );

        saved = true;
        break;
      default:
        break;
    }
  }

  return saved;
};

/**
 * Delete a progress/duration or referrer value from session history
 *
 * @param {string} type Type of content from SESSION_HISTORY_TYPES
 * @param {string} mediaId Media ID of content
 * @param {int} season If type is TV, you must include season number
 * @param {int} episode If type is TV, you must include episode number
 * @param {string} seriesId If type is TV, the seriesID is used as a key to store/retrieve episodes and must be included
 */
const deleteSessionHistory = (
  type = '',
  mediaId = '',
  season = 0,
  episode = 0,
  seriesId = null
) => {
  const versionedMediaId = getVersionedMediaId(mediaId);

  if (type && mediaId && versionedMediaId) {
    let savedObj;

    switch (type) {
      case SESSION_HISTORY_TYPES.TV:
        if (season > 0 && episode >= 0) {
          savedObj = removeIfFound(
            type,
            versionedMediaId,
            season,
            episode,
            seriesId
          );

          setLocalStorageItem(
            LOCAL_STORAGE.SESSION_HISTORY,
            JSON.stringify({
              ...savedObj,
            })
          );
        }
        break;
      case SESSION_HISTORY_TYPES.MOVIE:
        savedObj = removeIfFound(type, versionedMediaId);

        setLocalStorageItem(
          LOCAL_STORAGE.SESSION_HISTORY,
          JSON.stringify({
            ...savedObj,
          })
        );
        break;
      case SESSION_HISTORY_TYPES.REFERRER:
        savedObj = removeIfFound(type, mediaId);

        setLocalStorageItem(
          LOCAL_STORAGE.SESSION_HISTORY,
          JSON.stringify({
            ...savedObj,
          })
        );
        break;
      default:
        break;
    }
  }
};

/**
 * This function stores the values of parental control fields
 * @param {string} property Name of the property we want to store
 * @param {string || object || boolean} value Value of the property
 */
const setParentalControlsHistory = (property, value) => {
  const storedValues = getLocalStorageItem(
    LOCAL_STORAGE.PARENTAL_CONTROLS_HISTORY
  );

  if (storedValues) {
    const parentalControlsHistory = JSON.parse(storedValues);
    setLocalStorageItem(
      LOCAL_STORAGE.PARENTAL_CONTROLS_HISTORY,
      JSON.stringify({
        ...parentalControlsHistory,
        [property]: value,
      })
    );
  }
};

/**
 * Helper function to set the lastPlayed property to false for all episodes in a series
 *
 * @param {Array} seriesData takes series data and updates the lastPlayed property
 *
 */
const clearLastPlayed = (seriesData) => {
  if (seriesData.length < 1) return;
  seriesData.forEach((item) => {
    item.lastPlayed = false;
  });
};

/**
 * Retrieves the last played episode of a series
 * @param {string} seriesId The ID of the stored series
 * @returns {object} Episode item
 */
const getLastPlayedEpisode = (seriesId) => {
  const savedObj = getSavedObj();
  const found = savedObj.tvShows[seriesId]?.find(
    (item) => item.lastPlayed === true
  );
  return found;
};

/**
 * Get a versioned Media ID
 * @param {*} mediaId Media ID of content
 * @returns Media ID of content with session history version appended
 */
const getVersionedMediaId = (mediaId) =>
  // For versioning movie and tv show session history entries.
  // Note that this is NOT used for 'referrer' because the mediaId value stored for the referrer is actually from 'source.externalId'
  mediaId === '' ? mediaId : `${mediaId}_v${SESSION_HISTORY_TYPES.VERSION}`;

/**
 * Gets the episode viewable of the series
 *
 * when watching an episode, getNextEpisode() does the logic to get the next episode
 * we save that info on session history
 *
 * if no next episode is saved check for the fullyWatchedSeries flag
 *
 * @param {string} seriesId The ID of the stored series
 * @returns {object} Episode data
 */
const getWatchableEpisodeFromSeries = (seriesId) => {
  const lastPlayed = getLastPlayedEpisode(seriesId);

  // if last played is not fully watched return it
  // check progress, credit market & duration
  if (
    lastPlayed &&
    (!lastPlayed.mediaEndTime ||
      Math.floor(lastPlayed.mediaEndTime) > lastPlayed.progress)
  ) {
    return {
      season: stringifyNumber(lastPlayed?.season),
      episode: stringifyNumber(lastPlayed?.episode),
      id: lastPlayed?.mediaId?.replace('_v2', ''),
      fullyWatchedSeries: false,
      playType: lastPlayed.playType,
      mediaEndTime: lastPlayed.mediaEndTime,
      progress: lastPlayed.progress,
      seriesTitle: lastPlayed.title.split(' - ')[0],
    };
  }

  // otherwise send the next
  const nextEpisodeInfo = {
    season: stringifyNumber(lastPlayed?.nextEpisode?.seasonNumber),
    episode: stringifyNumber(lastPlayed?.nextEpisode?.sequenceNumber),
    mediaEndTime: lastPlayed?.nextEpisode
      ? lastPlayed.nextEpisode.duration * 60
      : 0, // duration it is in minutes
    id: lastPlayed?.nextEpisode?.id,
    fullyWatchedSeries:
      !!lastPlayed?.nextEpisode && // this is to check that at least was attempted to save a next episode (for existing history)
      Object.keys(lastPlayed?.nextEpisode).length === 0 && // this is to check that next episode is empty
      !!lastPlayed, // if I saw at least one episode, but I don't have any next episode
    playType: PLAYER_PLAY_TYPES.NEW,
  };

  return nextEpisodeInfo;
};

export {
  deleteSessionHistory,
  getSessionHistory,
  getLastPlayedEpisode,
  initSessionHistory,
  setSessionHistory,
  setParentalControlsHistory,
  getWatchableEpisodeFromSeries,
};
