import { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

// context
import useGlobalContext from './use-global-context';

import { fetchUserProfile } from '../crackle-sdk/v1/api/user';
import { isFeatureFlagEnabled } from '../utils/feature-flags';

import { getFirstRedeemableReward } from '../crackle-sdk/v1/api/loyalty';

import { LOYALTY_USER_POINT_STATUS } from '../enums/loyalty';

function useLoyaltyUser(userId) {
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [firstRedeemableReward, setFirstRedeemableReward] = useState(null);

  const { loyalty, setLoyaltyOptIn, setLoyaltyUserPoints } = useGlobalContext(
    (state) => ({
      loyalty: state.loyalty,
      setLoyaltyOptIn: state.setLoyaltyOptIn,
      setLoyaltyUserPoints: state.setLoyaltyUserPoints,
      setLoyaltyFreePassActive: state.setLoyaltyFreePassActive,
      setLoyaltyFreePassEndTime: state.setLoyaltyFreePassEndTime,
    }),
    shallow
  );

  const fetchLoyaltyUserData = useCallback(async () => {
    setLoading(true);

    if (userId) {
      const userData = await fetchUserProfile(userId);
      const firstReward = await getFirstRedeemableReward(userId);

      setFirstRedeemableReward(firstReward);
      setLoyaltyOptIn(userData.userLoyaltyOptIn.status);
      setLoyaltyUserPoints(userData.userLoyaltyPoints.points);
    }

    setLoading(false);
    setFetched(true);
  }, [setLoyaltyOptIn, setLoyaltyUserPoints, userId]);

  const refetch = () => {
    fetchLoyaltyUserData();
  };

  const getStatus = () => {
    let status = LOYALTY_USER_POINT_STATUS.OPTED_OUT;

    if (userId && loyalty.optedIn) {
      if (loyalty.userPoints > 0) {
        if (firstRedeemableReward) {
          if (firstRedeemableReward.redemption.status) {
            status = LOYALTY_USER_POINT_STATUS.OPTED_IN_AVAILABLE_REWARDS;
          } else {
            status =
              LOYALTY_USER_POINT_STATUS.OPTED_IN_AVAILABLE_REWARDS_NOT_ENOUGH_POINS;
          }
        } else {
          status = LOYALTY_USER_POINT_STATUS.OPTED_IN_NO_AVAILABLE_REWARDS;
        }
      } else {
        status = LOYALTY_USER_POINT_STATUS.OPTED_IN_NO_POINTS;
      }
    }

    return status;
  };

  useEffect(() => {
    if (isFeatureFlagEnabled('loyalty')) {
      if (!fetched) {
        fetchLoyaltyUserData();
      }
    }
  }, [fetchLoyaltyUserData, fetched]);

  return {
    loyalty: {
      ...loyalty,
      userPointsStatus: getStatus(),
      firstRedeemableReward,
    },
    loading: loading && !fetched,
    refetch,
  };
}

export default useLoyaltyUser;
