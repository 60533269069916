import { makeRequest } from './common/base';
const endpoints = {
  getApplicationConfiguration: () => '/appconfig',
};
/**
 * Gets an app configuration object
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
async function fetchAppConfig() {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(
    endpoints.getApplicationConfiguration(),
    requestOptions,
    true
  );
}

export { fetchAppConfig };
