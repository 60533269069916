import { Fragment, useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// Components
import ContentRow from '../content-row/content-row';
import LazyLoader from '../lazy-loader/lazy-loader';
import HomePointsBar from '../loyalty-home-points/home-points-bar';
import FreePassTile from '../free-pass-tile/free-pass-tile';
import LoadingSpinner from '../loading-spinner/loading-spinner';
import ContinueWatchingRow from '../continue-watching-row/continue-watching-row';

// utils
import { isFeatureFlagEnabled } from '../../utils/feature-flags';

// enums
import CONTENT_ROW_TYPES from '../../enums/content-row-types';
const ROW_TYPES_CONFIG = {
  'Standard Display': CONTENT_ROW_TYPES.STANDARD,
  'Details Display': CONTENT_ROW_TYPES.DETAILS,
  'Original Display': CONTENT_ROW_TYPES.ORIGINALS,
};
const CONTINUE_WATCHING_ROW_INDEX = 1;

function HomeContentRows({
  rows,
  showSeeAll,
  heroInitiallyCollapsed,
  handleContentSelected,
  handleRowLoaded,
  handleBackFromRow,
  forceLoadRow,
  freePassActive,
  pointsBarRef,
  lazyLoaderOptions,
  rowDownMovement,
  rowUpMovement,
  onSeeAllClick,
  rowIdFocus,
  rowScroll,
  contentIdFocus,
  loyaltyPointsBarDownMovement,
  loyaltyPointsBarUpMovement,
  updateFailedRowsIndexes,
  hoverEnabled,
  showContinueWatchingRow,
  continueWatchingCuration,
}) {
  const generateRowIndex = useCallback(
    (index) => {
      if (
        index >= CONTINUE_WATCHING_ROW_INDEX &&
        showContinueWatchingRow &&
        continueWatchingCuration?.length > 0
      ) {
        return index + 1;
      }

      return index;
    },
    [continueWatchingCuration, showContinueWatchingRow]
  );
  return (
    <div
      className={classnames('home__content-rows', {
        'hero-collapsed': heroInitiallyCollapsed,
      })}
      id="rows-container"
    >
      {rows?.length > 0 ? (
        rows.map((row, index) => {
          return (
            <Fragment key={row.id}>
              <LazyLoader
                key={'lazy' + row.id}
                rowUrl={row.url}
                rowId={row.id}
                rowIndex={generateRowIndex(index)}
                rowContentId={row.guid}
                rowType={ROW_TYPES_CONFIG[row.displayStyle]}
                showSeeAll={showSeeAll}
                options={lazyLoaderOptions}
                forceLoad={forceLoadRow === generateRowIndex(index)}
                updateFailedRowsIndexes={updateFailedRowsIndexes}
              >
                <ContentRow
                  key={row.id}
                  type={ROW_TYPES_CONFIG[row.displayStyle]}
                  title={row.title}
                  rowId={row.id?.toString()}
                  rowIndex={generateRowIndex(index)}
                  initialRowScroll={rowScroll}
                  onContentSelected={handleContentSelected}
                  onRowLoaded={handleRowLoaded}
                  onRowBack={handleBackFromRow}
                  rowDownMovement={rowDownMovement}
                  rowUpMovement={rowUpMovement}
                  onSeeAllClick={onSeeAllClick}
                  rowIdFocus={rowIdFocus}
                  contentIdFocus={contentIdFocus}
                  forceLoad={forceLoadRow === generateRowIndex(index)}
                  hoverEnabled={hoverEnabled}
                />
              </LazyLoader>
              {index === CONTINUE_WATCHING_ROW_INDEX - 1 &&
                showContinueWatchingRow && (
                  <ContinueWatchingRow
                    rowIndex={CONTINUE_WATCHING_ROW_INDEX}
                    rowScroll={rowScroll}
                    curation={continueWatchingCuration}
                    onContentSelected={handleContentSelected}
                    onRowLoaded={handleRowLoaded}
                    onRowBack={handleBackFromRow}
                    rowDownMovement={rowDownMovement}
                    rowUpMovement={rowUpMovement}
                    rowId="continue-watching-row"
                    rowIdFocus={rowIdFocus}
                    contentIdFocus={contentIdFocus}
                    forceLoad={forceLoadRow === CONTINUE_WATCHING_ROW_INDEX}
                    hoverEnabled={hoverEnabled}
                  />
                )}

              {index === 0 && isFeatureFlagEnabled('loyalty') && (
                <div
                  className={classnames('home__points-bar-container', {
                    'standard-spacing':
                      ROW_TYPES_CONFIG[row.displayStyle] ===
                      CONTENT_ROW_TYPES.STANDARD,
                  })}
                  ref={pointsBarRef}
                >
                  {freePassActive ? (
                    <FreePassTile
                      className="free-pass-reminder-content-row"
                      tileMessage="LOYALTY_AD_FREE_PASS_TIME_LEFT"
                      tileSize="SMALL"
                    />
                  ) : (
                    <HomePointsBar
                      selectionOverrides={{
                        right: '',
                        down:
                          loyaltyPointsBarDownMovement &&
                          loyaltyPointsBarDownMovement(),
                        up:
                          loyaltyPointsBarUpMovement &&
                          loyaltyPointsBarUpMovement(),
                      }}
                    />
                  )}
                </div>
              )}
            </Fragment>
          );
        })
      ) : (
        <div className="home__content-rows__spinner">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
}

HomeContentRows.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  showSeeAll: PropTypes.func,
  heroInitiallyCollapsed: PropTypes.bool,
  handleContentSelected: PropTypes.func,
  handleRowLoaded: PropTypes.func,
  handleBackFromRow: PropTypes.func,
  forceLoadRow: PropTypes.number,
  freePassActive: PropTypes.bool,
  pointsBarRef: PropTypes.objectOf(PropTypes.any),
  lazyLoaderOptions: PropTypes.object,
  rowUpMovement: PropTypes.func,
  rowDownMovement: PropTypes.func,
  onSeeAllClick: PropTypes.func,
  rowIdFocus: PropTypes.string,
  rowScroll: PropTypes.number,
  contentIdFocus: PropTypes.string,
  loyaltyPointsBarDownMovement: PropTypes.func,
  loyaltyPointsBarUpMovement: PropTypes.func,
  updateFailedRowsIndexes: PropTypes.func,
  hoverEnabled: PropTypes.bool,
  showContinueWatchingRow: PropTypes.bool,
  continueWatchingCuration: PropTypes.arrayOf(PropTypes.object),
};

HomeContentRows.defaultProps = {
  rows: [],
  showSeeAll: null,
  heroInitiallyCollapsed: false,
  handleContentSelected: null,
  handleRowLoaded: null,
  handleBackFromRow: null,
  forceLoadRow: null,
  freePassActive: false,
  pointsBarRef: null,
  lazyLoaderOptions: {},
  hoverEnabled: false,
  showContinueWatchingRow: false,
};

export default HomeContentRows;
