import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';
import classnames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';
import Button from '../button/button';
import FocusableSection from '../spatial-navigation/focusable-section';
import WatchlistButtonContainer from '../../containers/watchlist-button/watchlist-button-container';
import { shallow } from 'zustand/shallow';

// Hooks
import useToast from '../../hooks/use-toast';
import useModalHandler from '../../hooks/use-modal-handler';

// Icons
import { ReactComponent as AddOverlayIcon } from '../../assets/icons/add-overlay.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';
import { ReactComponent as RemoveIcon } from '../../assets/icons/remove.svg';
import { ReactComponent as RestartIcon } from '../../assets/icons/circle-forward.svg';
import { ReactComponent as ExclamationIcon } from '../../assets/icons/exclamation.svg';
import { ReactComponent as SeenIcon } from '../../assets/icons/seen.svg';

// Styles
import './watchlist-overlay.scss';

// Enums
import BUTTON_TYPES from '../../enums/button-types';
import ELEMENT_TYPES from '../../enums/element-types';
import CONTENT_ROW_TYPES from '../../enums/content-row-types';
import { CONTENT_TYPES } from '../../enums/content-types';
import TOAST_TYPES from '../../enums/toast-types';
import MODAL_EXIT_STATUS from '../../enums/modal-exit-status';

import { BACK_BUTTON_CONFIG } from '../../config/back-button-config';
import { isKey } from '../../utils/utils';
import { getFallbackPoster } from '../../utils/content-images';
import { KEYCODES } from '../../platform/index';

import { getEpisodeAndSeasonLabel } from '../../utils/content-details';
import useGlobalContext from '../../hooks/use-global-context';

function WatchlistOverlay({
  id,
  playbackId,
  type,
  poster,
  progress,
  duration,
  season,
  episode,
  title,
  viewed,
  previousContentId,
  onRemoveWatchlistHandler,
}) {
  const [posterError, setPosterError] = useState(false);
  const { showToast } = useToast();
  const { closeModal } = useModalHandler();

  const location = useLocation();
  const navigate = useNavigate();

  const { setWatchlistSelectedItem, setWatchlistPreviousSelectedItemId } =
    useGlobalContext(
      (state) => ({
        setWatchlistSelectedItem: state.setWatchlistSelectedItem,
        setWatchlistPreviousSelectedItemId:
          state.setWatchlistPreviousSelectedItemId,
      }),
      shallow
    );

  const posterType = CONTENT_ROW_TYPES.STANDARD;
  const fallBackImage = useMemo(
    () => getFallbackPoster(posterType),
    [posterType]
  );

  useEffect(() => {
    const onKeyDown = (event) => {
      if (isKey(event.keyCode, KEYCODES.BACK)) {
        closeModal(MODAL_EXIT_STATUS.WATCHLIST_CANCEL);
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [closeModal]);

  useEffect(() => {
    JsSpatialNavigation.focus('watchlist_overlay');

    if (!location.pathname.includes('watchlist')) {
      closeModal(MODAL_EXIT_STATUS.WATCHLIST_CONTENT_OVERLAY);
    }
  }, [location, closeModal]);

  const handleContentRemovedFromWatchList = () => {
    closeModal(MODAL_EXIT_STATUS.WATCHLIST_REMOVE_ITEM);
    showToast(TOAST_TYPES.WATCHLIST, {
      isInWatchlist: false,
      contentTitle: title,
    });

    onRemoveWatchlistHandler && onRemoveWatchlistHandler(previousContentId);
  };

  const handleClick = () => {
    setWatchlistSelectedItem({
      id,
      playbackId,
      type,
      poster,
      progress,
      duration,
      season,
      episode,
      title,
      viewed,
      previousContentId,
    });
    setWatchlistPreviousSelectedItemId(previousContentId);
    closeModal(MODAL_EXIT_STATUS.WATCHLIST_CONTENT_OVERLAY);
  };

  return (
    <div className="watchlist-overlay">
      <div>
        <div className="content-icon">
          <AddOverlayIcon />
        </div>
        <div className="content-poster">
          {viewed && (
            <SeenIcon className="content-card__poster-container__seen-icon" />
          )}
          <picture alt={title}>
            <source
              type="image/webp"
              srcSet={poster || `${fallBackImage.image}.webp`}
            />
            <img
              aria-hidden
              src={poster || `${fallBackImage.image}.png`}
              alt={title}
              width={Math.round(fallBackImage.width)}
              height={Math.round(fallBackImage.height)}
              onError={(e) => {
                e.target.src = `${fallBackImage.image}.webp`;
                setPosterError(true);
              }}
            />
          </picture>

          {posterError && (
            <div className="fallback-title">
              <span
                aria-hidden
                className={classnames('fallback-title-text', posterType)}
              >
                {title}
              </span>
            </div>
          )}

          {episode && season && type === CONTENT_TYPES.EPISODE ? (
            <div className="data">
              <Trans id="SEASON_EPISODE" values={{ season, episode }} />
            </div>
          ) : (
            ''
          )}
        </div>
        <FocusableSection
          className="content-buttons"
          sectionId="watchlist_overlay"
        >
          {progress > 0 ? (
            <>
              <Button
                back={BACK_BUTTON_CONFIG.CLOSE_WATCHLIST_MODAL}
                icon={PlayIcon}
                progressBar={!!progress && progress < duration}
                progressPercent={progress ? (progress / duration) * 100 : 0}
                type={
                  progress
                    ? BUTTON_TYPES.PROGRESSBUTTON
                    : BUTTON_TYPES.ICONBUTTON
                }
                elementType={ELEMENT_TYPES.LINK}
                onClick={handleClick}
                selectionOverrides={{ up: '', left: '', right: '' }}
              >
                {season && episode ? (
                  <>
                    <Trans id="RESUME" />{' '}
                    <Trans id="SEASON_EPISODE" values={{ season, episode }} />
                  </>
                ) : (
                  <Trans id="RESUME" />
                )}
              </Button>
              <Button
                back={BACK_BUTTON_CONFIG.CLOSE_WATCHLIST_MODAL}
                icon={RestartIcon}
                onClick={handleClick}
                elementType={ELEMENT_TYPES.LINK}
                type={BUTTON_TYPES.ICONBUTTON}
                selectionOverrides={{ left: '', right: '' }}
              >
                <Trans id="WATCH_FROM_BEGINNING" />
              </Button>
            </>
          ) : (
            <Button
              back={BACK_BUTTON_CONFIG.CLOSE_WATCHLIST_MODAL}
              icon={PlayIcon}
              elementType={ELEMENT_TYPES.BUTTON}
              type={BUTTON_TYPES.ICONBUTTON}
              onClick={() => {
                // allows to keep reference to change routes if needed
                navigate(`/watch/${playbackId}`);
                handleClick();
              }}
              selectionOverrides={{ up: '', left: '', right: '' }}
            >
              {season && episode ? (
                <Trans
                  id="WATCH_NOW_CONTENT"
                  values={{
                    content: getEpisodeAndSeasonLabel(episode, season),
                  }}
                />
              ) : (
                <Trans id="WATCH_NOW" />
              )}
            </Button>
          )}

          <Button
            back={BACK_BUTTON_CONFIG.CLOSE_WATCHLIST_MODAL}
            icon={ExclamationIcon}
            elementType={ELEMENT_TYPES.BUTTON}
            type={BUTTON_TYPES.ICONBUTTON}
            onClick={() => {
              // allows to keep reference to change routes if needed
              navigate(`/details/${id}`);
              handleClick();
            }}
            selectionOverrides={{ left: '', right: '' }}
          >
            {(type === CONTENT_TYPES.MOVIE ||
              type === CONTENT_TYPES.FEATURED_FILM) && (
              <Trans id="MOVIE_INFO" />
            )}
            {type === CONTENT_TYPES.SERIES && <Trans id="SERIES_INFO" />}
            {type === CONTENT_TYPES.EPISODE && <Trans id="EPISODE_INFO" />}
          </Button>

          <WatchlistButtonContainer
            id="remove_from_watchlist_button"
            back={BACK_BUTTON_CONFIG.CLOSE_WATCHLIST_MODAL}
            isInWatchlist
            inWatchlistIcon={RemoveIcon}
            inWatchlistLabel={t`WATCHLIST_REMOVE`}
            contentId={id}
            onContentRemoved={handleContentRemovedFromWatchList}
            selectionOverrides={{
              left: '',
              right: '',
              down: '',
            }}
            buttonPosition={progress > 0 ? 4 : 3}
            pageSection={'Watchlist section'}
          />
        </FocusableSection>
      </div>
    </div>
  );
}

WatchlistOverlay.propTypes = {
  id: PropTypes.string,
  playbackId: PropTypes.string,
  type: PropTypes.oneOf([
    CONTENT_TYPES.MOVIE,
    CONTENT_TYPES.FEATURED_FILM,
    CONTENT_TYPES.SERIES,
    CONTENT_TYPES.EPISODE,
  ]).isRequired,
  poster: PropTypes.string,
  progress: PropTypes.number,
  duration: PropTypes.number,
  season: PropTypes.number,
  episode: PropTypes.number,
  title: PropTypes.string,
  viewed: PropTypes.bool,
  previousContentId: PropTypes.string,
  onRemoveWatchlistHandler: PropTypes.func,
};

WatchlistOverlay.defaultProps = {
  poster: null,
  progress: null,
  duration: null,
  season: null,
  episode: null,
  viewed: false,
  previousContentId: null,
  onRemoveWatchlistHandler: null,
};

export default WatchlistOverlay;
