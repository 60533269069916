import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

// Icons
import { ReactComponent as BlueDiamondIcon } from '../../assets/icons/blue-diamond.svg';

// enums
import REWARD_TYPES from '../../enums/reward-types';

// Styles
import './redeemed-reward-toast.scss';

function RedeemedRewardToast({ rewardType }) {
  // hardcoded for now as the ticket says CD-3044
  if (
    ![REWARD_TYPES.SPONSORED_FREE_PASS, REWARD_TYPES.FREE_PASS].includes(
      rewardType
    )
  ) {
    return null;
  }
  return (
    <div className="redeemed-reward-toast" data-test-id="redeemed-toast">
      <div className="redeemed-reward-toast__icon">
        <BlueDiamondIcon />
      </div>
      <div className="redeemed-reward-toast__info">
        <p
          className="redeemed-reward-toast__info__upper-text"
          data-test-id="redeemed-toast-upper-text"
        >
          {t`LOYALTY_REDEEMED_FREE_PASS`}
        </p>
        <p
          className="redeemed-reward-toast__info__bottom-text"
          data-test-id="redeemed-toast-bottom-text"
        >
          {t`LOYALTY_REDEEMED_FREE_PASS_BOTTOM_TEXT`}
        </p>
      </div>
    </div>
  );
}

RedeemedRewardToast.propTypes = {
  rewardType: PropTypes.string,
  rewardTitle: PropTypes.string,
};

export default RedeemedRewardToast;
