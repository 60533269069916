class UserWatchlist {
  constructor({
    id,
    type,
    parentId,
    seasonId,
    seriesId,
    original,
    exclusive,
    isAvailable,
    title,
    parentTitle,
    episodeNumber,
    seasonNumber,
    duration,
    releaseDate,
    rating,
    ratingAge,
    image,
    previousContentId,
  }) {
    this._id = id;
    this._parentId = parentId;
    this._type = type;
    this._title = title;
    this._isAvailable = isAvailable;
    this._image = image;
    this._episodeNumber = episodeNumber;
    this._seasonNumber = seasonNumber;
    this._duration = duration;
    this._parentTitle = parentTitle;
    this._releaseDate = releaseDate;
    this._rating = rating;
    this._ratingAge = ratingAge;
    this._original = original;
    this._exclusive = exclusive;
    this._seasonId = seasonId;
    this._seriesId = seriesId;
    this._previousContentId = previousContentId;
  }

  // getters and setters
  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get parentId() {
    return this._parentId;
  }

  set parentId(parentId) {
    this._parentId = parentId;
  }

  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
  }

  get title() {
    return this._title;
  }

  set title(title) {
    this._title = title;
  }

  get seasonId() {
    return this._seasonId;
  }

  set seasonId(seasonId) {
    this._seasonId = seasonId;
  }

  get seriesId() {
    return this._seriesId;
  }

  set seriesId(seriesId) {
    this._seriesId = seriesId;
  }

  get isAvailable() {
    return this._isAvailable;
  }

  set isAvailable(isAvailable) {
    this._isAvailable = isAvailable;
  }

  get image() {
    return this._image;
  }

  set image(image) {
    this._image = image;
  }
  get episodeNumber() {
    return this._episodeNumber;
  }

  set episodeNumber(episodeNumber) {
    this._episodeNumber = episodeNumber;
  }

  get seasonNumber() {
    return this._seasonNumber;
  }

  set seasonNumber(seasonNumber) {
    this._seasonNumber = seasonNumber;
  }

  get duration() {
    return this._duration;
  }

  set duration(duration) {
    this._duration = duration;
  }

  get parentTitle() {
    return this._parentTitle;
  }

  set parentTitle(parentTitle) {
    this._parentTitle = parentTitle;
  }

  get releaseDate() {
    return this._releaseDate;
  }

  set releaseDate(releaseDate) {
    this._releaseDate = releaseDate;
  }

  get rating() {
    return this._rating;
  }

  set rating(rating) {
    this._rating = rating;
  }

  get ratingAge() {
    return this._ratingAge;
  }

  set ratingAge(ratingAge) {
    this._ratingAge = ratingAge;
  }

  get original() {
    return this._original;
  }

  set original(original) {
    this._original = original;
  }

  get exclusive() {
    return this._exclusive;
  }

  set exclusive(exclusive) {
    this._exclusive = exclusive;
  }

  get previousContentId() {
    return this._previousContentId;
  }

  set previousContentId(contentId) {
    this._previousContentId = contentId;
  }
}

export default UserWatchlist;
