import logger from './logger';

const PRIVACY_AGE_LIMIT = 16;

/**
 * convert seconds to HH:MM:SS format
 *
 * @param  {string} seconds
 */
function secondsTohhmmss(seconds) {
  return new Date(seconds * 1000).toISOString().substring(11, 19);
}

/**
 *
 * calculate user age in years
 * @param {string} birthday
 */
function calculateUserAge(birthday) {
  try {
    const birthdayDate = new Date(birthday);

    let userAge = new Date().getFullYear() - birthdayDate.getFullYear();
    const birthdayCurrentYear = new Date(
      new Date().getFullYear(),
      birthdayDate.getMonth(),
      birthdayDate.getDate()
    );

    if (new Date() < birthdayCurrentYear) {
      userAge -= 1;
    }
    return userAge;
  } catch (e) {
    logger.log(e);
    return 0; // if there is an error, return 0, the user will be considered under age limit
  }
}
/**
 *
 * function to determine if a user is under age limit
 * @param {string} birthday
 */
function isDOBUnderAgeLimit(birthday) {
  if (!birthday || birthday === '0000-00-00') {
    return true;
  }
  const userAge = calculateUserAge(birthday);
  return userAge <= PRIVACY_AGE_LIMIT;
}

export { secondsTohhmmss, isDOBUnderAgeLimit };
