import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate, Route, Routes } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';
import useGlobalContext from '../hooks/use-global-context';
import ROUTES from '../enums/routes';
import { isFeatureFlagEnabled } from '../utils/feature-flags';
import LoadingSpinner from '../components/loading-spinner/loading-spinner';
import FeatureFlag from '../components/feature-flag/feature-flag';
import Focusable from '../components/spatial-navigation/focusable';
import CHANNEL_TYPES from '../enums/channel-types';

const ContentDetailsPage = React.lazy(() =>
  import('../pages/content-details-page/content-details-page')
);
const AllEpisodes = React.lazy(() =>
  import('../containers/all-episodes/all-episodes')
);
const Search = React.lazy(() =>
  import('../containers/search/search-container')
);
const Settings = React.lazy(() => import('../containers/settings/settings'));
const Watch = React.lazy(() => import('../containers/watch/watch'));
const Redirect = React.lazy(() => import('../containers/redirect/redirect'));
const Rewards = React.lazy(() => import('../containers/rewards/rewards'));
const Watchlist = React.lazy(() =>
  import('../containers/watchlist/watchlist-container')
);
const SignOut = React.lazy(() => import('../containers/sign-out/sign-out'));
const Account = React.lazy(() => import('../containers/account/account'));
const Welcome = React.lazy(() => import('../containers/welcome/welcome'));
const SignInTimeout = React.lazy(() =>
  import('../containers/sign-in-timeout/sign-in-timeout')
);
const LandingScreen = React.lazy(() =>
  import('../containers/landing-screen/landing-screen')
);
const GetYourWatchlist = React.lazy(() =>
  import('../containers/get-your-watchlist/get-your-watchlist')
);
const Browse = React.lazy(() => import('../containers/browse/browse'));
import HomeScreen from '../containers/home/home';
const TraysScreen = React.lazy(() =>
  import('../containers/trays-screen/trays-screen')
);
const ExitScreen = React.lazy(() => import('../containers/exit/exit'));

function RouteList() {
  return (
    <Routes>
      <Route
        exact
        path="/index.html"
        element={
          <CTVRoute>
            <Navigate to="/" replace />
          </CTVRoute>
        }
      />
      <Route
        path="/"
        exact
        element={
          <CTVRoute showMenu={!isFeatureFlagEnabled('authSplashScreen')}>
            <Suspense fallback={<LoadingSpinnerContainer />}>
              {isFeatureFlagEnabled('authSplashScreen') ? (
                <LandingScreen />
              ) : (
                <HomeScreen />
              )}
            </Suspense>
          </CTVRoute>
        }
      />
      <Route
        path={ROUTES.HOME}
        element={
          <CTVRoute showMenu>
            <Suspense fallback={<LoadingSpinnerContainer />}>
              <HomeScreen />
            </Suspense>
          </CTVRoute>
        }
      />
      <Route
        path={ROUTES.EXIT_SCREEN}
        element={
          <CTVRoute>
            <Suspense fallback={<LoadingSpinnerContainer />}>
              <ExitScreen />
            </Suspense>
          </CTVRoute>
        }
      />
      <Route
        path={ROUTES.DETAILS}
        element={
          <CTVRoute>
            <Suspense fallback={<LoadingSpinnerContainer />}>
              <ContentDetailsPage />
            </Suspense>
          </CTVRoute>
        }
      />
      <Route
        path={ROUTES.ALL_EPISODES}
        element={
          <CTVRoute>
            <Suspense fallback={<LoadingSpinnerContainer />}>
              <AllEpisodes />
            </Suspense>
          </CTVRoute>
        }
      />
      <Route
        path={ROUTES.MOVIES}
        element={
          <CTVRoute showMenu>
            <Suspense fallback={<LoadingSpinnerContainer />}>
              <Browse channelType={CHANNEL_TYPES.MOVIES} />
            </Suspense>
          </CTVRoute>
        }
      />
      <Route
        path={ROUTES.SEARCH}
        element={
          <CTVRoute showMenu>
            <Suspense fallback={<LoadingSpinnerContainer />}>
              <Search />
            </Suspense>
          </CTVRoute>
        }
      />
      <Route
        path={ROUTES.SETTINGS}
        element={
          <CTVRoute showMenu>
            <Suspense fallback={<LoadingSpinnerContainer />}>
              <Settings />
            </Suspense>
          </CTVRoute>
        }
      />
      <Route
        path={ROUTES.TVSHOWS}
        element={
          <CTVRoute showMenu>
            <Suspense fallback={<LoadingSpinnerContainer />}>
              <Browse channelType={CHANNEL_TYPES.SERIES} />
            </Suspense>
          </CTVRoute>
        }
      />
      <Route
        path={ROUTES.WATCH}
        element={
          <CTVRoute>
            <Suspense fallback={<LoadingSpinnerContainer />}>
              <Watch />
            </Suspense>
          </CTVRoute>
        }
      />
      <Route
        path={ROUTES.REDIRECT}
        element={
          <CTVRoute>
            <Suspense fallback={<LoadingSpinnerContainer />}>
              <Redirect />
            </Suspense>
          </CTVRoute>
        }
      />
      <>
        {/* Routes that depend on feature flags should be inside this fragment */}
        {isFeatureFlagEnabled('sponsoredContent') ? (
          <Route
            path={ROUTES.SPONSORED_COLLECTIONS}
            element={
              <CTVRoute showMenu>
                <Suspense fallback={<LoadingSpinnerContainer />}>
                  <TraysScreen />
                </Suspense>
              </CTVRoute>
            }
          />
        ) : null}
        {isFeatureFlagEnabled('auth') ? (
          <>
            <Route
              path={ROUTES.SIGN_IN}
              element={
                <CTVRoute>
                  <Suspense fallback={<LoadingSpinnerContainer />}>
                    <LandingScreen />
                  </Suspense>
                </CTVRoute>
              }
            />
            <Route
              path={ROUTES.WATCHLIST}
              element={
                <FeatureFlag name="watchlist">
                  <CTVRoute showMenu>
                    <Suspense fallback={<LoadingSpinnerContainer />}>
                      <Watchlist />
                    </Suspense>
                  </CTVRoute>
                </FeatureFlag>
              }
            />
            <Route
              path={ROUTES.ACCOUNT}
              element={
                <CTVRoute>
                  <Suspense fallback={<LoadingSpinnerContainer />}>
                    <Account />
                  </Suspense>
                </CTVRoute>
              }
            />
            <Route
              path={ROUTES.WELCOME}
              element={
                <CTVRoute>
                  <Suspense fallback={<LoadingSpinnerContainer />}>
                    <Welcome />
                  </Suspense>
                </CTVRoute>
              }
            />
            <Route
              path={ROUTES.SIGN_IN_TIMEOUT}
              element={
                <CTVRoute>
                  <Suspense fallback={<LoadingSpinnerContainer />}>
                    <SignInTimeout />
                  </Suspense>
                </CTVRoute>
              }
            />
            <Route
              path={ROUTES.SIGN_OUT}
              element={
                <CTVRoute>
                  <Suspense fallback={<LoadingSpinnerContainer />}>
                    <SignOut />
                  </Suspense>
                </CTVRoute>
              }
            />
            <Route
              path={ROUTES.GET_YOUR_WATCHLIST}
              element={
                <CTVRoute>
                  <FeatureFlag name="watchlist">
                    <Suspense fallback={<LoadingSpinnerContainer />}>
                      <GetYourWatchlist />
                    </Suspense>
                  </FeatureFlag>
                </CTVRoute>
              }
            />

            <Route
              path={ROUTES.REWARDS}
              element={
                <FeatureFlag name="loyalty">
                  <CTVRoute showMenu>
                    <Suspense fallback={<LoadingSpinnerContainer />}>
                      <Rewards />
                    </Suspense>
                  </CTVRoute>
                </FeatureFlag>
              }
            />
          </>
        ) : null}
      </>
    </Routes>
  );
}

function LoadingSpinnerContainer() {
  return (
    <Focusable
      id="loading-spinner-container"
      className="suspense-loading-spinner"
      aria-label="Loading"
      isFocusOnPageLoad
      isFocusOnSectionEnter
      tabIndex={0}
      style={{ flex: '1', display: 'flex', alignItems: 'center' }}
    >
      <LoadingSpinner />
    </Focusable>
  );
}

// TODO Explain why this is needed
function Screen({ showMenu, children }) {
  const [loading, setLoading] = useState(true);
  const { setShowMenu } = useGlobalContext(
    (state) => ({
      setShowMenu: state.setShowMenu,
    }),
    shallow
  );

  if (loading) {
    JsSpatialNavigation.focus('#loading-spinner-container');
  }

  useEffect(() => {
    setShowMenu(showMenu);
    setLoading(false);
  }, [setShowMenu, showMenu]);

  return !loading ? children : null;
}

// TODO Explain why this is needed.
// Is it not the same as the Screen component?
function CTVRoute({ children, showMenu }) {
  return <Screen showMenu={showMenu}>{children}</Screen>;
}

CTVRoute.propTypes = {
  showMenu: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Screen.propTypes = {
  showMenu: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

RouteList.propTypes = {
  isAppLoaded: PropTypes.bool,
};

export default RouteList;
