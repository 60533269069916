import { useMemo } from 'react';
import useGlobalContext from './use-global-context';

function usePlayerControls() {
  const { showPlayerControls, setShowPlayerControls } = useGlobalContext(
    (state) => ({
      showPlayerControls: state.ui.showPlayerControls,
      setShowPlayerControls: state.setShowPlayerControls,
    })
  );

  const playerControls = useMemo(
    () => ({ showPlayerControls, setShowPlayerControls }),
    [showPlayerControls, setShowPlayerControls]
  );

  return playerControls;
}

export default usePlayerControls;
