import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

// Hooks
import { useInView } from 'react-intersection-observer';

// Components
import LoadingSpinner from '../loading-spinner/loading-spinner';
import ContentRow from '../content-row/content-row';

// Enums
import CONTENT_ROW_TYPES from '../../enums/content-row-types';

// Styles
import './continue-watching-row.scss';

function ContinueWatchingRow({
  curation,
  options,
  forceLoad,
  rowIndex,
  rowIdFocus,
  contentIdFocus,
  rowId,
  rowScroll,
  onRowBack,
  onRowLoaded,
  rowDownMovement,
  rowUpMovement,
  onContentSelected,
  hoverEnabled,
}) {
  const [setRef, inView] = useInView(options);

  return (
    <div ref={setRef} id={`row-${rowId}`}>
      {curation?.length > 0 && (inView || forceLoad) ? (
        <ContentRow
          curation={curation}
          key="continue-watching-row"
          type={CONTENT_ROW_TYPES.STANDARD}
          title={t`CONTINUE_WATCHING`}
          rowId={rowId}
          rowIndex={rowIndex}
          initialRowScroll={rowScroll}
          onContentSelected={onContentSelected}
          onRowLoaded={onRowLoaded}
          onRowBack={onRowBack}
          rowDownMovement={rowDownMovement}
          curationLength={curation.length}
          rowUpMovement={rowUpMovement}
          rowIdFocus={rowIdFocus}
          contentIdFocus={contentIdFocus}
          forceLoad={forceLoad}
          hoverEnabled={hoverEnabled}
          continueWatchingRow
        />
      ) : (
        <div className="continue-watching-row standard-row">
          <div className="continue-watching-row__content-row__title">
            {t`CONTINUE_WATCHING`}
          </div>
          <div className="content-row__carousel__spinner">
            <LoadingSpinner />
          </div>
        </div>
      )}
    </div>
  );
}

ContinueWatchingRow.propTypes = {
  curation: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.shape({
    root: PropTypes.objectOf(PropTypes.any),
    rootMargin: PropTypes.string,
    threshold: PropTypes.number,
  }),
  forceLoad: PropTypes.bool,
  rowId: PropTypes.string,
  contentIdFocus: PropTypes.string,
  rowIndex: PropTypes.number,
  rowIdFocus: PropTypes.string,
  rowScroll: PropTypes.string,
  rowUpMovement: PropTypes.func,
  rowDownMovement: PropTypes.func,
  onRowBack: PropTypes.func,
  onRowLoaded: PropTypes.func,
  onContentSelected: PropTypes.func,
  hoverEnabled: PropTypes.bool,
};

export default ContinueWatchingRow;
