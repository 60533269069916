/* eslint-disable no-underscore-dangle */
import Content from './content';

class Series extends Content {
  constructor({ images, availableSeasons, ...contentParams }) {
    super(contentParams);

    this._availableSeasons = availableSeasons;
    this._images = images;
  }

  // getters and setters

  get availableSeasons() {
    return this._availableSeasons;
  }

  set availableSeasons(availableSeasons) {
    this._availableSeasons = availableSeasons;
  }

  get images() {
    return this._images;
  }

  set images(images) {
    this._images = images;
  }
}

export default Series;
