import React, { useEffect, useState, useRef, useCallback } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import classnames from 'classnames';

// Components & Containers
import BaseContainer from './containers/base-container/base-container';
import SpatialNavigation from './components/spatial-navigation/spatial-navigation';
import GeolocationErrorScreen from './containers/geolocation-error/geolocation-error';
import ErrorScreen from './containers/error/error';
import ErrorBoundary from './containers/error/error-boundary';
const Console = React.lazy(() => import('./components/console/console'));
import ToastContainer from './containers/toast/toast-container';
import ModalContainer from './containers/modal/modal-container';
import FeatureFlag from './components/feature-flag/feature-flag';

// Configs
import ERROR_TYPE from './config/error-type-config';
import LANGUAGES from './config/language-config';
import RouteList from './config/route-config';
import APP_CONFIG from './config/app-config.json';

// API
import { fetchAppConfig } from './crackle-sdk/v1/api/appconfig';
import CrackleApiError from './crackle-sdk/v1/api/error';

// Context
import { GlobalContextProvider } from './context/global-context/provider';
import LanguageContext from './context/language-context';

// Utils
import { initialize as initializeDataDog } from './utils/data-dog';
import {
  initializeDevice,
  exitApplication,
  relaunchApplication,
  getDeviceInformation,
  KEYCODES,
  supportCapability,
} from './platform/';
import Detector from './utils/offline-detector';
import setClosedCaptionStyle from './utils/set-closed-caption-style';
import { initSessionHistory } from './utils/session-history';
import { initAdForgiveness } from './utils/ad-forgiveness';
import { setLocalStorageItem } from './utils/local-storage';
import mParticle from './utils/mparticle';
import mParticlePlatformLaunch from './utils/mParticlePlatformLaunch';
import mParticleZombieModeStatus from './utils/mParticleZombieModeStatus';
import logger from './utils/logger';
import { isDeeplink, deeplinkPrepareHistory } from './utils/startup';
import {
  isFeatureFlagEnabled,
  FEATURE_FLAGS,
  setFeatureFlag,
  mapFeatureFlagsFromAppConfig,
  getFeatureFlagValue,
} from './utils/feature-flags';
import {
  addScript,
  getAppVersion,
  getCommonVersion,
  scrollToTop,
} from './utils/utils';

// Styles
import './App.scss';

// ENUMS
import LOCAL_STORAGE from './enums/local-storage';
import PLATFORM_CAPABILITIES from './enums/platform-capabilities';

// Vizbee
import { getVizbeeScript } from './vizbee/utils';
import VizbeeWrapper from './vizbee/VizbeeWrapper';
import CrackleVizbeeDeeplink from './vizbee/CrackleVizbeeDeeplink';

function App() {
  logger.info('App rerender');
  const [isAppLoaded, setIsAppLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [focus, setFocus] = useState(null);
  const [geolocationError, setGeolocationError] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showConsole, setShowConsole] = useState(
    isFeatureFlagEnabled('SHOW_CONSOLE') && APP_CONFIG.ENV !== 'production'
  );

  const keyBuffer = useRef([]);
  const [appConfig, setAppConfig] = useState({});
  const platformId = APP_CONFIG.PLATFORM_ID || '1';

  const showError = useCallback((e) => {
    setFocus('secondary');
    setError(e);
  }, []);

  const resumeAppHandler = () => {
    mParticlePlatformLaunch(isDeeplink(), true);
    mParticleZombieModeStatus(
      getFeatureFlagValue('zombieModeVisibility'),
      true
    );
  };

  function printInitializationMessageToConsole() {
    logger.info(`App Initialization`);
    logger.info(`Brand: ${APP_CONFIG.BRAND}`);
    logger.info(`Platform: ${APP_CONFIG.PLATFORM}`);
    logger.info(`Env: ${APP_CONFIG.ENV}`);
    logger.info(`Version: ${getAppVersion()}`);
    logger.info(`Common Version: ${getCommonVersion()}`);
    logger.info(`Branch: ${APP_CONFIG.GIT_CURRENT_BRANCH}`);
    logger.info(`Commit: ${APP_CONFIG.GIT_CURRENT_COMMIT}`);
    let flagsInfo = '';
    for (const flag of Object.keys(FEATURE_FLAGS)) {
      flagsInfo += `${flag}: ${FEATURE_FLAGS[flag].value}\n`;
    }
    logger.info(`flags: \n${flagsInfo}`);
  }

  useEffect(() => {
    const tvSetup = async () => {
      try {
        printInitializationMessageToConsole();
        deeplinkPrepareHistory();
        initializeDataDog();
        const appConfigResponse = await fetchAppConfig();
        setAppConfig(appConfigResponse);

        initializeDevice(async () => {
          // Add the mParticle script here whether on web or TV
          const { duid } = await getDeviceInformation();
          await mParticle.mParticleAddScript(
            appConfigResponse.device.partnerName,
            duid
          );

          // set feature flags if there are any in app config
          if (appConfigResponse?.device?.featureFlags) {
            mapFeatureFlagsFromAppConfig(
              appConfigResponse?.device?.featureFlags
            );
          }

          // set client region
          if (appConfigResponse?.device?.clientRegion) {
            const { clientRegion, supportedRegions } = appConfigResponse.device;

            setLocalStorageItem(LOCAL_STORAGE.CLIENT_REGION, clientRegion);

            const isSupportedRegion = supportedRegions.find(
              (region) => region.regionCode === clientRegion
            );

            if (
              !isFeatureFlagEnabled('testDisableGeoError') &&
              !isSupportedRegion
            ) {
              setGeolocationError(true);
            }
          }

          mParticlePlatformLaunch(isDeeplink(), false);
          mParticleZombieModeStatus(
            getFeatureFlagValue('zombieModeVisibility'),
            false
          );

          initSessionHistory();
          initAdForgiveness();
          setClosedCaptionStyle();

          setIsAppLoaded(true);

          // load and initialize vizbee sdk
          if (
            isFeatureFlagEnabled('vizbee') &&
            supportCapability(PLATFORM_CAPABILITIES.VIZBEE) &&
            !VizbeeWrapper.isInitialized()
          ) {
            const vizbeeSDK = getVizbeeScript();
            if (vizbeeSDK) {
              // wait for vizbee library load event
              // then initialize vizbee
              window.addEventListener('VIZBEE_SDK_READY', () => {
                VizbeeWrapper.initializeVizbee();
              });
              addScript(vizbeeSDK, 'body');
            }
          }

          // scroll to top of the container when the app is loaded
          scrollToTop();
        }, resumeAppHandler);
      } catch (e) {
        showError(ERROR_TYPE.APP_LOAD);
        setIsAppLoaded(true);
        if (e instanceof CrackleApiError) {
          logger.error(e);
        } else {
          logger.error('Error occurred while fetching app config', e);
        }
      }
    };

    tvSetup();
  }, [platformId, showError]);

  useEffect(() => {
    // 7777
    const fullScreenSequence = [
      KEYCODES.NUMBER_7,
      KEYCODES.NUMBER_7,
      KEYCODES.NUMBER_7,
      KEYCODES.NUMBER_7,
    ];

    // 7031
    const consoleSequence = [
      KEYCODES.NUMBER_7,
      KEYCODES.NUMBER_0,
      KEYCODES.NUMBER_3,
      KEYCODES.NUMBER_1,
    ];

    const handleKeyEvent = (event) => {
      if (consoleSequence.includes(event.keyCode)) {
        const newBuffer = keyBuffer.current;
        newBuffer.push(event.keyCode);
        newBuffer.splice(
          -consoleSequence.length - 1,
          newBuffer.length - consoleSequence.length
        );
        if (newBuffer.join() === consoleSequence.join()) {
          setShowConsole(!showConsole);
          setFeatureFlag('SHOW_CONSOLE', showConsole ? 'false' : 'true');
          keyBuffer.current = [];
          return;
        }

        if (newBuffer.join() === fullScreenSequence.join()) {
          setIsFullScreen(!isFullScreen);
          keyBuffer.current = [];
          return;
        }
        keyBuffer.current = newBuffer;
      } else if (keyBuffer.current.length) keyBuffer.current = [];
    };
    if (APP_CONFIG.ENV !== 'production') {
      document.addEventListener('keydown', handleKeyEvent, false);
    }
    return () => {
      if (APP_CONFIG.ENV !== 'production') {
        document.removeEventListener('keydown', handleKeyEvent);
      }
    };
  }, [isFullScreen, showConsole]);

  const generateErrorScreen = () => {
    if (geolocationError) {
      return (
        <GeolocationErrorScreen onPrimaryClick={() => exitApplication()} />
      );
    }

    return (
      <ErrorScreen
        error={error}
        onPrimaryClick={
          supportCapability(PLATFORM_CAPABILITIES.EXIT)
            ? () => exitApplication()
            : null
        }
        onSecondaryClick={() => relaunchApplication()}
        focus={focus}
      />
    );
  };

  return (
    <ErrorBoundary
      onError={() => {
        showError(ERROR_TYPE.APP_LOAD);
      }}
    >
      <>
        <div
          className={classnames({
            'with-console': showConsole,
            'full-screen': isFullScreen,
          })}
        >
          <div className="app">
            <Router basename={APP_CONFIG.PUBLIC_URL}>
              {isAppLoaded && (
                <GlobalContextProvider setError={showError}>
                  <LanguageContext
                    locale={LANGUAGES.english}
                    isForTesting={false}
                  >
                    <SpatialNavigation>
                      {geolocationError || error ? (
                        generateErrorScreen()
                      ) : (
                        <>
                          <FeatureFlag name="vizbee">
                            <CrackleVizbeeDeeplink />
                          </FeatureFlag>
                          <ToastContainer />
                          <ModalContainer />
                          <Detector
                            polling={{
                              // this will force polling to the url
                              enabled: true,
                              url: APP_CONFIG.CONNECTIVITY_CHECK,
                              interval: 10000,
                            }}
                            render={(connection) => (
                              <BaseContainer
                                connection={connection}
                                appConfig={appConfig}
                              >
                                <RouteList isAppLoaded />
                              </BaseContainer>
                            )}
                          />
                        </>
                      )}
                    </SpatialNavigation>
                  </LanguageContext>
                </GlobalContextProvider>
              )}
            </Router>
          </div>
        </div>
        {showConsole ? (
          <div
            id="console-container"
            className={classnames({
              'full-screen': isFullScreen,
            })}
          >
            <Console />
          </div>
        ) : null}
      </>
    </ErrorBoundary>
  );
}
export default App;
