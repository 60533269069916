import { useQuery } from 'react-query';
import { fetchAppConfig } from '../dataSource/appConfig';

function useAppConfig(options = {}) {
  const { data, isLoading, isError, error, isSuccess } = useQuery(
    'app-config',
    fetchAppConfig,
    options
  );

  const appConfigDTO = data
    ? {
        vMapTemplate: data?.device?.vMapTemplate || '',
        adSponsorshipTemplate: data?.device?.adSponsorshipTemplate || '',
        featureFlags: data?.device?.featureFlags || {},
        adsParams: data?.device?.adsParams || {},
        rewardsTemplate: data?.device?.rewardsTemplate || '',
        clientRegion: data?.device?.clientRegion || '',
        supportedRegions: data?.device?.supportedRegions
          ? data.device.supportedRegions.map(
              (supportedRegion) => supportedRegion.regionCode
            )
          : [],
        adfp: data?.global?.adfp || null,
      }
    : {};

  return {
    data: appConfigDTO,
    error,
    isError,
    isLoading,
    isSuccess,
  };
}

export { useAppConfig };
