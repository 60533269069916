import { useQuery } from 'react-query';
import CONTENT_TYPES from '../enums/contentTypes';
import { fetchContentById } from '../dataSource/content';
import { getConfig } from '../../configuration';

function useContinueWatchingRow({
  sessionStorageArray,
  lastUpdateTime,
  queryOptions = {},
}) {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ['continueWatchingArray', lastUpdateTime],
    () => getContinueWatchingArray(sessionStorageArray),
    {
      queryOptions,
    }
  );

  if ((!isSuccess || isError) && !isLoading) {
    return {
      data: [],
      isLoading,
    };
  }

  return {
    data: data || [],
    isLoading,
  };
}
const getContinueWatchingArray = async (sessionStorageArray) => {
  const { PLATFORM_ID } = getConfig();
  const dataArray = await Promise.all(
    sessionStorageArray.map(async (CWItem) => {
      const {
        mediaId,
        contentPoster,
        progress,
        mediaEndTime,
        contentType,
        title,
        episode,
        season,
      } = CWItem;

      const progressPercent = (progress / mediaEndTime || 0.01) * 100;

      const data = await fetchContentById(mediaId);

      const { access } = data;

      const avail = access?.avails?.[0]?.platforms.find(
        (platform) => platform.id.toLowerCase() === PLATFORM_ID.toLowerCase()
      );

      let remainingDays = null;

      // calculate content remaining days
      if (avail?.windowEnd) {
        const presentDate = new Date();
        const contentEndDate = new Date(avail?.windowEnd);
        const diffInTime = contentEndDate.getTime() - presentDate.getTime();
        if (diffInTime > 0) {
          const oneDayInMilliSec = 1000 * 3600 * 24;
          remainingDays = Math.floor(diffInTime / oneDayInMilliSec);
        }
      }

      const cardInfo = {
        id: mediaId,
        image: contentPoster,
        title,
        type: contentType,
        progressPercent,
        isAvailable: remainingDays > 0,
      };

      if (contentType === CONTENT_TYPES.EPISODE) {
        cardInfo.season = season;
        cardInfo.episode = episode;

        const contentTitle = title.split(`S${season}E${episode}`)?.[0]?.trim();
        if (contentTitle.length) {
          cardInfo.title = contentTitle;
        }
      }
      return cardInfo;
    })
  );

  return dataArray.filter((item) => item.isAvailable);
};

export { useContinueWatchingRow };
