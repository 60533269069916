import { makeRequest } from './common/base';
import { getFeatureFlags } from '../configuration';
const flags = getFeatureFlags();

const endpoints = {
  userProfile: (userId) => `/users/${userId}`,
  userPreferences: (userId) => `/users/${userId}/preferences`,
  userWatchlist: (userId) => `/users/${userId}/watchlist`,
  userPrivacySettings: (userId) => `/users/${userId}/privacy`,
  userRemoveFromWatchlist: (userId, contentId) =>
    `/users/${userId}/watchlist/${contentId}`,
  isInWatchlist: (userId, contentId) =>
    `/users/${userId}/watchlist/${contentId}`,
};

/**
 * Get the user profile
 *
 * @param {string} userId The user ID
 * @returns {Promise<{}>} A promise containing a JSON data object with user profile
 */

const fetchUserProfile = async (userId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(endpoints.userProfile(userId), requestOptions);
};

/**
 * Update the user profile
 *
 * @param {string} userId The user ID
 * @param {object<{}>} updateObject The object with the values to update in the user profile
 * @returns {Promise<{}>} A promise containing a JSON data object with user profile updates
 */
const updateUserProfile = async (userId, updateObject) => {
  const data = JSON.stringify(updateObject);
  const requestOptions = {
    method: 'PUT',
    redirect: 'follow',
    body: data,
  };

  return makeRequest(endpoints.userProfile(userId), requestOptions);
};

/**
 * Get the user Privacy Settings
 *
 * @param {string} userId The user ID
 * @returns {Promise<{}>} A promise containing a JSON data object with user Privacy Settings
 */

const fetchUserPrivacySettings = async (userId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(endpoints.userPrivacySettings(userId), requestOptions);
};

/**
 * Save User Privacy Settings
 *
 * @param {string} userId The user ID
 * @param {object<{}>} updateObject The object with the values to update in the user privacy settings
 * @returns {Promise<{}>} A promise containing a JSON data object with user privacy settings updates
 */
const updateUserPrivacySettings = async (userId, updateObject) => {
  const data = JSON.stringify(updateObject);
  const requestOptions = {
    method: 'PUT',
    redirect: 'follow',
    body: data,
  };

  return makeRequest(endpoints.userPrivacySettings(userId), requestOptions);
};

/**
 * Get the user preferences
 *
 * @param {string} userId The user ID
 *
 * @returns {Promise<{}>} A promise containing a JSON data object with user preferences
 */
const getUserPreferences = async (userId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(endpoints.userPreferences(userId), requestOptions);
};

/**
 * Set the user preferences
 *
 * @param {string} userId The user ID
 * @param {bool} parentalControl If the user has parental controls enabled
 * @param {int} parentalControlPin User selected PIN for parental controls
 *
 * @TODO remaining settings (captions, language, list sorting, etc.)
 *
 * @returns {Promise<any>} A promise containing a JSON data object with user preferences
 */
const setUserPreferences = async (
  userId,
  parentalControl,
  parentalControlPin,
  maxAge
) => {
  const data =
    parentalControlPin === ''
      ? JSON.stringify({ parentalControl })
      : JSON.stringify({
          parentalControl,
          parentalControlPin,
          maxAge,
        });

  const requestOptions = {
    method: 'PUT',
    redirect: 'follow',
    body: data,
  };

  return makeRequest(endpoints.userPreferences(userId), requestOptions);
};

/**
 * Get the user watchlist
 *
 * @param {string} userId The user ID
 *
 * @returns {Promise<{}>} A promise containing a JSON data object with the user's watchlist
 */
const getUserWatchlist = async (userId, pageSize = 24, pageNumber = 1) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  const params = {
    pageSize,
    pageNumber,
  };

  const urlSearchParams = new URLSearchParams({ ...params });

  return makeRequest(
    `${endpoints.userWatchlist(userId)}?${urlSearchParams.toString()}`,
    requestOptions
  );
};

/**
 * Adds a new item into the user watchlist
 *
 * @param {string} userId The user ID
 * @param {contentId} contentId content ID to add to the watchlist
 * *
 * @returns {Promise<any>} A promise containing a JSON data object with the action result
 */
const addToUserWatchlist = async (userId, contentId) => {
  const data = JSON.stringify({ contentId });

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: data,
  };

  if (flags.testWatchlistMocks) {
    return await import('./mockData/watchListAddItem.json');
  }

  return makeRequest(endpoints.userWatchlist(userId), requestOptions);
};

/**
 * Removes an item from the user watchlist
 *
 * @param {string} userId The user ID
 * @param {contentId} watchlist content ID to remove from the watchlist
 * *
 * @returns {Promise<any>} A promise containing a JSON data object with the with action result
 */
const removeFromUserWatchlist = async (userId, contentId) => {
  const requestOptions = {
    method: 'DELETE',
    redirect: 'follow',
  };

  if (flags.testWatchlistMocks) {
    return '';
  }

  return makeRequest(
    endpoints.userRemoveFromWatchlist(userId, contentId),
    requestOptions
  );
};

/**
 * is Content in User Watchlist
 *
 * @param {string} userId The user ID
 * @param {contentId} contentId content ID to checked if it's added to watchlist
 * *
 * @returns {Promise<any>} A promise containing a JSON data object with data about content related to user watchlist
 */
const isContentInUserWatchlist = async (userId, contentId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  if (flags.testWatchlistMocks) {
    // return true or false randomly
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(Math.random() > 0.5);
      }, 300);
    });
  }

  return makeRequest(
    endpoints.isInWatchlist(userId, contentId),
    requestOptions
  );
};

export {
  fetchUserProfile,
  updateUserProfile,
  fetchUserPrivacySettings,
  updateUserPrivacySettings,
  getUserPreferences,
  setUserPreferences,
  getUserWatchlist,
  addToUserWatchlist,
  removeFromUserWatchlist,
  isContentInUserWatchlist,
};
