import { useInfiniteQuery, useQuery } from 'react-query';
import {
  fetchSearchResults,
  fetchSearchSuggestions,
} from '../dataSource/search';
import SearchResult from '../entities/searchResult';
import {
  getMetadataByLocale,
  getRatingsByRegion,
  getImagesBySizeRequirements,
} from '../utils/content';

function useSearchResults({
  searchTerm,
  queryOptions = { enabled: false },
  pageNumber = 1,
  pageSize = 20,
  imageSizes = [],
  searchFields = ['Title', 'Cast'],
}) {
  const searchData = useInfiniteQuery(
    ['search', searchTerm, searchFields],
    ({ pageParam = pageNumber }) =>
      prepareSearchResults(
        searchTerm,
        pageParam,
        pageSize,
        searchFields,
        imageSizes
      ),
    {
      getNextPageParam: (lastPage) => {
        let nextPage = null;
        if (lastPage.pagination.pageNumber < lastPage.pagination.totalPages) {
          nextPage = lastPage.pagination.pageNumber + 1;
        }

        return nextPage;
      },
      getPreviousPageParam: (firstPage) => {
        let previousPage = null;
        if (firstPage.pagination.pageNumber > 1) {
          previousPage = firstPage.pagination.pageNumber - 1;
        }

        return previousPage;
      },
      ...queryOptions,
    }
  );

  const {
    data,
    isLoading,
    isError,
    error,
    isSuccess,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    refetch,
  } = searchData;

  let searchResultsDTO = {};

  if (!isLoading && !isError) {
    searchResultsDTO = data?.pages.map((page) => {
      return {
        items: page?.items.map((item) => {
          return {
            id: item.id,
            images: item.images.map((image) => ({
              url: image.url,
              width: image.width,
              height: image.height,
            })),
            title: item.title,
            rating: {
              name: item.rating?.name || null,
              age: item.rating?.age || null,
            },
          };
        }),
        pagination: page.pagination,
      };
    });
  }

  return {
    data: searchResultsDTO,
    isLoading,
    isError,
    error,
    isSuccess,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    refetch,
  };
}

function useSearchSuggestions({
  searchTerm,
  pageNumber = 1,
  pageSize = 10,
  searchFields = ['Title'],
  queryOptions = { enabled: false },
}) {
  const { data, isLoading, isError, isSuccess, error, refetch } = useQuery(
    ['suggestions', searchTerm, searchFields],
    () =>
      prepareSearchSuggestions(searchTerm, pageNumber, pageSize, searchFields),
    queryOptions
  );

  let suggestionsDTO = {};

  if (isSuccess) {
    suggestionsDTO = {
      items: data.items,
    };
  }

  return {
    data: suggestionsDTO,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch,
  };
}

async function prepareSearchResults(
  searchTerm,
  pageNumber,
  pageSize,
  searchFields,
  imageSizes
) {
  const { items, pagination } = await fetchSearchResults(
    searchTerm,
    pageNumber,
    pageSize,
    searchFields
  );

  const results = items.map((item) => {
    const images = getImagesBySizeRequirements(
      item?.assets?.images,
      imageSizes
    );

    const { title } = getMetadataByLocale(item);

    return new SearchResult({
      id: item.id,
      images,
      title,
      rating: getRatingsByRegion(item),
    });
  });

  return {
    items: results,
    pagination: {
      pageNumber: pagination.pageNumber,
      totalPages: pagination.totalPages,
    },
  };
}

async function prepareSearchSuggestions(
  searchTerm,
  pageNumber,
  pageSize,
  searchFields
) {
  const { items, pagination } = await fetchSearchSuggestions(
    searchTerm,
    pageNumber,
    pageSize,
    searchFields
  );

  return {
    items,
    pagination: {
      pageNumber: pagination.pageNumber,
      totalPages: pagination.totalPages,
    },
  };
}

export { useSearchResults, useSearchSuggestions };
