const PLATFORM_CAPABILITIES = {
  PLATFORM_SESSION_ID: 'platformSessionId',
  IFRAME_SRC_DOC: 'iframeSrcDoc',
  LIMIT_AD_TRACKING: 'lat',
  CCPA: 'ccpa',
  VIZBEE: 'vizbee',
  ARIA_LABEL: 'ariaLabel',
  TTS_NAVIGATION_LIMIT_NEEDED: 'ttsNavigationLimitNeeded',
  CC_BUTTON: 'ccButton',
  EXIT: 'exit',
  EXIT_BUTTON: 'exitButton',
};

export default PLATFORM_CAPABILITIES;
