import logger from '../utils/logger';
import { KEYCODES } from '../platform/index';

export default class CrackleVizbeePlaybackHandler {
  constructor(playerInstance) {
    this.seekVideo = this.seekVideo.bind(this);
    this.stopVideo = this.stopVideo.bind(this);

    this.getTracks = this.getTracks.bind(this);
    this.getEnabledTracks = this.getEnabledTracks.bind(this);
    this.enableTracks = this.enableTracks.bind(this);
    this.disableTracks = this.disableTracks.bind(this);

    this.currentPlayerInstance = playerInstance;
  }

  //--------------------
  // Playback Commands
  //--------------------

  static pauseVideo = () => {
    try {
      CrackleVizbeePlaybackHandler.sendKeyEvent('keyup', KEYCODES.MEDIA_PAUSE);
    } catch (e) {
      logger.warn(
        'CrackleVizbeePlaybackHandler::pauseVideo - SOMETHING WENT WRONG: ',
        e
      );
    }
  };

  static playVideo = () => {
    try {
      CrackleVizbeePlaybackHandler.sendKeyEvent('keyup', KEYCODES.MEDIA_PLAY);
    } catch (e) {
      logger.warn(
        'CrackleVizbeePlaybackHandler::playVideo - SOMETHING WENT WRONG: ',
        e
      );
    }
  };

  seekVideo = (timeInMs) => {
    if (CrackleVizbeePlaybackHandler.isValidSeekEvent(timeInMs)) {
      try {
        if (this.currentPlayerInstance) {
          const seekTimeInSec = Math.round(timeInMs / 1000);
          const eventType = 'vizbee-seek';
          window.dispatchEvent(
            new CustomEvent('vizbee-command', {
              detail: { type: eventType, data: { seekTimeInSec } },
            })
          );
          return;
        }
        logger.warn('CrackleVizbeePlaybackHandler::seekVideo - IGNORING SEEK');
      } catch (e) {
        logger.warn(
          'CrackleVizbeePlaybackHandler::seekVideo - SOMETHING WENT WRONG: ',
          e
        );
      }
    }
  };

  stopVideo = () => {
    try {
      if (this.currentPlayerInstance) {
        const eventType = 'vizbee-stop';
        window.dispatchEvent(
          new CustomEvent('vizbee-command', { detail: { type: eventType } })
        );
      }
    } catch (e) {
      logger.warn(
        'CrackleVizbeePlaybackHandler::stopVideo - SOMETHING WENT WRONG: ',
        e
      );
    }
  };

  getTracks = () => {
    try {
      if (
        this.currentPlayerInstance &&
        this.currentPlayerInstance.subtitles &&
        this.currentPlayerInstance.subtitles.list()
      ) {
        const subtitles = this.currentPlayerInstance.subtitles.list();
        if (subtitles.length > 0) {
          const subtitleInfo =
            new window.vizbee.continuity.messages.SubtitleTrack();
          subtitleInfo.fromJSON({
            id: 1,
            lang: 'en',
          });
          return [subtitleInfo];
        }
      }
    } catch (e) {
      logger.warn(
        'CrackleVizbeePlaybackHandler::getTracks - SOMETHING WENT WRONG: '
      );
    }
    return [];
  };

  getEnabledTracks = () => {
    try {
      if (
        this.currentPlayerInstance &&
        this.currentPlayerInstance.subtitles &&
        this.currentPlayerInstance.subtitles.list()
      ) {
        const subtitles = this.currentPlayerInstance.subtitles.list();
        if (subtitles.length > 0 && subtitles[0] && subtitles[0].enabled) {
          const subtitleInfo =
            new window.vizbee.continuity.messages.SubtitleTrack();
          subtitleInfo.fromJSON({
            id: 1,
            lang: 'en',
          });
          return [subtitleInfo];
        }
      }
    } catch (e) {
      logger.warn(
        'CrackleVizbeePlaybackHandler::getEnabledTracks - SOMETHING WENT WRONG: ',
        e
      );
    }
    return [];
  };

  enableTracks() {
    this.toggleCC();
  }

  disableTracks() {
    this.toggleCC();
  }

  //----------------
  // Helper Methods
  //----------------

  static isValidSeekEvent = (timeInMs) => {
    return !isNaN(timeInMs) && Number(timeInMs) >= 0;
  };

  static sendKeyEvent = (keyEvent, keyCode) => {
    if (!keyCode) {
      logger.warn(
        'CrackleVizbeePlaybackHandler::sendKeyEvent - INVALID keyCode'
      );
      return;
    }

    const customKeyEvent = new Event(keyEvent);
    customKeyEvent.keyCode = keyCode;
    document.dispatchEvent(customKeyEvent);
  };

  toggleCC() {
    try {
      if (this.currentPlayerInstance) {
        const eventType = 'vizbee-cc';
        window.dispatchEvent(
          new CustomEvent('vizbee-command', { detail: { type: eventType } })
        );
      }
    } catch (e) {
      logger.warn(
        'CrackleVizbeePlaybackHandler::toggleCC - SOMETHING WENT WRONG: ',
        e
      );
    }
  }
}
