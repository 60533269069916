import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

// Config
import { getConfig } from '../../configuration';

// Enums
import CONTENT_TYPES from '../enums/contentTypes';
import ORIGINAL_GENRES from '../enums/originals-genres';
import SESSION_HISTORY_TYPES from '../enums/session-history';

// Data source
import {
  fetchContentById,
  fetchContentVod,
  fetchTrailer,
  fetchFirstPlayableMedia,
  fetchContentChildren,
} from '../dataSource/content';

// Entities
import VideoAsset from '../entities/videoAsset';
import CaptionAsset from '../entities/captionAsset';
import Vod from '../entities/vod';
import ContentFactory from '../entities/contentFactory';

// Utils
import {
  getMetadataByLocale,
  getGenresByLocale,
  getCreditsByLocale,
  getRatingsByRegion,
  getImagesBySizeRequirements,
} from '../utils/content';
import { convertSecondsToHHMMSS } from '../utils/time';
import getStreamForCurrent from '../utils/stream-source';

/////////////////////////////////////////////////////////////////////////////////////
// Hooks
/////////////////////////////////////////////////////////////////////////////////////

function useContent({ id, queryOptions = {}, imageSizes = [] }) {
  const content = useQuery(
    ['content', id],
    () => prepareContent(id, imageSizes),
    queryOptions
  );

  const featureFilm = useFeatureFilmDetails({
    id,
    queryOptions: {
      enabled: content?.data?.type === CONTENT_TYPES.FEATURED_FILM,
    },
    imageSizes,
  });
  const movie = useMovieDetails({
    id,
    queryOptions: { enabled: content?.data?.type === CONTENT_TYPES.MOVIE },
    imageSizes,
  });
  const episode = useEpisodeDetails({
    id,
    queryOptions: { enabled: content?.data?.type === CONTENT_TYPES.EPISODE },
    imageSizes,
  });
  const serie = useSerieDetails({
    id,
    queryOptions: { enabled: content?.data?.type === CONTENT_TYPES.SERIES },
    imageSizes,
  });

  const trailer = useTrailer({
    id,
    queryOptions: { enabled: content?.data?.type === CONTENT_TYPES.TRAILER },
    imageSizes,
  });

  if (!content.isSuccess) {
    return { ...content };
  }

  switch (content?.data?.type) {
    case CONTENT_TYPES.EPISODE:
      return {
        ...episode,
        isLoading: content.isLoading || episode.isLoading,
        isError: content.isError || episode.isError,
        isSuccess: content.isSuccess && episode.isSuccess,
      };
    case CONTENT_TYPES.MOVIE:
      return {
        ...movie,
        isLoading: content.isLoading || movie.isLoading,
        isError: content.isError || movie.isError,
        isSuccess: content.isSuccess && movie.isSuccess,
      };

    case CONTENT_TYPES.FEATURED_FILM:
      return {
        ...featureFilm,
        isLoading: content.isLoading || featureFilm.isLoading,
        isError: content.isError || featureFilm.isError,
        isSuccess: content.isSuccess && featureFilm.isSuccess,
      };
    case CONTENT_TYPES.SERIES:
      return {
        ...serie,
        isLoading: content.isLoading || serie.isLoading,
        isError: content.isError || serie.isError,
        isSuccess: content.isSuccess && serie.isSuccess,
      };
    case CONTENT_TYPES.TRAILER:
      return {
        ...trailer,
        isLoading: content.isLoading,
        isError: content.isError,
        isSuccess: content.isSuccess,
      };
  }
}

function useFeatureFilmDetails({
  id,
  queryOptions = { enabled: true },
  imageSizes = [],
}) {
  const featureFilm = useQuery(
    ['content', id],
    () => prepareContent(id, imageSizes),
    // staleTime: 1000 * 60 * 5,
    queryOptions
  );

  const movieId = featureFilm?.data?.parentId || null;

  const movie = useQuery(
    ['content', movieId],
    () => prepareContent(movieId, imageSizes),
    {
      ...queryOptions,
      enabled: !!movieId && queryOptions.enabled,
      // staleTime: 1000 * 60 * 5,
    }
  );

  const movieTrailer = useQuery(
    ['content-trailer', movieId],
    () => prepareContentTrailer(movieId),
    {
      ...queryOptions,
      enabled: !!movieId && queryOptions.enabled,
      // staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );

  const { getSessionHistory } = getConfig();

  async function refetch() {
    await featureFilm.refetch();
    await movie.refetch();
    await movieTrailer.refetch();
  }

  const isLoading =
    featureFilm.isLoading || movie.isLoading || movieTrailer.isLoading;
  const isError = featureFilm.isError || movie.isError;
  const isSuccess =
    featureFilm.isSuccess && movie.isSuccess && !movieTrailer.isLoading;

  let error = null;
  if (featureFilm.isError) {
    error = featureFilm.error;
  } else if (movie.isError) {
    error = movie.error;
  }

  let featureFilmDTO = {};

  if (isSuccess) {
    const found = getSessionHistory(
      SESSION_HISTORY_TYPES.MOVIE,
      featureFilm?.data?.id
    );

    featureFilmDTO = {
      id: featureFilm.data.id,
      movieId: movie.data.id,
      images: featureFilm.data?.images?.map((image) => ({
        url: image.url,
        width: image.width,
        height: image.height,
        aspectRatio: image.aspectRatio,
        type: image.type,
        name: image.name,
      })),
      quality: featureFilm.data.videos?.[0].height >= 720 ? 'HD' : 'SD',
      title: featureFilm.data.title,
      description: movie.data.mediumDescription,
      genres: featureFilm.data.genres?.map((genre) => genre.label),
      whyItCrackles: featureFilm.data.whyItCrackles,
      rating: {
        name: featureFilm.data.rating?.name,
        age: featureFilm.data.rating?.age,
      },
      duration: convertSecondsToHHMMSS(featureFilm.data.duration),
      durationInSeconds: featureFilm.data.duration,
      releaseDate: featureFilm.data.releaseDate?.toISOString(),
      releaseYear: featureFilm.data.getReleaseYear(),
      credits: featureFilm.data.credits,
      captions: featureFilm.data.captions?.map((caption) => ({
        language: caption.language,
        locale: caption.locale,
        label: caption.label,
      })),
      loyaltyPoints: featureFilm.data.loyaltyPoints,
      isExpiringSoon: featureFilm.data.isExpiringSoon,
      isAvailable: featureFilm.data.isAvailable,
      remainingDays: featureFilm.data.remainingDays,
      type: featureFilm.data.type,
      externalId: featureFilm.data.externalId,
      playbackId: featureFilm.data.id,
      creditsMark: featureFilm.data.creditsMark,
      progress: found?.progress || 0,
    };

    if (movieTrailer.data) {
      featureFilmDTO.trailer = {
        id: movieTrailer.data.contentId,
        streams: movieTrailer.data.streams,
        mode: movieTrailer.data.mode,
      };
    }
  }

  return {
    data: featureFilmDTO,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch,
  };
}

function useMovieDetails({
  id,
  queryOptions = { enabled: true },
  imageSizes = [],
}) {
  const movie = useQuery(
    ['content', id],
    () => prepareContent(id, imageSizes),
    queryOptions
  );

  const featureFilm = useQuery(
    ['content-playable', id],
    () => prepareFirstPlayableContent(id),
    queryOptions
  );

  const movieTrailer = useQuery(
    ['content-trailer', id],
    () => prepareContentTrailer(id),
    {
      ...queryOptions,
      retry: false,
    }
  );

  const { getSessionHistory } = getConfig();

  const isLoading =
    featureFilm.isLoading || movie.isLoading || movieTrailer.isLoading;
  const noFeatureFilmData = !isLoading && !featureFilm.data;
  const isError = noFeatureFilmData || featureFilm.isError || movie.isError;
  const isSuccess =
    !noFeatureFilmData &&
    featureFilm.isSuccess &&
    movie.isSuccess &&
    !movieTrailer.isLoading;

  let error = null;
  if (movie.isError) {
    error = movie.error;
  } else if (featureFilm.isError) {
    error = featureFilm.error;
  }

  let movieDTO = {};

  if (isSuccess) {
    const found = getSessionHistory(
      SESSION_HISTORY_TYPES.MOVIE,
      featureFilm?.data?.id
    );
    movieDTO = {
      id: featureFilm.data.id,
      movieId: movie.data.id,
      images: featureFilm.data?.images?.map((image) => ({
        url: image.url,
        width: image.width,
        height: image.height,
        aspectRatio: image.aspectRatio,
        type: image.type,
        name: image.name,
      })),
      quality: featureFilm.data.videos?.[0].height >= 720 ? 'HD' : 'SD',
      title: featureFilm.data.title,
      description: movie.data.mediumDescription,
      genres: featureFilm.data.genres?.map((genre) => genre.label),
      whyItCrackles: featureFilm.data.whyItCrackles,
      rating: {
        name: featureFilm.data.rating?.name,
        age: featureFilm.data.rating?.age,
      },
      duration: convertSecondsToHHMMSS(featureFilm.data.duration),
      durationInSeconds: featureFilm.data.duration,
      releaseDate: featureFilm.data.releaseDate?.toISOString(),
      releaseYear: featureFilm.data.getReleaseYear(),
      credits: featureFilm.data.credits,
      captions: featureFilm.data.captions?.map((caption) => ({
        language: caption.language,
        locale: caption.locale,
        label: caption.label,
      })),
      loyaltyPoints: featureFilm.data.loyaltyPoints,
      isExpiringSoon: featureFilm.data.isExpiringSoon,
      isAvailable: featureFilm.data.isAvailable,
      remainingDays: featureFilm.data.remainingDays,
      type: movie.data.type,
      externalId: movie.data.externalId,
      playbackId: featureFilm.data.id,
      creditsMark: featureFilm.data.creditsMark,
      progress: found?.progress || 0,
    };

    if (movieTrailer.data) {
      movieDTO.trailer = {
        id: movieTrailer.data.contentId,
        streams: movieTrailer.data.streams,
        mode: movieTrailer.data.mode,
      };
    }
  }

  async function refetch() {
    await movie.refetch();
    await featureFilm.refetch();
    await movieTrailer.refetch();
  }

  return {
    data: movieDTO,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch,
  };
}

function useEpisodeDetails({
  id,
  queryOptions = { enabled: true },
  imageSizes = [],
}) {
  const episode = useQuery(
    ['content', id],
    () => prepareContent(id, imageSizes),
    queryOptions
  );

  const seasonId = episode.data?.parentId || null;

  const season = useQuery(
    ['content', seasonId],
    () => prepareContent(seasonId, imageSizes),
    {
      ...queryOptions,
      enabled: queryOptions.enabled && !!seasonId,
    }
  );

  const seriesId = season.data?.parentId || null;
  const serie = useQuery(
    ['content', seriesId],
    () => prepareContent(seriesId, imageSizes),
    {
      ...queryOptions,
      enabled: queryOptions.enabled && !!seriesId,
    }
  );

  const { getSessionHistory } = getConfig();

  const isLoading = episode.isLoading || season.isLoading || serie.isLoading;
  const isError = episode.isError || season.isError || serie.isError;
  const isSuccess = episode.isSuccess && season.isSuccess && serie.isSuccess;

  let error = null;
  let found = null;

  if (episode.isError) {
    error = episode.error;
  } else if (season.isError) {
    error = season.error;
  } else if (serie.isError) {
    error = serie.error;
  }

  if (isSuccess) {
    found = getSessionHistory(
      SESSION_HISTORY_TYPES.TV,
      episode.data.id,
      episode.data.season,
      episode.data.episode,
      serie.data.id
    );
  }

  const episodeDTO =
    // !isLoading && !isError
    isSuccess
      ? {
          id: episode.data.id,
          seasonId: season.data.id,
          seriesId: serie.data.id,
          images: episode.data.images.map((image) => ({
            url: image.url,
            width: image.width,
            height: image.height,
            aspectRatio: image.aspectRatio,
            type: image.type,
            name: image.name,
          })),
          seriesImages: serie.data.images
            .map((image) => ({
              url: image.url,
              width: image.width,
              height: image.height,
              aspectRatio: image.aspectRatio,
              type: image.type,
              name: image.name,
            }))
            .filter((image) => [220, 320, 520].includes(image.width)),
          quality: episode.data.videos[0].height >= 720 ? 'HD' : 'SD',
          title: episode.data.title,
          description: episode.data.shortDescription,
          genres: episode.data.genres.map((genre) => genre.label),
          whyItCrackles: episode.data.whyItCrackles,
          rating: {
            name: episode.data.rating.name,
            age: episode.data.rating.age,
          },
          duration: convertSecondsToHHMMSS(episode.data.duration),
          durationInSeconds: episode.data.duration,
          releaseDate: episode.data.releaseDate?.toISOString(),
          releaseYear: episode.data.getReleaseYear(),
          credits: episode.data.credits,
          captions: episode.data.captions.map((caption) => ({
            language: caption.language,
            locale: caption.locale,
            label: caption.label,
          })),
          loyaltyPoints: episode.data.loyaltyPoints,
          seriesTitle: serie.data.title,
          episode: episode.data.episode,
          season: episode.data.season,
          isExpiringSoon: episode.data.isExpiringSoon,
          isAvailable: episode.data.isAvailable,
          remainingDays: episode.data.remainingDays,
          type: episode.data.type,
          externalId: episode.data.externalId,
          playbackId: episode.data.id,
          creditsMark: episode.data.creditsMark,
          progress: found?.progress || 0,
        }
      : {};

  async function refetch() {
    await episode.refetch();
    await season.refetch();
    await serie.refetch();
  }

  return {
    data: episodeDTO,
    isLoading,
    isError,
    error,
    isSuccess,
    refetch,
  };
}

function useSerieDetails({
  id,
  queryOptions = { enabled: true },
  imageSizes = [],
}) {
  const serie = useQuery(
    ['content', id],
    () => prepareContent(id, imageSizes),
    queryOptions
  );

  const seasons = useQuery(
    ['content-children', id],
    () => prepareContentChildren(id),
    queryOptions
  );

  const serieTrailer = useQuery(
    ['content-trailer', id],
    () => prepareContentTrailer(id),
    {
      ...queryOptions,
      // staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );
  const { getSessionHistory } = getConfig();

  const nextWatchableEpisode = getSessionHistory(
    SESSION_HISTORY_TYPES.TV,
    '',
    null,
    null,
    id
  );

  let episodeId = null;

  if (serie.isSuccess) {
    episodeId = nextWatchableEpisode?.id || false;
  }

  const nextEpisode = useQuery(
    ['content-playable', id],
    () => prepareFirstPlayableContent(id),
    {
      ...queryOptions,
      enabled: queryOptions.enabled && episodeId === false,
      // staleTime: 1000 * 60 * 5,
    }
  );

  if (episodeId === false && nextEpisode.isSuccess && nextEpisode.data) {
    episodeId = nextEpisode.data.id;
  }

  const episode = useQuery(
    ['content', episodeId],
    () => prepareContent(episodeId),
    {
      // staleTime: 1000 * 60 * 5,
      ...queryOptions,
      enabled: queryOptions.enabled && episodeId !== false,
    }
  );

  const isLoading =
    serie.isLoading ||
    seasons.isLoading ||
    episode.isLoading ||
    serieTrailer.isLoading;
  const noEpisode = !isLoading && !episode.data;
  const isError = seasons.isError || serie.isError || episode.isError;
  const isSuccess =
    !noEpisode &&
    seasons.isSuccess &&
    serie.isSuccess &&
    episode.isSuccess &&
    !serieTrailer.isLoading;

  let error = null;

  if (seasons.isError) {
    error = seasons.error;
  } else if (serie.isError) {
    error = serie.error;
  } else if (episode.isError) {
    error = episode.error;
  }

  const serieDTO = isSuccess
    ? {
        id: serie.data.id,
        images: serie.data.images.map((image) => ({
          url: image.url,
          width: image.width,
          height: image.height,
          aspectRatio: image.aspectRatio,
          type: image.type,
          name: image.name,
        })),
        quality: episode.data.videos[0].height >= 720 ? 'HD' : 'SD',
        seriesTitle: serie.data.title,
        seriesId: serie.data.id,
        description: serie.data.mediumDescription,
        genres: serie.data.genres.map((genre) => genre.label),
        whyItCrackles: serie.data.whyItCrackles,
        rating: {
          name: serie.data.rating.name,
          age: serie.data.rating.age,
        },
        releaseDate: serie.data.releaseDate?.toISOString(),
        releaseYear: serie.data.getReleaseYear(),
        credits: serie.data.credits,
        type: serie.data.type,
        isOriginal: serie.data.isOriginal,
        availableSeasons: seasons.data.length,
        captions: episode.data.captions.map((caption) => ({
          language: caption.language,
          locale: caption.locale,
          label: caption.label,
        })),
        loyaltyPoints: episode.data.loyaltyPoints,
        duration: convertSecondsToHHMMSS(episode.data.duration),
        durationInSeconds: episode.data.duration,
        episodeTitle: episode.data.title,
        episode: episode.data.episode,
        season: episode.data.season,
        isExpiringSoon: episode.data.isExpiringSoon,
        isAvailable: episode.data.isAvailable,
        remainingDays: episode.data.remainingDays,
        externalId: episode.data.externalId,
        playbackId: episode.data.id,
        creditsMark: episode.data.creditsMark,
        progress: nextWatchableEpisode?.progress || 0,
        fullyWatchedSeries: nextWatchableEpisode?.fullyWatchedSeries || false,
        seasons: seasons.data || [],
      }
    : {};

  if (serieTrailer.data) {
    serieDTO.trailer = {
      id: serieTrailer.data.contentId,
      streams: serieTrailer.data.streams,
      mode: serieTrailer.data.mode,
    };
  }

  async function refetch() {
    await serie.refetch();
    await serieTrailer.refetch();
    await seasons.refetch();
    await episode.refetch();
    await nextEpisode.refetch();
  }

  return {
    data: serieDTO,
    isLoading,
    isError,
    error,
    isSuccess,
    refetch,
  };
}

function useEpisodesInSeason(seasonId, queryOptions) {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ['episode-in-season', seasonId],
    () => prepareContentChildren(seasonId),
    {
      queryOptions,
      keepPreviousData: true,
    }
  );

  let episodesInSeasonDTO = {
    data: { episodes: [], id: seasonId },
    isLoading,
  };

  if (isSuccess && !isError) {
    episodesInSeasonDTO = {
      data: { episodes: data, id: seasonId },
      isLoading,
      isError,
      isSuccess,
    };
  }

  return episodesInSeasonDTO;
}

function useTrailer({ id, queryOptions }) {
  const [sourceTrailer, setSourceTrailer] = useState(null);
  const {
    data: childContentJSON,
    isSuccess: successContent,
    isError: errorContent,
  } = useQuery(['content', id], () => prepareContent(id), queryOptions);

  const {
    data: vod,
    isSuccess: successVod,
    isError: errorVod,
  } = useQuery(['vod', id], () => prepareVod(id), queryOptions);

  const isSuccess = successVod && successContent;
  const isError = errorVod && errorContent;

  useEffect(() => {
    function buildSource() {
      const platformStreamSource = getStreamForCurrent(vod?.streams);

      setSourceTrailer(platformStreamSource);
    }

    buildSource();
  }, [childContentJSON, vod]);

  return {
    trailer: sourceTrailer,
    isSuccess,
    isError,
  };
}

/////////////////////////////////////////////////////////////////////////////////////
// Prepare content
/////////////////////////////////////////////////////////////////////////////////////

function getIsOriginal(genres) {
  const originalGenres = Object.values(ORIGINAL_GENRES);

  return !!genres.find((genre) =>
    originalGenres.includes(genre.name.toLowerCase())
  );
}

const prepareContent = async (id, imageSizes) => {
  const data = await fetchContentById(id);

  // get platform id from config
  const { PLATFORM_ID } = getConfig();

  const {
    whyItCrackles,
    title,
    slug,
    shortDescription,
    mediumDescription,
    longDescription,
    releaseDate,
    seasonNumber = null,
    episodeNumber = null,
    exclusive: isExclusive,
  } = getMetadataByLocale(data);

  const { id: contentId, parentId, type, assets, loyaltyPoints, access } = data;

  const avail = access?.avails?.[0]?.platforms.find(
    (platform) => platform.id.toLowerCase() === PLATFORM_ID.toLowerCase()
  );

  let remainingDays = null;

  // calculate content remaining days
  if (avail?.windowEnd) {
    const presentDate = new Date();
    const contentEndDate = new Date(avail?.windowEnd);
    const diffInTime = contentEndDate.getTime() - presentDate.getTime();
    if (diffInTime > 0) {
      const oneDayInMilliSec = 1000 * 3600 * 24;
      remainingDays = Math.floor(diffInTime / oneDayInMilliSec);
    }
  }

  const genres = getGenresByLocale(data);
  const credits = getCreditsByLocale(data);

  const creditsMark = assets?.chapters?.find(
    (chapters) => chapters.type === 'endCreditStart'
  );

  // Create entities for video, images and captions assets
  const videos = assets?.video.map(
    (video) =>
      new VideoAsset({
        id: video.id,
        type: video.type,
        width: video.frameWidth,
        height: video.frameHeight,
        aspectRatio: video.aspectRatio,
        url: video.url,
        duration: video.duration,
      })
  );

  const images = getImagesBySizeRequirements(assets?.images, imageSizes);

  const captions = assets?.captions.map(
    (caption) =>
      new CaptionAsset({
        locale: caption.locale,
        language: caption.language,
        label: caption?.locale
          ? caption.locale.split('-')[0].toUpperCase()
          : '',
      })
  );

  const rating = getRatingsByRegion(data);

  const content = ContentFactory.createContent(type, {
    id: contentId,
    parentId,
    type,
    duration: assets?.video[0]?.duration || null,
    videos,
    images,
    captions,
    genres,
    whyItCrackles,
    title,
    slug,
    shortDescription,
    mediumDescription,
    longDescription,
    releaseDate,
    isExclusive,
    rating,
    credits,
    loyaltyPoints,
    seasonNumber,
    episodeNumber,
    remainingDays,
    isAvailable: remainingDays > 0,
    isExpiringSoon: access?.isExpiringSoon,
    externalId: data.externalId,
    creditsMark: creditsMark?.breakpoints[0]?.startInSeconds || 20,
    isOriginal: getIsOriginal(genres),
  });

  return content;
};

async function prepareVod(id) {
  const data = await fetchContentVod(id);

  const vod = new Vod(data);

  data.streams.forEach((stream) => {
    vod.addStream(stream);
  });

  return vod;
}

async function prepareContentTrailer(contentId) {
  const contentTrailer = await fetchTrailer(contentId);

  let trailerVod = null;

  if (contentTrailer?.id) {
    trailerVod = await prepareVod(contentTrailer.id);
  }

  return trailerVod;
}

async function prepareFirstPlayableContent(contentId) {
  const firstContentMedia = await fetchFirstPlayableMedia(contentId);

  let content = null;

  if (firstContentMedia?.id) {
    content = await prepareContent(firstContentMedia.id);
  }

  return content;
}

async function prepareContentChildren(contentId) {
  const children = await fetchContentChildren(contentId);

  return children;
}

export {
  useEpisodeDetails,
  useFeatureFilmDetails,
  useMovieDetails,
  useSerieDetails,
  useContent,
  useEpisodesInSeason,
};
