/**
 * Custom CrackleApiError
 */
class CrackleApiError extends Error {
  constructor(error) {
    super(`${error.code} --> ${error.message}`);
    this.name = error.type;
    this.code = error.code;
    this.details = error.details;
  }
}

export default CrackleApiError;
