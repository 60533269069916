import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Trans, t } from '@lingui/macro';

// components
import LoadingSpinner from '../loading-spinner/loading-spinner';
import SponsoredFreePassImage from '../sponsored-free-pass-image/sponsored-free-pass-image';

// Icons
import { ReactComponent as BlueDiamondIcon } from '../../assets/icons/blue-diamond.svg';

// context
import useGlobalContext from '../../hooks/use-global-context';

// hooks
import useFreePassTimer from '../../hooks/use-free-pass-timer';

// Style
import './free-pass-tile.scss';

function FreePassTile({
  className,
  border,
  tileMessage,
  tileSize,
  sponsoredAttributes,
}) {
  const sponsored = useGlobalContext((state) => state.adFreePass.sponsored);
  const freePassActive = useGlobalContext((state) => state.adFreePass.active);
  const endTime = useGlobalContext((state) => state.adFreePass.endTime);

  const { roundedTime, showTile, timeUnit } = useFreePassTimer();

  const generateTile = () => (
    <div
      className={classnames('free-pass-tile', className, {
        border,
        'small-size': tileSize === 'SMALL',
      })}
      data-test-id="free-pass-tile"
    >
      <div className="free-pass-tile__icon">
        {sponsored ? (
          <SponsoredFreePassImage
            slotParams={sponsoredAttributes}
            className="sponsored-image"
          />
        ) : (
          <BlueDiamondIcon />
        )}
      </div>
      <div className="free-pass-tile__icon__info">
        <div className="upper-text">{t`LOYALTY_AD_FREE_PASS_ACTIVE`}</div>
        <div className="bottom-text">
          <span data-test-id="free-pass-tile-text">
            <Trans
              id={tileMessage}
              values={{ timeLeft: `${roundedTime} ${timeUnit}` }}
            />
          </span>
        </div>
      </div>
    </div>
  );

  if ((freePassActive && !endTime) || !freePassActive) {
    return null;
  }

  return showTile ? (
    generateTile()
  ) : (
    <div className={classnames('free-pass-tile', className)}>
      <LoadingSpinner />
    </div>
  );
}

FreePassTile.propTypes = {
  className: PropTypes.string,
  border: PropTypes.bool,
  tileMessage: PropTypes.string,
  tileSize: PropTypes.oneOf(['SMALL', 'STANDARD']),
  sponsoredAttributes: PropTypes.shape({
    caid: PropTypes.string,
    rating: PropTypes.string,
  }),
};

FreePassTile.defaultProps = {
  className: '',
  border: false,
  tileMessage: '',
  tileSize: 'STANDARD',
};

export default FreePassTile;
