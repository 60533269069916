/**
 * Gets the value of a specific url param
 * @param {string} paramName Name of paramter to return value for
 * @param {string} queryString optional custom query string
 * @returns {string} The value of the specified url param
 */
const getUrlParamValue = (paramName, queryString) => {
  const urlParams = new URLSearchParams(queryString || window.location.search);
  return urlParams.has(paramName) ? urlParams.get(paramName) : null;
};

/**
 * Gets the value of watchlist params
 * @param {string} paramName Name of paramter to return value for
 * @param {string} queryString optional custom query string
 * @returns {string} The value of the specified url param
 */
function getWatchlistRouteParams(search) {
  const contentId = getUrlParamValue('contentId', search);
  const playbackId = getUrlParamValue('playbackId', search);
  const type = getUrlParamValue('type', search)?.replace('%20', ' ');
  const poster = getUrlParamValue('poster', search);
  const duration = null;
  const progress = null;
  const season =
    getUrlParamValue('season', search) === 'undefined'
      ? null
      : getUrlParamValue('season', search) || '0';
  const episode =
    getUrlParamValue('episode', search) === 'undefined'
      ? null
      : getUrlParamValue('episode', search) || '0';
  const title = getUrlParamValue('title', search)?.replace('%20', ' ');
  const viewed = getUrlParamValue('viewed', search) === 'true';
  const grid = getUrlParamValue('grid', search);
  const previousContentId = getUrlParamValue('previousContentId');

  return {
    contentId,
    playbackId,
    type,
    poster,
    duration,
    progress,
    season,
    episode,
    title,
    viewed,
    grid,
    previousContentId,
  };
}

/**
 * Adds/Updates a set of custom parameters within a url
 * @param {string} url Url address you want to edit
 * @param {Object} paramObject Url params as an object
 * @returns {string || null} Updated Url if params are present, null otherwise
 */
const replaceUrlParams = (url, paramObject) => {
  if (!paramObject || paramObject === {}) return null;

  let newUrl = url;
  const paramEntries = Object.entries(paramObject);

  paramEntries.forEach(([paramName, paramValue]) => {
    const pattern = new RegExp(`[\\?;&]${paramName}=([^&#;]*)`);
    const matches = pattern.exec(newUrl);

    if (matches === null) {
      // this fallback won't always work, it will depend on the param type. FW params are splitted by type using ; (see https://crackle.atlassian.net/wiki/spaces/~768039812/pages/1544847365/Freewheel+Params)
      newUrl += `&${paramName}=${paramValue}`;
    } else {
      const delimiter = matches[0].charAt(0);
      newUrl = newUrl.replace(
        pattern,
        `${delimiter}${paramName}=${paramValue}`
      );
    }
  });

  // replace all the unreplaced params with empty string
  // https://crackle.atlassian.net/browse/CD-4767
  const pattern = /\[(.+?)\]/g;
  const matches = newUrl.matchAll(pattern);

  for (const match of matches) {
    newUrl = newUrl.replace(match[0], '');
  }
  return newUrl;
};

const populateRouteParameters = (path, params) => {
  let result = path;
  Object.keys(params).forEach((key) => {
    result = result.replace(`:${key}`, params[key]);
  });
  return result;
};

/**
 *  Add new parameters to a given query string
 *  Given a query string from an url (queryString) and an object (extraParams) will add objects items to the given query string
 *  If removeIfNull is true, any extra params which value is null will be removed from the final query string
 *
 * @param {string} queryString current url query string
 * @param {object} extraParams object with parameters to add to the url
 * @param {boolean} removeIfNull remove param from query string if it's null
 * @returns
 */
const addParamsToQueryString = (
  queryString,
  extraParams,
  removeIfNull = false
) => {
  let params = {};
  if (queryString !== '') {
    params = queryString
      .replace('?', '')
      .split('&')
      .reduce((paramsObj, item) => {
        const [key, value] = item.split('=');
        return removeIfNull && !value ? {} : { ...paramsObj, [key]: value };
      }, {});
  }

  const mergedParams = { ...(params || {}), ...extraParams };

  if (removeIfNull) {
    Object.keys(mergedParams).forEach((key) => {
      if (mergedParams[key] === null) {
        delete mergedParams[key];
      }
    });
  }

  return `?${objectToQueryString(mergedParams)}`;
};

const objectToQueryString = (obj) =>
  Object.entries(obj)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

const isValidUrl = (url) => {
  try {
    const validUrl = new URL(url);
    return !!validUrl;
  } catch (e) {
    return false;
  }
};

export {
  getUrlParamValue,
  replaceUrlParams,
  populateRouteParameters,
  objectToQueryString,
  addParamsToQueryString,
  isValidUrl,
  getWatchlistRouteParams,
};
