import PropTypes from 'prop-types';
import { create } from 'zustand';
import { produce, enableES5 } from 'immer';
import createContext from 'zustand/context';
import NAVBAR_KEYS from '../../enums/navbarKeys';

enableES5();

const { Provider, useStore } = createContext();

// Turn the set method into an immer proxy
const immer = (config) => (set, get, api) =>
  config(
    (partial, replace) => {
      const nextState =
        typeof partial === 'function' ? produce(partial) : partial;
      return set(nextState, replace);
    },
    get,
    api
  );

const createStore = (setError) =>
  create(
    immer((set) => ({
      ssaiParams: null,
      hoverEnabled: false, // this is used to signal that the user is using a pointer device, such as a mouse or magic remote. Could this be on a custom hook instead?
      deviceInfo: null, // this is information about the device that is stored while the app is initializing. But this same info is consumed elsewhere directly from platform.js
      parentalSettingsEdit: false, // used to handle a specific use-case when the user clicks back after editing the parental controls on a locked title... there may be a better way to do this.
      playInitiationType: '', // used to store an mParticle var which must be set earlier than the place where the mParticle call is actually made. Probably makes sense as an analytics-only global state
      playerTraversing: false, // used by use-back-button and set by controls to hide player controls if the user hits the back button while the player is ffwd... probably best handled as internal component state of the player controls
      seasonEpisodes: [], // used to load data from the API inside controls.js ... worst filename as it deals with API fetching ... should be handled in a data-fetching hook and not as global state
      userRegion: '', // arguably this is part of the appConfig global state, which should be stored once the app initializes
      vmapUrl: '', // arguably this is part of the appConfig global state, which should be stored once the app initializes
      sponsoredAdsUrl: '', // arguably this is part of the appConfig global state, which should be stored once the app initializes
      showMoreInfoScreen: false,
      loyalty: {
        optedIn: false, // To notify if user has opted in loyalty program
        userPoints: null, // to keep reference of the last user points retrieved by api
      },
      adFreePass: {
        active: false, // use to know if there is an active ad-free pass
        endTime: null, // gives information about the ending time of an active ad-free pass
        sponsored: false, // to differentiate between a normal ad-free pass or an sponsored one
        sponsoredUrl: null, // The url for sponsored free pass logos
      },
      loyaltyAccrualPointsNotification: {
        // allows schedule an accrual point toast
        schedule: false, // determines if a notification should be shown on back from playback
        title: null, // title name for points notification
      },
      // ad free pass valid code for sending to freewheel for ad free experience
      ADFPCode: null,
      userPreferences: {
        ccEnabled: false, // if we need to react to the user changing their CC settings, this could be done by an event handler in the player for an event in platform
      },
      userPrivacySettings: {
        doNotSell: false, // used to pass doNotSell status to ad servers
        doNotShare: false, // used to pass doNotShare status to ad servers
      },
      userBirthday: undefined, // used to store the user's birthday to check if the user is old enough set privacy settings
      showMenu: false, // determines if main menu should be displayed
      zombieModeLastUserInteractionAt: null, // timestamp of the last user interaction with the app during playback
      zombieModeStillWatchingShownAt: null, // timestamp of the moment the still watching screen was shown to the user
      toast: {
        type: null,
        props: {},
        visibleTime: 5000,
      },
      search: {
        savedInputValue: '',
        stripVisibility: true,
      },
      ui: {
        showPlayerControls: null,
        navbar: {
          [NAVBAR_KEYS.MAIN]: {
            navbarIsOpen: false,
          },
          [NAVBAR_KEYS.GENRE]: {
            navbarIsOpen: true,
          },
          [NAVBAR_KEYS.SETTINGS]: {
            navbarIsOpen: true,
          },
        },
      },
      modals: {
        isModalShown: false,
        modalChange: false,
        modalArray: [],
        modal: null,
      },
      watchlist: {
        selectedItem: {},
        previousSelectedItemId: '',
      },
      setSSAIParams: (input) => set((state) => void (state.ssaiParams = input)),
      setCCEnabled: (input) =>
        set((state) => void (state.userPreferences.ccEnabled = input)),
      setUserPrivacySettings: (input) =>
        set((state) => void (state.userPrivacySettings = input)),
      setHoverEnabled: (input) =>
        set((state) => void (state.hoverEnabled = input)),
      setDeviceInfo: (input) => set((state) => void (state.deviceInfo = input)),
      setError,
      setParentalSettingsEdit: (input) =>
        set((state) => void (state.parentalSettingsEdit = input)),
      setPlayInitiationType: (input) =>
        set((state) => void (state.playInitiationType = input)),
      setPlayerTraversing: (input) =>
        set((state) => void (state.playerTraversing = input)),
      setSeasonEpisodes: (input) =>
        set((state) => void (state.seasonEpisodes = input)),
      setUserRegion: (input) => set((state) => void (state.userRegion = input)),
      setVmapUrl: (input) => set((state) => void (state.vmapUrl = input)),
      setSponsoredAdsUrl: (input) =>
        set((state) => void (state.sponsoredAdsUrl = input)),
      setShowMoreInfoScreen: (input) =>
        set((state) => void (state.showMoreInfoScreen = input)),
      setLoyaltyOptIn: (input) =>
        set((state) => void (state.loyalty.optedIn = input)),
      setLoyaltyFreePassActive: (input) =>
        set((state) => void (state.adFreePass.active = input)),
      setLoyaltyFreePassEndTime: (input) =>
        set((state) => void (state.adFreePass.endTime = input)),
      schedulePointsNotification: (contentTitle) =>
        set(() => ({
          loyaltyAccrualPointsNotification: {
            schedule: true,
            title: contentTitle,
          },
        })),
      removePointsNotificationSchedule: () =>
        set(() => ({
          loyaltyAccrualPointsNotification: {
            schedule: false,
            title: null,
          },
        })),
      setUserBirthday: (input) =>
        set((state) => void (state.userBirthday = input)),
      setLoyaltyUserPoints: (input) =>
        set((state) => void (state.loyalty.userPoints = input)),
      setLoyaltySponsoredPassUrl: (input) =>
        set((state) => void (state.adFreePass.sponsoredUrl = input)),
      setADFPCode: (input) => set((state) => void (state.ADFPCode = input)),
      setSponsoredFreePass: (input) =>
        set((state) => void (state.adFreePass.sponsored = input)),
      setShowMenu: (input) => set((state) => void (state.showMenu = input)),
      setZombieModeLastUserInteractionAt: (input) =>
        set((state) => void (state.zombieModeLastUserInteractionAt = input)),
      setZombieModeStillWatchingShownAt: (input) =>
        set((state) => void (state.zombieModeStillWatchingShownAt = input)),
      setToast: (input) => set((state) => void (state.toast = input)),
      setSavedInputValue: (input) =>
        set((state) => void (state.search.savedInputValue = input)),
      setStripVisibility: (input) =>
        set((state) => void (state.search.stripVisibility = input)),
      setShowPlayerControls: (input) =>
        set((state) => void (state.ui.showPlayerControls = input)),
      setNavbarStates: (input) =>
        set(
          (state) => void (state.ui.navbar = { ...state.ui.navbar, ...input })
        ),
      setIsModalShown: (input) =>
        set((state) => void (state.modals.isModalShown = input)),
      setModalChange: (input) =>
        set((state) => void (state.modals.modalChange = input)),
      setModalArray: (input) =>
        set((state) => void (state.modals.modalArray = input)),
      setModal: (input) => set((state) => void (state.modals.modal = input)),
      setWatchlistSelectedItem: (input) =>
        set((state) => void (state.watchlist.selectedItem = input)),
      setWatchlistPreviousSelectedItemId: (input) =>
        set((state) => void (state.watchlist.previousSelectedItemId = input)),
    }))
  );

function GlobalContextProvider({ children, setError }) {
  const createStoreFactory = () => {
    return createStore(setError);
  };
  return <Provider createStore={createStoreFactory}>{children}</Provider>;
}
export { GlobalContextProvider, useStore };
GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  setError: PropTypes.func.isRequired,
};
