/* eslint-disable no-underscore-dangle */
class Block {
  constructor({
    id,
    guid,
    title,
    type,
    url,
    images,
    description,
    displayStyle,
  }) {
    this._id = id;
    this._guid = guid;
    this._title = title;
    this._type = type;
    this._url = url;
    this._images = images;
    this._description = description;
    this._displayStyle = displayStyle;
  }

  // getters and setters
  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get guid() {
    return this._guid;
  }

  set guid(guid) {
    this._guid = guid;
  }

  get title() {
    return this._title;
  }

  set title(title) {
    this._title = title;
  }

  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
  }

  get url() {
    return this._url;
  }

  set url(url) {
    this._url = url;
  }

  get images() {
    return this._images;
  }

  set images(images) {
    this._images = images;
  }

  get description() {
    return this._description;
  }

  set description(description) {
    this._description = description;
  }

  get displayStyle() {
    return this._displayStyle;
  }

  set displayStyle(displayStyle) {
    this._displayStyle = displayStyle;
  }
}

export default Block;
