import { makeRequest } from '../common/base';

/**
 * Gets a list of media items matching a provided search string
 * @param {string} searchString The string of characters to search for
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
const fetchSearchResults = async (
  searchString,
  pageNumber = 1,
  pageSize = 20,
  searchFileds = ['Title', 'Cast']
) => {
  const params = {
    useFuzzyMatching: false,
    enforcemediaRights: true,
    pageNumber,
    pageSize,
    contentType: 'Channels',
    searchFields: searchFileds.join(','),
  };

  const urlSearchParams = new URLSearchParams({
    ...params,
  });

  return makeRequest(
    `/contentdiscovery/search/${searchString}?${urlSearchParams.toString()}`
  ).then((response) => {
    const searchResponse = response;

    return searchResponse;
  });
};

/**
 * Gets a list of suggestions of possible shows you are looking for
 * @param {string} searchString The string of characters to search for
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
const fetchSearchSuggestions = async (
  searchString,
  pageNumber = 1,
  pageSize = 10,
  searchFileds = ['Title']
) => {
  const params = {
    useFuzzyMatching: false,
    enforcemediaRights: true,
    pageNumber,
    pageSize,
    contentType: 'Channels',
    searchFields: searchFileds.join(','),
  };

  const urlSearchParams = new URLSearchParams({
    ...params,
  });

  return makeRequest(
    `/contentdiscovery/search/suggest/${searchString}?${urlSearchParams.toString()}`
  ).then((response) => response);
};

export { fetchSearchResults, fetchSearchSuggestions };
