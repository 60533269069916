import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { shallow } from 'zustand/shallow';
import { t } from '@lingui/macro';

// Components
import ErrorScreen from '../error/error';
import PinError from '../pin-modal/pin-error';
import Menu from '../menu/menu-container';

// Api
import CrackleApiError from '../../crackle-sdk/v1/api/error';
import { fetchUserProfile } from '../../crackle-sdk/v1/api/user';

// Enums
import ROUTES from '../../enums/routes';
import ERROR_TYPE from '../../config/error-type-config';

// Icons
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/sliders.svg';

// Hooks
import useGlobalContext from '../../hooks/use-global-context';
import useGuardedState from '../../hooks/use-guarded-state';
import useFreePassTimer from '../../hooks/use-free-pass-timer';
import useGlobalKeyEventsHandler from '../../hooks/use-global-key-events-handler';
import useExitButton from '../../hooks/use-exit-button';

// Utils
import { getNumericEnvVar } from '../../utils/utils';
import logger from '../../utils/logger';
import { isFeatureFlagEnabled } from '../../utils/feature-flags';
import { getLocalPrivacySettings } from '../../utils/user-actions';
import { getSignedInUser } from '../../utils/signed-in-user';
import {
  exitApplication,
  getDeviceInformation,
  supportCapability,
  setClosedCaptionCallback,
} from '../../platform/index';

// Styles
import './base-container.scss';
import './extra-css-transitions.scss';
import useGlobalEventHandler from '../../hooks/use-global-event-handler';
import PLATFORM_CAPABILITIES from '../../enums/platform-capabilities';
import { isDOBUnderAgeLimit } from '../../utils/dates';

const CONNECTION_CHANGE_BUFFER = getNumericEnvVar(
  'APP_CONNECTION_CHANGE_BUFFER',
  3000
);

function BaseContainer({ appConfig, connection, children }) {
  const {
    setUserRegion,
    setVmapUrl,
    setParentalSettingsEdit,
    setSponsoredAdsUrl,
    setDeviceInfo,
    setUserBirthday,
    hoverEnabled,
    setError,
    setSSAIParams,
    setCCEnabled,
    setUserPrivacySettings,
    setLoyaltyOptIn,
    setLoyaltyFreePassActive,
    setLoyaltyUserPoints,
    setLoyaltySponsoredPassUrl,
    setADFPCode,
    showMenu,
  } = useGlobalContext(
    (state) => ({
      setSSAIParams: state.setSSAIParams,
      setUserRegion: state.setUserRegion,
      setVmapUrl: state.setVmapUrl,
      setParentalSettingsEdit: state.setParentalSettingsEdit,
      setSponsoredAdsUrl: state.setSponsoredAdsUrl,
      setDeviceInfo: state.setDeviceInfo,
      setUserBirthday: state.setUserBirthday,
      hoverEnabled: state.hoverEnabled,
      setError: state.setError,
      setCCEnabled: state.setCCEnabled,
      setUserPrivacySettings: state.setUserPrivacySettings,
      setLoyaltyOptIn: state.setLoyaltyOptIn,
      setLoyaltyFreePassActive: state.setLoyaltyFreePassActive,
      setLoyaltyUserPoints: state.setLoyaltyUserPoints,
      setLoyaltySponsoredPassUrl: state.setLoyaltySponsoredPassUrl,
      setADFPCode: state.setADFPCode,
      showMenu: state.showMenu,
    }),
    shallow
  );

  useGlobalKeyEventsHandler();
  // handles the exit button on the remote for platforms that support it
  useExitButton();

  const { checkFreePassActive } = useFreePassTimer();
  const [isOnline, setIsOnline] = useGuardedState(connection.online);
  const timeoutBufferRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const userId = getSignedInUser()?.userId;

  useGlobalEventHandler();

  useEffect(() => {
    logger.debug('Setting closedCaptionCallback on TV');
    setClosedCaptionCallback((isCCEnabled) => {
      logger.debug(
        'TV called closedCaptionCallback, setting closed captions to:',
        isCCEnabled ? 'on' : 'off'
      );
      setCCEnabled(isCCEnabled);
    });
  }, [setCCEnabled]);

  logger.debug('BaseContainer render, connection online: ', connection.online);

  useEffect(() => {
    logger.debug(
      `Connection change detected, is online: ${connection.online}, starting buffer for ${CONNECTION_CHANGE_BUFFER}ms`
    );

    timeoutBufferRef.current = setTimeout(() => {
      logger.debug(
        `Connection consistent for ${CONNECTION_CHANGE_BUFFER}ms, changing online status to ${connection.online}`
      );

      setIsOnline(connection.online);
    }, CONNECTION_CHANGE_BUFFER);
    return () => clearTimeout(timeoutBufferRef.current);
  }, [connection.online, setIsOnline]);

  useEffect(() => {
    if (appConfig?.device?.vMapTemplate) {
      setVmapUrl(
        isFeatureFlagEnabled('testAdBlocker')
          ? null
          : appConfig.device.vMapTemplate
      );
    } else {
      setError(ERROR_TYPE.APP_LOAD);
      logger.error('Missing vMapTemplate from App Config');
    }

    // Get AdsParams template from App Config
    if (appConfig?.device?.adsParams) {
      setSSAIParams(appConfig?.device?.adsParams);
    }

    if (appConfig?.device?.clientRegion) {
      const { clientRegion } = appConfig.device;
      setUserRegion(clientRegion);
    } else {
      setError(ERROR_TYPE.APP_LOAD);
      logger.error('Missing clientRegion from App Config');
    }

    if (appConfig?.device?.rewardsTemplate) {
      setLoyaltySponsoredPassUrl(appConfig.device.rewardsTemplate);
    }

    if (appConfig?.device?.adSponsorshipTemplate) {
      setSponsoredAdsUrl(appConfig.device.adSponsorshipTemplate);
    }

    // get ad free pass code id
    setADFPCode(appConfig?.global?.adfp);

    // Set deviceInfo on the GlobalContext
    getDeviceInformation().then((tvDeviceInfo) => {
      setDeviceInfo(tvDeviceInfo);
    });

    // To set the user preferences
    const fetchUserPreferences = async () => {
      if (userId) {
        try {
          const userData = await fetchUserProfile(userId);

          if (userData) {
            // To enable all privacy restrictions when the user is under 16
            if (isDOBUnderAgeLimit(userData.birthday)) {
              userData.userPrivacy.doNotSell = true;
              userData.userPrivacy.doNotShare = true;
            }

            setUserPrivacySettings(userData.userPrivacy);
            setUserBirthday(userData.birthday);

            if (isFeatureFlagEnabled('loyalty')) {
              setLoyaltyOptIn(userData.userLoyaltyOptIn.status);
              checkFreePassActive();
              setLoyaltyUserPoints(userData.userLoyaltyPoints.points);

              if (isFeatureFlagEnabled('testLoyaltyApiMock')) {
                setLoyaltyFreePassActive(true);
              }
            }
          }
        } catch (e) {
          setError(ERROR_TYPE.APP_LOAD);
          if (e instanceof CrackleApiError) {
            logger.error(e);
          } else {
            logger.error('Error occurred while fetching user profile', e);
          }
        }
      } else {
        setUserPrivacySettings(getLocalPrivacySettings());
      }
    };

    fetchUserPreferences();
  }, [
    appConfig,
    setDeviceInfo,
    setSponsoredAdsUrl,
    setLoyaltySponsoredPassUrl,
    setError,
    setUserRegion,
    setVmapUrl,
    setSSAIParams,
    setUserPrivacySettings,
    setUserBirthday,
    userId,
    setLoyaltyOptIn,
    setLoyaltyFreePassActive,
    checkFreePassActive,
    setLoyaltyUserPoints,
    setADFPCode,
  ]);

  const tryAgainCallback = useCallback(() => {
    const event = new CustomEvent('force-health-check', {});

    window.dispatchEvent(event);
  }, []);

  const generateErrorScreen = useCallback(() => {
    if (location.pathname.includes('watch')) {
      return (
        <ErrorScreen
          onPrimaryClick={() => navigate(-1)}
          onSecondaryClick={tryAgainCallback}
          focus="secondary"
          primaryLabel={t`GO_BACK`}
          loading={connection.checking}
        />
      );
    }

    return (
      <ErrorScreen
        onPrimaryClick={
          supportCapability(PLATFORM_CAPABILITIES.EXIT)
            ? () => exitApplication()
            : null
        }
        onSecondaryClick={tryAgainCallback}
        focus="secondary"
        loading={connection.checking}
      />
    );
  }, [location.pathname, tryAgainCallback, connection.checking, navigate]);

  if (
    location.pathname.includes(ROUTES.PINSETTINGS) &&
    !location.pathname.includes(ROUTES.SETTINGS)
  ) {
    return (
      <PinError
        title={t`CONTENT_LOCKED`}
        description={t`CONTENT_LOCKED_DESCRIPTION`}
        icon={LockIcon}
        buttonIcon1={ArrowLeft}
        buttonIcon2={SettingsIcon}
        buttonLabel1={t`GO_BACK`}
        buttonLabel2={t`EDIT_PARENTAL_SETTINGS`}
        buttonCallback1={() => navigate(-1)}
        buttonCallback2={() => {
          navigate(ROUTES.SETTINGS, { replace: true });
          setParentalSettingsEdit(true);
        }}
        hasMultilineTitle
      />
    );
  }

  return !isOnline ? (
    generateErrorScreen()
  ) : (
    <>
      {showMenu && <Menu />}
      <div
        className={classnames('main', {
          'enable-css-transitions': isFeatureFlagEnabled('animations'),
          'full-width': !showMenu,
          'hover-enabled': hoverEnabled,
          'hover-disabled': !hoverEnabled,
        })}
        id="base-container"
        data-test-id="app-main-container"
      >
        {children}
      </div>
    </>
  );
}

BaseContainer.propTypes = {
  appConfig: PropTypes.objectOf(PropTypes.any),
  connection: PropTypes.shape({
    online: PropTypes.bool,
    // connection is being checked
    checking: PropTypes.bool,
  }),
  children: PropTypes.node.isRequired,
};

export default BaseContainer;
