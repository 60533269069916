import { useCallback, useEffect } from 'react';
import { useLocation, matchPath, useNavigate } from 'react-router-dom';
import ROUTES from '../enums/routes';
import { KEYCODES } from '../platform/index';
import { isKey } from '../utils/utils';

function useGlobalKeyEventsHandler() {
  const currentLocation = useLocation();
  const navigate = useNavigate();

  const navigateToSearch = useCallback(() => {
    const inSearchPage = !!matchPath(
      {
        path: ROUTES.SEARCH,
      },
      currentLocation.pathname
    );

    const inWatchPage = !!matchPath(
      {
        path: ROUTES.WATCH,
      },
      currentLocation.pathname
    );

    // If user is not in search page or watch page redirect user to search page
    if (!inSearchPage && !inWatchPage) {
      navigate(ROUTES.SEARCH);
    }
  }, [currentLocation.pathname, navigate]);

  useEffect(() => {
    function onKeyDown(event) {
      if (isKey(event.keyCode, KEYCODES.SEARCH)) {
        navigateToSearch();
      }
    }

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [navigateToSearch]);

  return null;
}

export default useGlobalKeyEventsHandler;
