import Series from './series';
import Movie from './movie';
import FeatureFilm from './featureFilm';
import Episode from './episode';

import CONTENT_TYPES from '../enums/contentTypes';
import Season from './season';
import Trailer from './trailer';

// pass all properties as object or each as different parameters??
class ContentFactory {
  static createContent = (contentType, contentParams) => {
    let content = null;

    switch (contentType) {
      case CONTENT_TYPES.EPISODE:
        content = new Episode(contentParams);
        break;
      case CONTENT_TYPES.MOVIE:
        content = new Movie(contentParams);
        break;
      case CONTENT_TYPES.FEATURED_FILM:
        content = new FeatureFilm(contentParams);
        break;
      case CONTENT_TYPES.SERIES:
        content = new Series(contentParams);
        break;
      case CONTENT_TYPES.SEASON:
        content = new Season(contentParams);
        break;
      case CONTENT_TYPES.TRAILER:
        content = new Trailer(contentParams);
        break;
      default:
        return null;
    }

    return content;
  };
}

export default ContentFactory;
