import { useState, useEffect, useCallback } from 'react';
import { t } from '@lingui/macro';

// api
import { getUserRewardsList } from '../crackle-sdk/v1/api/loyalty';

// utils
import { getSignedInUser } from '../utils/signed-in-user';
import { isFeatureFlagEnabled } from '../utils/feature-flags';

// enums
import REWARD_TYPES from '../enums/reward-types';

// context
import useGlobalContext from './use-global-context';

// This hook allows to keep track of the time passing of an ad-free pass, within it the time of left for the free-pass
// to expire is calculated and the states in the global state related are setted.
function useFreePassTimer() {
  const [showTile, setShowTile] = useState(false);
  const [roundedTime, setRoundedTime] = useState(null);
  const [shortTimeUnit, setShortTimeUnit] = useState(null);
  const [timeUnit, setTimeUnit] = useState(null);

  const endTime = useGlobalContext((state) => state.adFreePass.endTime);
  const setEndTime = useGlobalContext(
    (state) => state.setLoyaltyFreePassEndTime
  );

  const setFreePassActive = useGlobalContext(
    (state) => state.setLoyaltyFreePassActive
  );

  const setSponsoredFreePass = useGlobalContext(
    (state) => state.setSponsoredFreePass
  );

  const userId = getSignedInUser()?.userId;

  // This function is in charge of setting the end time of an ad-free pass looking within the user rewards
  // for a active free pass. If there's an active free pass it sets the global state freePassActive to true and the endTime of
  // called freePass, if there's no free pass active it doesn't allow the free pass tiles to show up
  const checkFreePassActive = useCallback(async () => {
    if (!userId) {
      setFreePassActive(false);
      setEndTime(null);
      setSponsoredFreePass(false);
      return;
    }

    if (!isFeatureFlagEnabled('loyalty')) {
      return;
    }

    const rewards = await getUserRewardsList(userId);

    const reward = rewards.find(
      (rew) =>
        rew.active &&
        (rew.rewardType === REWARD_TYPES.SPONSORED_FREE_PASS ||
          rew.rewardType === REWARD_TYPES.FREE_PASS)
    );

    if (reward) {
      const endRewardTime = new Date(reward.entitlementEndDate).getTime();

      if (
        reward.rewardType === REWARD_TYPES.SPONSORED_FREE_PASS ||
        isFeatureFlagEnabled('testLoyaltySponsoredFreePass')
      ) {
        setSponsoredFreePass(true);
      }

      setFreePassActive(true);
      setEndTime(endRewardTime);
    } else {
      setFreePassActive(false);
      setEndTime(null);
      setSponsoredFreePass(false);
      setShowTile(false);
    }
  }, [userId, setEndTime, setFreePassActive, setSponsoredFreePass]);

  // This useEffect allows to check if there's an ad-free pass active or not. If it's active
  // the function goes within the user rewards and looks for a free pass to set the timer
  useEffect(() => {
    checkFreePassActive();
  }, [checkFreePassActive]);

  // This useEffect calculates the time left for an active pass (hours, minutes and seconds) to display
  // in the components being used, here the states are setted.
  // In this useEffect the state of a free pass also ends when the timer goes to zero, deactivating the free-pass in the global state
  useEffect(() => {
    const interval = setInterval(() => {
      if (!endTime) {
        return;
      }
      const dateNow = new Date().getTime();

      const remainingTime = endTime - dateNow;

      if (remainingTime <= 0 && showTile) {
        setEndTime(null);
        setFreePassActive(false);
        setShowTile(false);
      }

      const remainingHours = Math.floor(
        (remainingTime % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
      );

      if (remainingHours > 0) {
        setRoundedTime(remainingHours);

        setShortTimeUnit('hr');
        if (remainingHours === 1) {
          setTimeUnit(t`HOUR`);
        } else {
          setTimeUnit(t`HOURS`);
        }

        if (!showTile) {
          setShowTile(true);
        }

        return;
      }

      const remainingMinutes = Math.floor(
        (remainingTime % (60 * 60 * 1000)) / (1000 * 60)
      );

      if (remainingMinutes > 0) {
        setRoundedTime(remainingMinutes);
        setShortTimeUnit('m');
        if (remainingMinutes === 1) {
          setTimeUnit(t`MINUTE`);
        } else {
          setTimeUnit(t`MINUTES`);
        }

        if (!showTile) {
          setShowTile(true);
        }
        return;
      }

      const remainingSeconds = Math.floor((remainingTime % (60 * 1000)) / 1000);

      if (remainingSeconds > 0) {
        setRoundedTime(remainingSeconds);

        setShortTimeUnit('s');
        if (remainingSeconds === 1) {
          setTimeUnit(t`SECOND`);
        } else {
          setTimeUnit(t`SECONDS`);
        }

        if (!showTile) {
          setShowTile(true);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval.current);
    };
  }, [endTime, showTile, setEndTime, setFreePassActive]);

  return {
    roundedTime,
    showTile,
    shortTimeUnit,
    timeUnit,
    checkFreePassActive,
  };
}

export default useFreePassTimer;
