// Enums
import LOCAL_STORAGE from '../enums/local-storage';
import CONTENT_TYPES from '../crackle-sdk/v2/api/enums/contentTypes';
import { getLocalStorageItem } from './local-storage';

function getContinueWatchingLocalArray() {
  const sessionHistory = JSON.parse(
    getLocalStorageItem(LOCAL_STORAGE.SESSION_HISTORY)
  );

  let continueWatchingLocalArray = [];

  const movies = sessionHistory?.movies || [];
  const tvShows = sessionHistory?.tvShows || {};
  const seriesIdsArray = Object.keys(tvShows);

  if (movies.length > 0) {
    continueWatchingLocalArray = movies;
  }

  if (seriesIdsArray.length > 0) {
    seriesIdsArray.forEach((seriesId) => {
      const seriesEpisode = tvShows[`${seriesId}`];
      const mostRecentEpisodeFromSeries = seriesEpisode[0];
      continueWatchingLocalArray = [
        ...continueWatchingLocalArray,
        mostRecentEpisodeFromSeries,
      ];
    });
  }

  continueWatchingLocalArray = continueWatchingLocalArray
    .map((CWItem) => {
      const {
        mediaId,
        contentPoster,
        progress,
        mediaEndTime,
        playType,
        episode,
        season,
        timestamp,
        title,
      } = CWItem;

      const realMediaId = mediaId.slice(0, mediaId.length - 3);
      const contentType =
        episode || season ? CONTENT_TYPES.EPISODE : CONTENT_TYPES.FEATURED_FILM;

      return {
        mediaId: realMediaId,
        contentPoster,
        progress,
        mediaEndTime,
        contentType,
        playType,
        timestamp,
        title,
        episode,
        season,
      };
    })
    .filter(({ progress, mediaEndTime }) => progress < mediaEndTime)
    .sort((a, b) => {
      if (a.timestamp && b.timestamp) {
        return b.timestamp - a.timestamp;
      }

      if (a.timestamp && !b.timestamp) {
        return -1;
      }

      if (b.timestamp && !a.timestamp) {
        return 1;
      }
    });

  return continueWatchingLocalArray.slice(0, 20);
}

function getContinueWatchingLastUpdateTime() {
  const sessionHistory = JSON.parse(
    getLocalStorageItem(LOCAL_STORAGE.SESSION_HISTORY)
  );

  return sessionHistory?.lastUpdated || 0;
}

export { getContinueWatchingLocalArray, getContinueWatchingLastUpdateTime };
