import { v4 as uuidv4 } from 'uuid';
import { isFeatureFlagEnabled } from '../utils/feature-flags';
import { getDeviceInformationForWeb } from '../utils/utils';
import APP_CONFIG from '../config/app-config.json';
import { SAMPLE_NON_DRM_DASH_URL } from '../crackle-sdk/v1/api/common/vars';
import logger from '../utils/logger';
import KEY_HINTS_TYPE from '../enums/key-hint-type';
import PLAYER_AD_BREAK_INSERT_TYPES from '../enums/player-ad-break-insert-types';
import PLATFORM_CAPABILITIES from '../enums/platform-capabilities';

import { ReactComponent as BackKey } from '../assets/icons/back-key.svg';

// TODO: update codes with xbox specifics
const KEYCODES = {
  BACK: [8, 196],
  DOWN: 40,
  ENTER: [13, 195],
  SPACE: 198,
  EXIT: 27,
  LEFT: 37,
  MEDIA_PAUSE: 19,
  MEDIA_FAST_FORWARD: [199, 202],
  MEDIA_PLAY: 415,
  MEDIA_PLAY_PAUSE: null,
  MEDIA_REWIND: [200, 201],
  MEDIA_STOP: 413,
  MEDIA_NEXT: 418,
  MEDIA_PREVIOUS: 419,
  RIGHT: 39,
  UP: 38,
  CC: 207,
  SEARCH: 198,
  DELETE_CHAR: 197,
  ENTER_CHAR: 202,
  NAV_RIGHT: 199,
  NAV_LEFT: 200,
  CHANNEL_UP: 33,
  CHANNEL_DOWN: 34,
  NUMBER_0: null,
  NUMBER_1: null,
  NUMBER_2: null,
  NUMBER_3: null,
  NUMBER_4: null,
  NUMBER_5: null,
  NUMBER_6: null,
  NUMBER_7: null,
  NUMBER_8: null,
  NUMBER_9: null,
};

const deviceData = {
  deviceModel: null,
  deviceId: null,
  osVersion: null,
};

const supportedAdInsertionMode = [
  PLAYER_AD_BREAK_INSERT_TYPES.CSAIV2,
  PLAYER_AD_BREAK_INSERT_TYPES.SSAI,
];
const platformCapabilities = {
  // limit ad tracking
  [PLATFORM_CAPABILITIES.LIMIT_AD_TRACKING]: true,
  [PLATFORM_CAPABILITIES.CCPA]: false,
  [PLATFORM_CAPABILITIES.VIZBEE]: false,
  // Does the platform support automatic arial label text-to-speech
  [PLATFORM_CAPABILITIES.ARIA_LABEL]: false,
  // Does the platform supports exiting the app?
  [PLATFORM_CAPABILITIES.EXIT]: true,
  // Does the platform has a specific CC button in the remote? If not, we will use the CC button in the player
  [PLATFORM_CAPABILITIES.CC_BUTTON]: false,
  // Does the platform has a specific exit button in the remote and allow to override the default exit behavior?
  [PLATFORM_CAPABILITIES.EXIT_BUTTON]: false,
  // Does the platform support iframe srcdoc attribute for sponsors?
  [PLATFORM_CAPABILITIES.IFRAME_SRC_DOC]: false,
  // Does the platform have platformSessionId?
  [PLATFORM_CAPABILITIES.PLATFORM_SESSION_ID]: false,
  // Does the platform need to announce the end of grids or not possible navigation?
  [PLATFORM_CAPABILITIES.TTS_NAVIGATION_LIMIT_NEEDED]: false,
};

function onBackEvent(e) {
  e.handled = true;

  return false;
}

const initializeDevice = (onLoad) => {
  if (isTv()) {
    // get id based on publisher
    // https://docs.microsoft.com/en-us/uwp/api/window.Windows.system.profile.systemidentification.getsystemidforpublisher?view=winrt-22621

    try {
      const systemId =
        window.Windows.System.Profile.SystemIdentification.getSystemIdForPublisher();

      // system id is a window.Windows.Storage.Streams.Buffer instance
      const dataReader = window.Windows.Storage.Streams.DataReader.fromBuffer(
        systemId.id
      );

      const deviceId = dataReader.readGuid();

      // get device family version and parse it to get os version
      // https://www.pangolinsquare.com/en/blog/typing-pangolin/uwp-dev/get-windows-version-at-uwp-app
      const currentVersion =
        window.Windows.System.Profile.AnalyticsInfo.versionInfo
          .deviceFamilyVersion;
      const currentUlong = parseInt(currentVersion, 10).toString(16);

      const bit00 = parseInt(currentUlong.slice(currentUlong.length - 4), 16);
      const bit16 = parseInt(
        currentUlong.slice(currentUlong.length - 8, currentUlong.length - 4),
        16
      );
      const bit32 = parseInt(
        currentUlong.slice(currentUlong.length - 12, currentUlong.length - 8),
        16
      );
      const bit48 = parseInt(
        currentUlong.slice(0, currentUlong.length - 12),
        16
      );

      const systemName =
        window.Windows.Security.ExchangeActiveSyncProvisioning.EasClientDeviceInformation()
          .systemProductName;

      deviceData.deviceId = deviceId;
      deviceData.osVersion = [bit48, bit32, bit16, bit00].join('.');
      deviceData.deviceModel = systemName;

      const systemNavManager =
        window.Windows.UI.Core.SystemNavigationManager.getForCurrentView();

      systemNavManager.onbackrequested = onBackEvent;

      if (typeof navigator !== 'undefined') {
        navigator.gamepadInputEmulation = 'keyboard';
      }

      document.addEventListener('keyup', (e) => {
        if (e.key === 'GamepadB') {
          e.preventDefault();
          e.stopPropagation();
        }
      });

      document.addEventListener('keydown', (e) => {
        if (e.key === 'GamepadB') {
          e.preventDefault();
        }
      });
    } catch (error) {
      logger.error(error);
    }

    // resume application look for documentation
    // if (isTv()) {
    //   window.Windows.Xbox.ApplicationModel.ApplicationFocusManagement.addEventListener(
    //     'focusedappchanged',
    //     (args) => {
    //       console.log('focusedappchanged', args);
    //       const packageName =
    //         window.Windows.ApplicationModel.Package.current.id.name;
    //       const { fill, full } =
    //         window.Windows.Xbox.ApplicationModel.ApplicationLocation;

    //       if (
    //         args.applicationLocation === fill ||
    //         args.applicationLocation === full
    //       ) {
    //         if (args.aumid.indexOf(packageName) !== -1) {
    //           // It is my app. Resume!
    //           onResume && onResume();
    //         }
    //       }
    //     }
    //   );
    // }

    onLoad();
  } else {
    onLoad();
  }
};

const getDeviceId = (callback) => {
  callback(deviceData.deviceId);
};

const isTv = () => {
  return window.Windows?.Xbox || false;
};

const isLowEndDevice = () => {
  return false;
};

const relaunchApplication = () => {
  window?.location.reload();
};

const setClosedCaptionCallback = () => void 0;

const setClosedCaptionStyleCallback = () => void 0;

const exitApplication = () => {
  window?.close();
  // TODO: update function with xbox specifics
};

const supportCapability = (capability) => {
  return platformCapabilities[capability];
};

const getLat = () => {
  const adId =
    window?.Windows?.System?.UserProfile?.AdvertisingManager?.advertisingId ||
    null;

  // if advertising id exists limit ad tracking is disabled
  return adId ? '1' : '0';
};

/**
 * Checks whether the user has enabled the Limit Ad Tracking feature
 * Turning this feature on prevents ad trackers from collecting your data
 *
 */

const getLimitAdTracking = (callback) => {
  callback(getLat());
};

const getAdsType = (callback) => {
  callback(null);
};

const getAdId = () => {
  const adId =
    window?.Windows?.System?.UserProfile?.AdvertisingManager?.advertisingId ||
    null;

  return adId || uuidv4();
};

/**
 * randomized and non-persistent device identifier
 *
 */
const getAdvertisementIdentifier = (callback) => {
  callback(getAdId());
};

// Text-to-speech function
const tts = () => {
  // TODO: update function with xbox specifics
};

/**
 * Get the Device's Model
 * @returns {String |null}
 */
const getDeviceModel = () => {
  return deviceData.deviceModel;
};

/**
 * Get device info from TV
 * @returns promise
 */
const getDeviceInformation = () => {
  let deviceInfo = {
    duid: deviceData.deviceId,
    modelName: getDeviceModel(),
  };

  return new Promise((resolve) => {
    if (!isTv()) {
      deviceInfo = getDeviceInformationForWeb();
    }

    resolve(deviceInfo);
  });
};

const getPlatformSessionId = () => null;

/**
 * Get network info from TV
 * @returns promise
 */
const getNetworkInformation = () => {
  return new Promise((resolve) => {
    // TODO: update function with xbox specifics
    resolve({ ip: null });
  });
};

/**
 * Get platform device name
 * @returns {String}
 */
const getPlatformDeviceName = () => {
  return `${APP_CONFIG.PLATFORM} (${getDeviceModel()})`;
};

/**
 * gets deeplink path
 * @returns {String}
 */
const getDeeplinkPath = () => {
  if (isFeatureFlagEnabled('testForceDeeplink')) {
    return '/watch/545158AE-AD7D-4325-980F-93421E200D5F?cmpid=Christmas2021';
  }

  const regexp =
    /\/((.*)\/)*watch\/([^?]+)(.*)?|\/((.*)\/)*sponsored_collections\/([^?]+)(.*)?/;

  if (document.location.pathname.match(regexp) !== null) {
    return document.location.pathname + document.location.search;
  }

  return '';
};

// TODO: update function with xbox specifics
const getDeviceOS = () => 'xbox';

const getDeviceOsVersion = () => deviceData.osVersion;

const getDeviceMaker = () => 'Microsoft';

function getTTSCancelationHTML() {
  return ``;
}

const getDeviceConnectionType = () => {
  const connectionType = navigator?.connection?.effectiveType;
  return new Promise((resolve) => {
    resolve(connectionType);
  });
};

const getStreamSource = (streams) => {
  const dashUrl =
    streams?.dash_playready?.url || streams?.['dash-playready']?.url || null;

  const drm = {
    playready: {
      LA_URL:
        streams?.dash_playready?.drm?.key_url ||
        streams?.['dash-playready']?.drm?.keyUrl ||
        streams?.dash_playready?.drm?.keyUrl ||
        null,
    },
  };
  const sourceStreamType =
    streams?.dash_playready?.url || streams?.['dash-playready']?.url
      ? 'dash_playready'
      : null;

  return {
    dash: isFeatureFlagEnabled('testNoDRMSampleVideo')
      ? SAMPLE_NON_DRM_DASH_URL
      : dashUrl,
    sourceStreamType,
    drm,
  };
};

const getKeyHintIcon = (type) => {
  let icon = null;
  switch (type) {
    case KEY_HINTS_TYPE.BACK:
      icon = <BackKey className="circle" />;
      break;
    case KEY_HINTS_TYPE.ADD_TO_WATCHLIST:
      icon = null;
      break;
    default:
  }

  return icon;
};

const showKeyHints = () => {
  return true;
};

const getPlayerConfig = () => {
  return {};
};

const getPlatformSpecificModules = () => {
  return [];
};

const getAdParams = (callback) => {
  callback({
    lat: getLat(),
    ifa: getAdId(),
    ifaType: null,
    ccpa: null,
  });
};

const isAdInsertionModeSupported = (mode) =>
  supportedAdInsertionMode.includes(mode);

export {
  getDeviceId,
  getDeviceModel,
  initializeDevice,
  isTv,
  isLowEndDevice,
  relaunchApplication,
  getAdvertisementIdentifier,
  getLimitAdTracking,
  setClosedCaptionCallback,
  setClosedCaptionStyleCallback,
  getDeviceInformation,
  getPlatformSessionId,
  getNetworkInformation,
  exitApplication,
  getPlatformDeviceName,
  getDeeplinkPath,
  getDeviceOS,
  getDeviceOsVersion,
  getDeviceMaker,
  tts,
  getTTSCancelationHTML,
  KEYCODES,
  getDeviceConnectionType,
  getStreamSource,
  getAdsType,
  getKeyHintIcon,
  showKeyHints,
  getPlayerConfig,
  getPlatformSpecificModules,
  getAdParams,
  supportCapability,
  isAdInsertionModeSupported,
};
