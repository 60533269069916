import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from '@lingui/macro';

// Components
import Button from '../../components/button/button';

// Config
import { BACK_BUTTON_CONFIG } from '../../config/back-button-config';

// Enums
import BUTTON_TYPES from '../../enums/button-types';

// Hooks
import useFocus from '../../hooks/use-focus';
import useModalHandler from '../../hooks/use-modal-handler';

// Styles
import './pin-error.scss';

// Contexts
import useGlobalContext from '../../hooks/use-global-context';

function PinError({
  buttonCallback1,
  buttonCallback2,
  buttonIcon1,
  buttonIcon2,
  buttonLabel1,
  buttonLabel2,
  hasMultilineTitle,
  icon: IconComponent,
  title,
  description,
}) {
  const hoverEnabled = useGlobalContext((state) => state.hoverEnabled);
  const { isModalOn } = useModalHandler();

  useFocus('.initialFocus');

  const getTitle = () => {
    if (hasMultilineTitle) {
      const splitTitle = title.split(' ');
      const firstWord = splitTitle[0];
      const secondWord = splitTitle[1];
      return (
        <h1>
          {firstWord}
          <br />
          {secondWord}
        </h1>
      );
    }
    return <h1>{title}</h1>;
  };

  return (
    <div
      className={classnames('pin-error', {
        'hover-enabled': hoverEnabled,
        'hover-disabled': !hoverEnabled,
      })}
    >
      <IconComponent className="pin-error__icon" />
      <div
        className={classnames('pin-error__info', {
          multiline: hasMultilineTitle,
        })}
      >
        {getTitle()}

        <div>
          <Button
            aria-label={`${title}. ${description}. ${t({
              id: 'PRESS_SELECT_TO',
              values: { action: buttonLabel1 },
            })}`}
            back={isModalOn() ? null : BACK_BUTTON_CONFIG.PIN_VERIFICATION}
            type={BUTTON_TYPES.ICONBUTTON}
            icon={buttonIcon1}
            isFocusOnPageLoad
            onClick={buttonCallback1}
            data-test-id="pin-error-back-button"
            selectionOverrides={{ up: '', left: '', right: '' }}
          >
            {buttonLabel1}
          </Button>
          <Button
            aria-label={buttonLabel2}
            back={isModalOn() ? null : BACK_BUTTON_CONFIG.PIN_VERIFICATION}
            type={BUTTON_TYPES.ICONBUTTON}
            icon={buttonIcon2}
            onClick={buttonCallback2}
            data-test-id="pin-error-edit-settings-button"
            selectionOverrides={{ down: '', left: '', right: '' }}
          >
            {buttonLabel2}
          </Button>
        </div>
      </div>
      <p>{description}</p>
    </div>
  );
}

PinError.propTypes = {
  buttonCallback1: PropTypes.func,
  buttonCallback2: PropTypes.func,
  buttonIcon1: PropTypes.elementType,
  buttonIcon2: PropTypes.elementType,
  buttonLabel1: PropTypes.string,
  buttonLabel2: PropTypes.string,
  hasMultilineTitle: PropTypes.bool,
  description: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default PinError;
