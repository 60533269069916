import { useEffect } from 'react';
import { Trans, t } from '@lingui/macro';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';

// Components
import Button from '../button/button';
import FocusableSection from '../spatial-navigation/focusable-section';

// Icons
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as CircleBackIcon } from '../../assets/icons/circle-back.svg';

// utils
import { isKey } from '../../utils/utils';
import { KEYCODES } from '../../platform/index';

// Hooks
import useModalHandler from '../../hooks/use-modal-handler';

// Enums
import ACTIVATION_CODE_TYPES from '../../enums/activation-code-types';
import BUTTON_TYPES from '../../enums/button-types';
import MODAL_TYPES from '../../enums/modal-types';
import MODAL_EXIT_STATUS from '../../enums/modal-exit-status';

// Styles
import './get-your-watchlist-overlay.scss';

function GetYourWatchlistOverlay() {
  const { showModal, closeModal } = useModalHandler();

  useEffect(() => {
    JsSpatialNavigation.focus('get-your-watchlist-focusable');
  }, []);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (isKey(event.keyCode, KEYCODES.BACK)) {
        closeModal(MODAL_EXIT_STATUS.GET_YOUR_WATCHLIST_EXIT);
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [closeModal]);

  const handleCreateAccountButton = () => {
    showModal(MODAL_TYPES.AUTH, {
      codeType: ACTIVATION_CODE_TYPES.SIGNUP,
      from: MODAL_TYPES.GET_YOUR_WATCHLIST,
    });
  };

  const handleSignInButton = () => {
    showModal(MODAL_TYPES.AUTH, {
      codeType: ACTIVATION_CODE_TYPES.SIGNIN,
      from: MODAL_TYPES.GET_YOUR_WATCHLIST,
    });
  };

  const handleCancelButton = () => {
    closeModal(MODAL_EXIT_STATUS.GET_YOUR_WATCHLIST_EXIT);
  };

  return (
    <div className="get-your-watchlist">
      <AddIcon aria-label="AddIcon" className="add-icon" />

      <div className="get-your-watchlist__content">
        <div className="get-your-watchlist__content__left">
          <h1>
            <Trans id="WATCHLIST_GET_YOUR">
              Get your <br /> <span className="watchlist-title">Watchlist</span>
            </Trans>
          </h1>
        </div>
        <div className="get-your-watchlist__content__right">
          <FocusableSection
            sectionId="get-your-watchlist-focusable"
            leaveForDown=""
            leaveForUp=""
            leaveForLeft=""
            leaveForRight=""
          >
            <Button
              data-test-id="create-account"
              className="get-your-watchlist__content__right__sign-up-button"
              aria-label={t`CREATE_AN_ACCOUNT`}
              type={BUTTON_TYPES.TEXTBUTTON}
              isFocusOnPageLoad
              isFocusOnSectionEnter
              onClick={handleCreateAccountButton}
              selectionOverrides={{ up: '', left: '', right: '' }}
            >
              <div aria-hidden="true">{t`CREATE_AN_ACCOUNT`}</div>
            </Button>
            <Button
              data-test-id="sign-in"
              className="get-your-watchlist__content__right__sign-in-button"
              type={BUTTON_TYPES.TEXTBUTTON}
              aria-label={t`SIGN_IN`}
              onClick={handleSignInButton}
              selectionOverrides={{ left: '', right: '' }}
            >
              <div aria-hidden="true">{t`SIGN_IN`}</div>
            </Button>
            <Button
              data-test-id="cancel"
              className="get-your-watchlist__content__right__cancel-button"
              onClick={handleCancelButton}
              aria-label={t`CANCEL`}
              selectionOverrides={{ down: '', left: '', right: '' }}
            >
              <div aria-hidden="true">
                <CircleBackIcon />
                <span>{t`CANCEL`}</span>
              </div>
            </Button>
          </FocusableSection>
        </div>
      </div>
      <div className="get-your-watchlist__footer">
        <p>
          <Trans>GET_YOUR_WATCHLIST_FOOTER</Trans>
        </p>
      </div>
    </div>
  );
}

export default GetYourWatchlistOverlay;
