import { v4 as uuidv4 } from 'uuid';
import { isFeatureFlagEnabled } from '../utils/feature-flags';
import logger from '../utils/logger';
import { getDeviceInformationForWeb, addScript } from '../utils/utils';
import APP_CONFIG from '../config/app-config.json';
import { SAMPLE_NON_DRM_DASH_URL } from '../crackle-sdk/v1/api/common/vars';
import PLAYER_AD_BREAK_INSERT_TYPES from '../enums/player-ad-break-insert-types';
import PLATFORM_CAPABILITIES from '../enums/platform-capabilities';

const TV_EVENTS = {
  LIBRARY_LOADED: 'VIZIO_LIBRARY_DID_LOAD',
};

const LIB_URL = 'http://localhost:12345/scfs/cl/js/vizio-companion-lib.js';

const KEYCODES = {
  BACK: [8, 461],
  DOWN: 40,
  ENTER: 13,
  SPACE: 32,
  EXIT: 27,
  LEFT: 37,
  MEDIA_PAUSE: 19,
  MEDIA_FAST_FORWARD: 417,
  MEDIA_PLAY: 415,
  MEDIA_PLAY_PAUSE: null,
  MEDIA_REWIND: 412,
  MEDIA_STOP: 413,
  MEDIA_NEXT: 418,
  MEDIA_PREVIOUS: 419,
  RIGHT: 39,
  UP: 38,
  CC: null,
  SEARCH: null,
  DELETE_CHAR: null,
  ENTER_CHAR: null,
  NAV_RIGHT: null,
  NAV_LEFT: null,
  CHANNEL_UP: 33,
  CHANNEL_DOWN: 34,
  NUMBER_0: 48,
  NUMBER_1: 49,
  NUMBER_2: 50,
  NUMBER_3: 51,
  NUMBER_4: 52,
  NUMBER_5: 53,
  NUMBER_6: 54,
  NUMBER_7: 55,
  NUMBER_8: 56,
  NUMBER_9: 57,
};

let deviceInfo = {
  modelName: null,
  duid: null,
  series: null,
  firmware: null,
};

let adInfo = {
  IFA: uuidv4(),
  IFA_TYPE: null,
  LMT: '1',
};

const supportedAdInsertionMode = [
  PLAYER_AD_BREAK_INSERT_TYPES.CSAI,
  PLAYER_AD_BREAK_INSERT_TYPES.CSAIV2,
  PLAYER_AD_BREAK_INSERT_TYPES.SSAI,
];
const platformCapabilities = {
  // limit ad tracking
  [PLATFORM_CAPABILITIES.LIMIT_AD_TRACKING]: true,
  [PLATFORM_CAPABILITIES.CCPA]: false,
  [PLATFORM_CAPABILITIES.VIZBEE]: true,
  // Does the platform support automatic arial label text-to-speech
  [PLATFORM_CAPABILITIES.ARIA_LABEL]: true,
  // Does the platform supports exiting the app?
  [PLATFORM_CAPABILITIES.EXIT]: true,
  // Does the platform has a specific CC button in the remote? If not, we will use the CC button in the player
  [PLATFORM_CAPABILITIES.CC_BUTTON]: true,
  // Does the platform has a specific exit button in the remote and allow to override the default exit behavior?
  [PLATFORM_CAPABILITIES.EXIT_BUTTON]: true,
  // Does the platform support iframe srcdoc attribute for sponsors?
  [PLATFORM_CAPABILITIES.IFRAME_SRC_DOC]: true,
  // Does the platform have platformSessionId?
  [PLATFORM_CAPABILITIES.PLATFORM_SESSION_ID]: false,
  // Does the platform need to announce the end of grids or not possible navigation?
  [PLATFORM_CAPABILITIES.TTS_NAVIGATION_LIMIT_NEEDED]: false,
};

const initializeDevice = (onLoad, onResume) => {
  const onLibraryLoaded = () => {
    if (window.VIZIO.deviceInfo) {
      deviceInfo.duid = window.VIZIO.deviceInfo.SYSTEM_INFO.DIID;
      deviceInfo.modelName = window.VIZIO.deviceInfo.MODEL_NAME;
      deviceInfo.series = window.VIZIO.deviceInfo.SYSTEM_INFO.SERIES;

      window.VIZIO.getFirmwareVersion((firmwareVersion) => {
        deviceInfo.firmware = firmwareVersion;
        setAdvertisingListener();
        onLoad();
      });
    } else {
      window.VIZIO.getDeviceId((deviceId) => {
        deviceInfo.duid = deviceId;
        deviceInfo.modelName = getDeviceModel();

        window.VIZIO.getFirmwareVersion((firmwareVersion) => {
          deviceInfo.firmware = firmwareVersion;
          setAdvertisingListener();
          onLoad();
        });
      });
    }

    document.removeEventListener(TV_EVENTS.LIBRARY_LOADED, onLibraryLoaded);
  };

  if (isTv()) {
    document.addEventListener(TV_EVENTS.LIBRARY_LOADED, onLibraryLoaded);
    addScript(LIB_URL, 'body');

    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        // Trigger a platform launch on resume app
        if (onResume && isTv()) {
          onResume();
        }
      }
    });
  } else {
    onLoad();
  }
};

const getDeviceId = (callback) => {
  window.VIZIO && window.VIZIO.getDeviceId(callback);
};

const isTv = () => {
  return window.navigator.userAgent.includes('VIZIO');
};

const isLowEndDevice = () => {
  // high end devices in vizio are only M or P
  const modelLetter =
    window.VIZIO?.deviceInfo?.MODEL_NAME?.charAt(0).toUpperCase();

  return modelLetter && !(modelLetter === 'M' || modelLetter === 'P');
};

const relaunchApplication = () => {
  window?.location.reload();
};

const setClosedCaptionCallback = (callback) => {
  window?.VIZIO?.setClosedCaptionHandler(callback);
};

const setClosedCaptionStyleCallback = (callback) => {
  try {
    window?.VIZIO?.setClosedCaptionStyleChangeHandler(callback);
  } catch (error) {
    logger.debug(error);
  }
};

const exitApplication = () => {
  window?.VIZIO?.exitApplication();
};

/**
 * Set AdvertiserIDListener
 * @param callback {Function} - Event callback function with JSON response
 *
 * * Parameter that will be passed to the callback
 * @param {object} IFA Parameter object
 * e.g. {IFA: <unique id string>, IFA_TYPE: "vida", LMT: <0 or 1>}
 * IFA - Unique identifier string for advertising
 * IFA_TYPE - String value for IFA type (“vida”)
 * LMT - 0 or 1 (1=Limited tracking enabled, 0=Limited tracking disabled)
 *
 * Example:
 * window.VIZIO.setAdvertiserIDListener( function(AdvertiserID) {
      console.log("Advertiser ID: " + AdvertiserID.IFA)
      console.log("Advertiser ID Type: " + AdvertiserID.IFA_TYPE)
      console.log("Limit Ad Tracking: " + AdvertiserID.LMT)
   })
 */

const supportCapability = (capability) => {
  return platformCapabilities[capability];
};

const getAdvertisementIdentifier = (callback) => {
  callback(adInfo.IFA);
};

const getLimitAdTracking = (callback) => {
  callback(adInfo.LMT);
};

const getAdsType = (callback) => {
  callback(adInfo.IFA_TYPE);
};

const setAdvertisingListener = () => {
  if (window.VIZIO) {
    window.VIZIO.setAdvertiserIDListener((adSettings) => {
      adInfo = Object.assign(adInfo, {
        ...adSettings,
        LMT: adSettings.LMT ? '1' : '0',
      });
    });
  }
};

/**
 * Announce a TTS string. The display will read the supplied string using the current TTS settings.
 * Note: The Chromevox text-to-speech accessibility library has been integrated with the VIZIO companion library.
 * @param announcement {String} - text to speech
 *
 * Example:
 *  window.VIZIO.Chromevox.play("Hello!");
 */
const tts = (text) => {
  window?.VIZIO?.Chromevox.play(text);
};

/**
 * Get the Device's Model
 * @returns {String |null}
 */
const getDeviceModel = () => {
  return (window.VIZIO && window.VIZIO.deviceModel) || null;
};

/**
 * Get device info from TV
 * @returns promise
 */
const getDeviceInformation = () => {
  return new Promise((resolve) => {
    if (!isTv()) {
      deviceInfo = getDeviceInformationForWeb();
    }
    resolve(deviceInfo);
  });
};

const getPlatformSessionId = () => null;

/**
 * Get network info from TV
 * @returns promise
 */
const getNetworkInformation = () => {
  try {
    return window?.VIZIO.getNetworkInformation().then((networkInfo) => {
      return new Promise((resolve) => {
        resolve({
          ip: networkInfo?.value[3]?.VALUE || null,
        });
      });
    });
  } catch (error) {
    logger.debug(error);

    return new Promise((resolve) => {
      resolve({
        ip: null,
      });
    });
  }
};

/**
 * Get platform device name
 * @returns {String}
 */
const getPlatformDeviceName = () => {
  const serie = deviceInfo.series ? deviceInfo.series : 'unknown';
  return `${APP_CONFIG.PLATFORM} ${serie} Series (${getDeviceModel()})`;
};

/**
 * gets deeplink path
 * @returns {String}
 */
const getDeeplinkPath = () => {
  if (isFeatureFlagEnabled('testForceDeeplink')) {
    return '/watch/545158AE-AD7D-4325-980F-93421E200D5F?cmpid=Christmas2021';
  }

  const regexp =
    /\/((.*)\/)*watch\/([^?]+)(.*)?|\/((.*)\/)*sponsored_collections\/([^?]+)(.*)?/;

  if (document.location.pathname.match(regexp) !== null) {
    return document.location.pathname + document.location.search;
  }

  return '';
};

const getDeviceOS = () => 'SmartCast';

const getDeviceOsVersion = () => deviceInfo.firmware;

const getDeviceMaker = () => 'Vizio';

function getTTSCancelationHTML() {
  return ``;
}

const getDeviceConnectionType = () => {
  const connectionType = navigator?.connection?.effectiveType;
  return new Promise((resolve) => {
    resolve(connectionType);
  });
};

const getStreamSource = (streams) => {
  const dashUrl =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url || null;
  const drm = {
    widevine: {
      LA_URL:
        streams?.dash_widevine?.drm?.keyUrl ||
        streams?.['dash-widevine']?.drm?.keyUrl ||
        null,
    },
  };
  const sourceStreamType =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url
      ? 'dash_widevine'
      : null;

  return {
    dash: isFeatureFlagEnabled('testNoDRMSampleVideo')
      ? SAMPLE_NON_DRM_DASH_URL
      : dashUrl,
    sourceStreamType,
    drm,
  };
};

const getPlayerConfig = () => {
  return {};
};

const getKeyHintIcon = () => {
  return null;
};

const showKeyHints = () => {
  return false;
};

const getPlatformSpecificModules = () => {
  return [];
};

const getAdParams = (callback) => {
  callback({
    lat: adInfo.LMT,
    ifa: adInfo.IFA,
    ifaType: adInfo.IFA_TYPE,
    ccpa: null,
  });
};

const isAdInsertionModeSupported = (mode) =>
  supportedAdInsertionMode.includes(mode);

export {
  getDeviceId,
  getDeviceModel,
  initializeDevice,
  isTv,
  isLowEndDevice,
  relaunchApplication,
  setClosedCaptionCallback,
  setClosedCaptionStyleCallback,
  getDeviceInformation,
  getPlatformSessionId,
  getNetworkInformation,
  exitApplication,
  getPlatformDeviceName,
  getDeeplinkPath,
  getDeviceOS,
  getDeviceOsVersion,
  getDeviceMaker,
  tts,
  getTTSCancelationHTML,
  KEYCODES,
  getDeviceConnectionType,
  getStreamSource,
  getAdvertisementIdentifier,
  getLimitAdTracking,
  getAdsType,
  getPlayerConfig,
  getKeyHintIcon,
  showKeyHints,
  getPlatformSpecificModules,
  getAdParams,
  supportCapability,
  isAdInsertionModeSupported,
};
