import classnames from 'classnames';
import PropTypes from 'prop-types';

// Styles
import './button.scss';

// Components
import Focusable from '../spatial-navigation/focusable';
import ProgressBar from '../progress-bar/progress-bar';

// Enums
import ELEMENT_TYPES from '../../enums/element-types';
import PROGRESSBAR_TYPES from '../../enums/progress-bar-types';

function Button({
  icon: IconComponent,
  children,
  className,
  focused,
  progressBar,
  progressPercent,
  label,
  type,
  disabled,
  onClick,
  ...props
}) {
  // Minimum progress to show = 5% but don't go over 100%
  let progressWidth = progressPercent < 5 ? 5 : progressPercent;

  if (progressWidth > 100) {
    progressWidth = 100;
  }

  // if button is disabled cancel click action
  function handleClick(event) {
    if (disabled) {
      // cancel default event if button is a link
      event.preventDefault();
    } else {
      onClick && onClick(event);
    }
  }

  return (
    <Focusable
      className={classnames('button', type, className, { disabled })}
      elementType={props.elementType}
      onFocus={focused}
      onClick={(e) => {
        handleClick(e);
      }}
      {...props}
    >
      {IconComponent && <IconComponent />}
      {label && label}
      {children}
      {progressBar && (
        <ProgressBar
          type={PROGRESSBAR_TYPES.BUTTON}
          progress={progressWidth}
          className="button__progress"
        />
      )}
    </Focusable>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  focused: PropTypes.func,
  icon: PropTypes.elementType,
  label: PropTypes.string,
  progressBar: PropTypes.bool,
  progressPercent: PropTypes.number,
  type: PropTypes.string,
  elementType: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  className: '',
  focused: () => void 0,
  icon: null,
  label: null,
  progressBar: false,
  progressPercent: 0,
  type: null,
  elementType: ELEMENT_TYPES.BUTTON,
  disabled: false,
  onClick: null,
};

export default Button;
