import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';

// Components
import Button from '../../components/button/button';
import FocusableSection from '../../components/spatial-navigation/focusable-section';

// Icons
import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg';
import { ReactComponent as CheckMark } from '../../assets/icons/check-mark.svg';
import { ReactComponent as CircleBackIcon } from '../../assets/icons/circle-back.svg';

// Hooks
import useGlobalContext from '../../hooks/use-global-context';
import useModalHandler from '../../hooks/use-modal-handler';

// Utils
import { isKey } from '../../utils/utils';
import mParticle from '../../utils/mparticle';
import { exitApplication, KEYCODES } from '../../platform/index';
import { stopSessionRecording } from '../../utils/data-dog';

// enums
import MODAL_TYPES from '../../enums/modal-types';

// Styles
import './exit.scss';

function ExitScreen({ sectionId }) {
  const titleText = t`EXIT_BRAND`;
  const exitButtonText = t`EXIT_COMPLETELY`;
  const cancelButtonText = t`CANCEL`;
  const descriptionText = t`EXIT_DESCRIPTION`;

  const [playInitialLabel, setPlayInitialLabel] = useState(true);
  const hoverEnabled = useGlobalContext((state) => state.hoverEnabled);

  const { closeModal, isModalOn, getModalType } = useModalHandler();

  const navigate = useNavigate();
  const location = useLocation();

  // Avoid retaining stale values for the state, since modal is always "listening" in the background
  useEffect(() => {
    JsSpatialNavigation.focus(`@${sectionId}`);
  }, [sectionId]);

  const handleExitApp = () => {
    stopSessionRecording();
    exitApplication();
  };

  const handleBackNavigation = () => {
    if (isModalOn && getModalType() === MODAL_TYPES.EXIT_APP) {
      closeModal();
      return;
    }
    // state has the previous route path, to have a way to deal with focus
    navigate(location.state, { replace: true, state: location.state });
  };

  return (
    <div
      className={classnames('exit-screen', {
        'hover-enabled': hoverEnabled,
        'hover-disabled': !hoverEnabled,
      })}
      data-test-id="exit-screen"
    >
      <div className="content-gradients">
        <div className="orange-gradient" />
        <div className="blue-gradient" />
      </div>
      <AlertIcon aria-label="Alert icon" className="alert-icon" />

      <div className="exit-screen__content">
        <div className="exit-screen__content__left">
          <h1>
            <Trans id="EXIT_BRAND">
              Exit <br /> Brand
            </Trans>
          </h1>
        </div>

        <div className="exit-screen__content__right">
          <FocusableSection
            sectionId={sectionId}
            leaveForUp=""
            leaveForRight=""
            leaveForDown=""
            leaveForLeft=""
          >
            <Button
              id="exit-screen_exit-button"
              icon={CheckMark}
              aria-label={
                playInitialLabel
                  ? `${titleText} ${descriptionText} ${exitButtonText}`
                  : exitButtonText
              }
              onClick={() => {
                handleExitApp();
                mParticle.mParticleButtonClick({
                  buttonLabel: exitButtonText,
                  buttonPosition: 1,
                  pageSection: 'Exit Screen',
                });
              }}
              isFocusOnPageLoad
              isFocusOnSectionEnter
              selectionOverrides={{ up: '', left: '', right: '' }}
              onKeyDown={(e) => {
                if (isKey(e.keyCode, KEYCODES.BACK)) {
                  handleBackNavigation();
                }
              }}
            >
              {exitButtonText}
            </Button>

            <Button
              id="exit-screen_cancel-button"
              data-test-id="exit-screen_cancel-button"
              icon={CircleBackIcon}
              aria-label={
                playInitialLabel
                  ? `${titleText}.${descriptionText}. ${t({
                      id: 'PRESS_SELECT_TO',
                      values: { action: cancelButtonText },
                    })}`
                  : cancelButtonText
              }
              onFocus={() => {
                if (playInitialLabel) {
                  setPlayInitialLabel(false);
                }
              }}
              onClick={() => {
                mParticle.mParticleButtonClick({
                  buttonLabel: 'Cancel',
                  buttonPosition: 2,
                  pageSection: 'Exit Screen',
                });
                handleBackNavigation();
              }}
              selectionOverrides={{ down: '', left: '', right: '' }}
              onKeyDown={(e) => {
                if (isKey(e.keyCode, KEYCODES.BACK)) {
                  handleBackNavigation();
                }
              }}
            >
              {cancelButtonText}
            </Button>
          </FocusableSection>
        </div>
      </div>

      <div className="exit-screen__footer">
        <p>
          <Trans id={descriptionText} />
        </p>
      </div>
    </div>
  );
}

ExitScreen.propTypes = {
  sectionId: PropTypes.string,
};

ExitScreen.defaultProps = {
  sectionId: 'exit-focusable',
};

export default ExitScreen;
