// Enums
import { CRACKLE_API_RESPONSE_ERROR_CODES } from '../enums/crackle-api-response-codes';
import { getHeaders, getApiUrl } from './vars';

// API
import CrackleApiError from '../error';

// Utils
import { getConfig } from '../../configuration';
import { getNewAuthTokens } from './getNewAuthTokens';

/**
 * Appends headers to the original response
 * @param {Object} response The fetched response
 * @param {Object} parsedResponse the fetched response as json
 * @param {Object} headers The headers needed from the response {[Property name in object]: [header name needed] }
 * @returns {Object} A JSON object with the headers and the fetched response
 */

const appendHeadersToResponse = (response, parsedResponse, headers) => {
  if (!headers) {
    return parsedResponse.data;
  }

  let responseDataJSON = { ...parsedResponse.data };
  const headersArray = Object.entries(headers);

  for (let i = 0; i < headersArray.length; i += 1) {
    responseDataJSON = {
      ...responseDataJSON,
      [headersArray[i][0]]: response.headers?.get(headersArray[i][1]) || null,
    };
  }

  return responseDataJSON;
};

/**
 * Appends client region to appConfig.device JSON object
 * @param {Object} response The appConfig fetch response
 * @param {Object} responseJSON The appConfig fetch response JSON
 * @returns {Object} A JSON object with a device.clientRegion property appended
 */
const appendClientRegion = (response, responseJSON) => {
  const responseJSONCopy = { ...responseJSON };
  const region =
    response.headers.get('x-crackle-client-region') ||
    response.headers.get('x-crackle-region');
  responseJSONCopy.device = {
    ...responseJSONCopy.device,
    clientRegion: region.toUpperCase(),
  };

  return responseJSONCopy;
};

/**
 * Makes a request to the Crackle API using the existing accessToken. If
 * the request fails due to an expired accessToken, uses retryRequest to
 * get a new accessToken and retry the original request.
 * @param {string} endpoint The endpoint to send the request to
 * @param {Object} requestOptions Options object for the request
 * @param {boolean} isAppConfigEndpoint Identifies appConfig endpoint
 * @param {string} baseUrl Overrides the default API_URL value if passed
 * @param {object} headers bring an object of headers that will be mapped if needed for the response
 * @returns {Promise<{}>} A promise containing a data object
 */
const makeRequest = async (
  endpoint,
  requestOptions,
  isAppConfigEndpoint = false,
  baseUrl = getApiUrl(),
  headers = null
) => {
  const options = {
    ...requestOptions,
    headers: getConfig().getAuthAccessToken()
      ? {
          ...getHeaders(),
          Authorization: `Bearer ${getConfig().getAuthAccessToken()}`,
        }
      : getHeaders(),
  };

  return tryRequest(endpoint, options, isAppConfigEndpoint, baseUrl, headers);
};

/**
 * Gets new auth tokens from the Crackle API using getNewAuthTokens, then
 * retries a request that previously failed due to an expired accessToken
 * using the newly obtained accessToken.
 * @param {boolean} isAppConfigEndpoint Identifies appConfig endpoint
 * @param {string} endpoint The endpoint to send the request to
 * @param {Object} requestOptions Options object for the request
 * @param {string} baseUrl Overrides the default API_URL value if passed
 * @param {object} headers bring an object of headers that will be mapped if needed for the response
 * @returns {Promise<{}>} A promise containing a data object
 */
const retryRequest = async (
  endpoint,
  requestOptions,
  isAppConfigEndpoint,
  baseUrl,
  headers
) => {
  const newAuthTokensResponse = await getNewAuthTokens();

  if (!newAuthTokensResponse?.auth?.accessToken) {
    // if refresh token failed, it will be handled by the event listener
    return Promise.resolve(null);
  }
  const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
    newAuthTokensResponse.auth;

  getConfig().setAuthAccessToken(newAccessToken);
  getConfig().setAuthRefreshToken(newRefreshToken);

  const retryOptions = {
    ...requestOptions,
    headers: { ...getHeaders(), Authorization: `Bearer ${newAccessToken}` },
  };

  const retryResponse = await fetch(`${baseUrl}${endpoint}`, retryOptions);

  const retryResponseJSON = await retryResponse.json().catch(() => {
    return {};
  });
  if (!retryResponse.ok) {
    const hasNotAuthorizedError =
      retryResponseJSON.error.code ===
        CRACKLE_API_RESPONSE_ERROR_CODES.EXPIRED_TOKEN_PROVIDED ||
      retryResponseJSON.error.code ===
        CRACKLE_API_RESPONSE_ERROR_CODES.REQUEST_NOT_AUTHORIZED;

    // if retryResponse fails because of an expired token or is not authorized, trigger the event to clear user data & redirect to login
    if (hasNotAuthorizedError) {
      const expiredUserSessionEvent = new CustomEvent('expiredUserSession');
      window.dispatchEvent(expiredUserSessionEvent);
      return Promise.resolve(null);
    }

    throw new CrackleApiError(retryResponseJSON.error);
  }

  const retryResponseDataWithHeaders = appendHeadersToResponse(
    retryResponse,
    retryResponseJSON,
    headers
  );

  return Promise.resolve(
    isAppConfigEndpoint
      ? appendClientRegion(retryResponse, retryResponseDataWithHeaders)
      : retryResponseDataWithHeaders
  );
};

/**
 * Makes a request to the Crackle API
 * @param {string} endpoint The endpoint to send the request to
 * @param {Object} requestOptions Options object for the request
 * @param {boolean} isAppConfigEndpoint Identifies appConfig endpoint
 * @param {string} baseUrl Overrides the default API_URL value if passed
 * @param {object} headers bring an object of headers that will be mapped if needed for the response
 * @returns {Promise<{}>} A promise containing a data object
 */
export const tryRequest = async (
  endpoint,
  requestOptions,
  isAppConfigEndpoint,
  baseUrl,
  headers
) => {
  const initialResponse = await fetch(`${baseUrl}${endpoint}`, requestOptions);

  const initialResponseJSON = await initialResponse.json().catch(() => {
    return {};
  });

  if (!initialResponse.ok) {
    const hasExpiredTokenError =
      (initialResponseJSON.error.code ===
        CRACKLE_API_RESPONSE_ERROR_CODES.EXPIRED_TOKEN_PROVIDED &&
        !requestOptions?.headers['x-crackle-refresh-token']) ||
      initialResponseJSON.error.code ===
        CRACKLE_API_RESPONSE_ERROR_CODES.REQUEST_NOT_AUTHORIZED;

    if (hasExpiredTokenError) {
      return retryRequest(
        endpoint,
        requestOptions,
        isAppConfigEndpoint,
        baseUrl,
        headers
      );
    }

    // if this failing request is a refresh token request trigger the event expiredUserSession
    if (requestOptions?.headers['x-crackle-refresh-token']) {
      const expiredUserSessionEvent = new CustomEvent('expiredUserSession');
      window.dispatchEvent(expiredUserSessionEvent);
      return Promise.resolve(null);
    }
    throw new CrackleApiError(initialResponseJSON.error);
  }

  const responseDataWithHeaders = appendHeadersToResponse(
    initialResponse,
    initialResponseJSON,
    headers
  );

  return Promise.resolve(
    isAppConfigEndpoint
      ? appendClientRegion(initialResponse, responseDataWithHeaders)
      : responseDataWithHeaders
  );
};

export { makeRequest, appendHeadersToResponse };
