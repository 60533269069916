import LOCAL_STORAGE from '../enums/local-storage';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from './local-storage';
import {
  removeAuthAccessToken,
  removeAuthRefreshToken,
  setAuthAccessToken,
  setAuthRefreshToken,
} from './auth-tokens';
/**
 * Retrieves the currently signed in user from local storage
 * @returns {Object} A user object with email, firstName, lastName, and userId OR null
 */
const getSignedInUser = () => {
  const signedInUser = getLocalStorageItem(LOCAL_STORAGE.SIGNED_IN_USER);
  return JSON.parse(signedInUser);
};

/**
 * Removes the currently signed in user entry from local storage
 * @returns {null}
 */
const removeSignedInUser = () => {
  removeLocalStorageItem(LOCAL_STORAGE.SIGNED_IN_USER);
  removeAuthAccessToken();
  removeAuthRefreshToken();
};

/**
 * Saves the currently signed in user in local storage
 * @param {Object} user A user object with email, firstName, lastName, and userId
 */
const setSignedInUser = (user, accessToken, refreshToken) => {
  setLocalStorageItem(LOCAL_STORAGE.SIGNED_IN_USER, JSON.stringify(user));
  setAuthAccessToken(accessToken);
  setAuthRefreshToken(refreshToken);
};

export { getSignedInUser, removeSignedInUser, setSignedInUser };
