import { makeRequest } from './common/base';

const endpoints = {
  getContentById: (id) => `/content/${id}`,
  getContentChildren: (id) => `/content/${id}/children`,
  getPlayableMedia: (id) => `/content/${id}/media`,
  getTrailer: (id) => `/content/${id}/trailer`,
};

/**
 * Gets information for a specific piece of content by its id
 * @param {string} id The mediaId of the content to get information for
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
async function fetchContent(id) {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(endpoints.getContentById(id), requestOptions);
}
/**
 * Gets a list of the child content related to a specific piece of content by its id. (i.e. episodes in a season, seasons in a series)
 * @param {string} id The mediaId of the content to find children for
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
async function fetchContentChildren(id) {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(endpoints.getContentChildren(id), requestOptions);
}
/**
 * Gets the immediate playable children of a Series or Movie Channel by id
 * @param {string} id The mediaId of the content to find children for
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
async function fetchContentMedia(id) {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(endpoints.getPlayableMedia(id), requestOptions);
}
/**
 * Gets the trailer of a given content id
 * @param {string} id The id of the content to find trailer for
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
async function fetchTrailer(id) {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(`${endpoints.getTrailer(id)}`, requestOptions);
}

export { fetchContent, fetchContentChildren, fetchContentMedia, fetchTrailer };
