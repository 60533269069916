import LOCAL_STORAGE from '../enums/local-storage';
import { updateUserPrivacySettings } from '../crackle-sdk/v1/api/user';
import CrackleApiError from '../crackle-sdk/v1/api/error';
import logger from './logger';
import { getSignedInUser } from './signed-in-user';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from './local-storage';

/**
 * Retrieves the ccpa option value stored in local storage
 * @returns {boolean} ccpa option is either false or true
 */

function getCCPAOption() {
  const storedValue = getLocalStorageItem(LOCAL_STORAGE.CCPA_OPTION);

  if (storedValue) {
    return JSON.parse(storedValue);
  }

  return false;
}
/**
 * Retrieves the privacy settings stored in local storage
 * @returns {Object} privacy settings object (doNotSell, doNotShare)
 */

function getLocalPrivacySettings() {
  let privacySettings = { doNotSell: false, doNotShare: false }; // default values

  const legacyCCPA = getCCPAOption();
  const storedValue = getLocalStorageItem(LOCAL_STORAGE.PRIVACY_SETTINGS);

  if (legacyCCPA && !storedValue) {
    privacySettings.doNotSell = legacyCCPA;

    setLocalStorageItem(
      LOCAL_STORAGE.PRIVACY_SETTINGS,
      JSON.stringify(privacySettings)
    );

    // if legacy CCPA is defined, used it as a fallback value and remove it from local storage
    removeLocalStorageItem(LOCAL_STORAGE.CCPA_OPTION);
    return privacySettings;
  }

  if (legacyCCPA && storedValue) {
    // legacyCCPA will be ignored because we already have a privacy stored, remove legacy from local storage
    removeLocalStorageItem(LOCAL_STORAGE.CCPA_OPTION);
  }

  if (storedValue) {
    try {
      privacySettings = JSON.parse(storedValue);
      return privacySettings;
    } catch (e) {
      logger.warn(e);
    }
  }

  // if stored value is not valid or empty, use default values
  setLocalStorageItem(
    LOCAL_STORAGE.PRIVACY_SETTINGS,
    JSON.stringify(privacySettings)
  );

  return privacySettings;
}

/**
 * Sets the value of privacy settings in local storage
 * @param {Object} data the value of the privacy settings that is going to be saved
 */

function saveLocalPrivacySettings(data) {
  return setLocalStorageItem(
    LOCAL_STORAGE.PRIVACY_SETTINGS,
    JSON.stringify(data)
  );
}

/**
 * Saves the value of privacy settings to user profile in API
 * @param {Object} data the value of the privacy settings that are going to be saved
 * @returns {Promise<{}>} The promise with the request
 */

async function saveUserPrivacySettings(data) {
  const userId = getSignedInUser()?.userId;
  if (userId) {
    try {
      await updateUserPrivacySettings(userId, data);
      return true;
    } catch (e) {
      if (e instanceof CrackleApiError) {
        logger.error(e);
      } else {
        logger.error('Error occurred while updating user profile', e);
      }
      return false;
    }
  } else {
    saveLocalPrivacySettings(data);
    return true;
  }
}

export { getCCPAOption, getLocalPrivacySettings, saveUserPrivacySettings };
