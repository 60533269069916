import { t } from '@lingui/macro';

// Enums
import { CONTENT_TYPES } from '../enums/content-types';
import APP_CONFIG from '../config/app-config.json';
import ORIGINAL_GENRES from '../enums/originals-genres';

// Utils
import { getThumbnail } from './all-episodes';
import PLAYER_PLAY_TYPES from '../enums/player-play-types';
import { isFeatureFlagEnabled } from './feature-flags';
import CHANNEL_TYPES from '../enums/channel-types';

const SERIES_IMAGE_NAME = 'Textless';

/**
 * Check if current season button should be active
 *
 * @param {object} location Current page URL
 * @param {string} selector Current season button index
 * @return {boolean} Current season button active status
 */
function isActive(location, selector) {
  const urlParams = new URLSearchParams(location.search);
  const selectParam = urlParams.has('select') ? urlParams.get('select') : '';

  if (selectParam === selector) return true;

  return false;
}

/**
 *
 * Checks if a content is available for the current platform
 * @param {string} data content JSON with expiry date of a content returned by the API
 * @returns {boolean} Number of days until which the content expires
 */
function isContentAvailable(data) {
  const avail = data?.access?.avails?.[0]?.platforms.find(
    (platform) =>
      platform.id.toLowerCase() === APP_CONFIG.PLATFORM_ID.toLowerCase()
  );

  if (avail?.windowEnd) {
    const presentDate = new Date();
    const contentEndDate = new Date(avail?.windowEnd);
    const diffInTime = contentEndDate.getTime() - presentDate.getTime();

    return diffInTime > 0;
  }

  return false;
}

/**
 * Gets first image tagged with type thumbnail
 *
 * @param {string} contentType Access different part of response depending on content type
 * @param {object} data Response object from API
 * @return {(string|null)} Image URL string or null
 */
const getBackgroundImage = (contentType, data) => {
  let imageArray = [];

  if (
    contentType === CONTENT_TYPES.FEATURED_FILM ||
    contentType === CONTENT_TYPES.SERIES ||
    contentType === CONTENT_TYPES.EPISODE
  ) {
    imageArray = data.assets.images;
  } else if (contentType === CONTENT_TYPES.MOVIE) {
    imageArray = data.images || data.assets.images;
  }

  let imageNameContains = '';
  if (CONTENT_TYPES.SERIES) {
    imageNameContains = SERIES_IMAGE_NAME; // for series, only use images with "Textless" in the name
  }

  let image = getThumbnail(
    imageArray,
    window.innerWidth,
    '16:9',
    imageNameContains
  );

  // if we've search an image by its  name but we didn't find any, fallback to another 16:9 image
  if (imageNameContains !== '' && !image) {
    image = getThumbnail(imageArray, window.innerWidth, '16:9');
  }

  if (image && image.url) {
    return image.url;
  }

  return null;
};

/**
 * Get array from single-character-separated string list
 *
 * @param {string} list A single-character-separated string of values
 * @param {string} separator Character separating individaul values
 * @returns {array} An array of string values
 */
const getArrayFromStringList = (list, separator = ',') => {
  return list ? list.split(separator).map((s) => s.trim()) : null;
};

/**
 * Get string list from array
 *
 * @param {array} data Array of string values
 * @param {string} separator Character to separate string values with
 * @param {number} number Desired number of items in the output string
 * @return {(string|null)} String or null
 */
const getStringListFromArray = (data, number, separator = ', ') => {
  const newData = Array.isArray(data) ? Array.from(data) : [];

  if (number) {
    const limit = number < data.length ? number : data.length;
    newData.length = limit;
  }

  return newData?.join(separator) || null;
};

/**
 * Gets all caption languages and prepends with "CC"
 *
 * @param {object} data Response object from API
 * @return {array} Array of strings or empty array
 */
const getCaptions = (data) => {
  if (data && data.assets && data.assets.captions) {
    const captions = [];

    for (const item of data.assets.captions) {
      if (item.locale) {
        const language = item.locale.match(/.?[^-]/);

        if (language && language[0]) {
          captions.push(language[0]);
        }
      }
    }

    const unique = [...new Set(captions)];
    return unique;
  }

  return [];
};

/**
 * Gets all textual caption languages and prepends with "CC"
 *
 * @param {object} data Response object from API
 * @return {array} Array of strings or empty array
 */
const getTextualCaptions = (data) => {
  if (data && data.cc) {
    const captions = [];

    for (const item of data.cc) {
      if (item.language) {
        const language = item?.language;
        if (language) {
          captions.push(language);
        }
      }
    }

    const unique = [...new Set(captions)];
    return unique;
  }

  return [];
};

/**
 * Gets content categories
 *
 * @param {object} data Response object from API
 * @return {(array|null)} Array of category objects from API or null
 */
const getCategories = (data) => {
  return (data.catalog[0] || {}).categories || [];
};

/**
 * Gets content standard or high definition
 *
 * @param {object} data Response object from API
 * @return {string} Type of definition
 */
const getDefinition = (data) => {
  const isHD = data?.assets?.video?.some((vidObj) => vidObj.frameHeight >= 720);
  return isHD ? 'HD' : 'SD';
};

/**
 * Gets duration and formats it to "0hr 0m" format
 *
 * @param {object} data Response object from API
 * @return {(string|null)} Duration string or null
 */
const getDuration = (data) => {
  const metaData = data.metadata[0];

  if (metaData.duration) {
    // Format from API = "138:42 min"
    const parts = metaData.duration.match(/(\d+)/g);

    // Get the 138m part, don't care about seconds
    if (parts && parts[0]) {
      // Divide by 60 for hour format
      return formatDuration(parts[0]);
    }
  }

  return null;
};

const formatDurationObject = (duration, abreviation = 'm') => {
  return duration.h
    ? `${duration.h}hr ${duration.m}${abreviation}`
    : `${duration.m}${abreviation}`;
};

const formatDuration = (mins, abreviation = 'm') => {
  const hours = mins / 60;
  const minutes = mins % 60;

  return Math.floor(hours)
    ? `${Math.floor(hours)}hr ${minutes}${abreviation}`
    : `${minutes}${abreviation}`;
};

const formatDurationObjectTTS = (duration) => {
  const hours = duration.h;
  const minutes = duration.m;

  return Math.floor(hours)
    ? `${Math.floor(hours)}${t`HOURS`} ${minutes}${t`MINUTES`}`
    : `${minutes}${t`MINUTES`}`;
};
const formatDurationTTS = (mins) => {
  const hours = mins / 60;
  const minutes = mins % 60;

  return Math.floor(hours)
    ? `${Math.floor(hours)}${t`HOURS`} ${minutes}${t`MINUTES`}`
    : `${minutes}${t`MINUTES`}`;
};

const getFormatedTimeFromSeconds = (seconds) => {
  if (seconds < 60) {
    return `${Math.floor(seconds)} ${t`SECONDS`}`;
  }
  const minutes = Math.floor(seconds / 60);
  const isHourLong = minutes >= 60;
  if (isHourLong) {
    const hours = Math.floor(seconds / 60 / 60);
    const remainderMinutes = minutes % (hours * 60);
    return `${hours} ${
      hours > 1 ? t`HOURS` : t`HOUR`
    }, ${remainderMinutes} ${t`MINUTES`}`;
  }

  return `${minutes} ${t`MINUTES`}`;
};

function getAriaLabelMoreInfoScreen({
  title,
  seriesTitle,
  episodeTitle,
  season,
  episode,
  duration,
  ratingTags,
  releaseDate,
  genres,
  directors,
  cast,
  moreInfoLabel,
  longDescription,
}) {
  const rating = ratingTags?.[0]
    ? `${ratingTags[0]?.tts}: ${ratingTags[0]?.value}`
    : '';
  const cc =
    ratingTags?.[1] && ratingTags?.[1]?.value?.length
      ? `${ratingTags[1]?.tts}: ${ratingTags[1]?.value.join(',')}`
      : '';

  // retrieves an array of integer ['hoour', 'minutes'] ex: "1hr 29m" => ['1', '29']
  const durationArray =
    (duration && duration.split('hr').join('').split('m')[0].split(' ')) || '';

  const formattedDurationTTS =
    durationArray.length > 1
      ? `${durationArray[0]} ${durationArray[0] === '1' ? t`HOUR` : t`HOURS`} ${
          durationArray[1]
        } ${t`MINUTES`}`
      : `${durationArray[0]} ${t`MINUTES`}`;

  const formattedTitle =
    seriesTitle && season && episode
      ? `${seriesTitle}, ${t`SEASON`} ${season}, ${t`EPISODE`} ${episode}`
      : title;

  const ttsLabel = [
    formattedTitle,
    episodeTitle,
    rating,
    cc,
    longDescription ? `${t`DESCRIPTION_LABEL`}: ${longDescription}` : '',
    releaseDate?.getFullYear()
      ? `${t`RELEASE_DATE`} ${releaseDate?.getFullYear()}`
      : '',
    durationArray ? formattedDurationTTS : '',
    genres.length ? `${t`GENRE`} ${genres}` : '',
    directors ? `${t`DIRECTED_BY`} ${directors}` : '',
    cast ? `${t`STARRING`} ${cast}` : '',
    `${t`EXIT`} ${moreInfoLabel}`,
  ];

  const ariaLabel = ttsLabel.join('. ');

  return ariaLabel;
}

const getTTSString = (
  metadata,
  userRegion,
  playState,
  title,
  episodeName,
  season,
  episode,
  seriesPlayType,
  whyIt,
  availableSeasons,
  genres
) => {
  const {
    contentType,
    releaseYear,
    directors,
    cast,
    quality,
    cc,
    rating,
    description,
    isExpiringSoon,
    remainingDays,
    duration,
  } = metadata;

  const playStateLabel = getPlayStateLabelForTTS(
    contentType,
    season,
    episode,
    playState,
    seriesPlayType
  );

  const availableSeasonLabel =
    availableSeasons > 1
      ? t({
          id: 'AVAILABLE_SEASONS',
          values: { seasons: availableSeasons },
        })
      : t`AVAILABLE_SEASON`;

  const whyItCrackles = isFeatureFlagEnabled('showWhyItCrackles') && !!whyIt;

  const strRatingAndCC = ratingAndTextualCC(cc, rating?.name, userRegion);

  const ttsLabel = [
    title,
    availableSeasons ? availableSeasonLabel : null,
    episodeName,
    `${playStateLabel}`,
    `${t`DESCRIPTION_LABEL`}: ${description}`,
    releaseYear ? `${t`RELEASE_DATE`} ${releaseYear}` : null,
    duration ? `${t`DURATION`} ${formatDurationObjectTTS(duration)}` : '',
    `${t`GENRE`} ${genres || null}`,
    whyItCrackles ? `${t`WHY_IT_CRACKLES`} ${whyIt}` : '',
    isExpiringSoon ? getRemainingDaysLabel(remainingDays) : '',
    contentType !== CONTENT_TYPES.SERIES
      ? `${t`DIRECTED_BY`} ${directors}`
      : null,
    `${t`STARRING`} ${cast || null}`,
    `${t`QUALITY`} ${quality}`,
    strRatingAndCC || null,
    `${playStateLabel}`,
  ];

  return ttsLabel.filter((ttsValue) => ttsValue !== null).join('. ');
};

function getPlayStateLabelForTTS(
  contentType,
  season,
  episode,
  playState,
  seriesPlayType
) {
  let playStateDetails = '';

  if (contentType === CONTENT_TYPES.EPISODE) {
    playStateDetails = `${
      season && episode ? `${t`SEASON`} ${season} ${t`EPISODE`} ${episode}` : ''
    }`;
  }

  if (contentType === CONTENT_TYPES.SERIES) {
    // If the series wasn't yet started must show watch now label without episode info
    playStateDetails = `${
      seriesPlayType === PLAYER_PLAY_TYPES.RESUME && season && episode
        ? `${t`SEASON`} ${season} ${t`EPISODE`} ${episode}`
        : ''
    }`;
  }

  let playStateLabel = '';

  switch (playState) {
    case 'watch':
      playStateLabel = `${t`WATCH_NOW`} ${playStateDetails}`;
      break;
    case 'watch-again':
      playStateLabel = `${t({
        id: 'WATCH_CONTENT_AGAIN',
        values: { content: playStateDetails },
      })}`;
      break;
    case 'resume':
      playStateLabel = `${t`RESUME`} ${playStateDetails}`;
      break;
    default:
  }

  return playStateLabel;
}

/**
 * Gets duration and formats it to seconds
 *
 * @param {object} data Response object from API
 * @param {boolean} seconds Whether to include seconds in the duration
 * @return {(string|null)} Duration without seconds (e.g. the 138m part) | Full Duration (e.g. 138min + 42sec)
 *                          - Format from API = "138:42 min"
 */
const getDurationSeconds = (data, seconds = false) => {
  const metaData = data.metadata[0];

  if (metaData.duration) {
    const parts = metaData.duration.match(/(\d+)/g);
    if (parts) {
      // Divide by 60 for hour format
      const hours = Math.floor(parts[0] / 60);
      const minutes = parts[0] % 60;

      // 1 hour = 3600s
      // 1 minute = 60s
      const durationWithoutSeconds = hours * 3600 + minutes * 60;
      return seconds
        ? durationWithoutSeconds + parseInt(parts[1], 10)
        : durationWithoutSeconds;
    }
  }

  return null;
};

/**
 * Gets medium description for content
 *
 * @param {object} data Response object from API
 * @return {(string|null)} Description string or null
 */
const getMediumDescription = (data) => {
  return (data.metadata[0] || {}).mediumDescription || null;
};

/**
 * Gets content rating by current region
 *
 * @param {object} data Response object from API
 * @param {string} userRegion Current user's location
 * @return {(string|null)} Rating string or null
 */
const getRating = (data, userRegion = '') => {
  if (data && data.rating) {
    const americanRating = data.rating.find((obj) => obj.region === 'US');

    if (userRegion) {
      const userRating = data.rating.find((obj) => obj.region === userRegion);

      if (userRating && userRating.rating) {
        return userRating.rating;
      }
    }

    // Default to US rating if content does not have one matching user location
    return (americanRating && americanRating.rating) || null;
  }

  return null;
};

/**
 * Gets content rating tags data
 *
 * @param {object} data Response object from API
 * @param {string} userRegion Current user's location
 * @return {array} Rating tags data array
 */
const getRatingTags = (data, userRegion = '') => {
  return [
    // { TODO, not in API yet
    //   tts: 'Definition',
    //   value: getDefinition(response),
    // },
    {
      tts: 'Rating',
      value: getRating(data, userRegion),
    },
    {
      tts: 'Closed captioning options',
      value: getCaptions(data),
    },
  ];
};

/**
 * Gets content rating and cc tags data in string for TTS
 *
 * @param {object} data array of objects. Each object contains two keys: "key" and "value".
 *
 */
const ratingAndTextualCC = (cc, rating, userRegion) => {
  const data = { rating, cc };
  const ratingStr = rating;
  const ccStr = getTextualCaptions(data, userRegion);

  const result = `${ratingStr ? `Rating: ${ratingStr}.` : ''}${
    ccStr && ccStr.length > 0
      ? ` Closed captioning options: ${ccStr.join(', ')}`
      : ''
  }`;

  return result;
};

/**
 * Gets content rating and cc tags data in string for TTS
 *
 * @param {object} data array of objects. Each object contains two keys: "key" and "value".
 *
 */
const ratingAndCC = (data) => {
  const str = data.reduce((prev, item) => {
    if (item && item.tts && item.value) {
      // Array of CC options
      if (item.value instanceof Array && item.value?.length > 0) {
        const ccOptions = item.value.reduce((acum, lang, idx) => {
          const delimiter = idx + 1 < item.value.length ? ',' : '';
          const splitLang = lang.split('').join(' ');

          return acum + `${splitLang.trim()}${delimiter}`;
        }, item.tts + ': ');

        return prev + ccOptions;
      }

      if (item.value instanceof Array && item.value?.length === 0) {
        return prev + '';
      }

      // Everything else
      return prev + `${item.tts}: ${item.value}. `;
    }
    return null;
  }, '');
  return str;
};

/**
 * Gets content rating tags html code
 *
 * @param {array} ratingTags Content rating tags data
 * @return {array} Array of <li> elements
 */
const getRatingTagsHtml = (ratingTags) => {
  if (!ratingTags) return null;

  return ratingTags.map((item) => {
    if (item && item.tts && item.value) {
      // Array of CC options
      if (item.value instanceof Array) {
        return ccToListItems(item.value, item.tts);
      }
      // Everything else
      return (
        <li aria-label={`${item.tts}: ${item.value}`} key={item.value}>
          {item.value.toUpperCase()}
        </li>
      );
    }
    return null;
  });
};

/**
 * Gets content release date
 *
 * @param {object} data Response object from API
 * @return {(object|null)} Date object or null
 */
const getReleaseDate = (data) => {
  const date = (data.metadata[0] || {}).releaseDate;
  return (date && new Date(date)) || null;
};

/**
 * Gets season number
 *
 * @param {object} data Response object from API
 * @return {(string|null)} Season string or null
 */
const getSeason = (data) => {
  const season = (data.metadata[0] || {}).seasonNumber;

  return stringifyNumber(season);
};

const stringifyNumber = (number) => {
  return typeof number === 'undefined' || number === null
    ? null
    : `${number}` || '0';
};

/**
 * Gets episode number
 *
 * @param {object} data Response object from API
 * @return {(string|null)} Episode string or null
 */
const getEpisode = (data) => {
  const episode = (data.metadata[0] || {}).episodeNumber;

  return stringifyNumber(episode);
};

/**
 * Gets cast(starring) data
 *
 * @param {object} data Response object from API
 * @return {(array|null)} Cast array or null
 */
const getCast = (data) => {
  return getArrayFromStringList(data?.credits?.[0]?.cast);
};

/**
 * Gets directors data
 *
 * @param {object} data Response object from API
 * @return {(array|null)} Directors array or null
 */
const getDirectors = (data) => {
  return getArrayFromStringList(data?.credits?.[0]?.directors);
};

/**
 * Gets genres data
 *
 * @param {object} data Response object from API
 * @return {(array|null)} Genres array or null
 */
const getGenres = (data) => {
  return (
    data?.catalog?.[0]?.categories?.map((category) => category?.label) || null
  );
};

/**
 * Gets genres ids
 *
 * @param {object} data Response object from API
 * @return {(array|null)} Genres array or null
 */
const getGenresIds = (data) => {
  return (
    data?.catalog?.[0]?.categories?.map((category) => category?.id) || null
  );
};

/**
 * Gets long description for content
 *
 * @param {object} data Response object from API
 * @return {(string|null)} Long description string or null
 */
const getLongDescription = (data) => {
  return (data.metadata[0] || {}).longDescription || null;
};

/**
 * Gets short description for content
 *
 * @param {object} data Response object from API
 * @return {(string|null)} Description string or null
 */
const getShortDescription = (data) => {
  return (data.metadata[0] || {}).shortDescription || null;
};

/**
 * Gets content title
 *
 * @param {object} data Response object from API
 * @return {(string|null)} Title string or null
 */
const getTitle = (data) => {
  return (data?.metadata?.[0] || {}).title || null;
};

/**
 * Gets why it crackle
 *
 * @param {object} data Response object from API
 * @return {(string|null)} Why it crackle
 */
const getWhyIt = (data) => {
  return (data?.metadata?.[0] || {}).whyItCrackles || null;
};

/**
 * Gets content type
 *
 * @param {object} data Response object from API
 * @return {(string|null)} Content type string or null
 */
const getType = (data) => {
  return data.type || null;
};

/**
 * Takes array of CC options and returns array of <li> elements
 * with the appropriate TTS tag and output.
 *
 * @param {array} value Array of subtitle options, ex. ['cc', 'fr']
 * @param {string} tts Text-to-speech tag for each cc option
 * @return {array} Array of <li> elements
 */
const ccToListItems = (value, tts) => {
  const retVal = [];

  for (const cc of value) {
    // Split and rejoin so TTS says "E N" instead of "ennn"
    retVal.push(
      <li aria-label={`${tts}: ${cc.split('').join(' ')}`} key={cc}>
        {`CC:${cc.toUpperCase()}`}
      </li>
    );
  }

  return retVal;
};

/**
 * Gets the start time in seconds of the video's credits (credit marker)
 *
 * @param {object} data Response object from API
 * @return {(number|null)} StartInSeconds seconds
 */
const getCreditMarker = (data) => {
  return (
    (data?.assets?.chapters?.[1]?.breakpoints?.[0] || {}).startInSeconds || null
  );
};

/**
 * Helper function to calculate difference between a date and present date
 * @param {string} date End date/Upper limit for the difference
 * @returns {number} Number of days between 2 dates
 */
const getDifferenceInDays = (date) => {
  const presentDate = new Date();
  const contentEndDate = new Date(date);
  const diffInTime = contentEndDate.getTime() - presentDate.getTime();

  // if content has already expired
  if (diffInTime < 0) {
    return -1;
  }

  const oneDayInMilliSec = 1000 * 3600 * 24;
  const diffInDays = diffInTime / oneDayInMilliSec;

  return Math.floor(diffInDays);
};

/**
 * Calculates the number of days to when an asset expires, from present day
 * @param {string} date expiry date of a content returned by the API
 * @returns {number || string} Number of days until which the content expires
 */
const getRemainingDays = (data) => {
  const avail = data?.access?.avails?.[0]?.platforms.find(
    (platform) =>
      platform.id.toLowerCase() === APP_CONFIG.PLATFORM_ID.toLowerCase()
  );

  let remainingDays = 0;

  if (avail?.windowEnd) {
    remainingDays = getDifferenceInDays(avail?.windowEnd);
  }

  return remainingDays;
};

const getRemainingDaysLabel = (days) => {
  // this conditional considers the edge case when isExpiringSoon is true but content has already expired
  if (days < 0) {
    return t({ id: 'CONTENT_EXPIRATION_DAYS_REMAINING', values: { days: 0 } });
  }

  return days > 0
    ? t({ id: 'CONTENT_EXPIRATION_DAYS_REMAINING', values: { days } })
    : t({ id: 'CONTENT_EXPIRATION_LESS_THAN_1_DAY_REMAINING' });
};

const getIsExpiringSoon = (data) => {
  return data?.access?.isExpiringSoon;
};

// Check if content is crackle original
const getIsOriginal = (data) => {
  const genres = getCategories(data);

  const originalGenres = Object.values(ORIGINAL_GENRES);

  return !!genres.find((genre) =>
    originalGenres.includes(genre.name.toLowerCase())
  );
};

const getEpisodeDataForNextMedia = (data) => {
  const nextMedia = {
    id: data.id,
    title: getTitle(data),
    images: data.assets.images,
    seasonNumber: getSeason(data),
    episodeNumber: getEpisode(data),
    duration: getDuration(data),
    shortDescription: getShortDescription(data),
  };

  return nextMedia;
};

/** @function getEpisodeAndSeasonLabel  */
/** @param {number} episode - episode number */
/** @param {number} season - season number */
/** @param {string} separator - separator between season and episode */
/** @param {boolean} abbreviate - abbreviate season and episode */
/** @returns {string} label - label for episode and season */
const getEpisodeAndSeasonLabel = (
  episode,
  season,
  separator = ':',
  abbreviate = true,
  forTTS = false
) => {
  const episodeNumber = stringifyNumber(episode) || '';
  const seasonNumber = stringifyNumber(season) || '';

  let label = null;

  if (forTTS) {
    return `${t`SEASON`} ${seasonNumber} ${separator} ${t`EPISODE`} ${episodeNumber}`;
  }

  if (episodeNumber && seasonNumber) {
    let episodeLabel = '';
    let seasonLabel = '';

    episodeLabel = abbreviate ? t`EPISODE_ABBREVIATION` : `${t`EPISODE`} `;
    episodeLabel += `${episode}`;

    seasonLabel = abbreviate ? t`SEASON_ABBREVIATION` : `${t`SEASON`} `;
    seasonLabel += `${season}`;

    label = `${seasonLabel}${separator}${episodeLabel}`;
  }

  return label;
};

// get channel type by content type
function getChannelTypeByContentType(type) {
  let channel = null;

  switch (type) {
    case CONTENT_TYPES.SERIES:
    case CONTENT_TYPES.EPISODE:
      channel = CHANNEL_TYPES.SERIES;
      break;
    default:
      channel = CHANNEL_TYPES.MOVIES;
  }

  return channel;
}

// check if content is Series or a Movie
function isContentSeriesOrMovie(contentType) {
  return (
    contentType === CONTENT_TYPES.MOVIE ||
    contentType === CONTENT_TYPES.FEATURED_FILM ||
    contentType === CONTENT_TYPES.SERIES
  );
}

// check if content is Series
function isContentSeries(contentType) {
  return contentType === CONTENT_TYPES.SERIES;
}

export {
  ccToListItems,
  getBackgroundImage,
  getCaptions,
  getCast,
  getCategories,
  getDefinition,
  getDirectors,
  getDuration,
  formatDurationTTS,
  formatDuration,
  formatDurationObject,
  getDurationSeconds,
  getFormatedTimeFromSeconds,
  getCreditMarker,
  getEpisode,
  getGenres,
  getGenresIds,
  getLongDescription,
  getMediumDescription,
  getRating,
  getRatingTags,
  ratingAndCC,
  getRatingTagsHtml,
  getReleaseDate,
  getRemainingDays,
  getSeason,
  stringifyNumber,
  getShortDescription,
  getStringListFromArray,
  getTitle,
  getType,
  isActive,
  getTTSString,
  getIsOriginal,
  getWhyIt,
  getEpisodeDataForNextMedia,
  getEpisodeAndSeasonLabel,
  getIsExpiringSoon,
  getRemainingDaysLabel,
  isContentAvailable,
  getChannelTypeByContentType,
  isContentSeriesOrMovie,
  isContentSeries,
  getPlayStateLabelForTTS,
  getAriaLabelMoreInfoScreen,
};
