/* eslint-disable prefer-template */
import { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';
import scrollIntoView from 'scroll-into-view-if-needed';

// APIs
import { fetchHeroInfo } from '../../crackle-sdk/v1/api/curations';
import {
  fetchContent,
  fetchContentMedia,
} from '../../crackle-sdk/v1/api/content';
import CrackleApiError from '../../crackle-sdk/v1/api/error';

// Config
import ERROR_TYPE from '../../config/error-type-config';

// Components
import Hero from '../../containers/hero/hero-container';
import LoadingSpinner from '../../components/loading-spinner/loading-spinner';
import HomeFreePass from '../../components/home-free-pass/home-free-pass';
import HomeContentRows from '../../components/home-content-rows/home-content-rows';

// Hooks
import useGlobalContext from '../../hooks/use-global-context';
import useModalHandler from '../../hooks/use-modal-handler';
import { api } from '../../crackle-sdk/v2/';

// Utils
import logger from '../../utils/logger';
import { scaleFrom720p } from '../../utils/scale-from-720p';
import { addParamsToQueryString, getUrlParamValue } from '../../utils/url';
import { isFeatureFlagEnabled } from '../../utils/feature-flags';
import { getWatchableEpisodeFromSeries } from '../../utils/session-history';
import { getSeason, getEpisode } from '../../utils/content-details';
import {
  getContinueWatchingLocalArray,
  getContinueWatchingLastUpdateTime,
} from '../../utils/continue-watching';
import { getSignedInUser } from '../../utils/signed-in-user';
import mParticle from '../../utils/mparticle';

// Icon
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';

// Enums
import { CONTENT_TYPES } from '../../enums/content-types';
import CURATIONS_PAGE from '../../enums/curations-page';
import MODAL_TYPES from '../../enums/modal-types';
import SPATIAL_EVENTS from '../../enums/spatial-events';

// Styles
import './home.scss';

function HomeScreen() {
  const setError = useGlobalContext((state) => state.setError);
  const hoverEnabled = useGlobalContext((state) => state.hoverEnabled);
  const freePassActive = useGlobalContext((state) => state.adFreePass.active);
  const { isModalOn } = useModalHandler();
  const [homeHeroData, setHomeHeroData] = useState(null);
  const [isFetchingHeroData, setIsFetchingHeroData] = useState(false);
  const [failedFetchHeroData, setFailedFetchHeroData] = useState(false);
  const [forceCollapse, setForceCollapse] = useState(false);
  // keep track of failed fetched rows to avoid try to focus on them
  const [failedRowIndexes, setFailedRowIndexes] = useState([]);
  const userId = getSignedInUser()?.userId;

  const pointsBarRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  const { search } = location;

  const rowIdFocus = getUrlParamValue('row', search);
  const contentIdFocus = getUrlParamValue('content', search);
  const rowIndex = getUrlParamValue('rowIndex', search) * 1; // if the user is coming back from a content detail, we set here the row index to force the loading
  const rowScroll = getUrlParamValue('rowScroll', search) * 1;

  // it fetches first the hero data and then the info of the hero's media, saving the info in GlobalContext to prevent fetching from API again on next rendering
  useEffect(() => {
    if (!isFetchingHeroData && !homeHeroData && !failedFetchHeroData) {
      // if disable hero is active avoid rendering hero
      if (isFeatureFlagEnabled('testDisableHero')) {
        setTimeout(() => {
          setFailedFetchHeroData(true);
        }, 500);
      } else {
        setIsFetchingHeroData(true);
        fetchHeroInfo()
          .then((res) => {
            const contentId =
              res?.type === CONTENT_TYPES.SERIES
                ? res.contentId
                : res.mediaContentId;

            fetchContent(contentId)
              .then((resMedia) => {
                if (resMedia && resMedia.type === CONTENT_TYPES.EPISODE) {
                  // fetch the parent (serie) of the parent (season) of the episode
                  fetchContent(resMedia.parentId).then((response) => {
                    const heroData = {
                      ...res,
                      type: resMedia.type,
                      metadata: resMedia.metadata,
                      assets: resMedia.assets,
                      season: getSeason(resMedia),
                      episode: getEpisode(resMedia),
                      parentId: response.parentId,
                    };
                    setHomeHeroData(heroData);
                    setIsFetchingHeroData(false);
                  });
                } else if (resMedia && resMedia.type === CONTENT_TYPES.SERIES) {
                  const episodeInfo = getWatchableEpisodeFromSeries(contentId);

                  const heroData = {
                    ...res,
                    mediaContentId: episodeInfo.id,
                    parentId: contentId,
                    type: resMedia.type,
                    metadata: resMedia.metadata,
                    duration: episodeInfo.mediaEndTime,
                    assets: resMedia.assets,
                    season: episodeInfo?.season,
                    episode: episodeInfo?.episode,
                    watchAgainSeries: episodeInfo.fullyWatchedSeries,
                  };

                  // if no episode is selectable from session history, then fetch for the first episode of the series
                  if (!episodeInfo || !episodeInfo.season) {
                    fetchContentMedia(contentId).then((response) => {
                      heroData.season = response.seasonNumber;
                      heroData.episode = response.episodeNumber;
                      heroData.mediaContentId = response.id;
                      heroData.duration = response.duration * 60; // duration it's in minutes
                      setHomeHeroData(heroData);
                      setIsFetchingHeroData(false);
                    });
                  } else {
                    setHomeHeroData(heroData);
                    setIsFetchingHeroData(false);
                  }
                } else {
                  const heroData = {
                    ...res,
                    type: resMedia?.type,
                    metadata: resMedia?.metadata,
                    assets: resMedia?.assets,
                  };
                  setHomeHeroData(heroData);
                  setIsFetchingHeroData(false);
                }
              })
              .catch((e) => {
                setIsFetchingHeroData(false);
                setFailedFetchHeroData(true);
                if (e instanceof CrackleApiError) {
                  logger.error(e);
                } else {
                  logger.error('Error occurred while fetching hero info', e);
                }
              });
          })
          .catch((e) => {
            setIsFetchingHeroData(false);
            setFailedFetchHeroData(true);
            if (e instanceof CrackleApiError) {
              logger.error(e);
            } else {
              logger.error('Error occurred while fetching hero info', e);
            }
          });
      }
    }
  }, [
    failedFetchHeroData,
    homeHeroData,
    isFetchingHeroData,
    setError,
    setHomeHeroData,
    setIsFetchingHeroData,
  ]);

  // To deal with coming back from pressing join rewards button
  useEffect(() => {
    const focus = getUrlParamValue('focus', search);
    if (rowsArray?.length > 0 && focus === 'points-bar') {
      scrollIntoView(pointsBarRef.current, {
        block: 'center',
      });
    }
  }, [rowsArray, search]);

  useEffect(() => {
    // TODO: review
    // focus = "hero" | "rows" | "points-bar" | "continue-watching"
    // If focus = exists ==> go there
    // If focus = doesn't exists
    //    If hero loaded  ==> go to hero
    //    If !hero loaded ==> go to first content card

    // if not content or row is present in url focus the hero
    if (
      !(getUrlParamValue('content', search) && getUrlParamValue('row', search))
    ) {
      if (failedFetchHeroData) {
        JsSpatialNavigation.focus('.content-card');
        document.getElementById('base-container').scrollTop = 0;
      } else if (getUrlParamValue('focus', search) === 'points-bar') {
        setForceCollapse(true);
        JsSpatialNavigation.focus('@points-bar-section');
      } else if (!isModalOn()) {
        JsSpatialNavigation.focus('hero-focusable');
      }
    }
  }, [location, homeHeroData, search, failedFetchHeroData, isFetchingHeroData]);

  const setFocusedContent = useCallback(
    (contentId, rowId, rowIndex, rowScroll) => {
      const { pathname } = location;

      const searchParams = addParamsToQueryString(
        search,
        rowId
          ? {
              content: contentId,
              row: rowId,
              rowIndex: rowIndex,
              focus: null,
              rowScroll,
            }
          : { content: contentId, row: null, rowIndex: null, focus: null },
        true
      );

      navigate(pathname + searchParams, {
        replace: true,
      });
    },
    [navigate, location, search]
  );

  const handleContentSelected = useCallback(
    (
      id,
      rowId,
      mediaTitle,
      rowTitle,
      tileIndex,
      rowIndex,
      rowType,
      rowScroll
    ) => {
      setFocusedContent(id, rowId, rowIndex, rowScroll);

      mParticle.mParticleContentTileClick({
        contentTileName: mediaTitle,
        contentTilePosition: tileIndex + 1,
        trayName: rowTitle,
        trayPosition: rowIndex + 1,
        trayType: rowType,
        pageSection: rowTitle,
      });
    },
    [setFocusedContent]
  );

  // bind modal close event for exit modal
  useEffect(() => {
    const onModalClose = (e) => {
      const { type } = e.detail;

      // The exit modal closes when menu is expanded
      if (type === MODAL_TYPES.EXIT_APP) {
        JsSpatialNavigation.focus('.menu-home');
      }
    };

    window.addEventListener('close-modal', onModalClose);
    return () => {
      window.removeEventListener('close-modal', onModalClose);
    };
  }, [failedFetchHeroData]);

  const { data: rowsArray, isError: rowsError } = api.useCurations({
    page: CURATIONS_PAGE.HOME,
  });

  const continueWatchingLocalArray = getContinueWatchingLocalArray() || [];
  const showContinueWatchingRow =
    isFeatureFlagEnabled('continueWatching') &&
    Boolean(userId) &&
    continueWatchingLocalArray.length > 0;

  const { data: continueWatchingCuration, isError: continueWatchingError } =
    api.useContinueWatchingRow({
      sessionStorageArray: continueWatchingLocalArray,
      lastUpdateTime: getContinueWatchingLastUpdateTime(),
    });

  if (rowsError) {
    setError(ERROR_TYPE.APP_LOAD);
  }

  // Handle case when last continue watching item is completely watched and remove from the row
  useEffect(() => {
    if (!isFeatureFlagEnabled('continueWatching')) {
      return;
    }

    if (
      rowIdFocus === 'continue-watching-row' &&
      continueWatchingCuration.length === 0
    ) {
      const row =
        document?.getElementsByClassName('content-row') &&
        document?.getElementsByClassName('content-row')[0];

      JsSpatialNavigation.focus(`@${row?.id || 'navbar-app-menu'}`);
    }
  }, [rowIdFocus, continueWatchingCuration]);

  // To capture user movement and stop if the library is trying to focus an element that's not the supposed to be focused
  useEffect(() => {
    const onUserMoved = (event) => {
      if (
        (event.detail.direction === 'up' ||
          event.detail.direction === 'down') &&
        event.srcElement &&
        event.srcElement?.className.indexOf('content-row') > -1
      ) {
        const parentRow = event.srcElement.closest('.content-row');

        if (parentRow) {
          // get the supposed next element to be focused (removing @ and .)
          const nextElement = parentRow
            .getAttribute('data-leave-' + event.detail.direction)
            .replace('@', '')
            .replace('.', '');

          // if navigation is about to focus an element that's not the supposed to be focused, then abort movement
          if (event.detail.nextElement?.className.indexOf(nextElement) === -1) {
            event.preventDefault();
          }
        }
      }
    };

    window.addEventListener(SPATIAL_EVENTS.WILL_UNFOCUS, onUserMoved);

    return () => {
      window.removeEventListener(SPATIAL_EVENTS.WILL_UNFOCUS, onUserMoved);
    };
  }, []);

  // the hero is collapsed when rendering the home for the first time
  const heroInitiallyCollapsed =
    (rowIndex !== null && !failedFetchHeroData && Boolean(rowsArray?.length)) ||
    forceCollapse ||
    !!rowIdFocus;

  // wait for first row to load in case need to be focused on start
  const handleRowLoaded = useCallback(
    function () {
      // Focus wasnt given after hero fails
      if (
        document.getElementsByClassName('content-card focusedElement')
          .length === 0 &&
        failedFetchHeroData
      ) {
        //  we set the focus on the first available row
        const row =
          document?.getElementsByClassName('content-row') &&
          document?.getElementsByClassName('content-row')[0];

        JsSpatialNavigation.focus(`@${row?.id || 'navbar-app-menu'}`);
      }
    },
    [failedFetchHeroData]
  );

  function handleBackFromRow(index, rowId) {
    if (index) {
      JsSpatialNavigation.focus(`.content-card-${rowId}-0`);
      return;
    }

    if (failedFetchHeroData) {
      // if hero failed and first row already on focus, show exit app modal else got to first item of first available row
      const firstAvailableCard = document.querySelectorAll(
        '[data-tray-slide-index="0"]'
      )[0].childNodes[0];
      if (document.activeElement === firstAvailableCard) {
        JsSpatialNavigation.focus('.menu-home');
      } else {
        JsSpatialNavigation.focus(firstAvailableCard);
      }
    } else {
      JsSpatialNavigation.focus('.menu-home');
    }
  }

  function showSeeAll(rowTotalItems, rowItemsLength) {
    return rowTotalItems && rowItemsLength && rowTotalItems > rowItemsLength;
  }

  const heroDownMovement = useCallback(
    function () {
      const firstRow = document.getElementById(`content-row-${0}`);

      // default behavior
      if (firstRow || !failedRowIndexes.includes(0)) {
        return `@content-row-${0}`;
      }

      if (isFeatureFlagEnabled('loyalty')) {
        // if first row in focus and reward section exists move to home component
        if (!firstRow && document.getElementById('points-bar-section')) {
          return `@points-bar-section`;
        }
      }

      if (!firstRow) {
        for (let i = 1; i < rowsArray?.length - 1; i++) {
          if (document.getElementById(`content-row-${i}`)) {
            return `@content-row-${i}`;
          }
        }
      }

      return '';
    },
    [failedRowIndexes]
  );

  const loyaltyPointsBarUpMovement = useCallback(
    function () {
      const firstRow = document.getElementById(`content-row-${0}`);

      // default behavior
      if (firstRow || !failedRowIndexes.includes(0)) {
        return `@content-row-${0}`;
      }

      return '@hero-focusable';
    },
    [failedRowIndexes]
  );

  const loyaltyPointsBarDownMovement = useCallback(
    function () {
      let rowSectionId = '';

      // default behavior
      if (!failedRowIndexes.includes(1)) {
        return '@content-row-1';
      }

      for (let i = 1; i < rowsArray?.length - 1; i++) {
        const row = document.getElementById(`content-row-${i}`);
        if (row) {
          rowSectionId = `@content-row-${i}`;
          break;
        }
      }

      return rowSectionId;
    },
    [failedRowIndexes]
  );

  const rowDownMovement = useCallback(
    function (rowIndex) {
      const nextRowIndex = rowIndex + 1;

      const nextRow = document.getElementById(`content-row-${nextRowIndex}`);

      if (isFeatureFlagEnabled('loyalty')) {
        // if first row in focus and reward section exists move to home component
        if (rowIndex === 0 && document.getElementById('points-bar-section')) {
          return `@points-bar-section`;
        }
      }

      // default behavior
      if (nextRow || !failedRowIndexes.includes(nextRowIndex)) {
        return `@content-row-${nextRowIndex}`;
      }

      if (rowIndex === rowsArray?.length - 1) {
        return '';
      }

      for (let i = nextRowIndex; i < rowsArray?.length - 1; i++) {
        if (document.getElementById(`content-row-${i}`)) {
          return `@content-row-${i}`;
        }
      }

      // Block down movement
      return '';
    },
    [failedRowIndexes]
  );

  const rowUpMovement = useCallback(
    function (rowIndex) {
      const prevRowIndex = rowIndex - 1;

      const firstRow = document.getElementById(`content-row-${0}`);
      const prevRow = document.getElementById(`content-row-${prevRowIndex}`);

      if (rowIndex === 0 && !failedFetchHeroData) {
        return '@hero-focusable';
      }

      if (isFeatureFlagEnabled('loyalty')) {
        if (
          prevRowIndex === 0 &&
          document.getElementById('points-bar-section')
        ) {
          return `@points-bar-section`;
        }
      }

      // default behavior
      if (
        (prevRow && prevRowIndex !== 0) ||
        !failedRowIndexes.includes(prevRowIndex)
      ) {
        return `@content-row-${prevRowIndex}`;
      }

      for (let i = prevRowIndex; i >= 0; i--) {
        if (
          i === 0 &&
          document.getElementById('points-bar-section') &&
          isFeatureFlagEnabled('loyalty')
        ) {
          return `@points-bar-section`;
        }

        if (document.getElementById(`content-row-${i}`)) {
          return `@content-row-${i}`;
        }

        if (i === 0 && !firstRow && !failedFetchHeroData) {
          return '@hero-focusable';
        }
      }

      return '';
    },
    [failedRowIndexes, failedFetchHeroData]
  );

  const updateFailedRowsIndexes = useCallback(function (index) {
    setFailedRowIndexes((prev) => [...prev, index]);
  }, []);

  function onSeeAllClick(rowId, rowIndex, rowScroll) {
    navigate(
      `${location.pathname}?content=see-all-card-${rowId}&row=${rowId}&rowIndex=${rowIndex}&rowScroll=${rowScroll}`,
      { replace: true }
    );
  }

  const lazyLoaderOptions = {
    triggerOnce: false,
    root: document.querySelector('#base-container'),
    // had to disable the eslint rule, cause templating here was breaking the build
    rootMargin: scaleFrom720p(430) + 'px ' + scaleFrom720p(100) + 'px',
    threshold: 0,
  };

  return (
    <div className="home">
      {isFeatureFlagEnabled('loyalty') && freePassActive && (
        <HomeFreePass className="home__small-free-pass" />
      )}
      <div className="home__hero">
        {isFetchingHeroData || !homeHeroData ? (
          <div
            className={
              heroInitiallyCollapsed && document.getElementById(`content-row-0`)
                ? 'placeholder-hero-collapsed'
                : 'placeholder-hero'
            }
          >
            <span className="placeholder-hero__spinner">
              <LoadingSpinner />
            </span>
          </div>
        ) : null}

        {!isFetchingHeroData && homeHeroData ? (
          <Hero
            data={homeHeroData}
            onSelectHeroContent={handleContentSelected}
            initiallyCollapsed={heroInitiallyCollapsed}
            heroDownMovement={heroDownMovement}
          />
        ) : null}

        {!isFeatureFlagEnabled('loyalty') && (
          <div data-test-id="brand-logo" className="hero__logo-container">
            <LogoIcon className="hero__logo" />
          </div>
        )}

        {isFeatureFlagEnabled('loyalty') && (
          <div data-test-id="brand-logo" className="home__hero__logo-container">
            <LogoIcon className="home__hero__logo" />
          </div>
        )}
      </div>
      <HomeContentRows
        rows={rowsArray}
        heroInitiallyCollapsed={heroInitiallyCollapsed}
        showSeeAll={showSeeAll}
        handleContentSelected={handleContentSelected}
        handleRowLoaded={handleRowLoaded}
        handleBackFromRow={handleBackFromRow}
        forceLoadRow={rowIndex}
        freePassActive={freePassActive}
        pointsBarRef={pointsBarRef}
        lazyLoaderOptions={lazyLoaderOptions}
        rowDownMovement={rowDownMovement}
        rowUpMovement={rowUpMovement}
        onSeeAllClick={onSeeAllClick}
        showContinueWatchingRow={
          showContinueWatchingRow &&
          continueWatchingCuration.length > 0 &&
          !continueWatchingError
        }
        continueWatchingCuration={
          continueWatchingError ? [] : continueWatchingCuration
        }
        rowIdFocus={rowIdFocus}
        rowScroll={rowScroll}
        contentIdFocus={contentIdFocus}
        loyaltyPointsBarDownMovement={loyaltyPointsBarDownMovement}
        loyaltyPointsBarUpMovement={loyaltyPointsBarUpMovement}
        updateFailedRowsIndexes={updateFailedRowsIndexes}
        hoverEnabled={hoverEnabled}
      />
    </div>
  );
}

export default HomeScreen;
