import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';
import { useInView } from 'react-intersection-observer';

// Styles
import './content-card.scss';
// Chromium 47
import './content-card.legacy.scss';

// Components
import Focusable from '../spatial-navigation/focusable';
import ProgressBar from '../progress-bar/progress-bar';

// Enums
import CONTENT_ROW_TYPES from '../../enums/content-row-types';
import ELEMENT_TYPES from '../../enums/element-types';
import ROUTES from '../../enums/routes';
import PROGRESSBAR_TYPES from '../../enums/progress-bar-types';

// Icons
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import { ReactComponent as SeenIcon } from '../../assets/icons/seen.svg';

// Utils
import { scaleFrom720p } from '../../utils/scale-from-720p';
import { getFallbackPoster } from '../../utils/content-images';
import { getEpisodeAndSeasonLabel } from '../../utils/content-details';
import { populateRouteParameters } from '../../utils/url';

const getTitles = (type, title, description) => {
  switch (type) {
    case CONTENT_ROW_TYPES.STANDARD:
      return (
        <div aria-hidden className="content-card__title">
          <p>{title}</p>
        </div>
      );

    case CONTENT_ROW_TYPES.DETAILS:
      return (
        <div className="content-card__title-block">
          <div aria-hidden className="title">
            {title}
          </div>
          {description !== '' && (
            <div aria-hidden className="desc">
              {description}
            </div>
          )}
        </div>
      );

    case CONTENT_ROW_TYPES.ORIGINALS:
      return (
        <>
          <div aria-hidden className="content-card__originals-title-block">
            {title}
          </div>
          <div className="content-card__overlay">
            <div aria-hidden className="content-card__overlay__badge">
              <Trans>ORIGINAL_BADGE</Trans>
            </div>
          </div>
        </>
      );

    default:
      return (
        <div aria-hidden className="content-card__title">
          {title}
        </div>
      );
  }
};

function ContentCard({
  browseGridNumber,
  className = '',
  description = '',
  id,
  index,
  isBlocked,
  forcePoster = false,
  poster = '',
  rowId,
  title,
  type = '',
  channelRow,
  ariaLabel,
  elementType = ELEMENT_TYPES.LINK,
  viewed,
  seasonNumber = null,
  episodeNumber = null,
  progressPercent = null,
  to = '',
  ...otherProps
}) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: scaleFrom720p(100) + 'px ' + scaleFrom720p(100) + 'px',
  });

  // States
  const [imageLoadError, setImageLoadError] = useState(false);
  const [imageLoadOk, setImageLoadOk] = useState(false);

  const cardTitle = useMemo(
    () => getTitles(type, title, description),
    [type, title, description]
  );

  const fallBackImage = useMemo(() => getFallbackPoster(type), [type]);

  const detailsLink = populateRouteParameters(ROUTES.DETAILS, {
    mediaId: id,
  });

  const getCardOverlays = () => {
    const episodeLabel = getEpisodeAndSeasonLabel(episodeNumber, seasonNumber);
    let icon;

    if (isBlocked && viewed) {
      icon = (
        <LockIcon className="content-card__poster-container__locked-icon" />
      );
    } else if (!isBlocked && viewed) {
      icon = <SeenIcon className="content-card__poster-container__seen-icon" />;
    }

    return (
      <>
        {icon}
        {episodeLabel && (
          <div
            className={classnames(
              'content-card__poster-container__episode-overlay',
              {
                'episode-overlay-with-percentage-bar': progressPercent !== null,
              }
            )}
          >
            {episodeLabel}
          </div>
        )}
      </>
    );
  };

  function getCardProgress() {
    if (!progressPercent) {
      return <></>;
    }
    return (
      <ProgressBar
        progress={progressPercent}
        type={PROGRESSBAR_TYPES.ROW_CARD}
        className="content-card__progress"
      />
    );
  }

  let props = {
    className: classnames(
      'content-card',
      `content-card-${rowId ? rowId : `grid-${browseGridNumber}`}-${index}`,
      type,
      className
    ),
    elementType,
  };

  if (!browseGridNumber) {
    const detailsSearchParams = new URLSearchParams({ rowId, row: channelRow });
    props = {
      ...props,
      id: `card-${id}-${rowId}`,
      to:
        to.length > 0 ? to : `${detailsLink}?${detailsSearchParams.toString()}`,
    };
  } else {
    props = {
      ...props,
      id: `card-${id}`,
      to: detailsLink,
    };
  }

  // If title of standard card is more than 10 characters long without a space
  // (since space would trigger it into 2 lines), then we want to truncate it
  const truncateTitle = title?.length > 10 && title?.indexOf(' ') === -1;

  return (
    <Focusable aria-label={ariaLabel || title} {...props} {...otherProps}>
      <div
        className={classnames(
          'content-card__poster-container',
          truncateTitle ? 'left' : ''
        )}
        ref={ref}
      >
        <picture alt={title}>
          <source type="image/webp" srcSet={`${fallBackImage.image}.webp`} />
          <img
            aria-hidden
            src={`${fallBackImage.image}.png`}
            alt={title}
            width={Math.round(fallBackImage.width)}
            height={Math.round(fallBackImage.height)}
          />
        </picture>
        {(inView || forcePoster) && poster && !imageLoadError ? (
          <img
            aria-hidden
            src={poster}
            alt={title}
            onError={(e) => {
              e.target.src = `${fallBackImage.image}.webp`;
              setImageLoadError(true);
            }}
            onLoad={() => {
              setImageLoadOk(true);
            }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: imageLoadOk ? 1 : 0,
            }}
          />
        ) : null}
        {getCardOverlays()}
        {getCardProgress()}
        {isBlocked && <LockIcon className="content-card__locked-icon" />}
        {(!poster || imageLoadError) && type === CONTENT_ROW_TYPES.STANDARD && (
          <span
            aria-hidden
            className={classnames('content-card__fallback-text', type)}
          >
            {truncateTitle ? `${title.substring(0, 10)}...` : title}
          </span>
        )}
        {(!poster || imageLoadError) &&
          type !== CONTENT_ROW_TYPES.STANDARD &&
          type !== CONTENT_ROW_TYPES.ORIGINALS &&
          type !== CONTENT_ROW_TYPES.DETAILS && (
            <span
              aria-hidden
              className={classnames('content-card__fallback-title-text', type)}
            >
              {title}
            </span>
            // eslint-disable-next-line indent
          )}
      </div>
      {cardTitle}
    </Focusable>
  );
}

ContentCard.propTypes = {
  browseGridNumber: PropTypes.number || null,
  className: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  index: PropTypes.number,
  isBlocked: PropTypes.bool,
  isInfiniteDupe: PropTypes.bool,
  forcePoster: PropTypes.bool,
  poster: PropTypes.string,
  rowId: PropTypes.number,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  channelRow: PropTypes.string,
  ariaLabel: PropTypes.string,
  elementType: PropTypes.string,
  isInWatchlist: PropTypes.bool,
  viewed: PropTypes.bool,
  episodeNumber: PropTypes.number || null,
  seasonNumber: PropTypes.number || null,
  progressPercent: PropTypes.number || null,
  to: PropTypes.string,
};

export default ContentCard;
