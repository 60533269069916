import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';

// Components
import Navbar from '../navbar/navbar';
import NavbarItem from '../navbar-item/navbar-item';
import NavbarLogoContainer from '../navbar-logo-container/navbar-logo-container';
import MenuPointsBar from '../menu-points-bar/menu-points-bar';

// Enums
import ROUTES from '../../enums/routes';
import BROWSE from '../../enums/browse';
import NAVBAR_KEYS from '../../enums/navbarKeys';
import MODAL_TYPES from '../../enums/modal-types';
import PLATFORM_CAPABILITIES from '../../enums/platform-capabilities';

// Icons
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as MoviesIcon } from '../../assets/icons/film.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import { ReactComponent as TvShowsIcon } from '../../assets/icons/tv.svg';
import { ReactComponent as WatchlistIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/sliders.svg';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as DiamondIcon } from '../../assets/icons/diamond.svg';

// Configs
import { BACK_BUTTON_CONFIG } from '../../config/back-button-config';

// Utils
import { isKey } from '../../utils/utils';
import mParticle from '../../utils/mparticle';
import { supportCapability, KEYCODES } from '../../platform/index';

// Styles
import './menu.scss';
import { isFeatureFlagEnabled } from '../../utils/feature-flags';

const navbarItems = {
  middle: [
    {
      back: BACK_BUTTON_CONFIG.MENU_ITEM,
      children: <Trans>SEARCH</Trans>,
      className: 'menu-search',
      id: 'menu-search',
      'data-test-id': 'navbar-menu-search',
      icon: SearchIcon,
      selectionOverrides: { up: '', left: '' },
      to: ROUTES.SEARCH,
      tts: 'SEARCH',
    },
    {
      back: BACK_BUTTON_CONFIG.MENU_HOME,
      className: 'menu-home',
      id: 'menu-home',
      'data-test-id': 'navbar-menu-home',
      children: <Trans>HOME</Trans>,
      selectionOverrides: { left: '' },
      icon: HomeIcon,
      to: isFeatureFlagEnabled('authSplashScreen')
        ? ROUTES.HOME
        : ROUTES.LANDING,
      tts: 'HOME',
    },
    {
      back: BACK_BUTTON_CONFIG.MENU_MOVIES,
      children: <Trans>MOVIES</Trans>,
      selectionOverrides: { left: '' },
      className: 'menu-movies',
      id: 'menu-movies',
      'data-test-id': 'navbar-menu-movies',
      icon: MoviesIcon,
      to: ROUTES.MOVIES,
      tts: 'MOVIES',
    },
    {
      back: BACK_BUTTON_CONFIG.MENU_SHOWS,
      children: <Trans>TV_SHOWS</Trans>,
      selectionOverrides: { left: '' },
      className: 'menu-shows',
      id: 'menu-shows',
      'data-test-id': 'navbar-menu-shows',
      icon: TvShowsIcon,
      to: ROUTES.TVSHOWS,
      tts: 'TV_SHOWS',
    },
    {
      back: BACK_BUTTON_CONFIG.MENU_ITEM,
      children: <Trans>WATCHLIST</Trans>,
      selectionOverrides: { left: '' },
      className: 'menu-watchlist',
      id: 'menu-watchlist',
      'data-test-id': 'navbar-menu-watchlist',
      icon: WatchlistIcon,
      tts: 'WATCHLIST',
      featureFlag: 'watchlist',
      to: ROUTES.WATCHLIST,
    },
    {
      back: BACK_BUTTON_CONFIG.MENU_REWARDS,
      children: (
        <>
          <Trans>LOYALTY_REWARDS</Trans>{' '}
          <MenuPointsBar className="navbar-item__text__points-bar" />
        </>
      ),
      selectionOverrides: { left: '' },
      className: 'menu-rewards',
      id: 'menu-rewards',
      'data-test-id': 'navbar-menu-rewards',
      icon: DiamondIcon,
      to: ROUTES.REWARDS,
      tts: 'LOYALTY_REWARDS',
      featureFlag: 'loyalty',
    },
  ],
  lower: [
    {
      back: BACK_BUTTON_CONFIG.MENU_SETTINGS,
      children: <Trans>SETTINGS</Trans>,
      className: 'menu-settings',
      id: 'menu-settings',
      'data-test-id': 'navbar-menu-settings',
      icon: SettingsIcon,
      selectionOverrides: { down: '', left: '' },
      to: ROUTES.SETTINGS,
      tts: 'SETTINGS',
    },
  ],
};

function Menu({
  children,
  hoverEnabled,
  showModal,
  getSpatialOverridesExceptions,
  spatialBackOverride,
  isRewardsPage,
  isHomePage,
}) {
  const LogoComponent = LogoIcon;

  return (
    <div
      className={classnames('menus-wrapper', {
        'hover-enabled': hoverEnabled,
        'hover-disabled': !hoverEnabled,
      })}
    >
      <Navbar
        className="app-menu"
        openByDefault={false}
        navbarKey={NAVBAR_KEYS.MAIN}
      >
        <div className="app-menu__upper">
          <NavbarLogoContainer className="app-logo">
            <LogoComponent />
          </NavbarLogoContainer>
        </div>
        <div className="app-menu__middle">
          {navbarItems.middle.map((item) => (
            <MenuNavbarItem
              key={item.id}
              item={item}
              getSpatialOverridesExceptions={getSpatialOverridesExceptions}
              spatialBackOverride={spatialBackOverride}
              isRewardsPage={isRewardsPage}
              isHomePage={isHomePage}
              showModal={showModal}
              featureFlag
            />
          ))}
        </div>

        <div className="app-menu__lower">
          {navbarItems.lower.map((item) => (
            <MenuNavbarItem
              key={item.id}
              item={item}
              getSpatialOverridesExceptions={getSpatialOverridesExceptions}
              spatialBackOverride={spatialBackOverride}
              isRewardsPage={isRewardsPage}
              isHomePage={isHomePage}
              showModal={showModal}
              featureFlag
            />
          ))}
        </div>
      </Navbar>
      <div className="backdrop">
        <div className="app-menu__background" />
      </div>
      {children}
    </div>
  );
}

Menu.propTypes = {
  children: PropTypes.array,
  navbarOpenByDefault: PropTypes.bool,
  parentalSettingsEdit: PropTypes.object,
  hoverEnabled: PropTypes.bool,
  showModal: PropTypes.func,
  getSpatialOverridesExceptions: PropTypes.func,
  spatialBackOverride: PropTypes.func,
  isRewardsPage: PropTypes.bool,
  isHomePage: PropTypes.func,
};

export default Menu;

function MenuNavbarItem({
  item,
  getSpatialOverridesExceptions,
  spatialBackOverride,
  isRewardsPage,
  isHomePage,
  showModal,
}) {
  const { tts, className, to, id, featureFlag, ...otherProps } = item;

  if (!!featureFlag && !isFeatureFlagEnabled(featureFlag)) {
    return null;
  }

  return (
    <NavbarItem
      {...otherProps}
      to={to}
      navbarKey={NAVBAR_KEYS.MAIN}
      back={spatialBackOverride(item)}
      selectionOverrides={getSpatialOverridesExceptions(item)}
      aria-label={`${t`MENU`} ${t({ id: tts })}`}
      key={to}
      className={classnames(className, {
        'rewards-item': isRewardsPage && className === 'menu-rewards',
      })}
      onClick={() => {
        mParticle.mParticleMenuClick({
          categoryName: tts,
          categoryPosition: tts,
          pageSection: BROWSE.HOME,
        });
      }}
      onKeyDown={(event) => {
        if (
          isKey(event.keyCode, KEYCODES.BACK) &&
          isHomePage() &&
          id === 'menu-home'
        ) {
          if (supportCapability(PLATFORM_CAPABILITIES.EXIT)) {
            showModal(MODAL_TYPES.EXIT_APP);
          }
        }
      }}
    />
  );
}

MenuNavbarItem.propTypes = {
  item: PropTypes.object,
  getSpatialOverridesExceptions: PropTypes.func,
  spatialBackOverride: PropTypes.func,
  isRewardsPage: PropTypes.bool,
  isHomePage: PropTypes.func,
  showModal: PropTypes.func,
};
