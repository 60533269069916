import 'react-app-polyfill/stable';
import 'whatwg-fetch';
import 'core-js/modules/esnext.string.match-all'; // see https://github.com/facebook/create-react-app/issues/8875
import 'core-js/modules/esnext.string.match-all'; // see https://github.com/facebook/create-react-app/issues/8875
import 'element-scroll-polyfill';
import 'intersection-observer';
import { createRoot } from 'react-dom/client';

// Styles
import './index.scss';

// Config
import APP_CONFIG from './config/app-config.json';

// Crackle SDK
import { init as initSDKv1 } from './crackle-sdk/v1';
import { init as initSDKv2, api } from './crackle-sdk/v2';

// Utils
import reportWebVitals from './utils/reportWebVitals';
import {
  isFeatureFlagEnabled,
  getFeatureFlagValue,
} from './utils/feature-flags';
import {
  getAuthAccessToken,
  getAuthRefreshToken,
  removeAuthAccessToken,
  removeAuthRefreshToken,
  setAuthAccessToken,
  setAuthRefreshToken,
} from './utils/auth-tokens';
import { getSessionHistory } from './utils/session-history';

// Components
import App from './App';

// We need to initialize the SDK before we render the app
// We are using 2 versions of the SDK in this app
let SDKConfigV1 = {
  BRAND: APP_CONFIG.BRAND,
  PLATFORM: APP_CONFIG.PLATFORM,
  ENV: APP_CONFIG.ENV,
  PLATFORM_ID: APP_CONFIG.PLATFORM_ID,
  API_URL_PROD: APP_CONFIG.API_URL_PROD,
  API_URL_STG: APP_CONFIG.API_URL_STG,
  HEARTBEAT_API_URL_PROD: APP_CONFIG.HEARTBEAT_API_URL_PROD,
  HEARTBEAT_API_URL_STG: APP_CONFIG.HEARTBEAT_API_URL_STG,
  logLevel: getFeatureFlagValue('logLevel'),
  getAuthAccessToken: getAuthAccessToken,
  getAuthRefreshToken: getAuthRefreshToken,
  removeAuthAccessToken: removeAuthAccessToken,
  removeAuthRefreshToken: removeAuthRefreshToken,
  setAuthAccessToken: setAuthAccessToken,
  setAuthRefreshToken: setAuthRefreshToken,
};
let SDKFeatureFlagsV1 = {
  testLoyaltyApiMock: isFeatureFlagEnabled('testLoyaltyApiMock'),
  testSponsoredContentMocks: isFeatureFlagEnabled('testSponsoredContentMocks'),
  testWatchlistMocks: isFeatureFlagEnabled('testWatchlistMocks'),
  testHTTP: isFeatureFlagEnabled('testHTTP'),
  testStgApi: isFeatureFlagEnabled('testStgApi'),
  showWhyItCrackles: isFeatureFlagEnabled('showWhyItCrackles'),
};
initSDKv1(SDKConfigV1, SDKFeatureFlagsV1);

let SDKConfigV2 = {
  BRAND: APP_CONFIG.BRAND,
  PLATFORM: APP_CONFIG.PLATFORM,
  ENV: APP_CONFIG.ENV,
  PLATFORM_ID: APP_CONFIG.PLATFORM_ID,
  API_URL_PROD: APP_CONFIG.API_URL_PROD,
  API_URL_STG: APP_CONFIG.API_URL_STG,
  HEARTBEAT_API_URL_PROD: APP_CONFIG.HEARTBEAT_API_URL_PROD,
  HEARTBEAT_API_URL_STG: APP_CONFIG.HEARTBEAT_API_URL_STG,
  logLevel: getFeatureFlagValue('logLevel'),
  getAuthAccessToken: getAuthAccessToken,
  getAuthRefreshToken: getAuthRefreshToken,
  removeAuthAccessToken: removeAuthAccessToken,
  removeAuthRefreshToken: removeAuthRefreshToken,
  setAuthAccessToken: setAuthAccessToken,
  setAuthRefreshToken: setAuthRefreshToken,
  getSessionHistory: getSessionHistory,
};
let SDKFeatureFlagsV2 = {
  testLoyaltyApiMock: isFeatureFlagEnabled('testLoyaltyApiMock'),
  testSponsoredContentMocks: isFeatureFlagEnabled('testSponsoredContentMocks'),
  testWatchlistMocks: isFeatureFlagEnabled('testWatchlistMocks'),
  testHTTP: isFeatureFlagEnabled('testHTTP'),
  testStgApi: isFeatureFlagEnabled('testStgApi'),
  showWhyItCrackles: isFeatureFlagEnabled('showWhyItCrackles'),
};
initSDKv2(SDKConfigV2, SDKFeatureFlagsV2);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <api.ContextProvider>
    <App />
  </api.ContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
