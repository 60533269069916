import { Trans, t } from '@lingui/macro';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Suspense, forwardRef } from 'react';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

// Components
import Button from '../button/button';
import FocusableSection from '../spatial-navigation/focusable-section';

import LoadingSpinner from '../loading-spinner/loading-spinner';

// Configs
import { BACK_BUTTON_CONFIG } from '../../config/back-button-config';

// Enums
import BUTTON_TYPES from '../../enums/button-types';
import ELEMENT_TYPES from '../../enums/element-types';
import PLAY_INITIATION_TYPES from '../../enums/play-initiation-types';

// Icons
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';

// Utils
import { sanitizeSponsoredData, isKey } from '../../utils/utils';
import { scaleFrom720p } from '../../utils/scale-from-720p';
import { KEYCODES } from '../../platform/index';

import mParticle from '../../utils/mparticle';

// Styles
import './hero.scss';
import 'react-circular-progressbar/dist/styles.css';
import { isFeatureFlagEnabled } from '../../utils/feature-flags';
import SponsoredAdView from '../sponsored-ads/sponsored-ads';
import FeatureFlag from '../feature-flag/feature-flag';

const HeroTrailer = React.lazy(() => import('./hero-trailer/hero-trailer'));

const Hero = forwardRef(function Hero(
  {
    data,
    onSelectHeroContent,
    setHeroBannerLoaded,
    showHeroTrailer,
    trailerSource,
    setShowCircleTimer,
    isHeroCollapsed,
    onAnyFocused,
    hoverEnabled,
    onNoneFocused,
    label,
    isBlocked,
    watchNowLink,
    durationProgress,
    showCircleTimer,
    creditMarker,
    durationPercent,
    focusedElementHero,
    setIsWatchNowFocused,
    setPlayInitiationType,
    resetHeroTrailer,
    trailerTimeLeft,
    isWatchNowFocused,
    moreInfoLink,
    creditsMarkFixed,
    heroDownMovement,
  },
  ref
) {
  const {
    backgroundImage: backgroundImgUrl = '',
    description = '',
    genres: tags = [],
    mediaContentId = '',
    trailerContentId = '',
    rating = '',
    title: featuredTitle = '',
    whyItCrackles: contextTitle = '',
  } = data || {};

  function getPlayButtonIcons() {
    if (showCircleTimer) {
      return null;
    }
    if (isBlocked) {
      return LockIcon;
    }

    return PlayIcon;
  }

  function heroBackground() {
    return (
      <>
        <img
          aria-hidden
          src={backgroundImgUrl}
          alt="hero"
          width={Math.round(scaleFrom720p(1244))}
          height={Math.round(scaleFrom720p(700))}
          onLoad={() => setHeroBannerLoaded(true)}
          className="hero__background__image"
        />
        {showHeroTrailer && trailerSource ? (
          <Suspense fallback={<LoadingSpinner />}>
            <HeroTrailer
              trailerContentId={trailerContentId}
              source={trailerSource}
              setShowCircleTimer={setShowCircleTimer}
              creditsMarkFixed={creditsMarkFixed}
            />
          </Suspense>
        ) : null}
      </>
    );
  }

  return (
    <div
      ref={ref}
      className={classnames('hero', {
        collapsed: isHeroCollapsed,
      })}
      onMouseEnter={(e) => {
        if (hoverEnabled) {
          onAnyFocused(e);
        }
      }}
      onMouseLeave={hoverEnabled ? onNoneFocused : null}
    >
      <div className="hero__background">{heroBackground()}</div>
      <div className="hero--background-gradient" />
      <div className="hero__blends-container">
        <div className="hero__blends-container__blue-glow" />
        <div className="hero__blends-container__tan-glow" />
      </div>

      <div className="hero__metadata">
        <FeatureFlag name="sponsorship">
          <SponsoredAdView
            slotParams={{
              slid: '780x72_1_hero',
              hero: sanitizeSponsoredData(featuredTitle),
              caid: mediaContentId.toString(),
              w: '780',
              h: '72',
            }}
          />
        </FeatureFlag>
        <h1
          aria-label={`${t`FEATURED`} ${t`TITLE`}: ${featuredTitle}`}
          className="hero__featured-title"
        >
          {featuredTitle}
        </h1>
        {isFeatureFlagEnabled('showWhyItCrackles') && (
          <h4
            aria-label={`${t`FEATURED`} ${t`SUBTITLE`}: ${contextTitle}`}
            className="hero__context-title"
          >
            {contextTitle}
          </h4>
        )}

        <div className="hero__ratings-tags">
          <div className="hero__ratings-tags--inner-container">
            <p aria-label={`${t`FEATURED`} ${t`RATING`} ${rating}`}>{rating}</p>
            <ul>
              {tags.map((tag) => (
                <li
                  aria-label={`${t`FEATURED`} ${t`GENRE`} ${tag.name}`}
                  key={tag.id}
                >
                  {tag.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="hero__collapsible-container">
          <FocusableSection
            className="hero__buttons"
            onNoneFocused={onNoneFocused}
            onAnyFocused={onAnyFocused}
            leaveForDown={(heroDownMovement && heroDownMovement()) || ''}
            sectionId="hero-focusable"
          >
            <Button
              key="watch-now"
              aria-label={`${t`FEATURED`} ${featuredTitle}, ${label}, ${contextTitle}, ${description}, ${t`RATING`} ${rating}, ${t`GENRE`} ${tags
                .map((tag) => tag?.name)
                .join(', ')}`}
              className="watch-now"
              data-test-id="hero-watch-now"
              elementType={ELEMENT_TYPES.LINK}
              icon={getPlayButtonIcons()}
              isFocusOnSectionEnter
              selectionOverrides={{
                up: '',
                down: (heroDownMovement && heroDownMovement()) || '',
              }}
              to={watchNowLink}
              type={
                durationProgress && !showCircleTimer
                  ? BUTTON_TYPES.PROGRESSBUTTON
                  : BUTTON_TYPES.ICONBUTTON
              }
              progressBar={
                !showCircleTimer &&
                !!durationProgress &&
                durationProgress < creditMarker
              }
              progressPercent={durationPercent}
              onFocus={() => {
                focusedElementHero();
                setIsWatchNowFocused(true);
              }}
              onClick={
                () => {
                  onSelectHeroContent(trailerContentId);
                  setPlayInitiationType(PLAY_INITIATION_TYPES.MANUAL_HERO);
                  mParticle.mParticleButtonClick({
                    buttonLabel: 'Watch Now',
                    buttonPosition: 1,
                    pageSection: 'Featured',
                  });
                }
                // eslint-disable-next-line react/jsx-curly-newline
              }
              onKeyDown={(event) => {
                if (isKey(event.keyCode, KEYCODES.BACK)) {
                  setIsWatchNowFocused(false);
                  resetHeroTrailer();
                  JsSpatialNavigation.focus('.menu-home');
                }
              }}
            >
              {showCircleTimer && !isHeroCollapsed && (
                <div
                  aria-hidden
                  className={classnames('circle__progress', {
                    'circle__progress__secondary-color': !isWatchNowFocused,
                  })}
                >
                  <CircularProgressbarWithChildren
                    value={trailerTimeLeft * 20}
                    counterClockwise
                    strokeWidth={9}
                    styles={{
                      path: {
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                        // Customize transition animation
                        transition: 'stroke-dashoffset 1s linear 0s',
                        // Rotate the path
                        transform: 'rotate(0turn)',
                        transformOrigin: 'center center',
                      },
                      root: {
                        verticalAlign: 'middle',
                      },
                    }}
                  >
                    <span
                      className={classnames('circle__progress__text', {
                        'circle__progress__text__secondary-color':
                          !isWatchNowFocused,
                      })}
                    >
                      {trailerTimeLeft > 4 ? '5' : trailerTimeLeft + 1}
                    </span>
                  </CircularProgressbarWithChildren>
                </div>
              )}
              <div
                aria-hidden
                className={classnames({
                  'circle__progress--padding': showCircleTimer,
                })}
              >
                {label}
              </div>
            </Button>

            <Button
              key="more_info"
              aria-label={`${t`FEATURED`} ${t`MORE_INFO`}`}
              back={BACK_BUTTON_CONFIG.HERO_MORE_INFO}
              elementType={ELEMENT_TYPES.LINK}
              selectionOverrides={{
                right: '',
                up: '',
                down: (heroDownMovement && heroDownMovement()) || '',
              }}
              to={moreInfoLink}
              data-test-id="hero-more-info"
              type={BUTTON_TYPES.TEXTBUTTON}
              onFocus={() => {
                setIsWatchNowFocused(false);
              }}
              onClick={
                () => {
                  onSelectHeroContent(trailerContentId);
                  mParticle.mParticleButtonClick({
                    buttonLabel: 'More Info',
                    buttonPosition: 2,
                    pageSection: 'Featured',
                  });
                }
                // eslint-disable-next-line react/jsx-curly-newline
              }
            >
              <div aria-hidden>
                <Trans>MORE_INFO</Trans>
              </div>
            </Button>
          </FocusableSection>

          <div className="hero__description">
            <p aria-label={`Featured Description: ${description}`}>
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

Hero.propTypes = {
  data: PropTypes.shape({
    backgroundImage: PropTypes.string,
    description: PropTypes.string.isRequired,
    genres: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
    ).isRequired,
    mediaContentId: PropTypes.string,
    rating: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    whyItCrackles: PropTypes.string,
  }),
  onSelectHeroContent: PropTypes.func,
  setHeroBannerLoaded: PropTypes.func,
  showHeroTrailer: PropTypes.bool,
  trailerSource: PropTypes.object,
  setShowCircleTimer: PropTypes.func,
  isHeroCollapsed: PropTypes.bool,
  onAnyFocused: PropTypes.func,
  hoverEnabled: PropTypes.bool,
  onNoneFocused: PropTypes.func,
  label: PropTypes.string,
  getPlayButtonIcons: PropTypes.func,
  watchNowLink: PropTypes.string,
  durationProgress: PropTypes.number,
  showCircleTimer: PropTypes.bool,
  creditMarker: PropTypes.number,
  durationPercent: PropTypes.number,
  focusedElementHero: PropTypes.func,
  setIsWatchNowFocused: PropTypes.func,
  setPlayInitiationType: PropTypes.func,
  resetHeroTrailer: PropTypes.func,
  trailerTimeLeft: PropTypes.number,
  isWatchNowFocused: PropTypes.bool,
  moreInfoLink: PropTypes.string,
  creditsMarkFixed: PropTypes.number,
  isBlocked: PropTypes.bool,
  heroDownMovement: PropTypes.func,
};

export default Hero;
