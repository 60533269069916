import PropTypes from 'prop-types';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';
import { t } from '@lingui/macro';
import { isKey } from '../../utils/utils';

// Components
import FocusableSection from '../spatial-navigation/focusable-section';
import Focusable from '../spatial-navigation/focusable';

// Enums
import ELEMENT_TYPES from '../../enums/element-types';
import { KEYCODES } from '../../platform/index';

// Icons
import { ReactComponent as BackspaceIcon } from '../../assets/icons/backspace.svg';

// Styles
import './number-strip.scss';

function NumberStrip({
  onEnterCharHandler,
  onRemoveCharHandler,
  abcBtn,
  spaceBtn,
  onToggleStripVisibilityHandler,
  onAnyCharFocused,
  defaultSectionTitle,
  isFirstFocused,
  setIsFirstFocused,
  focusableBack,
  selectionOverridesNumbers,
  selectionOverridesAbcButton,
  selectionOverridesSpaceButton,
  selectionOverridesBackspaceButton,
  sectionLeave,
  ...props
}) {
  const numbersArray = [...Array(10).keys()];

  return (
    <FocusableSection
      className="number-strip"
      sectionId="number-strip-focusable"
      enterTo="default-element"
      {...sectionLeave}
      onAnyFocused={onAnyCharFocused}
    >
      {abcBtn && (
        <Focusable
          aria-label={t`LETTERS`}
          className="number-strip__abc-btn bordered-button abc-btn"
          elementType={ELEMENT_TYPES.BUTTON}
          onClick={onToggleStripVisibilityHandler}
          onKeyDown={(event) => {
            if (isKey(event.keyCode, KEYCODES.ENTER_CHAR)) {
              onToggleStripVisibilityHandler();
            }
            if (isKey(event.keyCode, KEYCODES.BACK)) {
              JsSpatialNavigation.focus('#number-strip-0');
            }
          }}
          selectionOverrides={selectionOverridesAbcButton}
          {...props}
        >
          <span aria-hidden>ABC</span>
        </Focusable>
      )}
      {spaceBtn && (
        <Focusable
          aria-label={t`SPACE`}
          className="number-strip__space-btn bordered-button"
          elementType={ELEMENT_TYPES.BUTTON}
          onClick={() => onEnterCharHandler(' ')}
          onKeyDown={(event) => {
            if (isKey(event.keyCode, KEYCODES.ENTER_CHAR)) {
              onEnterCharHandler(' ');
            }
            if (isKey(event.keyCode, KEYCODES.BACK)) {
              JsSpatialNavigation.focus('#number-strip-0');
            }
          }}
          selectionOverrides={selectionOverridesSpaceButton}
          {...props}
        >
          <span aria-hidden>SPACE</span>
        </Focusable>
      )}
      {numbersArray.map((number, index) => (
        <Focusable
          back={index === 0 ? focusableBack : null}
          aria-label={
            isFirstFocused
              ? `${t({ id: defaultSectionTitle })}. ${number}`
              : number
          }
          key={number.toString()}
          id={`number-strip-${number}`}
          className="number-strip__numbers"
          elementType={ELEMENT_TYPES.BUTTON}
          isFocusOnPageLoad={index === 1}
          isFocusOnSectionEnter={index === 1}
          onClick={() => onEnterCharHandler(number)}
          onKeyDown={(event) => {
            if (isKey(event.keyCode, KEYCODES.ENTER_CHAR)) {
              onEnterCharHandler(number);
            }

            if (isKey(event.keyCode, KEYCODES.BACK)) {
              JsSpatialNavigation.focus('#number-strip-0');
            }
          }}
          onBlur={() => setIsFirstFocused && setIsFirstFocused(false)}
          selectionOverrides={selectionOverridesNumbers}
          data-test={`key-number-${number}`}
          {...props}
        >
          <span aria-hidden>{number}</span>
        </Focusable>
      ))}
      <Focusable
        aria-label={t`CLEAR`}
        className="number-strip__backspace-icon"
        elementType={ELEMENT_TYPES.BUTTON}
        onClick={onRemoveCharHandler}
        onKeyDown={(event) => {
          if (isKey(event.keyCode, KEYCODES.ENTER_CHAR)) {
            onRemoveCharHandler();
          }
          if (isKey(event.keyCode, KEYCODES.BACK)) {
            JsSpatialNavigation.focus('#number-strip-0');
          }
        }}
        selectionOverrides={selectionOverridesBackspaceButton}
        {...props}
      >
        <BackspaceIcon />
      </Focusable>
    </FocusableSection>
  );
}

NumberStrip.propTypes = {
  onRemoveCharHandler: PropTypes.func,
  onAnyCharFocused: PropTypes.func,
  initialLabel: PropTypes.string,
  onEnterCharHandler: PropTypes.func,
  abcBtn: PropTypes.bool,
  spaceBtn: PropTypes.bool,
  onToggleStripVisibilityHandler: PropTypes.func,
  defaultSectionTitle: PropTypes.string,
  isFirstFocused: PropTypes.bool,
  setIsFirstFocused: PropTypes.func,
  focusableBack: PropTypes.string,
  selectionOverridesNumbers: PropTypes.objectOf(PropTypes.any),
  selectionOverridesAbcButton: PropTypes.objectOf(PropTypes.any),
  selectionOverridesSpaceButton: PropTypes.objectOf(PropTypes.any),
  selectionOverridesBackspaceButton: PropTypes.objectOf(PropTypes.any),
  sectionLeave: PropTypes.objectOf(PropTypes.string),
};

NumberStrip.defaultProps = {
  defaultSectionTitle: '',
  isFirstFocused: false,
  setIsFirstFocused: null,
};

export default NumberStrip;
