import { useMatch } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// Components
import Focusable from '../spatial-navigation/focusable';

// Styles
import './navbar-item.scss';

// Hooks
import useNavbar from '../../hooks/use-navbar';

// Enums
import ELEMENT_TYPES from '../../enums/element-types';

function NavbarItem({
  children,
  className = '',
  icon: IconComponent = null,
  isActiveOverride = null,
  to,
  navbarKey,
  openByDefault = false,
  ...props
}) {
  const { isOpen } = useNavbar(openByDefault, navbarKey);
  const routeMatch = !!useMatch({
    path: to,
    exact: true,
  });

  const isActiveRoute =
    isActiveOverride !== null ? isActiveOverride : routeMatch;

  return (
    <Focusable
      elementType={ELEMENT_TYPES.LINK}
      className={classnames(
        'navbar-item',
        className,
        { open: isOpen },
        { 'active-route': isActiveRoute }
      )}
      isFocusOnSectionEnter={isActiveRoute}
      to={to}
      {...props}
    >
      {IconComponent && (
        <div className="navbar-item__icon-wrapper">
          <IconComponent />
        </div>
      )}
      <span aria-hidden className="navbar-item__text">
        {children}
      </span>
    </Focusable>
  );
}

NavbarItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.elementType,
  isActiveOverride: PropTypes.bool,
  to: PropTypes.string.isRequired,
  navbarKey: PropTypes.string,
  openByDefault: PropTypes.bool,
};

export default NavbarItem;
