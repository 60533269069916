import { useLocation } from 'react-router-dom';
import { isFeatureFlagEnabled } from '../../utils/feature-flags';
import MenuView from '../../components/menu/menu';

// Enums
import ROUTES from '../../enums/routes';
import SEARCH_ELEMENTS from '../../enums/search-elements';

// Configs
import { BACK_BUTTON_CONFIG } from '../../config/back-button-config';

// context
import useModalHandler from '../../hooks/use-modal-handler';

// Contexts
import useGlobalContext from '../../hooks/use-global-context';
import GenreSubMenuContainer from '../genre-submenu/genre-submenu-container';
import SettingsSubMenu from '../../components/submenu/settings-submenu';

function MenuContainer() {
  const parentalSettingsEdit = useGlobalContext(
    (state) => state.parentalSettingsEdit
  );
  const hoverEnabled = useGlobalContext((state) => state.hoverEnabled);
  const { pathname } = useLocation();
  const { showModal } = useModalHandler();

  const isSettingsPage = pathname === ROUTES.SETTINGS;
  const isRewardsPage = pathname === ROUTES.REWARDS;
  const isBrowsePage =
    pathname === ROUTES.MOVIES || pathname === ROUTES.TVSHOWS;

  const isHomePage = () => {
    // in case AUTH_SPLASH_SCREEN is disable both path "/" and "/home" will point to home screen
    const homePageRoutes = isFeatureFlagEnabled('authSplashScreen')
      ? [ROUTES.HOME]
      : [ROUTES.HOME, ROUTES.LANDING];

    return homePageRoutes.includes(pathname);
  };

  const overrideNavigationRoutes = {
    [ROUTES.HOME]: {
      right: '.focusedElement',
    },
    [ROUTES.LANDING]: {
      right: '.focusedElement',
    },
    [ROUTES.SETTINGS]: {
      right: '@navbar-app-submenu__settings',
    },
    [ROUTES.SEARCH]: {
      right: SEARCH_ELEMENTS.BUTTON.KEYBOARD_KEY,
    },
    [ROUTES.WATCHLIST]: {
      right: '@watchlist-focusable',
    },
    [ROUTES.REWARDS]: {
      right: '@rewards-focusable',
    },
  };

  const getSpatialOverridesExceptions = (item) => {
    const currentPathHasOverrides = overrideNavigationRoutes[pathname];
    if (currentPathHasOverrides) {
      return {
        ...item.selectionOverrides,
        ...overrideNavigationRoutes[pathname],
      };
    }

    return item.selectionOverrides;
  };

  const spatialBackOverride = (item) => {
    if (item.id === 'menu-home') {
      return null;
    }

    if (isSettingsPage) {
      // When editing settings after coming in from the `content locked` modal, navigate back to the
      // content details screen that we came from
      return parentalSettingsEdit && item.className === 'menu-settings'
        ? BACK_BUTTON_CONFIG.CONTENT_DETAILS
        : item.back;
    }

    return item.back;
  };

  return (
    <MenuView
      hoverEnabled={hoverEnabled}
      showModal={showModal}
      spatialBackOverride={spatialBackOverride}
      getSpatialOverridesExceptions={getSpatialOverridesExceptions}
      isRewardsPage={isRewardsPage}
      isHomePage={isHomePage}
    >
      {isBrowsePage && <GenreSubMenuContainer />}
      {isSettingsPage && <SettingsSubMenu />}
    </MenuView>
  );
}

export default MenuContainer;
