import classnames from 'classnames';

// Modals
import WatchlistOverlaySeries from '../../components/watchlist-overlay/watchlist-overlay-series';
import WatchlistOverlay from '../../components/watchlist-overlay/watchlist-overlay';
import RewardsOverlay from '../../components/rewards-overlay/rewards-overlay';
import RewardsAuthOverlay from '../../components/rewards-overlay/rewards-auth-overlay';
import AuthOverlay from '../../components/auth-overlay/auth-overlay';
import ErrorOverlay from '../../components/error-overlay/error-overlay';
import PinModal from '../pin-modal/pin-modal';
import ExitScreen from '../exit/exit';
import StillWatching from '../../components/still-watching-overlay/still-watching-overlay';
import GetYourWatchlistOverlay from '../../components/watchlist-overlay/get-your-watchlist-overlay';
import SortOverlay from '../../components/sort-overlay/sort-overlay';

// enums
import MODAL_TYPES from '../../enums/modal-types';

// hooks
import useModalHandler from '../../hooks/use-modal-handler';
// contexts
import useGlobalContext from '../../hooks/use-global-context';

// Styles
import './modal.scss';
import GetYourWatchlist from '../get-your-watchlist/get-your-watchlist';

function ModalContainer() {
  const { modal } = useModalHandler();
  const hoverEnabled = useGlobalContext((state) => state.hoverEnabled);

  function getModalComponent() {
    let modalComponent = null;
    switch (modal.type) {
      case MODAL_TYPES.WATCHLIST_ADD_SERIES:
        modalComponent = <WatchlistOverlaySeries {...modal.props} />;
        break;
      case MODAL_TYPES.WATCHLIST_CONTENT_CLICK:
        modalComponent = <WatchlistOverlay {...modal.props} />;
        break;
      case MODAL_TYPES.WATCHLIST_AUTH:
        modalComponent = <GetYourWatchlist />;
        break;
      case MODAL_TYPES.GET_YOUR_WATCHLIST:
        modalComponent = <GetYourWatchlistOverlay />;
        break;
      case MODAL_TYPES.REWARDS_AUTH:
        modalComponent = <RewardsAuthOverlay {...modal.props} />;
        break;
      case MODAL_TYPES.AUTH:
        modalComponent = <AuthOverlay {...modal.props} />;
        break;
      case MODAL_TYPES.REWARDS_REDEEM:
        modalComponent = <RewardsOverlay {...modal.props} />;
        break;
      case MODAL_TYPES.PIN_MODAL:
        modalComponent = <PinModal {...modal.props} />;
        break;
      case MODAL_TYPES.SORT:
        modalComponent = <SortOverlay {...modal.props} />;
        break;
      case MODAL_TYPES.ERROR:
        modalComponent = <ErrorOverlay {...modal.props} />;
        break;
      case MODAL_TYPES.EXIT_APP:
        modalComponent = <ExitScreen />;
        break;
      case MODAL_TYPES.STILL_WATCHING:
        modalComponent = <StillWatching />;
        break;
      default:
        modalComponent = null;
    }

    return modalComponent;
  }

  return (
    <div
      className={classnames('modal-container', {
        visible: modal !== null,
        'hover-enabled': hoverEnabled,
        'hover-disabled': !hoverEnabled,
      })}
    >
      {modal && getModalComponent()}
    </div>
  );
}

export default ModalContainer;
