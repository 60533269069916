/* eslint-disable no-underscore-dangle */
import Content from './content';

class Episode extends Content {
  constructor({
    seasonNumber,
    episodeNumber,
    captions,
    images,
    videos,
    duration,
    creditsMark,
    seriesId,
    ...contentParams
  }) {
    super(contentParams);

    this._seriesId = seriesId;
    this._duration = duration;
    this._season = seasonNumber;
    this._episode = episodeNumber;
    this._creditsMark = creditsMark;
    this._captions = [];
    this._images = [];
    this._videos = [];

    images.forEach((image) => {
      this.addImage(image);
    });

    videos.forEach((video) => {
      this.addVideo(video);
    });

    captions.forEach((caption) => {
      this.addCaption(caption);
    });
  }

  // getters and setters
  get seriesId() {
    return this._seriesId;
  }

  set seriesId(seriesId) {
    this._seriesId = seriesId;
  }

  get duration() {
    return this._duration;
  }

  set duration(duration) {
    this._duration = duration;
  }

  get season() {
    return this._season;
  }

  set season(season) {
    this._season = season;
  }

  get episode() {
    return this._episode;
  }

  set episode(episode) {
    this._episode = episode;
  }

  get captions() {
    return this._captions;
  }

  addCaption(caption) {
    // check if caption already exists in the array by locale
    const captionExists = !!this._captions.some(
      (captionItem) => captionItem.locale === caption.locale
    );

    if (!captionExists) {
      this._captions.push(caption);
    }
  }

  removeCaption(locale) {
    const captions = this._captions.filter(
      (caption) => caption.locale === locale
    );

    this._captions = captions;
  }

  removeCaptions() {
    this._captions = [];
  }

  get images() {
    return this._images;
  }

  addImage(image) {
    this._images.push(image);
  }

  removeImage(imageId) {
    const images = this._images.filter((image) => image.id === imageId);

    this._images = images;
  }

  removeImages() {
    this._images = [];
  }

  get videos() {
    return this._videos;
  }

  addVideo(video) {
    this._videos.push(video);
  }

  removeVideo(videoId) {
    const videos = this._videos.filter((video) => video.id === videoId);

    this._videos = videos;
  }

  removeVideos() {
    this._videos = [];
  }

  get creditsMark() {
    return this._creditsMark;
  }

  set creditsMark(creditsMark) {
    this._creditsMark = creditsMark;
  }
}

export default Episode;
