/* eslint-disable no-underscore-dangle */

class Stream {
  constructor({ type, url, drm }) {
    this._type = type;
    this._url = url;
    this._drm = drm;
  }

  // getters and setters
  get drm() {
    return this._drm;
  }

  set drm(drm) {
    this._drm = drm;
  }

  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
  }

  get url() {
    return this._url;
  }

  set url(url) {
    this._url = url;
  }
}

export default Stream;
