/* eslint-disable no-underscore-dangle */

class CaptionAsset {
  constructor({ locale, language, label }) {
    this._locale = locale;
    this._language = language;
    this._label = label;
  }

  get locale() {
    return this._locale;
  }

  set locale(locale) {
    this._locale = locale;
  }

  get language() {
    return this._language;
  }

  set language(language) {
    this._language = language;
  }

  get label() {
    return this._label;
  }

  set label(label) {
    this._label = label;
  }
}

export default CaptionAsset;
