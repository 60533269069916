import { QueryClient, QueryClientProvider } from 'react-query';
import PropTypes from 'prop-types';
import logger from '../../utils/logger';

function ContextProvider({ children }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // https://www.codemzy.com/blog/react-query-cachetime-staletime
        cacheTime: 1000 * 60 * 60 * 1 + 5 * 60 * 1000, // 1h + 5m
        staleTime: 1000 * 60 * 60 * 1, // 1 hour
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchIntervalInBackground: false,
        useErrorBoundary: false,
        suspense: false,
      },
    },
    logger,
  });
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
ContextProvider.displayName = 'SDK V2 ContextProvider';
ContextProvider.propTypes = {
  children: PropTypes.node,
};

export { ContextProvider };
