import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Hooks
import { useInView } from 'react-intersection-observer';

// utils
import { isFeatureFlagEnabled } from '../../utils/feature-flags';

// Components
import LoadingSpinner from '../loading-spinner/loading-spinner';

// API
import { api } from '../../crackle-sdk/v2';

// Enums
import CONTENT_ROW_TYPES from '../../enums/content-row-types';

// Styles
import './lazy-loader.scss';

function LazyLoader({
  children,
  options,
  rowUrl,
  forceLoad,
  rowIndex,
  showSeeAll,
  rowId,
  rowType,
  rowContentId,
  updateFailedRowsIndexes,
}) {
  const [setRef, inView] = useInView(options);

  const { data: curation, isError } = api.useContentRow({
    playlistUrl: rowUrl,
    rowType,
    queryOptions: { enabled: inView || forceLoad },
  });

  const seeAll =
    showSeeAll &&
    rowType === CONTENT_ROW_TYPES.STANDARD &&
    showSeeAll(curation?.pagination?.totalItems, curation?.items?.length);

  useEffect(() => {
    if (isError || curation?.items?.length === 0) {
      updateFailedRowsIndexes && updateFailedRowsIndexes(rowIndex);
    }
  }, [updateFailedRowsIndexes, rowIndex, isError, curation?.items?.length]);

  if (isError || curation?.items?.length === 0) {
    return <></>;
  }

  return (
    <div ref={setRef} id={`row-${rowId}`}>
      {curation?.items?.length > 0 && (inView || forceLoad) ? (
        React.cloneElement(children, {
          curation: curation?.items,
          seeAll,
          curationLength:
            isFeatureFlagEnabled('sponsoredContent') && seeAll
              ? curation?.items?.length + 1
              : curation?.items?.length || 0,
          rowContentId,
        })
      ) : (
        <div
          className={classnames('lazy-loader', {
            'originals-row':
              children.props.type === CONTENT_ROW_TYPES.ORIGINALS,
            'standard-row': children.props.type === CONTENT_ROW_TYPES.STANDARD,
            'details-row': children.props.type === CONTENT_ROW_TYPES.DETAILS,
          })}
        >
          <div className="lazy-loader__content-row__title">
            {children.props.title}
          </div>
          <div className="content-row__carousel__spinner">
            <LoadingSpinner />
          </div>
        </div>
      )}
    </div>
  );
}

LazyLoader.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.shape({
    root: PropTypes.objectOf(PropTypes.any),
    rootMargin: PropTypes.string,
    threshold: PropTypes.number,
  }),
  rowUrl: PropTypes.string,
  forceLoad: PropTypes.bool,
  rowId: PropTypes.number,
  rowType: PropTypes.string,
  rowContentId: PropTypes.string,
  showSeeAll: PropTypes.func,
  rowIndex: PropTypes.number,
  updateFailedRowsIndexes: PropTypes.func,
};

export default LazyLoader;
