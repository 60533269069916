import PLAYER_MODULES from '../enums/player-modules';
import { isFeatureFlagEnabled } from '../utils/feature-flags';
import { getDeviceInformationForWeb } from '../utils/utils';
import APP_CONFIG from '../config/app-config.json';
import { SAMPLE_NON_DRM_DASH_URL } from '../crackle-sdk/v1/api/common/vars';
import PLAYER_AD_BREAK_INSERT_TYPES from '../enums/player-ad-break-insert-types';
import PLATFORM_CAPABILITIES from '../enums/platform-capabilities';
import {
  initializeAdConfiguration,
  getAdId,
  getLat,
} from '../utils/ad-personalization-management';

const KEYCODES = {
  BACK: [8, 461],
  DOWN: 40,
  ENTER: 13,
  SPACE: 32,
  EXIT: 27,
  LEFT: 37,
  MEDIA_PAUSE: 19,
  MEDIA_FAST_FORWARD: 417,
  MEDIA_PLAY: 415,
  MEDIA_PLAY_PAUSE: null,
  MEDIA_REWIND: 412,
  MEDIA_STOP: 413,
  MEDIA_NEXT: 418,
  MEDIA_PREVIOUS: 419,
  RIGHT: 39,
  UP: 38,
  CC: null,
  SEARCH: null,
  DELETE_CHAR: null,
  ENTER_CHAR: null,
  NAV_RIGHT: null,
  NAV_LEFT: null,
  CHANNEL_UP: 33,
  CHANNEL_DOWN: 34,
  NUMBER_0: 48,
  NUMBER_1: 49,
  NUMBER_2: 50,
  NUMBER_3: 51,
  NUMBER_4: 52,
  NUMBER_5: 53,
  NUMBER_6: 54,
  NUMBER_7: 55,
  NUMBER_8: 56,
  NUMBER_9: 57,
};

const deviceData = {
  deviceModel: null,
  deviceId: null,
  osVersion: null,
  countryCode: 'us',
};

const platformCapabilities = {
  // limit ad tracking
  [PLATFORM_CAPABILITIES.LIMIT_AD_TRACKING]: false,
  [PLATFORM_CAPABILITIES.CCPA]: false,
  [PLATFORM_CAPABILITIES.VIZBEE]: true,
  // Does the platform support automatic arial label text-to-speech
  [PLATFORM_CAPABILITIES.ARIA_LABEL]: false,
  // Does the platform supports exiting the app?
  [PLATFORM_CAPABILITIES.EXIT]: true,
  // Does the platform has a specific CC button in the remote? If not, we will use the CC button in the player
  [PLATFORM_CAPABILITIES.CC_BUTTON]: false,
  // Does the platform has a specific exit button in the remote and allow to override the default exit behavior?
  [PLATFORM_CAPABILITIES.EXIT_BUTTON]: false,
  // Does the platform support iframe srcdoc attribute for sponsors?
  [PLATFORM_CAPABILITIES.IFRAME_SRC_DOC]: true,
  // Does the platform have platformSessionId?
  [PLATFORM_CAPABILITIES.PLATFORM_SESSION_ID]: false,
  // Does the platform need to announce the end of grids or not possible navigation?
  [PLATFORM_CAPABILITIES.TTS_NAVIGATION_LIMIT_NEEDED]: false,
};

const supportedAdInsertionMode = [
  PLAYER_AD_BREAK_INSERT_TYPES.CSAI,
  PLAYER_AD_BREAK_INSERT_TYPES.CSAIV2,
  PLAYER_AD_BREAK_INSERT_TYPES.SSAI,
];

const initializeDevice = (onLoad, onResume) => {
  // if the platform does not support LAT at the OS level we initialize the ad configuration
  if (!platformCapabilities.lat) {
    initializeAdConfiguration();
  }

  if (isTv()) {
    window.webOS.deviceInfo((info) => {
      deviceData.deviceModel = info.modelName;
      deviceData.osVersion = info.sdkVersion;

      window?.webOSDev?.LGUDID({
        onSuccess: (response) => {
          deviceData.deviceId = response.id;
          setCountrCode(onLoad);
        },
        onFailure: () => {
          setCountrCode(onLoad);
        },
      });
    });

    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        // Trigger a platform launch on resume app
        if (onResume && isTv()) {
          onResume();
        }
      }
    });
  } else {
    onLoad();
  }
};

const getDeviceId = (callback) => {
  callback(deviceData.deviceId);
};

const getDeviceConnectionType = async () => {
  const { type } = await getNetworkInformation();

  return type;
};

const isTv = () => {
  return window?.webOS?.platform?.tv || false;
};

const isLowEndDevice = () => {
  return false;
};

const relaunchApplication = () => {
  window?.location.reload();
};

const setClosedCaptionCallback = () => void 0;

const setClosedCaptionStyleCallback = () => void 0;

const exitApplication = () => {
  window.close();
};

const supportCapability = (capability) => {
  return platformCapabilities[capability];
};

const getAdvertisementIdentifier = (callback) => {
  callback(getAdId());
};

const getLimitAdTracking = (callback) => {
  callback(getLat());
};

const getAdsType = (callback) => {
  callback(null);
};

// Text-to-speech function
const tts = (text) => {
  window.webOS.service.request('luna://com.webos.service.tts', {
    method: 'speak',
    parameters: { text, clear: true },
    onSuccess: () => void 0,
    onFailure: () => void 0,
  });
};

/**
 * Get the Device's Model
 * @returns {String |null}
 */
const getDeviceModel = () => {
  return deviceData.deviceModel;
};

/**
 * Get device info from TV
 * @returns promise
 */
const getDeviceInformation = () => {
  let deviceInfo = {
    duid: null,
    modelName: getDeviceModel(),
  };

  return new Promise((resolve) => {
    if (isTv()) {
      window.webOS.deviceInfo((info) => {
        deviceInfo.modelName = info.modelName;
      });

      getDeviceId((id) => {
        deviceInfo = { ...deviceInfo, duid: id };
      });
    } else {
      deviceInfo = getDeviceInformationForWeb();
    }

    resolve(deviceInfo);
  });
};

const getPlatformSessionId = () => null;

/**
 * Get network info from TV
 * @returns promise
 */
const getNetworkInformation = () => {
  return new Promise((resolve) => {
    window?.webOSDev?.connection.getStatus({
      onSuccess: (res) => {
        let ip = null;
        let type = null;
        if (res.wired) {
          ip = res.wired.ipAddress;
          type = 'Ethernet';
        } else if (res.wifi) {
          ip = res.wifi.ipAddress;
          type = 'WiFi';
        }

        resolve({ ip, type });
      },
      onFailure: () => {
        // API calling error
        resolve({ ip: null, type: null });
      },
      subscribe: true,
    });
  });
};

/**
 * Get platform device name
 * @returns {String}
 */
const getPlatformDeviceName = () => {
  return `${APP_CONFIG.PLATFORM} (${getDeviceModel()})`;
};

/**
 * gets deeplink path
 * @returns {String}
 */
const getDeeplinkPath = () => {
  if (isFeatureFlagEnabled('testForceDeeplink')) {
    return '/watch/545158AE-AD7D-4325-980F-93421E200D5F?cmpid=Christmas2021';
  }

  const regexp =
    /\/((.*)\/)*watch\/([^?]+)(.*)?|\/((.*)\/)*sponsored_collections\/([^?]+)(.*)?/;

  if (document.location.pathname.match(regexp) !== null) {
    return document.location.pathname + document.location.search;
  }

  return '';
};

const getDeviceOS = () => 'webOS';

const getDeviceOsVersion = () => deviceData.osVersion;

const getDeviceMaker = () => 'LG';

const setCountrCode = (callback) => {
  window.webOS.service.request('luna://com.webos.settingsservice', {
    method: 'getSystemSettings',
    parameters: {
      category: 'option',
      keys: ['smartServiceCountryCode2'],
    },
    onSuccess: (response) => {
      deviceData.countryCode =
        response?.settings?.smartServiceCountryCode2 || 'us';
      callback();
    },
    onFailure: () => {
      callback();
    },
  });
};

function getTTSCancelationHTML() {
  return ``;
}

const getStreamSource = (streams) => {
  const dashUrl =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url || null;
  const drm = {
    widevine: {
      LA_URL:
        streams?.dash_widevine?.drm?.keyUrl ||
        streams?.['dash-widevine']?.drm?.keyUrl ||
        null,
    },
  };
  const sourceStreamType =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url
      ? 'dash_widevine'
      : null;

  return {
    dash: isFeatureFlagEnabled('testNoDRMSampleVideo')
      ? SAMPLE_NON_DRM_DASH_URL
      : dashUrl,
    sourceStreamType,
    drm,
  };
};

const getPlayerConfig = () => {
  return {};
};

const getKeyHintIcon = () => {
  return null;
};

const showKeyHints = () => {
  return false;
};

const getPlatformSpecificModules = () => {
  return [PLAYER_MODULES.WEBOS];
};

const getAdParams = (callback) => {
  callback({
    lat: getLat(),
    ifa: getAdId(),
    ifaType: null,
    ccpa: null,
  });
};

const isAdInsertionModeSupported = (mode) =>
  supportedAdInsertionMode.includes(mode);

export {
  getDeviceId,
  getDeviceModel,
  initializeDevice,
  isTv,
  isLowEndDevice,
  relaunchApplication,
  setClosedCaptionCallback,
  setClosedCaptionStyleCallback,
  getDeviceInformation,
  getPlatformSessionId,
  getNetworkInformation,
  exitApplication,
  getPlatformDeviceName,
  getDeeplinkPath,
  getDeviceOS,
  getDeviceOsVersion,
  getDeviceMaker,
  tts,
  getTTSCancelationHTML,
  KEYCODES,
  getDeviceConnectionType,
  getStreamSource,
  getAdvertisementIdentifier,
  getLimitAdTracking,
  getAdsType,
  getPlayerConfig,
  getKeyHintIcon,
  showKeyHints,
  getPlatformSpecificModules,
  getAdParams,
  supportCapability,
  isAdInsertionModeSupported,
};
