/* eslint-disable no-underscore-dangle */
class Rating {
  constructor({ id, region, name, age }) {
    this._id = id;
    this._region = region;
    this._name = name;
    this._age = age;
  }

  // getters and setters
  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get region() {
    return this._region;
  }

  set region(region) {
    this._region = region;
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
  }

  get age() {
    return this._age;
  }

  set age(age) {
    this._age = age;
  }
}

export default Rating;
