import { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';
import FocusableSection from '../spatial-navigation/focusable-section';

// Hooks
import useToast from '../../hooks/use-toast';
import useModalHandler from '../../hooks/use-modal-handler';

// Icons
import { ReactComponent as QuestionOverlayIcon } from '../../assets/icons/question-overlay.svg';

// Styles
import './watchlist-overlay.scss';

// utils
import { isKey } from '../../utils/utils';
import { getFallbackPoster } from '../../utils/content-images';
import { KEYCODES } from '../../platform/index';
import { BACK_BUTTON_CONFIG } from '../../config/back-button-config';

// enums
import TOAST_TYPES from '../../enums/toast-types';
import MODAL_EXIT_STATUS from '../../enums/modal-exit-status';
import CONTENT_ROW_TYPES from '../../enums/content-row-types';

// components
import WatchlistButtonContainer from '../../containers/watchlist-button/watchlist-button-container';

function WatchlistOverlaySeries({
  poster,
  season,
  episode,
  seriesId,
  episodeId,
  seriesTitle,
  episodeTitle,
  onContentAdded,
  onContentRemoved,
}) {
  const { showToast } = useToast();

  const { closeModal } = useModalHandler();

  const posterType = CONTENT_ROW_TYPES.STANDARD;
  const fallBackImage = useMemo(
    () => getFallbackPoster(posterType),
    [posterType]
  );

  const closeOverlay = useCallback(() => {
    closeModal(MODAL_EXIT_STATUS.WATCHLIST_ADD_SERIES_OVERLAY);
  }, [closeModal]);

  useEffect(() => {
    JsSpatialNavigation.focus('@watchlist_overlay_series_focusable');
  }, []);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (isKey(event.keyCode, KEYCODES.BACK)) {
        closeOverlay();
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [closeOverlay]);

  const handleSeriesAddedToWatchList = () => {
    showToast(TOAST_TYPES.WATCHLIST, {
      isInWatchlist: true,
      contentTitle: seriesTitle,
    });

    onContentAdded && onContentAdded(true);

    closeOverlay();
  };

  const handleSeriesRemovedFromWatchList = () => {
    showToast(TOAST_TYPES.WATCHLIST, {
      isInWatchlist: false,
      contentTitle: seriesTitle,
    });

    onContentRemoved && onContentRemoved(true);

    closeOverlay();
  };

  const handleEpisodeAddedToWatchList = () => {
    showToast(TOAST_TYPES.WATCHLIST, {
      isInWatchlist: true,
      contentTitle: episodeTitle,
    });

    onContentAdded && onContentAdded(false);

    closeOverlay();
  };

  const handleEpisodeRemovedFromWatchList = () => {
    showToast(TOAST_TYPES.WATCHLIST, {
      isInWatchlist: false,
      contentTitle: episodeTitle,
    });

    onContentRemoved && onContentRemoved(false);

    closeOverlay();
  };

  return (
    <div className="watchlist-overlay">
      <div>
        <div className="content-icon">
          <QuestionOverlayIcon />
        </div>
        <div className="content-text">
          <p>
            <Trans id="SERIES_ADD_TO_WATCHLIST_1" /> <br />
            <span>
              <Trans id="SERIES_ADD_TO_WATCHLIST_2" />
            </span>
          </p>
        </div>
        <div className="content-poster">
          <picture alt={seriesTitle}>
            <source
              type="image/webp"
              srcSet={poster || `${fallBackImage.image}.webp`}
            />
            <img
              aria-hidden
              src={poster || `${fallBackImage.image}.png`}
              alt={seriesTitle}
              width={Math.round(fallBackImage.width)}
              height={Math.round(fallBackImage.height)}
              onError={(e) => {
                e.target.src = `${fallBackImage.image}.webp`;
              }}
            />
          </picture>
          <div className="data">
            <Trans id="SEASON_EPISODE" values={{ season, episode }} />
          </div>
        </div>
        <div className="content-buttons">
          <FocusableSection
            sectionId="watchlist_overlay_series_focusable"
            leaveForUp=""
            leaveForRight=""
            leaveForDown=""
            leaveForLeft=""
          >
            <WatchlistButtonContainer
              back={BACK_BUTTON_CONFIG.CLOSE_WATCHLIST_MODAL}
              id="add_serie_button"
              isFocusOnSectionEnter
              className="initialFocus"
              inWatchlistLabel={t`WATCHLIST_FULL_SERIES_IN_WATCHLIST`}
              notInWatchlistLabel={t`WATCHLIST_ADD_FULL_SERIES`}
              contentId={seriesId}
              onContentAdded={handleSeriesAddedToWatchList}
              onContentRemoved={handleSeriesRemovedFromWatchList}
              buttonPosition={1}
              pageSection={'Content Detail'}
            />
            <WatchlistButtonContainer
              back={BACK_BUTTON_CONFIG.CLOSE_WATCHLIST_MODAL}
              inWatchlistLabel={t`WATCHLIST_EPISODE_IN_WATCHLIST`}
              notInWatchlistLabel={t`WATCHLIST_ADD_EPISODE`}
              contentId={episodeId}
              onContentAdded={handleEpisodeAddedToWatchList}
              onContentRemoved={handleEpisodeRemovedFromWatchList}
              buttonPosition={2}
              pageSection={'Content Detail'}
            />
          </FocusableSection>
        </div>
      </div>
    </div>
  );
}

WatchlistOverlaySeries.propTypes = {
  poster: PropTypes.string,
  season: PropTypes.number.isRequired,
  episode: PropTypes.number.isRequired,
  seriesId: PropTypes.string.isRequired,
  episodeId: PropTypes.string.isRequired,
  seriesTitle: PropTypes.string.isRequired,
  episodeTitle: PropTypes.string.isRequired,
  onContentAdded: PropTypes.func,
  onContentRemoved: PropTypes.func,
};

WatchlistOverlaySeries.defaultProps = {
  poster: null,
  onContentAdded: null,
  onContentRemoved: null,
};

export default WatchlistOverlaySeries;
