import { useQuery } from 'react-query';
import {
  getRatingsByRegion,
  getMetadataByLocale,
  getPosterImageForContentRow,
} from '../utils/content';
import ContentResult from '../entities/contentResult';
import { fetchRowContent } from '../dataSource/curations';

function useContentRow({ playlistUrl, rowType, queryOptions = {} }) {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ['playlistUrl', playlistUrl],
    () => getContentRow(playlistUrl, rowType),
    {
      queryOptions,
    }
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
  };
}

async function getContentRow(playlistUrl, rowType) {
  const data = await fetchRowContent(playlistUrl);

  const { items, pagination } = data;

  const result = items?.map((item) => {
    const { title, whyItCrackles } = getMetadataByLocale(item);

    const playlistItem = new ContentResult({
      id: item.id,
      title,
      rating: getRatingsByRegion(item),
      images: (item?.assets?.images || []).concat(item?.parent?.assets?.images),
      season: item?.metadata[0]?.seasonNumber,
      episode: item?.metadata[0]?.episodeNumber,
    });

    playlistItem.image = getPosterImageForContentRow(
      rowType,
      playlistItem.images
    )?.url;
    playlistItem.parentId = item.parentId;
    playlistItem.type = item.type;
    playlistItem.whyItCrackles = whyItCrackles;

    return {
      id: playlistItem.id,
      image: playlistItem.image,
      title: playlistItem.title,
      rating: playlistItem.rating?.name,
      type: playlistItem.type,
      whyItCrackles: playlistItem.whyItCrackles,
    };
  });

  return { items: result, pagination };
}

export { useContentRow };
