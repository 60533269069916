import { useState, useEffect, useCallback } from 'react';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// API
import CrackleApiError from '../../crackle-sdk/v1/api/error';

// Config
import PIN_CONFIG from '../../config/pin-config';

// Components
import FocusableSection from '../../components/spatial-navigation/focusable-section';
import Button from '../../components/button/button';
import NumberStrip from '../../components/number-strip/number-strip';
import PinConfirm from './pin-confirm';
import PinError from './pin-error';
import Keypad from '../../components/keypad/keypad';

// Enums
import BUTTON_TYPES from '../../enums/button-types';
import PARENTAL_CONTROLS from '../../enums/parental-controls';
import PIN_MODAL_TYPES from '../../enums/pin-modal-types';
import PIN_STATUS from '../../enums/pin-status';
import MODAL_EXIT_STATUS from '../../enums/modal-exit-status';

// Icons
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import { ReactComponent as CheckMark } from '../../assets/icons/check-mark.svg';
import { ReactComponent as UnlockIcon } from '../../assets/icons/unlock.svg';
import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg';
import { ReactComponent as CircleForwardIcon } from '../../assets/icons/circle-forward.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import { ReactComponent as PointerIcon } from '../../assets/icons/pointer.svg';
import { ReactComponent as CircleBackIcon } from '../../assets/icons/circle-back.svg';
import { ReactComponent as KeyIcon } from '../../assets/icons/key.svg';

// Utils
import mParticle from '../../utils/mparticle';
import logger from '../../utils/logger';
import parentalControls from '../../utils/parental-controls';
import { getResetPin, isKey } from '../../utils/utils';
import { KEYCODES } from '../../platform/index';

// Hooks
import useModalHandler from '../../hooks/use-modal-handler';

// Styles
import './pin-modal.scss';

function PinModal({ type, onClose, mode }) {
  const [pinEntry, setPinEntry] = useState([]);
  const [isPinEntered, setIsPinEntered] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [saveButtonVoiceLabel, setSaveButtonVoiceLabel] = useState(
    PIN_CONFIG.lock.saveButton
  );
  const [showPinError, setShowPinError] = useState(false);
  const [showPinConfirm, setShowPinConfirm] = useState(false);

  // To differentiate which kind of pin confirm show
  const [pinStatus, setPinStatus] = useState(PIN_STATUS.LOCKED);

  const { closeModal } = useModalHandler();

  const enteredPin = pinEntry && pinEntry.join('');

  // ID of the selected Mode/Tile i.e. "kids" or "teens"
  const settingsModeId =
    mode === PARENTAL_CONTROLS.GROWN_UPS ? PARENTAL_CONTROLS.TEENS : mode;

  const parentalControlsPin = parentalControls.getPIN();

  /**
   * Navigates to settings page
   * Shifts the focus on the previously selected Tile/Mode
   */
  const goToSettings = useCallback(() => {
    onClose && onClose();
    closeModal(MODAL_EXIT_STATUS.PIN_MODAL_CLOSE);
    JsSpatialNavigation.focus(`#${parentalControls.getMode()}`);
  }, [onClose, closeModal]);

  // handle back navigation
  useEffect(() => {
    const onKeyDown = (event) => {
      if (isKey(event.keyCode, KEYCODES.BACK)) {
        if (!showPinError && !showPrompt) {
          closeModal(MODAL_EXIT_STATUS.PIN_MODAL_CLOSE);
        }

        // back button when in pinConfirm
        if (showPinConfirm) {
          if (pinStatus === PIN_STATUS.VERIFICATION) {
            goToSettings();
          } else if (pinStatus === PIN_STATUS.UNLOCKED) {
            parentalControls.setLock(false);
            goToSettings();
          }

          closeModal(MODAL_EXIT_STATUS.PIN_MODAL_CLOSE);
        }

        // "Are you sure?"" page to go back to save pin
        if (showPrompt && !showPinError) {
          setShowPrompt(false);
        }

        // Back when there is a pin error
        if (!showPrompt && showPinError) {
          setShowPinError(false);
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [
    closeModal,
    showPinError,
    showPrompt,
    showPinConfirm,
    pinStatus,
    goToSettings,
  ]);

  // To handle focus between components mount and dismount
  useEffect(() => {
    if (!showPinError && !showPinConfirm) {
      JsSpatialNavigation.focus('#number-strip-1');
    }

    if (showPinConfirm) {
      JsSpatialNavigation.focus('.pin-confirm__button');
    }
  }, [showPinError, showPinConfirm]);

  useEffect(() => {
    if (pinEntry.length >= 4) {
      JsSpatialNavigation.focus('#save-pin');
      setIsPinEntered(true);
    } else {
      setIsPinEntered(false);
    }
  }, [pinEntry]);

  const enterPin = (digit) => {
    if (pinEntry.length >= 4) {
      return;
    }
    setPinEntry([...pinEntry, digit]);
  };

  const removePin = () => {
    const lastDigitRemoved = pinEntry.slice(0, -1);
    setPinEntry(lastDigitRemoved);
  };

  const savePin = async () => {
    if (type === PIN_MODAL_TYPES.LOCK) {
      // Save Pin and go to Pin Saved page, if on Verification Page
      if (showPrompt) {
        parentalControls.setPIN(enteredPin);

        parentalControls.setMode(settingsModeId);
        parentalControls.setLock(false);
        setPinStatus(PIN_STATUS.VERIFICATION);
        setShowPinConfirm(true);
        return;
      }
      // Go to Verification Page
      isPinEntered && goToPinVerification();
    }

    if (
      type === PIN_MODAL_TYPES.UNLOCK &&
      settingsModeId === PARENTAL_CONTROLS.PIN_RESET
    ) {
      // Go To Pin-Successful Screen, if pin matches the Reset PIN and clear the previously stored PIN
      if (enteredPin === getResetPin().toString()) {
        parentalControls.setPIN('');
        parentalControls.setMode(PARENTAL_CONTROLS.GROWN_UPS);
        parentalControls.setLock(false);
        setPinStatus(PIN_STATUS.UNLOCKED);
        setShowPinConfirm(true);
      } else {
        setShowPinError(true);
      }
      return;
    }

    if (type === PIN_MODAL_TYPES.UNLOCK) {
      try {
        // Go To Pin-Successful Screen, if pin matches and disable the lock
        if (parentalControlsPin === enteredPin) {
          parentalControls.setLock(false);
          unlockParentalControls();
          setPinStatus(PIN_STATUS.UNLOCKED);
          setShowPinConfirm(true);
        } else {
          // Navigate to error URL, if the stored and entered pins don't match
          setShowPinError(true);
        }
      } catch (e) {
        if (e instanceof CrackleApiError) {
          logger.error(e);
        } else {
          logger.error('Error occurred while fetching user preferences', e);
        }
      }
    }
  };

  const unlockParentalControls = () => {
    if (mode === parentalControls.getMode()) {
      parentalControls.setMode(PARENTAL_CONTROLS.GROWN_UPS);
    } else {
      parentalControls.setMode(mode);
    }
  };

  /**
   * Navigates back to the pin-setup from prompt page
   * @example ./settings/pin-settings?kids
   * Shifts the focus on "1" in number-strip
   */
  const goToPinSetup = () => {
    setShowPrompt(false);
    JsSpatialNavigation.focus('#number-strip-1');
  };

  /**
   * Navigates to Pin Verification page
   * Sets the appropriate TTS label for Save Button
   */
  const goToPinVerification = () => {
    setShowPrompt(true);
    setSaveButtonVoiceLabel(
      `${PIN_CONFIG.verification.title}. ${PIN_CONFIG.verification.description}. PIN is, ${pinEntry}. Pressing Select will save the current PIN`
    );
  };

  /**
   * Go to Unlock-Settings Screen after pressing "Try-Again" button on Invalid-Pin Screen
   * Highlight "1" with last entered digit in focus
   */
  const goToUnlockScreen = () => {
    setPinEntry([]);
    setShowPinError(false);
    JsSpatialNavigation.focus('#number-strip-1');
  };

  /**
   * Disable the lock, if the right pin is entered
   * Navigate back to Settings with the selected mode in focus
   */
  const unlockSettings = () => {
    parentalControls.setLock(false);
    goToSettings();
  };

  // Show the Pin-Saved Screen if pin is saved and stored globally
  if (showPinConfirm && pinStatus === PIN_STATUS.VERIFICATION) {
    return (
      <PinConfirm
        title={PIN_CONFIG.saved.title}
        description={PIN_CONFIG.saved.description}
        icon={LockIcon}
        buttonId="okay-button"
        buttonIcon={CheckMark}
        onClick={() => {
          goToSettings();
          mParticle.mParticleButtonClick({
            buttonLabel: PIN_CONFIG.saved.title,
            buttonPosition: 1,
            pageSection: 'Pin Modal',
          });
        }}
      />
    );
  }

  // Show Pin-Successful Screen if submitted the right pin
  if (showPinConfirm && pinStatus === PIN_STATUS.UNLOCKED) {
    return (
      <PinConfirm
        title={PIN_CONFIG.success.title}
        description={
          parentalControlsPin?.length < 1
            ? PIN_CONFIG.success.resetDescription
            : PIN_CONFIG.success.description
        }
        icon={UnlockIcon}
        buttonId="success-button"
        buttonIcon={CheckMark}
        onClick={() => {
          unlockSettings();
          mParticle.mParticleButtonClick({
            buttonLabel: PIN_CONFIG.success.title,
            buttonPosition: 1,
            pageSection: 'Pin Modal',
          });
        }}
      />
    );
  }

  // Show Error Screen if pin does not match with stored pin and app navigates to "error"
  if (showPinError) {
    return (
      <PinError
        title={PIN_CONFIG.error.title}
        description={
          settingsModeId === PARENTAL_CONTROLS.PIN_RESET
            ? PIN_CONFIG.error.resetDescription
            : PIN_CONFIG.error.description
        }
        icon={AlertIcon}
        buttonIcon1={CircleForwardIcon}
        buttonIcon2={CrossIcon}
        buttonLabel1="Try Again"
        buttonLabel2="Exit"
        buttonCallback1={goToUnlockScreen}
        buttonCallback2={goToSettings}
      />
    );
  }

  // Display Modal Info based on type

  let modalHeader;
  let modalDescription;
  let saveButtonLabel;
  let backButtonLabel;

  switch (type) {
    case PIN_MODAL_TYPES.LOCK:
      modalHeader = showPrompt
        ? PIN_CONFIG.verification.title
        : PIN_CONFIG.lock.title;

      modalDescription = showPrompt
        ? PIN_CONFIG.verification.description
        : PIN_CONFIG.lock.description;

      saveButtonLabel = showPrompt
        ? PIN_CONFIG.verification.saveButton
        : PIN_CONFIG.lock.saveButton;

      backButtonLabel = showPrompt
        ? PIN_CONFIG.verification.backButton
        : PIN_CONFIG.lock.backButton;
      break;

    case PIN_MODAL_TYPES.UNLOCK:
      modalHeader =
        settingsModeId === PARENTAL_CONTROLS.PIN_RESET
          ? PIN_CONFIG.reset.title
          : PIN_CONFIG.unlock.title;
      modalDescription = PIN_CONFIG.unlock.description;
      saveButtonLabel = PIN_CONFIG.unlock.saveButton;
      backButtonLabel = PIN_CONFIG.unlock.backButton;
      break;

    default:
      break;
  }

  return (
    <FocusableSection sectionId="pin-modal-focusable" className="pin-modal">
      <LockIcon className="pin-modal__lock-icon" />
      <h1>{modalHeader}</h1>
      <span className="pin-modal__description">{modalDescription}</span>

      <Keypad
        pinEntry={pinEntry}
        isPinEntered={isPinEntered}
        pointerIcon={PointerIcon}
        showPrompt={showPrompt}
      />

      <NumberStrip
        isFocusOnSectionEnter
        back={null}
        onEnterCharHandler={enterPin}
        onRemoveCharHandler={removePin}
        disabled={showPrompt}
        initialLabel={!showPrompt ? `${modalHeader}. ${modalDescription}` : ''}
        sectionLeave={{
          leaveForUp: '',
          leaveForDown: isPinEntered ? '#save-pin' : '#pin-modal-cancel',
          leaveForLeft: '',
          leaveForRight: '',
        }}
      />

      <div
        className={classnames('pin-modal__buttons', {
          faded: pinEntry.length < 4,
        })}
      >
        <Button
          aria-label={saveButtonVoiceLabel}
          back={null}
          id="save-pin"
          type={BUTTON_TYPES.ICONBUTTON}
          icon={showPrompt ? CheckMark : KeyIcon}
          data-test-id={
            showPrompt ? 'pin-verification-button' : 'pin-save-button'
          }
          disabled={!isPinEntered}
          onClick={() => {
            savePin();
            mParticle.mParticleButtonClick({
              buttonLabel: saveButtonLabel,
              buttonPosition: 1,
              pageSection: 'Pin Modal',
            });
          }}
          selectionOverrides={{
            up:
              pinEntry.length < 4
                ? '@number-strip-focusable'
                : '.number-strip__backspace-icon',
            left: '',
            down: '',
          }}
        >
          {saveButtonLabel}
        </Button>

        <Button
          aria-label={backButtonLabel}
          id="pin-modal-cancel"
          back={null}
          type={BUTTON_TYPES.ICONBUTTON}
          icon={CircleBackIcon}
          onClick={() => {
            showPrompt ? goToPinSetup() : goToSettings();
            mParticle.mParticleButtonClick({
              buttonLabel: backButtonLabel,
              buttonPosition: 2,
              pageSection: 'Pin Modal',
            });
          }}
          selectionOverrides={{
            up:
              pinEntry.length < 4
                ? '@number-strip-focusable'
                : '.number-strip__backspace-icon',
            right: '',
            down: '',
            left: isPinEntered ? '#save-pin' : '',
          }}
          onFocus={
            () =>
              showPrompt &&
              setSaveButtonVoiceLabel(PIN_CONFIG.verification.saveButton)
            // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          {backButtonLabel}
        </Button>
      </div>
    </FocusableSection>
  );
}

PinModal.propTypes = {
  type: PropTypes.string.isRequired,
  // on close modal callback
  onClose: PropTypes.func,
  mode: PropTypes.string,
};

export default PinModal;
