import { v4 as uuidv4 } from 'uuid';
import PLAYER_MODULES from '../enums/player-modules';
import { isFeatureFlagEnabled } from '../utils/feature-flags';
import speak from '../utils/TextToSpeech';
import { getDeviceInformationForWeb } from '../utils/utils';
import APP_CONFIG from '../config/app-config.json';
import { SAMPLE_NON_DRM_DASH_URL } from '../crackle-sdk/v1/api/common/vars';
import PLAYER_AD_BREAK_INSERT_TYPES from '../enums/player-ad-break-insert-types';
import PLATFORM_CAPABILITIES from '../enums/platform-capabilities';

const KEYCODES = {
  BACK: [8, 10009, 65385],
  DOWN: 40,
  ENTER: 13,
  SPACE: 32,
  EXIT: [27, 45, 10182],
  LEFT: 37,
  MEDIA_PAUSE: 19,
  MEDIA_FAST_FORWARD: 417,
  MEDIA_PLAY: 415,
  MEDIA_PLAY_PAUSE: 10252,
  MEDIA_REWIND: 412,
  MEDIA_STOP: 413,
  MEDIA_NEXT: 418,
  MEDIA_PREVIOUS: 419,
  RIGHT: 39,
  UP: 38,
  CC: 10221,
  SEARCH: null,
  DELETE_CHAR: null,
  ENTER_CHAR: null,
  NAV_RIGHT: null,
  NAV_LEFT: null,
  CHANNEL_UP: 427,
  CHANNEL_DOWN: 428,
  NUMBER_0: 48,
  NUMBER_1: 49,
  NUMBER_2: 50,
  NUMBER_3: 51,
  NUMBER_4: 52,
  NUMBER_5: 53,
  NUMBER_6: 54,
  NUMBER_7: 55,
  NUMBER_8: 56,
  NUMBER_9: 57,
};

const KEYS = [
  'MediaPlay',
  'MediaPause',
  'MediaStop',
  'MediaFastForward',
  'MediaRewind',
  'MediaPlayPause',
  'Caption',
  'ChannelUp',
  'ChannelDown',
  '7',
  '0',
  '3',
  '1',
];

const supportedAdInsertionMode = [
  PLAYER_AD_BREAK_INSERT_TYPES.CSAI,
  PLAYER_AD_BREAK_INSERT_TYPES.CSAIV2,
  PLAYER_AD_BREAK_INSERT_TYPES.SSAI,
];
const platformCapabilities = {
  // limit ad tracking
  [PLATFORM_CAPABILITIES.LIMIT_AD_TRACKING]: true,
  [PLATFORM_CAPABILITIES.CCPA]: false,
  [PLATFORM_CAPABILITIES.VIZBEE]: true,
  // Does the platform support automatic arial label text-to-speech
  [PLATFORM_CAPABILITIES.ARIA_LABEL]: true,
  // Does the platform supports exiting the app?
  [PLATFORM_CAPABILITIES.EXIT]: true,
  // Does the platform has a specific CC button in the remote? If not, we will use the CC button in the player
  [PLATFORM_CAPABILITIES.CC_BUTTON]: false,
  // Does the platform has a specific exit button in the remote and allow to override the default exit behavior?
  [PLATFORM_CAPABILITIES.EXIT_BUTTON]: false,
  // Does the platform support iframe srcdoc attribute for sponsors?
  [PLATFORM_CAPABILITIES.IFRAME_SRC_DOC]: true,
  // Does the platform have platformSessionId?
  [PLATFORM_CAPABILITIES.PLATFORM_SESSION_ID]: true,
  // Does the platform need to announce the end of grids or not possible navigation?
  [PLATFORM_CAPABILITIES.TTS_NAVIGATION_LIMIT_NEEDED]: true,
};

const initializeDevice = (onLoad, onResume) => {
  // these keys are registered in the parent page when using an iframe to include the app
  window?.tizen?.tvinputdevice?.registerKeyBatch(KEYS);

  document.addEventListener('visibilitychange', () => {
    // when changing app while watching, playback must be stopped and go to details
    if (location?.pathname?.includes('/watch')) {
      history?.back();
    }
    if (!document.hidden) {
      // Trigger a platform launch on resume app
      if (onResume && isTv()) {
        onResume();
      }
    }
  });

  onLoad();
};

const getDeviceConnectionType = () => {
  let networkType;

  switch (window?.webapis?.network?.getActiveConnectionType()) {
    case 0:
      networkType = 'Disconnected';
      break;
    case 1:
      networkType = 'WiFi';
      break;
    case 2:
      networkType = 'Cellular';
      break;
    case 3:
      networkType = 'Ethernet';
      break;
    default:
      networkType = null;
      break;
  }

  return new Promise((resolve) => {
    resolve(networkType);
  });
};

const getDeviceId = (callback) => {
  callback(window.parentTizen.deviceId);
};

const isTv = () => {
  return !!window.webapis;
};

const isLowEndDevice = () => {
  return false;
};

const relaunchApplication = () => {
  if (isTv()) {
    if (window.tizen) {
      const appId =
        window?.tizen?.application?.getCurrentApplication()?.appInfo?.id;
      window?.tizen?.application?.launch(appId);
    } else {
      // if the application is embed in an iframe, we won't have access to the tizen object, then we need to send a message to the parent page to relaunch the app
      window.parent.postMessage('relaunch', '*');
    }
  } else {
    window?.location.reload();
  }
};

const setClosedCaptionCallback = () => void 0;

const setClosedCaptionStyleCallback = () => void 0;

const exitApplication = () => {
  if (window.tizen) {
    window?.tizen?.application?.getCurrentApplication()?.exit();
  } else {
    // if the application is embed in an iframe, we won't have access to the tizen object, then we need to send a message to the parent page to exit the app
    window.parent.postMessage('exit', '*');
  }
};

/** Does Samsung tizen needs Advertising callback? */
/**
 * Checks whether the user has enabled the Limit Ad Tracking feature
 * Turning this feature on prevents ad trackers from collecting your data
 *
 * @returns boolean
 */
const isLATEnabled = () => {
  let isLat = null;

  if (isTv()) {
    try {
      isLat = window?.webapis?.adinfo.isLATEnabled();
    } catch (err) {
      isLat = true;
    }
  } else {
    isLat = true;
  }

  return isLat;
};

const supportCapability = (capability) => {
  return platformCapabilities[capability];
};

/**
 * Checks whether the user has enabled the Limit Ad Tracking feature
 * Turning this feature on prevents ad trackers from collecting your data
 *
 * @returns number
 */
const getLimitAdTracking = (callback) => {
  getAdParams((adParams) => {
    callback(adParams.lat);
  });
};

const getAdsType = (callback) => {
  getAdParams((adParams) => {
    callback(adParams.ifaType);
  });
};

const temporaryAdID = uuidv4();

/**
 * randomized and non-persistent device identifier
 *
 * @returns string
 */
const getAdvertisementIdentifier = (callback) => {
  getAdParams((adParams) => {
    callback(adParams.ifa);
  });
};

const isVoiceGuideEnabled = () => {
  return window?.webapis?.tvinfo.getMenuValue(
    window?.webapis?.tvinfo?.TvInfoMenuKey.VOICE_GUIDE_KEY
  );
};

// Text-to-speech function
const tts = (text) => {
  isVoiceGuideEnabled() && speak(text);
};

/**
 * Get the Device's Model
 * @returns {String |null}
 */
const getDeviceModel = () => {
  return window?.parentTizen.deviceModel;
};

/**
 * Get device info from TV
 * @returns promise
 */
const getDeviceInformation = () => {
  let deviceInfo = {};

  return new Promise((resolve) => {
    if (isTv()) {
      const deviceId = window?.parentTizen.deviceId;
      deviceInfo = {
        duid: deviceId,
        modelName: getDeviceModel(),
      };
    } else {
      deviceInfo = getDeviceInformationForWeb();
    }
    resolve(deviceInfo);
  });
};

const getPlatformSessionId = () => {
  let sessionId = null;
  if (isTv()) {
    sessionId = window.parentTizen.sessionId;
  }

  return sessionId;
};
/**
 * Get network info from TV
 * @returns promise
 */
const getNetworkInformation = () => {
  return new Promise((resolve) => {
    resolve({
      ip: window?.webapis?.network.getIp(),
    });
  });
};

/**
 * Get platform device name
 * @returns {String}
 */
const getPlatformDeviceName = () => {
  return `${APP_CONFIG.PLATFORM} (${getDeviceModel()})`;
};

/**
 * get deeplink path
 * @returns {String}
 */
const getDeeplinkPath = () => {
  if (isFeatureFlagEnabled('testForceDeeplink')) {
    return '/watch/f125e5c8-cb21-44b8-8fdc-abc094bda679?cmpid=Christmas2021';
  }

  // if its a browser it should be handled different
  if (!isTv()) {
    const regexp =
      /\/((.*)\/)*watch\/([^?]+)(.*)?|\/((.*)\/)*sponsored_collections\/([^?]+)(.*)?/;

    if (document.location.pathname.match(regexp) !== null) {
      return document.location.pathname + document.location.search;
    }
  }
  let linkValue = '';
  let launchData = null;

  // window.parentTizen.launchData is defined in the parent page (when using an iframe to include the app)
  if (window.parentTizen?.launchData) {
    launchData = window.parentTizen.launchData;
  } else {
    const requestedAppControl = window?.tizen?.application
      ?.getCurrentApplication()
      ?.getRequestedAppControl();

    if (requestedAppControl) {
      launchData = requestedAppControl.appControl.data;
    }
  }

  if (launchData) {
    if (launchData && launchData.length > 0) {
      const payload = launchData.find((item) => {
        return item.key === 'PAYLOAD';
      });

      //----------------------
      // Vizbee Changes Start
      //----------------------

      // check if app launch is from vizbee
      // payload must have an attribute 'vzb'
      if (isFeatureFlagEnabled('vizbee')) {
        if (payload && payload.value && payload.value[0]) {
          const payloadValueStr = payload.value[0];
          if (typeof payloadValueStr == 'string') {
            const payloadValueObj = JSON.parse(payload.value[0]);
            if (
              payloadValueObj &&
              Object.keys(payloadValueObj).indexOf('vzb') > -1
            ) {
              return '';
            }
          }
        }
      }

      //----------------------
      // Vizbee Changes End
      //----------------------

      if (payload && payload.value && payload.value[0]) {
        linkValue = JSON.parse(payload.value[0]).values;
      }
    }
  }

  return linkValue;
};

const getDeviceOS = () => 'Tizen';

const getDeviceOsVersion = () => {
  // window.parentTizen.deviceOsVersion is defined in the parent page (when using an iframe to include the app)
  return (
    window.parentTizen?.deviceOsVersion ||
    window?.tizen?.systeminfo.getCapability(
      'http://tizen.org/feature/platform.version'
    ) ||
    ''
  );
};

const getDeviceMaker = () => 'Samsung';

// Set focus on hidden li element to cancel tts reading raw elements on open application when loading a web application
function getTTSCancelationHTML() {
  return `<ul class="aria-hidden-iframe"><li id="aria-hidden-el-iframe" autofocus tabindex="0"></li></ul>
    <style type="text/css">
      ul.aria-hidden-iframe{list-style-type: none;}
      ul.aria-hidden-iframe,
      ul.aria-hidden-iframe li {
        opacity: 0;
        margin: 0;
        padding: 0;
        width: 0;
        height: 0;
      }
    </style>`;
}

const getStreamSource = (streams) => {
  const dashUrl =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url || null;
  const drm = {
    widevine: {
      LA_URL:
        streams?.dash_widevine?.drm?.keyUrl ||
        streams?.['dash-widevine']?.drm?.keyUrl ||
        null,
    },
  };
  const sourceStreamType =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url
      ? 'dash_widevine'
      : null;

  return {
    dash: isFeatureFlagEnabled('testNoDRMSampleVideo')
      ? SAMPLE_NON_DRM_DASH_URL
      : dashUrl,
    sourceStreamType,
    drm,
  };
};

const getPlayerConfig = () => {
  return {
    tweaks: {
      file_protocol: true,
      app_id: APP_CONFIG.BITMOVIN_APP_ID,
    },
    analytics: {
      config: {
        origin: isTv() ? APP_CONFIG.BITMOVIN_APP_ID : APP_CONFIG.HOST,
      },
    },
  };
};

const getKeyHintIcon = () => {
  return null;
};

const showKeyHints = () => {
  return false;
};

const getPlatformSpecificModules = () => {
  return [PLAYER_MODULES.TIZEN];
};

const getAdParams = (callback) => {
  let ifa = null;

  try {
    if (!isLATEnabled()) {
      ifa = window?.webapis?.adinfo.getTIFA();
    } else {
      ifa = temporaryAdID;
    }
  } catch (err) {
    // no actions needed
  }

  callback({
    lat: isLATEnabled() ? '1' : '0',
    ifa,
    ifaType: 'tifa',
    ccpa: null,
  });
};

const isAdInsertionModeSupported = (mode) =>
  supportedAdInsertionMode.includes(mode);

export {
  getDeviceId,
  getDeviceModel,
  initializeDevice,
  isTv,
  isLowEndDevice,
  relaunchApplication,
  setClosedCaptionCallback,
  setClosedCaptionStyleCallback,
  getDeviceInformation,
  getNetworkInformation,
  exitApplication,
  getPlatformDeviceName,
  getDeeplinkPath,
  getDeviceOS,
  getDeviceOsVersion,
  getDeviceMaker,
  tts,
  getTTSCancelationHTML,
  KEYCODES,
  getDeviceConnectionType,
  getStreamSource,
  getAdvertisementIdentifier,
  getLimitAdTracking,
  getPlatformSessionId,
  getAdsType,
  getPlayerConfig,
  getKeyHintIcon,
  showKeyHints,
  getPlatformSpecificModules,
  getAdParams,
  supportCapability,
  isAdInsertionModeSupported,
};
