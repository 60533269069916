import { useRef, useEffect, useState, useCallback } from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';

// Icons
import { ReactComponent as ChevronIcon } from '../../assets/icons/chevron.svg';

// Config
import Rows from '../../config/row-config';

// Components
import ContentCard from '../content-card/content-card';
import FocusableSection from '../spatial-navigation/focusable-section';
import SponsoredAdView from '../sponsored-ads/sponsored-ads';
import SeeAllCard from '../see-all-card/see-all-card';

// Hooks
import useDebouncedCallback from '../../hooks/use-debounced-callback';
import useThrottling from '../../hooks/use-throttling';

// Styles
import './content-row.scss';

// Utils
import { sanitizeSponsoredData, isKey } from '../../utils/utils';
import { scaleFrom720p } from '../../utils/scale-from-720p';
import { KEYCODES } from '../../platform/index';
import parentalControls from '../../utils/parental-controls';
import { isFeatureFlagEnabled } from '../../utils/feature-flags';
import FeatureFlag from '../feature-flag/feature-flag';
import {
  getAriaLabelForRowItem,
  getChildrenWidth,
  getMovementDirection,
  isDirectionalKey,
} from '../../utils/content-row';

function ContentRow({
  curation,
  seeAll,
  rowContentId,
  title,
  type,
  rowIndex = null,
  rowId,
  initialRowScroll,
  onContentSelected,
  onRowLoaded,
  onRowBack,
  rowDownMovement,
  rowUpMovement,
  onSeeAllClick,
  rowIdFocus,
  contentIdFocus,
  curationLength,
  forceLoad,
  hoverEnabled,
  continueWatchingRow,
}) {
  const [leftArrowEnabled, setLeftArrowEnabled] = useState(true);
  const [rightArrowEnabled, setRightArrowEnabled] = useState(true);
  const mountRef = useRef(null);
  const [rowScroll, setRowScroll] = useState(
    rowIdFocus === rowId && rowIdFocus !== 'continue-watching-row'
      ? initialRowScroll || 0
      : 0
  );
  // to keep reference of tha last focused card
  const [auxIndex, setAuxIndex] = useState(0);
  const carouselRef = useRef(null);
  const childrenWidthRef = useRef(null);

  const badgeTranslation = t`ORIGINAL_BADGE`;

  const throttleFunction = useThrottling(200);

  const numVisible = Math.floor(Rows[type].visibleElements);
  let screenWidth = window.innerWidth;

  // convert distance px depending on screen width
  const distanceFromMenu = scaleFrom720p(95);
  const marginCard = scaleFrom720p(22);
  const peekCard = scaleFrom720p(100);

  if (isFeatureFlagEnabled('SHOW_CONSOLE')) {
    screenWidth = window.innerWidth * 0.7;
  }

  useEffect(() => {
    setLeftArrowEnabled(rowScroll < 0);
  }, [rowScroll]);
  // triggers onLoad event
  useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true;
    }
    onRowLoaded && onRowLoaded();
  }, [onRowLoaded]);

  const handleCardFocus = useDebouncedCallback(function (cardId, index) {
    setAuxIndex(index);
    const focusedCard = document.getElementById(cardId);
    const rect = focusedCard?.getBoundingClientRect();
    if (!childrenWidthRef.current) {
      childrenWidthRef.current = getChildrenWidth(carouselRef, type);
    }
    const totalChildrenWidth = childrenWidthRef.current;

    if (focusedCard) {
      document
        .querySelector('.focusedElement')
        ?.classList.remove('focusedElement');
      focusedCard.classList.add('focusedElement');
    }

    const scrollDistance =
      screenWidth - marginCard - distanceFromMenu - peekCard;

    let currentMovement = rect
      ? (rect.left - distanceFromMenu - marginCard) * -1
      : 0;

    if (isFeatureFlagEnabled('SHOW_CONSOLE')) {
      currentMovement = rect
        ? (distanceFromMenu + marginCard - rect.left) * 0.7
        : 0;
    }

    if (curationLength <= numVisible) {
      return;
    }

    if (index === 0) {
      setRowScroll(0);
      setRightArrowEnabled(true);
      setLeftArrowEnabled(false);
      return;
    }
    if (index >= curationLength - numVisible) {
      setRowScroll(totalChildrenWidth * -1 + scrollDistance);
      setRightArrowEnabled(false);
      return;
    }

    if (!rightArrowEnabled) setRightArrowEnabled(true);
    setRowScroll((prev) => prev + currentMovement);
  }, 100);

  function scrollPage(direction) {
    let scrollDistance = 0;
    const left = 1;
    const right = -1;
    const scrollDirection = direction === 'left' ? left : right;
    if (!childrenWidthRef.current) {
      childrenWidthRef.current = getChildrenWidth(carouselRef, type);
    }
    const totalChildrenWidth = childrenWidthRef.current;

    scrollIntoViewIfNeeded(carouselRef.current, {
      scrollMode: 'if-needed',
      block: 'nearest',
    });
    if (rowScroll * right <= screenWidth && scrollDirection === left) {
      if (!rightArrowEnabled) setRightArrowEnabled(true);
      setRowScroll(scrollDistance > 0 ? 0 : scrollDistance);
      return;
    }

    scrollDistance = screenWidth - peekCard - marginCard - distanceFromMenu;

    // when the next row ends we don't scroll all the viewable screen
    // instead the distance to scroll is calculated with the remainder of the total cards width
    const remainderToLast = totalChildrenWidth + rowScroll - scrollDistance;
    if (
      scrollDirection === right &&
      totalChildrenWidth &&
      remainderToLast < scrollDistance
    ) {
      setRightArrowEnabled(false);
      setRowScroll((prev) =>
        prev + remainderToLast * scrollDirection > 0
          ? 0
          : prev + remainderToLast * scrollDirection
      );
      return;
    }

    if (!rightArrowEnabled) setRightArrowEnabled(true);
    setRowScroll((prev) =>
      prev + scrollDistance * scrollDirection > 0
        ? 0
        : prev + scrollDistance * scrollDirection
    );
  }

  function generateCard(content, index) {
    const assetId = content.id;
    const cardId = `card-${assetId}-${rowId}`;
    const season = content.season ? Number(content.season) : null;
    const episode = content.episode ? Number(content.episode) : null;
    const progressPercent = content.progressPercent ?? null;
    return (
      <div
        data-tray-slide-index={index}
        data-test-id={`slide-${rowIndex}-${index}`}
        className="content-card-container"
        key={index}
      >
        <ContentCard
          description={
            isFeatureFlagEnabled('showWhyItCrackles')
              ? content.whyItCrackles || ''
              : ''
          }
          className={`${cardId}-${rowIndex}`}
          isFocusOnSectionEnter={auxIndex === index}
          isFocusOnPageLoad={rowId === rowIdFocus && contentIdFocus === assetId}
          id={assetId}
          key={cardId}
          index={index}
          forcePoster={index <= numVisible} // force to load first visible posters
          isBlocked={parentalControls.checkIfBlocked(content.rating)}
          poster={content.image}
          title={content.title}
          type={type}
          rowId={rowId}
          seasonNumber={season}
          episodeNumber={episode}
          progressPercent={progressPercent}
          to={continueWatchingRow ? `/watch/${assetId}` : ''}
          onClick={() => {
            onContentSelected &&
              onContentSelected(
                content.id,
                rowId,
                title,
                content.title,
                index,
                rowIndex,
                type,
                rowScroll
              );
          }}
          onFocus={() => {
            setAuxIndex(index);
            handleCardFocus(cardId, index);
          }}
          onKeyDown={(event) => {
            if (isKey(event.keyCode, KEYCODES.BACK)) {
              onRowBack && onRowBack(index, rowId);
            }

            // if a directional key is pressed, handle the focus using the jsnavigation move function
            // adding throttling to prevent the focus from moving too fast
            if (isDirectionalKey(event.keyCode)) {
              event.preventDefault();
              event.stopPropagation();
              throttleFunction(() => {
                JsSpatialNavigation.move(getMovementDirection(event.keyCode));
              });
            }
          }}
          channelRow={title}
          ariaLabel={getAriaLabelForRowItem(
            type,
            content.title,
            content.whyItCrackles,
            title,
            index,
            badgeTranslation
          )}
          {...content.extraProps}
        />
      </div>
    );
  }

  function showArrows() {
    return hoverEnabled && curation.length > numVisible;
  }

  if (!curation || curation.length === 0) return <></>;

  const getFocusDefaultElement = useCallback(() => {
    if (continueWatchingRow && rowIdFocus === rowId) {
      return !document.querySelector('.focusedElement')
        ? `.card-${curation[0].id}-${rowId}-${rowIndex}`
        : null;
    }

    return contentIdFocus && !mountRef.current && rowIdFocus === rowId
      ? `.card-${contentIdFocus}-${rowId}-${rowIndex}`
      : null;
  }, [continueWatchingRow, contentIdFocus, rowIdFocus, rowId, rowIndex]);

  return (
    <FocusableSection
      className="content-row"
      sectionId={`content-row-${rowIndex}`}
      id={`content-row-${rowIndex}`}
      data-test-id={`content-row-${rowIndex}`}
      isFocusOnPageLoad={forceLoad}
      onAnyFocused={() => {
        if (
          document.activeElement
            .getAttribute('class')
            .indexOf('content-row-0') > -1
        ) {
          // if it is the first row, keep window scrolled to the top, hero will be collapsed
          document.getElementById('base-container').scrollTop = 0;
          return;
        }

        scrollIntoViewIfNeeded(carouselRef.current, {
          block: 'center',
        });
      }}
      leaveForUp={rowUpMovement && rowUpMovement(rowIndex)}
      leaveForDown={rowDownMovement && rowDownMovement(rowIndex)}
      leaveForRight=""
      leaveForLeft=".menu-home"
      defaultElement={getFocusDefaultElement()}
    >
      <div className="content-row__title">
        <div className="content-ads-title">
          <div className="content-title">{title}</div>
          <FeatureFlag name="sponsorship">
            <SponsoredAdView
              slotParams={{
                block: rowContentId,
                row: sanitizeSponsoredData(title),
                slid: `780x72_${rowIndex + 1}_tray`,
                w: '780',
                h: '72',
              }}
              className="content-row__ads"
            />
          </FeatureFlag>
        </div>
        {showArrows() && (
          <div className="content-row__arrow">
            <div
              className={`left-arrow ${leftArrowEnabled ? '' : 'disabled'}`}
              onClick={() => {
                leftArrowEnabled && scrollPage('left');
              }}
            >
              <ChevronIcon />
            </div>

            <div
              className={`right-arrow ${rightArrowEnabled ? '' : 'disabled'}`}
              onClick={() => {
                scrollPage('right');
              }}
            >
              <ChevronIcon />
            </div>
          </div>
        )}
      </div>

      <div className="content-row__inner" key={`content-row-${rowId}`}>
        <div
          ref={carouselRef}
          className={classnames('content-row__carousel not-swiper', type)}
          style={{ transform: `translateX(${rowScroll}px)` }}
        >
          {curation?.length > 0 &&
            curation.map((item, index) => generateCard(item, index))}
          {seeAll && (
            <FeatureFlag name="sponsoredContent">
              <div
                data-tray-slide-index={curationLength - 1}
                className="content-card-container"
              >
                <SeeAllCard
                  key={`see-all-card-${rowId}`}
                  rowId={rowId}
                  collectionId={rowContentId}
                  className="see-all-card"
                  isFocusOnPageLoad={
                    rowId === rowIdFocus &&
                    contentIdFocus === `see-all-card-${rowId}`
                  }
                  onFocus={() => {
                    handleCardFocus(
                      `see-all-card-${rowId}`,
                      curationLength - 1
                    );
                  }}
                  isFocusOnSectionEnter={auxIndex === curationLength - 1}
                  selectionOverrides={{
                    left: `.content-card-${rowId}-${curationLength - 2}`,
                  }}
                  onKeyDown={(event) => {
                    if (isKey(event.keyCode, KEYCODES.BACK)) {
                      onRowBack && onRowBack(curationLength - 1, rowId);
                    }

                    // if a directional key is pressed, handle the focus using the jsnavigation move function
                    // adding throttling to prevent the focus from moving too fast
                    if (isDirectionalKey(event.keyCode)) {
                      event.preventDefault();
                      event.stopPropagation();
                      throttleFunction(() => {
                        JsSpatialNavigation.move(
                          getMovementDirection(event.keyCode)
                        );
                      });
                    }
                  }}
                  onClick={() => {
                    onSeeAllClick && onSeeAllClick(rowId, rowIndex, rowScroll);
                  }}
                />
              </div>
            </FeatureFlag>
          )}
        </div>
      </div>
    </FocusableSection>
  );
}

ContentRow.propTypes = {
  curation: PropTypes.arrayOf(PropTypes.object),
  seeAll: PropTypes.bool,
  curationLimit: PropTypes.number,
  rowContentId: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  rowId: PropTypes.string,
  rowIndex: PropTypes.number,
  initialRowScroll: PropTypes.number,
  onContentSelected: PropTypes.func,
  onRowLoaded: PropTypes.func,
  onRowBack: PropTypes.func,
  onSeeAllClick: PropTypes.func,
  rowUpMovement: PropTypes.func,
  rowDownMovement: PropTypes.func,
  rowIdFocus: PropTypes.string,
  contentIdFocus: PropTypes.string,
  curationLength: PropTypes.number,
  forceLoad: PropTypes.bool,
  hoverEnabled: PropTypes.bool,
  continueWatchingRow: PropTypes.bool,
};

ContentRow.defaultProps = {
  rowIndex: null,
};

export default ContentRow;
