import { isFeatureFlagEnabled } from '../utils/feature-flags';
import { getDeviceInformationForWeb } from '../utils/utils';
import APP_CONFIG from '../config/app-config.json';
import { SAMPLE_NON_DRM_DASH_URL } from '../crackle-sdk/v1/api/common/vars';
import PLATFORM_CAPABILITIES from '../enums/platform-capabilities';
import logger from '../utils/logger';
import KEY_HINTS_TYPE from '../enums/key-hint-type';
import PLAYER_AD_BREAK_INSERT_TYPES from '../enums/player-ad-break-insert-types';
import {
  initializeAdConfiguration,
  getAdId,
  getLat,
} from '../utils/ad-personalization-management';

import { ReactComponent as BackKey } from '../assets/icons/back-key.svg';

// TODO: update codes with playstation specifics
const KEYCODES = {
  BACK: [8, 196, 27],
  DOWN: 40,
  ENTER: [13, 195],
  SPACE: 112,
  EXIT: 129,
  LEFT: 37,
  MEDIA_PAUSE: 135,
  MEDIA_FAST_FORWARD: [134, 117, 119],
  MEDIA_PLAY: 179,
  MEDIA_PLAY_PAUSE: 217,
  MEDIA_REWIND: [133, 116, 118],
  MEDIA_STOP: 178,
  MEDIA_NEXT: 418,
  MEDIA_PREVIOUS: 419,
  RIGHT: 39,
  UP: 38,
  CC: 114,
  SEARCH: 112,
  DELETE_CHAR: 113,
  ENTER_CHAR: 119,
  NAV_RIGHT: 117,
  NAV_LEFT: 116,
  CHANNEL_UP: 117,
  CHANNEL_DOWN: 119,
  NUMBER_0: 48,
  NUMBER_1: 49,
  NUMBER_2: 50,
  NUMBER_3: 51,
  NUMBER_4: 52,
  NUMBER_5: 53,
  NUMBER_6: 54,
  NUMBER_7: 55,
  NUMBER_8: 56,
  NUMBER_9: 57,
};

const supportedAdInsertionMode = [
  PLAYER_AD_BREAK_INSERT_TYPES.CSAIV2,
  PLAYER_AD_BREAK_INSERT_TYPES.SSAI,
];

const deviceData = {
  deviceModel: null,
  deviceId: null,
  osVersion: null,
};

const platformCapabilities = {
  // limit ad tracking
  [PLATFORM_CAPABILITIES.LIMIT_AD_TRACKING]: false,
  [PLATFORM_CAPABILITIES.CCPA]: false,
  [PLATFORM_CAPABILITIES.VIZBEE]: false,
  // Does the platform support automatic arial label text-to-speech
  [PLATFORM_CAPABILITIES.ARIA_LABEL]: false,
  // Does the platform supports exiting the app?
  [PLATFORM_CAPABILITIES.EXIT]: false,
  // Does the platform has a specific CC button in the remote? If not, we will use the CC button in the player
  [PLATFORM_CAPABILITIES.CC_BUTTON]: false,
  // Does the platform has a specific exit button in the remote and allow to override the default exit behavior?
  [PLATFORM_CAPABILITIES.EXIT_BUTTON]: false,
  // Does the platform support iframe srcdoc attribute for sponsors?
  [PLATFORM_CAPABILITIES.IFRAME_SRC_DOC]: true,
  // Does the platform have platformSessionId?
  [PLATFORM_CAPABILITIES.PLATFORM_SESSION_ID]: false,
  // Does the platform need to announce the end of grids or not possible navigation?
  [PLATFORM_CAPABILITIES.TTS_NAVIGATION_LIMIT_NEEDED]: false,
};

const initializeDevice = (onLoad) => {
  // if the platform does not support LAT at the OS level we initialize the ad configuration
  if (!platformCapabilities.lat) {
    initializeAdConfiguration();
  }

  if (isTv()) {
    try {
      // tbd playstation specifics
      window.msdk.device.getId().then((id) => {
        deviceData.deviceId = id;
      });
      window.msdk.system
        .getServiceParameter(window.msdk.system.serviceParameter.id.SYSTEM_NAME)
        .then((systemName) => {
          deviceData.deviceModel = systemName;
        });
    } catch (error) {
      logger.error(error);
    }

    onLoad();
  } else {
    onLoad();
  }
};

const getDeviceId = (callback) => {
  callback(deviceData.deviceId);
};

const isTv = () => {
  return !!(window.msdk || false);
};

const isLowEndDevice = () => {
  return false;
};

const relaunchApplication = () => {
  window?.location.reload();
};

const setClosedCaptionCallback = () => void 0;

const setClosedCaptionStyleCallback = () => void 0;

const exitApplication = () => {
  window?.close();
  // TODO: update function with playstation specifics
};

const supportCapability = (capability) => {
  return platformCapabilities[capability];
};

/**
 * Checks whether the user has enabled the Limit Ad Tracking feature
 * Turning this feature on prevents ad trackers from collecting your data
 *
 */

const getLimitAdTracking = (callback) => {
  callback(getLat());
};

const getAdsType = (callback) => {
  callback(null);
};

/**
 * randomized and non-persistent device identifier
 *
 */
const getAdvertisementIdentifier = (callback) => {
  callback(getAdId());
};

// Text-to-speech function
const tts = () => {
  // TODO: update function with playstation specifics
};

/**
 * Get the Device's Model
 * @returns {String |null}
 */
const getDeviceModel = () => {
  return deviceData.deviceModel;
};

/**
 * Get device info from TV
 * @returns promise
 */
const getDeviceInformation = () => {
  let deviceInfo = {
    duid: deviceData.deviceId,
    modelName: getDeviceModel(),
  };

  return new Promise((resolve) => {
    if (!isTv()) {
      deviceInfo = getDeviceInformationForWeb();
    }

    resolve(deviceInfo);
  });
};

const getPlatformSessionId = () => null;

/**
 * Get network info from TV
 * @returns promise
 */
const getNetworkInformation = () => {
  return new Promise((resolve) => {
    // TODO: update function with playstation specifics
    resolve({ ip: null });
  });
};

/**
 * Get platform device name
 * @returns {String}
 */
const getPlatformDeviceName = () => {
  return `${APP_CONFIG.PLATFORM} (${getDeviceModel()})`;
};

/**
 * gets deeplink path
 * @returns {String}
 */
const getDeeplinkPath = () => {
  if (isFeatureFlagEnabled('testForceDeeplink')) {
    return '/watch/545158AE-AD7D-4325-980F-93421E200D5F?cmpid=Christmas2021';
  }

  const regexp =
    /\/((.*)\/)*watch\/([^?]+)(.*)?|\/((.*)\/)*sponsored_collections\/([^?]+)(.*)?/;

  if (document.location.pathname.match(regexp) !== null) {
    return document.location.pathname + document.location.search;
  }

  return '';
};

// TODO: update function with playstation specifics
const getDeviceOS = () => 'playstation';

const getDeviceOsVersion = () => deviceData.osVersion;

const getDeviceMaker = () => 'Sony';

function getTTSCancelationHTML() {
  return ``;
}

const getDeviceConnectionType = async () => {
  if (!window.msdk) return '';

  const { type } = await window.msdk.device.getNetworkConnectionType();

  return type === window.msdk?.device.networkConnectionType.WIRELESS
    ? 'Wireless connection'
    : 'Wired connection';
};

const getStreamSource = (streams) => {
  const dashUrl =
    streams?.dash_playready?.url || streams?.['dash-playready']?.url || null;

  const drm = {
    playready: {
      utf8message: true,
      plaintextChallenge: true,
      mediaKeySystemConfig: {
        sessionTypes: ['temporary'],
      },
      LA_URL:
        streams?.dash_playready?.drm?.key_url ||
        streams?.['dash-playready']?.drm?.keyUrl ||
        streams?.dash_playready?.drm?.keyUrl ||
        null,
    },
  };
  const sourceStreamType =
    streams?.dash_playready?.url || streams?.['dash-playready']?.url
      ? 'dash_playready'
      : null;

  return {
    dash: isFeatureFlagEnabled('testNoDRMSampleVideo')
      ? SAMPLE_NON_DRM_DASH_URL
      : dashUrl,
    sourceStreamType,
    drm,
  };
};

const getKeyHintIcon = (type) => {
  let icon = null;
  switch (type) {
    case KEY_HINTS_TYPE.BACK:
      icon = <BackKey className="circle" />;
      break;
    case KEY_HINTS_TYPE.ADD_TO_WATCHLIST:
      icon = null;
      break;
    default:
  }

  return icon;
};

const showKeyHints = () => {
  return true;
};

const getPlayerConfig = () => {
  return {
    tweaks: {
      playstation_5: {
        esvm: true,
      },
    },
  };
};

const getPlatformSpecificModules = () => {
  return [];
};

const getAdParams = (callback) => {
  callback({
    lat: getLat(),
    ifa: getAdId(),
    ifaType: null,
    ccpa: null,
  });
};

const isAdInsertionModeSupported = (mode) =>
  supportedAdInsertionMode.includes(mode);

export {
  getDeviceId,
  getDeviceModel,
  initializeDevice,
  isTv,
  isLowEndDevice,
  relaunchApplication,
  getAdvertisementIdentifier,
  getLimitAdTracking,
  setClosedCaptionCallback,
  setClosedCaptionStyleCallback,
  getDeviceInformation,
  getPlatformSessionId,
  getNetworkInformation,
  exitApplication,
  getPlatformDeviceName,
  getDeeplinkPath,
  getDeviceOS,
  getDeviceOsVersion,
  getDeviceMaker,
  tts,
  getTTSCancelationHTML,
  KEYCODES,
  getDeviceConnectionType,
  getStreamSource,
  getAdsType,
  getKeyHintIcon,
  showKeyHints,
  getPlayerConfig,
  getPlatformSpecificModules,
  getAdParams,
  supportCapability,
  isAdInsertionModeSupported,
};
