import { makeRequest } from '../common/base';

/**
 * Get block info
 *
 * @param {string} blockId The id of the block
 * @param {number} pageName The name of the block page
 *
 * @returns {Promise<{}>} A promise containing a JSON data object with block info
 */
const fetchBlock = async (blockId, pageName) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  const params = {
    page: pageName,
  };

  const urlSearchParams = new URLSearchParams({ ...params });

  return makeRequest(
    `/curations/block/${blockId}?${urlSearchParams.toString()}`,
    requestOptions
  );
};
export { fetchBlock };
