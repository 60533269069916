import { useEffect } from 'react';
import SPATIAL_EVENTS from '../enums/spatial-events';
import { t } from '@lingui/macro';
import { tts } from '../platform/index';
import PLATFORM_CAPABILITIES from '../enums/platform-capabilities';
import { supportCapability } from '../platform/index';

const onNavigationNotPossible = () => {
  tts(t`TTS_NAVIGATION_NOT_POSSIBLE`);
};

function useNavigationTTS() {
  useEffect(() => {
    supportCapability(PLATFORM_CAPABILITIES.TTS_NAVIGATION_LIMIT_NEEDED) &&
      window.addEventListener(
        SPATIAL_EVENTS.NAVIGATE_FAILED,
        onNavigationNotPossible
      );

    return () => {
      supportCapability(PLATFORM_CAPABILITIES.TTS_NAVIGATION_LIMIT_NEEDED) &&
        window.removeEventListener(
          SPATIAL_EVENTS.NAVIGATE_FAILED,
          onNavigationNotPossible
        );
    };
  }, []);
}

function useDisableNavigationTTS() {
  useEffect(() => {
    supportCapability(PLATFORM_CAPABILITIES.TTS_NAVIGATION_LIMIT_NEEDED) &&
      window.removeEventListener(
        SPATIAL_EVENTS.NAVIGATE_FAILED,
        onNavigationNotPossible
      );
    return () => {
      supportCapability(PLATFORM_CAPABILITIES.TTS_NAVIGATION_LIMIT_NEEDED) &&
        window.addEventListener(
          SPATIAL_EVENTS.NAVIGATE_FAILED,
          onNavigationNotPossible
        );
    };
  }, []);
}

export { useNavigationTTS, useDisableNavigationTTS };
