import { generatePath } from 'react-router-dom';
import ROUTES from '../enums/routes';
import { getDeeplinkPath } from '../platform/index';
import { getUrlParamValue } from './url';
import APP_CONFIG from '../config/app-config.json';
import { isFeatureFlagEnabled } from './feature-flags';

/**
 * Check if it is deeplink
 * https://crackle.atlassian.net/browse/CHIC-43
 */
const isDeeplink = () => {
  const regexp = new RegExp(
    isFeatureFlagEnabled('sponsoredContent')
      ? '/((.*)/)*watch/([^?]+)(.*)?|/((.*)/)*sponsored_collections/([^?]+)(.*)?'
      : '/((.*)/)*watch/([^?]+)(.*)?'
  );
  const url = getDeeplinkPath();

  return url.match(regexp) !== null;
};

/**
 * Adds history to deeplink visitors
 * https://crackle.atlassian.net/browse/CHIC-43
 */
const deeplinkPrepareHistory = () => {
  const basePath = `${
    APP_CONFIG.PUBLIC_URL.slice(-1) === '/'
      ? APP_CONFIG.PUBLIC_URL.slice(0, -1)
      : APP_CONFIG.PUBLIC_URL
  }`; // removing trailing slash

  const url = getDeeplinkPath();
  if (url !== '') {
    const regexp = new RegExp(
      isFeatureFlagEnabled('sponsoredContent')
        ? '/((.*)/)*watch/([^?]+)(.*)?|/((.*)/)*sponsored_collections/([^?]+)(.*)?'
        : '/((.*)/)*watch/([^?]+)(.*)?'
    );

    const pathParts = url.match(regexp);

    const cmpid = getUrlParamValue('cmpid', pathParts[4]);

    const isWatchDeeplink = pathParts[0]?.includes('/watch/');

    // we have to emulate all the pathway to this deeplinked page
    const homePath = generatePath(ROUTES.HOME);
    const secondaryPath = isWatchDeeplink
      ? generatePath(ROUTES.DETAILS, {
          mediaId: pathParts[3],
        })
      : generatePath(ROUTES.SPONSORED_COLLECTIONS, {
          collectionId: pathParts[7],
        });

    // home page
    window.history.pushState(
      { urlPath: `${basePath}${homePath}` },
      '',
      `${basePath}${homePath}`
    );

    // details page
    window.history.pushState(
      { urlPath: `${basePath}${secondaryPath}` },
      '',
      `${basePath}${secondaryPath}`
    );

    if (!isWatchDeeplink) return;

    const watchPath = generatePath(ROUTES.WATCH, {
      mediaId: pathParts[3],
    });

    // watch page
    window.history.pushState(
      {
        urlPath: `${basePath}${watchPath}?${
          cmpid ? `cmpid=${cmpid}&` : ''
        }deeplink=true`,
      },
      '',
      `${basePath}${watchPath}?${cmpid ? `cmpid=${cmpid}&` : ''}deeplink=true` // this is to auto-display the controls
    );
  }
};

export { isDeeplink, deeplinkPrepareHistory };
