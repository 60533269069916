import { getConfig, getFeatureFlags } from '../../configuration';

export function getApiUrl() {
  const config = getConfig();
  const flags = getFeatureFlags();

  const protocol =
    flags.testHTTP && config.ENV !== 'production' ? 'http' : 'https';

  const url =
    flags.testStgApi && config.ENV !== 'production'
      ? config.API_URL_STG
      : config.API_URL_PROD;
  return `${protocol}://${url}`;
}

export function getHeartbeatApiUrl() {
  const config = getConfig();
  const flags = getFeatureFlags();
  const protocol =
    flags.testHTTP && config.ENV !== 'production' ? 'http' : 'https';

  const url =
    flags.testStgApi && config.ENV !== 'production'
      ? config.HEARTBEAT_API_URL_STG
      : config.HEARTBEAT_API_URL_PROD;
  return `${protocol}://${url}`;
}

export function getHeaders() {
  const config = getConfig();
  return {
    'Content-Type': 'application/json',
    'x-crackle-brand': config.BRAND,
    'x-crackle-platform': config.PLATFORM_ID,
    'x-crackle-region': 'us',
    'x-crackle-locale': 'en-us',
    'x-crackle-apiversion': 'v2.0.0',
  };
}

export const SAMPLE_NON_DRM_DASH_URL =
  'https://b81f25e9298e27d79471a5fc3bb7b885.egress.mediapackage-vod.us-west-2.amazonaws.com/out/v1/1377bb3b96e14c76ab156a4c98273ac3/ab18c117df9c45fcaa62d8459c6a0680/83c59987661b4bd8b761bde52c3ed905/index.mpd';
