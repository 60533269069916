import PropTypes from 'prop-types';

// Styles
import './navbar-logo-container.scss';

// Hooks
import useNavbar from '../../hooks/use-navbar';

function NavbarLogoContainer({ children, className = '' }) {
  const { isOpen } = useNavbar();

  return (
    <div
      className={`navbar-logo-container ${className} ${isOpen ? 'open' : ''}`}
    >
      {children}
    </div>
  );
}

NavbarLogoContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default NavbarLogoContainer;
