import { makeRequest } from '../common/base';

/**
 * Get playlist items
 *
 * @param {string} playlistId The id of the playlist
 * @param {number} pageSize The number of elements in the page
 * @param {number} pageNumber The number of the page
 *
 * @returns {Promise<{}>} A promise containing a JSON data object with playlist content
 */
const fetchPlaylist = async (playlistId, pageSize, pageNumber) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  const params = {
    pageSize,
    pageNumber,
  };

  const urlSearchParams = new URLSearchParams({ ...params });
  return makeRequest(
    `/contentdiscovery/playlist/${playlistId}/items?${urlSearchParams.toString()}`,
    requestOptions
  );
};

export { fetchPlaylist };
