import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Hooks
import useGlobalContext from '../../hooks/use-global-context';

// Utils
import { replaceUrlParams } from '../../utils/url';
import { getTTSCancelationHTML } from '../../platform/index';
import logger from '../../utils/logger';

// Styles
import './sponsored-free-pass-image.scss';

function SponsoredFreePassImage({ slotParams = {}, className }) {
  const [htmlDoc, setHtmlDoc] = useState(getTTSCancelationHTML());
  const sponsoredPassUrl = useGlobalContext(
    (state) => state.adFreePass.sponsoredUrl
  );

  const playerParams = { ...slotParams, w: 204, h: 204 };
  const newUrl =
    sponsoredPassUrl && replaceUrlParams(sponsoredPassUrl, playerParams);

  const adUrl = newUrl || sponsoredPassUrl;

  function getResizeStyle() {
    return `<style type="text/css">
      body {zoom: ${window.innerWidth / 3840}; overflow: hidden;} 
      </style>`;
  }

  useEffect(() => {
    const getIframe = async () => {
      try {
        const response = await fetch(adUrl);
        let srcHtml = await response.text();

        srcHtml = srcHtml.replace('</body>', `${getResizeStyle()}</body>`);

        setHtmlDoc(srcHtml);
      } catch (err) {
        logger.debug('Sponsored Ad Not Loaded', err);
      }
    };

    getIframe();
  }, [adUrl]);

  return (
    <div
      className={classnames(
        'sponsored-free-pass-image',
        { visible: htmlDoc },
        className
      )}
    >
      {adUrl && (
        <iframe
          title="sponsored-free-pass"
          id="sponsored-free-pass"
          srcDoc={htmlDoc}
          onError={() => setHtmlDoc(null)}
          className="sponsored-free-pass-image__frame"
        />
      )}
    </div>
  );
}

SponsoredFreePassImage.propTypes = {
  slotParams: PropTypes.shape({
    caid: PropTypes.string,
    rating: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default SponsoredFreePassImage;
