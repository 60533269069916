const ROUTES = {
  DETAILS: '/details/:mediaId',
  ALL_EPISODES: '/all-episodes/:mediaId',
  HOME: '/home',
  MOVIES: '/movies',
  PINSETTINGS: '/pin-settings',
  SAMPLEPLAYER: '/sample-player',
  SEARCH: '/search',
  SPONSORED_COLLECTIONS: '/sponsored_collections/:collectionId',
  SETTINGS: '/settings',
  TVSHOWS: '/tv-shows',
  WATCH: '/watch/:mediaId',
  REDIRECT: '/redirect/:mediaId',
  WATCHLIST: '/watchlist',
  ACCOUNT: '/account',
  WELCOME: '/welcome',
  SIGN_IN_TIMEOUT: '/sign-in-timeout',
  LANDING: '/',
  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',
  GET_YOUR_WATCHLIST: '/get-your-watchlist',
  REWARDS: '/rewards',
  EXIT_SCREEN: '/exit-screen',
};

export default ROUTES;
