/* eslint-disable no-underscore-dangle */
import Content from './content';

class Season extends Content {
  constructor({ seasonNumber, ...contentParams }) {
    super(contentParams);

    this._seasonNumber = seasonNumber;
  }

  // getters and setters
  get seasonNumber() {
    return this._seasonNumber;
  }

  set seasonNumber(seasonNumber) {
    this._seasonNumber = seasonNumber;
  }
}

export default Season;
