import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../enums/routes';
import { removeSignedInUser } from '../utils/signed-in-user';

export default function useGlobalEventHandler() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const expiredUserSession = () => {
      // remove all storaged user data
      removeSignedInUser();
      // redirect to sign in page, saving the current location
      navigate(ROUTES.SIGN_IN, {
        state: {
          last: location.pathname,
        },
      });
    };
    window.addEventListener('expiredUserSession', expiredUserSession);

    return () => {
      window.removeEventListener('expiredUserSession', expiredUserSession);
    };
  }, [location, navigate]);
}
