import PRIVACY_OPTIONS from '../enums/ccpa';

function getGPPString(privacySettings = {}) {
  const { doNotSell = false, doNotShare = false } = privacySettings;

  const SIGNALS_MATRIX = [];
  SIGNALS_MATRIX[0] = [];
  SIGNALS_MATRIX[1] = [];

  SIGNALS_MATRIX[1][1] = 'V';
  SIGNALS_MATRIX[0][1] = 'I';
  SIGNALS_MATRIX[1][0] = 'Z';
  SIGNALS_MATRIX[0][0] = 'q';

  const SIGNALS = SIGNALS_MATRIX[Number(doNotSell)][Number(doNotShare)];

  const GPC = navigator?.globalPrivacyControl ? '.YA' : '';

  const gppTemplate = `DBABLA~BVV${SIGNALS}AAAAAWA${GPC}`;

  return gppTemplate;
}

/* 
This function uses the adParams provided by the device to determine the privacy options (doNotSale, doNotShare).
If the device does not set the CCPA option, we use the value set either by the API or the local storage.
If the device has a CCPA option defined, 
    If the user is signed in and the device is opted out, we need to opt out the user for both options.
    If the user is signed in but the device is not opted out, we use the values set in the API for the current user.  
    If the user is not signed in, we use the device opted out value for both options.
*/
function getPrivacyOptions(doNotSell, doNotShare, adParams, userId) {
  const privacy = { doNotSell, doNotShare };

  if (adParams.ccpa) {
    const doNotSellDevice =
      adParams.ccpa === PRIVACY_OPTIONS.NP_OO_NOOT ||
      adParams.ccpa === PRIVACY_OPTIONS.NP_OO ||
      adParams.ccpa === PRIVACY_OPTIONS.OO_NOOT;

    // if we have a user id and the device is opted out, we need to opt out the user for both options
    if (userId && doNotSellDevice) {
      privacy.doNotSell = true;
      privacy.doNotShare = true;
    }

    // if we don't have a user id we use the device opted out value for both options
    if (!userId) {
      privacy.doNotSell = doNotSellDevice;
      privacy.doNotShare = doNotSellDevice;
    }
  }

  return privacy;
}

export { getGPPString, getPrivacyOptions };
