import { useEffect, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import useGlobalContext from './use-global-context';
import NAVBAR_KEYS from '../enums/navbarKeys';

function useNavbar(openByDefault = false, keyNavbar = NAVBAR_KEYS.MAIN) {
  const { navbar, setNavbarStates } = useGlobalContext(
    (state) => ({
      navbar: state.ui.navbar,
      setNavbarStates: state.setNavbarStates,
    }),
    shallow
  );

  useEffect(() => {
    setValueNavbarType(openByDefault, keyNavbar);
  }, [openByDefault, keyNavbar]);

  const setValueNavbarType = (value, key) => {
    const navbarStatus = { [key]: { navbarIsOpen: value } };
    setNavbarStates(navbarStatus);
  };

  const navBarOpen = useMemo(
    () => ({
      isOpen: navbar[keyNavbar].navbarIsOpen,
      setIsOpen: setValueNavbarType,
    }),
    [navbar, setNavbarStates, keyNavbar]
  );

  return navBarOpen;
}

export default useNavbar;
