import APP_CONFIG from '../config/app-config.json';
import * as platformLg from './platform-lg';
import * as platformPlaystation from './platform-playstation';
import * as platformSamsung from './platform-samsung';
import * as plaformVidaa from './platform-vidaa';
import * as platformXbox from './platform-xbox';
import * as platformVizio from './platform-vizio';

const platforms = {
  lg: platformLg,
  playstation: platformPlaystation,
  samsung: platformSamsung,
  vidaa: plaformVidaa,
  vizio: platformVizio,
  xbox: platformXbox,
};

// Based on the platform, we will import the platform specific functions
const currentPlatform = platforms[APP_CONFIG.PLATFORM] || platforms['vizio'];

const getDeviceId = currentPlatform.getDeviceId;
const getDeviceModel = currentPlatform.getDeviceModel;
const initializeDevice = currentPlatform.initializeDevice;
const isTv = currentPlatform.isTv;
const isLowEndDevice = currentPlatform.isLowEndDevice;
const relaunchApplication = currentPlatform.relaunchApplication;
const setClosedCaptionCallback = currentPlatform.setClosedCaptionCallback;
const setClosedCaptionStyleCallback =
  currentPlatform.setClosedCaptionStyleCallback;
const getDeviceInformation = currentPlatform.getDeviceInformation;
const getNetworkInformation = currentPlatform.getNetworkInformation;
const exitApplication = currentPlatform.exitApplication;
const getPlatformDeviceName = currentPlatform.getPlatformDeviceName;
const getDeeplinkPath = currentPlatform.getDeeplinkPath;
const getDeviceOS = currentPlatform.getDeviceOS;
const getDeviceOsVersion = currentPlatform.getDeviceOsVersion;
const getDeviceMaker = currentPlatform.getDeviceMaker;
const tts = currentPlatform.tts;
const getTTSCancelationHTML = currentPlatform.getTTSCancelationHTML;
const KEYCODES = currentPlatform.KEYCODES;
const getDeviceConnectionType = currentPlatform.getDeviceConnectionType;
const getStreamSource = currentPlatform.getStreamSource;
const getAdvertisementIdentifier = currentPlatform.getAdvertisementIdentifier;
const getLimitAdTracking = currentPlatform.getLimitAdTracking;
const getPlatformSessionId = currentPlatform.getPlatformSessionId;
const getAdsType = currentPlatform.getAdsType;
const getPlayerConfig = currentPlatform.getPlayerConfig;
const getKeyHintIcon = currentPlatform.getKeyHintIcon;
const showKeyHints = currentPlatform.showKeyHints;
const getPlatformSpecificModules = currentPlatform.getPlatformSpecificModules;
const getAdParams = currentPlatform.getAdParams;
const supportCapability = currentPlatform.supportCapability;
const isAdInsertionModeSupported = currentPlatform.isAdInsertionModeSupported;

export {
  getDeviceId,
  getDeviceModel,
  initializeDevice,
  isTv,
  isLowEndDevice,
  relaunchApplication,
  setClosedCaptionCallback,
  setClosedCaptionStyleCallback,
  getDeviceInformation,
  getNetworkInformation,
  exitApplication,
  getPlatformDeviceName,
  getDeeplinkPath,
  getDeviceOS,
  getDeviceOsVersion,
  getDeviceMaker,
  tts,
  getTTSCancelationHTML,
  KEYCODES,
  getDeviceConnectionType,
  getStreamSource,
  getAdvertisementIdentifier,
  getLimitAdTracking,
  getPlatformSessionId,
  getAdsType,
  getPlayerConfig,
  getKeyHintIcon,
  showKeyHints,
  getPlatformSpecificModules,
  getAdParams,
  supportCapability,
  isAdInsertionModeSupported,
};
