import classnames from 'classnames';
import PropTypes from 'prop-types';

// Styles
import './keypad.scss';

function Keypad({ pinEntry, pointerIcon: PointerIcon, showPrompt }) {
  return (
    <div className="pin-modal__keypad">
      {[...Array(4).keys()].map((digit, index) => {
        const activeNumber = digit === pinEntry.length;
        return (
          <div
            className={classnames('pin-modal__digits', {
              active: !showPrompt && activeNumber,
            })}
            key={`blank${digit}`}
          >
            <PointerIcon />
            <input
              data-test-id={`pin-holder-${index}`}
              type="number"
              value={isNaN(pinEntry[digit]) ? '' : pinEntry[digit]}
              disabled
            />
          </div>
        );
      })}
    </div>
  );
}

Keypad.propTypes = {
  pinEntry: PropTypes.arrayOf(PropTypes.number),
  pointerIcon: PropTypes.elementType,
  showPrompt: PropTypes.bool,
};

export default Keypad;
