import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// enums
import PLATFORM_CAPABILITIES from '../enums/platform-capabilities';
import MODAL_TYPES from '../enums/modal-types';
import ROUTES from '../enums/routes';

// utils
import { supportCapability, KEYCODES } from '../platform/index';
import { isFeatureFlagEnabled } from '../utils/feature-flags';
import { isKey } from '../utils/utils';

// Hooks
import useModalHandler from './use-modal-handler';

export default function useExitButton() {
  const { getModalType, closeAllModals } = useModalHandler();
  const navigate = useNavigate();
  const location = useLocation();

  // Handles exit screen for apps that allow the use of exit button
  useEffect(() => {
    const onKeyDown = (event) => {
      const keysArray = [KEYCODES.EXIT];

      if (isFeatureFlagEnabled('testExitButton')) {
        keysArray.push(KEYCODES.NUMBER_1);
      }

      if (
        isKey(event.keyCode, keysArray) &&
        !location.pathname.includes(ROUTES.EXIT_SCREEN) &&
        getModalType() !== MODAL_TYPES.EXIT_APP // avoid double exit screen
      ) {
        closeAllModals();
        navigate(ROUTES.EXIT_SCREEN, {
          replace: true,
          // state is used to keep reference of the previous page
          state: `${location.pathname}${location.search}`,
        });
      }
    };

    if (supportCapability(PLATFORM_CAPABILITIES.EXIT_BUTTON)) {
      window.addEventListener('keydown', onKeyDown);
    }

    return () => {
      if (supportCapability(PLATFORM_CAPABILITIES.EXIT_BUTTON)) {
        window.removeEventListener('keydown', onKeyDown);
      }
    };
  }, [navigate, getModalType, closeAllModals, location]);
}
