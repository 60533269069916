import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { useState } from 'react';

// Components
import Navbar from '../navbar/navbar';
import NavbarItem from '../navbar-item/navbar-item';

// Styles
import './genre-submenu.scss';

import LoadingSpinner from '../loading-spinner/loading-spinner';
// Enums
import BROWSE from '../../enums/browse';
import GENRE_TYPES from '../../enums/genre-types';
import ROUTES from '../../enums/routes';
import NAVBAR_KEYS from '../../enums/navbarKeys';

// Configs
import { BACK_BUTTON_CONFIG } from '../../config/back-button-config';
import CHANNEL_TYPES from '../../enums/channel-types';

// Utils
import mParticle from '../../utils/mparticle';

const generateAllGenreButton = (pathname, search, hasSpecificGenre) => {
  const isMoviesPath = pathname === ROUTES.MOVIES;
  const isActive =
    !search.includes('genre') || search.includes(`genre=${BROWSE.ALL}`);
  return (
    <NavbarItem
      navbarKey={NAVBAR_KEYS.GENRE}
      aria-label={`${t`SUBMENU`} ${t`ALL`}`}
      id={`genre-all-${
        isMoviesPath ? CHANNEL_TYPES.MOVIES : CHANNEL_TYPES.SERIES
      }`}
      back={
        isMoviesPath
          ? BACK_BUTTON_CONFIG.SUBMENU_MOVIES
          : BACK_BUTTON_CONFIG.SUBMENU_SHOWS
      }
      className="app-submenu__item"
      key="0"
      to={`${pathname}?genre=${BROWSE.ALL}`}
      isActiveOverride={isActive}
      isFocusOnPageLoad={!hasSpecificGenre}
      selectionOverrides={{ up: '' }}
      onFocus={() => {
        // Focus on first element in genre list = scroll to very top, margin included
        const element = document.getElementsByClassName('app-submenu genre')[0];
        element.scrollTop = 0;
      }}
      onClick={() => {
        const type = isMoviesPath
          ? `${BROWSE.MOVIES} Menu`
          : `${BROWSE.TV_SHOWS} Menu`;

        const section = isMoviesPath ? BROWSE.ALL_MOVIES : BROWSE.ALL_TV_SHOWS;
        const sectionName = isMoviesPath ? BROWSE.MOVIES : BROWSE.TV_SHOWS;
        mParticle.mParticleMenuClick({
          categoryName: 'All',
          categoryPosition: type,
          pageSection: isActive ? section : `${sectionName}: Genre Dropdown`,
        });
      }}
    >
      All
    </NavbarItem>
  );
};
function GenreSubMenu({
  pathname,
  search,
  genres,
  isLoading,
  hasSpecificGenre,
}) {
  const isMoviesPath = pathname === ROUTES.MOVIES;
  return (
    <div className="content-subgenre-menu">
      {isLoading ? (
        <LoadingSpinner className="app-submenu__spinner" />
      ) : (
        <Navbar
          className="app-submenu genre"
          openByDefault
          openAlways
          openOnNoneFocused
          defaultElement={`#genre-all-${isMoviesPath ? 'movies' : 'tv'}`}
          navbarKey={NAVBAR_KEYS.GENRE}
        >
          {generateAllGenreButton(pathname, search, hasSpecificGenre)}
          {genres &&
            genres.map((genre, index) =>
              genre?.label ? (
                <GenreNavbarItem
                  key={genre?.id}
                  genre={genre}
                  index={index}
                  search={search}
                  pathname={pathname}
                  genresLength={genres?.length}
                />
              ) : null
            )}
        </Navbar>
      )}
    </div>
  );
}

GenreSubMenu.propTypes = {
  pathname: PropTypes.string,
  search: PropTypes.string,
  genres: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  hasSpecificGenre: PropTypes.bool,
};

export default GenreSubMenu;

function GenreNavbarItem({ genre, index, search, pathname, genresLength }) {
  const [firstFocus, setFirstFocus] = useState(false);
  const genreLabel = new URLSearchParams(search).get('genre');

  const { label, id, type, name } = genre;
  const isMoviesType = type === GENRE_TYPES.MOVIE;
  const isActive = genreLabel === label;
  const isAllActive =
    !search.includes('genre') || search.includes(`genre=${BROWSE.ALL}`);

  return (
    <NavbarItem
      navbarKey={NAVBAR_KEYS.GENRE}
      openByDefault
      aria-label={`${t`SUBMENU`} ${label}`}
      back={
        isMoviesType
          ? BACK_BUTTON_CONFIG.SUBMENU_MOVIES
          : BACK_BUTTON_CONFIG.SUBMENU_SHOWS
      }
      className="app-submenu__item"
      isActiveOverride={isActive}
      key={id}
      data-test-id={`navbar-item-${index}`}
      to={`${pathname}?${new URLSearchParams({
        genre: label,
      })}`}
      selectionOverrides={{
        down: index === genresLength - 1 ? '' : null,
        left: isMoviesType ? '.menu-movies' : '.menu-shows',
        right: firstFocus ? '.content-card-grid-1-0' : null,
      }}
      onClick={() => {
        setFirstFocus(true);
        const type = isMoviesType
          ? `${BROWSE.MOVIES} Menu`
          : `${BROWSE.TV_SHOWS} Menu`;
        const sectionName = isMoviesType ? BROWSE.MOVIES : BROWSE.TV_SHOWS;
        const section = isMoviesType ? BROWSE.ALL_MOVIES : BROWSE.ALL_TV_SHOWS;

        mParticle.mParticleMenuClick({
          categoryName: name,
          categoryPosition: type,
          pageSection: isAllActive ? section : `${sectionName}: Genre Dropdown`,
        });
      }}
      onBlur={() => {
        setFirstFocus(false);
      }}
    >
      {label}
    </NavbarItem>
  );
}

GenreNavbarItem.propTypes = {
  genre: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  index: PropTypes.number,
  search: PropTypes.string,
  pathname: PropTypes.string,
  genresLength: PropTypes.number,
};
