const MODAL_EXIT_STATUS = {
  WATCHLIST_ADD_SERIES_OVERLAY: 'WATCHLIST_ADD_SERIES_OVERLAY',
  WATCHLIST_CONTENT_OVERLAY: 'WATCHLIST_CONTENT_OVERLAY',
  WATCHLIST_CANCEL: 'WATCHLIST_CANCEL',
  WATCHLIST_REMOVE_ITEM: 'WATCHLIST_REMOVE_ITEM',
  WATCHLIST_EMPTY_STATE: 'WATCHLIST_EMPTY_STATE',
  GET_YOUR_WATCHLIST_EXIT: 'GET_YOUR_WATCHLIST_EXIT',
  WATCHLIST_SIGN_IN: 'WATCHLIST_SIGN_IN',
  PIN_MODAL_CLOSE: 'PIN_MODAL_CLOSE',
  REWARDS_ADS_NOTICE_EXIT: 'REWARDS_ADS_NOTICE_EXIT',
  REWARDS_ADS_NOTICE_CANCEL: 'REWARDS_ADS_NOTICE_CANCEL',
  REWARDS_ADS_NOTICE_CONTINUE: 'REWARDS_ADS_NOTICE_CONTINUE',
  REWARDS_ALREADY_OPT_IN: 'REWARDS_ALREADY_OPT_IN',
  REWARDS_AUTH_OVERLAY: 'REWARDS_AUTH_OVERLAY',
  AUTH_SIGNED_IN: 'AUTH_SIGNED_IN',
  AUTH_SIGNED_IN_OPT_IN: 'AUTH_SIGNED_IN_OPT_IN',
  AUTH_SIGNED_OUT: 'AUTH_SIGNED_OUT',
  AUTH_CREATE_ACCOUNT: 'AUTH_CREATE_ACCOUNT',
  REWARDS_REDEEM_CLOSE: 'REWARDS_REDEEM_CLOSE',
  SUCCESSFUL_REDEEM: 'SUCCESSFUL_REDEEM',
  GO_BACK_REDEMPTION_ERROR: 'GO_BACK_REDEMPTION_ERROR',
  SORT_EXIT: 'SORT_EXIT',
  TRY_AGAIN_REWARD: 'TRY_AGAIN_REWARD',
  VIDEO_EXPIRED_ERROR_GO_BACK: 'VIDEO_EXPIRED_ERROR_GO_BACK',
  VIDEO_EXPIRED_ERROR_TRY_AGAIN: 'VIDEO_EXPIRED_ERROR_TRY_AGAIN',
  ZOMBIE_MODE_CONTINUE: 'ZOMBIE_MODE_CONTINUE',
  ZOMBIE_MODE_EXIT: 'ZOMBIE_MODE_EXIT',
};

export default MODAL_EXIT_STATUS;
