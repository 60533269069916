import { makeRequest } from '../common/base';

const endpoints = {
  getRecomendationsForChannelTypeAndByContentId: (channelType, contentId) =>
    `/recommendations/${channelType}/content/${contentId}`,
};

const fetchRecommendations = async (channelType, contentId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(
    `${endpoints.getRecomendationsForChannelTypeAndByContentId(
      channelType,
      contentId
    )}`,
    requestOptions
  );
};

export { fetchRecommendations };
