import useGlobalContext from './use-global-context';

function useSearch() {
  const {
    savedInputValue,
    stripVisibility,
    setSavedInputValue,
    setStripVisibility,
  } = useGlobalContext((state) => ({
    savedInputValue: state.search.savedInputValue,
    stripVisibility: state.search.stripVisibility,
    setSavedInputValue: state.setSavedInputValue,
    setStripVisibility: state.setStripVisibility,
  }));

  return {
    savedInputValue,
    stripVisibility,
    setSavedInputValue,
    setStripVisibility,
  };
}

export default useSearch;
