const SEARCH_ELEMENTS = {
  BUTTON: {
    ABC: '.number-strip__abc-btn',
    KEYBOARD_KEY: '.number-strip__numbers',
    NUMERIC: '.letter-strip__numeric-btn',
    FIRST_SUGGESTION: '.search__results__suggestions > .suggestion',
    START_OVER: '.start-over-btn',
  },
  FLEX_GRID: {
    FIRST_ITEM: '.grid__search-layout > .content-card',
  },
  MESSAGES: {
    MIN_CHARS: 'minChars',
    NO_RESULTS: 'noResults',
  },
};

export default SEARCH_ELEMENTS;
