// config
import APP_CONFIG from '../config/app-config.json';
import CrackleVizbeeDeeplinkHandler from './CrackleVizbeeDeeplinkHandler';
import CrackleVizbeePlaybackHandler from './CrackleVizbeePlaybackHandler';
import VizbeeMediaConverter from './VizbeeMediaConverter';
import logger from '../utils/logger';

let isVzbInitialized = false;
const FUNC_LOG_PREFIX = 'VizbeeWrapper::';

function initializeVizbee() {
  if (!window.vizbee) {
    logger.warn(
      `${FUNC_LOG_PREFIX}initializeVizbee - function called without loading the SDK`
    );
    return;
  }

  if (!APP_CONFIG.VIZBEE_APP_ID) {
    logger.warn(
      `${FUNC_LOG_PREFIX}initializeVizbee - Vizbee AppId is not configured`
    );
    return;
  }

  const vzbInstance = window.vizbee.continuity.ContinuityContext.getInstance();
  vzbInstance.start(APP_CONFIG.VIZBEE_APP_ID);
  isVzbInitialized = true;

  // set deeplink handler
  setDeeplinkHandler();
}

function setDeeplinkHandler() {
  if (isVzbInitialized) {
    const vzbInstance =
      window.vizbee.continuity.ContinuityContext.getInstance();
    vzbInstance.getAppAdapter().setDeeplinkHandler((videoInfo) => {
      CrackleVizbeeDeeplinkHandler.onDeeplink(videoInfo);
    });
  } else {
    logger.warn(
      `${FUNC_LOG_PREFIX}setDeeplinkHandler - Vizbee not initialized yet`
    );
  }
}

function setPlayerWithVideoInfo(playerInstance, videoInfo) {
  if (!isVzbInitialized) {
    logger.warn(
      `${FUNC_LOG_PREFIX}setPlayerWithVideoInfo - Vizbee not initialized yet`
    );
    return;
  }

  if (!playerInstance) {
    logger.warn(
      `${FUNC_LOG_PREFIX}setPlayerWithVideoInfo - player instance is invalid`
    );
    return;
  }

  if (!videoInfo) {
    logger.warn(
      `${FUNC_LOG_PREFIX}setPlayerWithVideoInfo - videoInfo is invalid`
    );
    return;
  }

  logger.info(
    `${FUNC_LOG_PREFIX}setPlayerWithVideoInfo - videoInfo=${JSON.stringify(
      videoInfo
    )}`
  );

  // create vizbee player adapter
  const vzbPlayerAdapter =
    new window.vizbee.continuity.adapters.PlayerAdapter();

  // set player type and element
  vzbPlayerAdapter.setPlayerType(
    window.vizbee.continuity.adapters.PlayerType.BITMOVIN
  );
  vzbPlayerAdapter.setPlayerElement(playerInstance);

  // set event handlers
  const crackleVizbeePlaybackHandler = new CrackleVizbeePlaybackHandler(
    playerInstance
  );
  vzbPlayerAdapter.setPauseHandler(() => {
    CrackleVizbeePlaybackHandler.pauseVideo();
  });
  vzbPlayerAdapter.setPlayHandler(() => {
    CrackleVizbeePlaybackHandler.playVideo();
  });
  vzbPlayerAdapter.setSeekHandler((timeMs) => {
    crackleVizbeePlaybackHandler.seekVideo(timeMs);
  });
  vzbPlayerAdapter.setStopHandler((reason) => {
    crackleVizbeePlaybackHandler.stopVideo(reason);
  });
  vzbPlayerAdapter.getTracksHandler(() => {
    return crackleVizbeePlaybackHandler.getTracks();
  });
  vzbPlayerAdapter.getEnabledTracksHandler(() => {
    return crackleVizbeePlaybackHandler.getEnabledTracks();
  });
  vzbPlayerAdapter.setEnableTracksHandler((trackIds) => {
    crackleVizbeePlaybackHandler.enableTracks(trackIds);
  });
  vzbPlayerAdapter.setDisableTracksHandler((trackIds) => {
    crackleVizbeePlaybackHandler.disableTracks(trackIds);
  });

  // create vizbee videoInfo
  const vizbeeVideoInfo = VizbeeMediaConverter.getVizbeeVideoInfo(videoInfo);
  if (!vizbeeVideoInfo) {
    logger.warn(
      `${FUNC_LOG_PREFIX}setPlayerWithVideoInfo - vizbeeVideoInfo is invalid`
    );
    return;
  }

  // set vizbee player adapter with videoInfo
  const vzbInstance = window.vizbee.continuity.ContinuityContext.getInstance();
  vzbInstance.setPlayerAdapterWithVideoInfo(vzbPlayerAdapter, vizbeeVideoInfo);

  // set ad type
  let adType = window.vizbee.continuity.adapters.AdType.CSAI;
  if (videoInfo.adInsertionMode === 'SSAI') {
    adType = window.vizbee.continuity.adapters.AdType.SSAI;
  }
  vzbInstance.setAdType(adType);
}

function stopVideo() {
  if (isVzbInitialized) {
    // call SDKs interrupted event
    logger.error(`${FUNC_LOG_PREFIX}stopVideo - calling SDKs video stop`);

    const vzbInstance =
      window.vizbee.continuity.ContinuityContext.getInstance();
    vzbInstance.stopVideo();
  }
}

function isInitialized() {
  return isVzbInitialized;
}

const vizbeeWrapper = {
  initializeVizbee,
  setPlayerWithVideoInfo,
  stopVideo,
  isInitialized,
};

export default vizbeeWrapper;
